import React from 'react';

import { colors } from 'helpers/colors';

function SvgQuestionMarkCircledOutline({
  size = 30,
  color = colors.gray[700]
}) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M10 16.5625C8.25952 16.5625 6.59032 15.8711 5.35961 14.6404C4.1289 13.4097 3.4375 11.7405 3.4375 10C3.4375 8.25952 4.1289 6.59032 5.35961 5.35961C6.59032 4.1289 8.25952 3.4375 10 3.4375C11.7405 3.4375 13.4097 4.1289 14.6404 5.35961C15.8711 6.59032 16.5625 8.25952 16.5625 10C16.5625 11.7405 15.8711 13.4097 14.6404 14.6404C13.4097 15.8711 11.7405 16.5625 10 16.5625ZM10 17.5C11.9891 17.5 13.8968 16.7098 15.3033 15.3033C16.7098 13.8968 17.5 11.9891 17.5 10C17.5 8.01088 16.7098 6.10322 15.3033 4.6967C13.8968 3.29018 11.9891 2.5 10 2.5C8.01088 2.5 6.10322 3.29018 4.6967 4.6967C3.29018 6.10322 2.5 8.01088 2.5 10C2.5 11.9891 3.29018 13.8968 4.6967 15.3033C6.10322 16.7098 8.01088 17.5 10 17.5Z"
        fill={color}
      />
      <path
        d="M7.42659 7.92437C7.42531 7.95465 7.43023 7.98487 7.44106 8.01317C7.45188 8.04147 7.46839 8.06726 7.48955 8.08895C7.51071 8.11064 7.53609 8.12777 7.56411 8.13929C7.59214 8.15081 7.62223 8.15648 7.65253 8.15594H8.42597C8.55534 8.15594 8.65847 8.05 8.67534 7.92156C8.75972 7.30656 9.18159 6.85844 9.93347 6.85844C10.5766 6.85844 11.1653 7.18 11.1653 7.95344C11.1653 8.54875 10.8147 8.8225 10.2607 9.23875C9.62972 9.69719 9.13003 10.2325 9.16565 11.1016L9.16847 11.305C9.16945 11.3665 9.19458 11.4252 9.23842 11.4683C9.28227 11.5115 9.34132 11.5356 9.40284 11.5356H10.1632C10.2253 11.5356 10.2849 11.5109 10.3289 11.467C10.3728 11.423 10.3975 11.3634 10.3975 11.3012V11.2028C10.3975 10.5297 10.6535 10.3337 11.3444 9.80969C11.9153 9.37562 12.5107 8.89375 12.5107 7.88219C12.5107 6.46563 11.3144 5.78125 10.0047 5.78125C8.8169 5.78125 7.51565 6.33437 7.42659 7.92437ZM8.88628 13.3272C8.88628 13.8269 9.28472 14.1962 9.83315 14.1962C10.4041 14.1962 10.7969 13.8269 10.7969 13.3272C10.7969 12.8097 10.4032 12.4459 9.83222 12.4459C9.28472 12.4459 8.88628 12.8097 8.88628 13.3272Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgQuestionMarkCircledOutline;
