import React from 'react';

import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import styled from 'styled-components';
import Button from 'components/reusable/button';

const Wrapper = styled.div`
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: column;
`;

const SectionWrapper = styled.div`
  display: flex;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
  margin-bottom: 16px;
`;

const SectionCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const NumberWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 30px;
`;

const StyledButton = styled(Button)`
  flex: 1;
  display: flex;
  min-height: 40px;
`;

function HowItWorksModalContentMobile({ onClose, list }) {
  return (
    <Wrapper>
      <Indent top={24} bottom={24}>
        <Text textAlign="center" size={18} weight={500}>
          How it Works?
        </Text>
      </Indent>

      {list.map(({ label, description }, i) => (
        <SectionWrapper key={label}>
          <NumberWrapper>
            <Text size={56} lineHeight="1em" height={44} weight={400}>
              {i + 1}
            </Text>
          </NumberWrapper>
          <Indent right={16} />
          <SectionCol>
            <Text weight={500} size={14}>
              {label}
            </Text>
            <Text weight={400} size={10}>
              {description}
            </Text>
          </SectionCol>
        </SectionWrapper>
      ))}

      <StyledButton bgColor={colors.gray[300]} onClick={onClose}>
        Cancel
      </StyledButton>
      <Indent bottom={16} />
    </Wrapper>
  );
}

export default HowItWorksModalContentMobile;
