import ApplicationHelper from 'helpers/application_helper';

const $ = window.jQuery;

//v1
export const createUser = async user => {
  try {
    const result = await $.ajax({
      method: 'POST',
      url: '/user',
      data: JSON.stringify({
        user: {
          ...user,
          registration_source: ApplicationHelper.isMobile ? 'mr' : 'desktop'
        }
      }),
      dataType: 'JSON',
      contentType: 'application/json'
    });
    return { error: false, result: result.user };
  } catch (err) {
    const message = err.responseJSON
      ? err.responseJSON.message
      : 'Failed to create a user';
    return { error: true, message };
  }
};

export const finishSignUp = async user => {
  try {
    await $.ajax({
      method: 'POST',
      url: '/finish_signup',
      data: JSON.stringify({ user }),
      dataType: 'JSON',
      contentType: 'application/json'
    });
    return { error: false };
  } catch (err) {
    const message = err.responseJSON
      ? err.responseJSON.message
      : 'Something went wrong';
    return { error: true, message };
  }
};
