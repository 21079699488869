import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import SearchResults from './search_results';
import useClickOutside from '../../hooks/useClickOutside';

const Container = styled.div`
  position: relative;
  z-index: 35;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 240px;
`;

const SearchContainer = styled(Flex)`
  position: relative;
  z-index: 2;
  width: 100%;
  height: 48px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.48);
  border-radius: 30px;
  background-color: ${colors.light};
`;

const Input = styled.input`
  width: 186px;
  border: none;
  outline: none;
  font-family: 'Gotham Pro';
  font-weight: 400;
  font-size: 14px;
  ::placeholder {
    color: ${colors.gray[300]};
    font-size: 14px;
  }
`;

const HeaderSearch = ({ onChange, searchResult, cancelSearch, active }) => {
  const ref = useClickOutside(cancelSearch);
  return (
    <Container ref={ref}>
      <SearchContainer wrap="nowrap">
        <Input id="search-input" placeholder="Search" onChange={onChange} />
        <Indent right={6} />
        {svgIcons.circleSearch(colors.gray[500])}
      </SearchContainer>

      {active && (
        <SearchResults results={searchResult} cancelSearch={cancelSearch} />
      )}
    </Container>
  );
};

export default HeaderSearch;