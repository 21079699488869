import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import AuthHelper from 'helpers/auth_helper.js';
import InviteFriendPopup from 'components/modals/v1/invite_friend_popup';
import SCTrack from 'classes/sc_track';
import { LocalStorage } from 'classes/sc_localstorage';
import SharePortfolioModal from 'components/views/logged_in/menu/my_profile/share/share_portfolio_modal';
import { routes } from 'helpers/router_helper';
import HamburgerMenuItems from './hamburger_menu_items';
import ApplicationHelper from 'helpers/application_helper';
import HamburgerMenuHeader from 'components/reusable/legacy/header/hamburger/hamburger_menu_header';
import HamburgerMenuQr from 'components/reusable/legacy/header/hamburger/hamburger_menu_qr';
import SvgDollarCircledOutline from 'components/reusable/svg/svg_dollar_circled_outline';
import SvgCreditCardOutline from 'components/reusable/svg/svg_credit_card_outline';
import SvgHeartOutline from 'components/reusable/svg/svg_heart_outline';
import SvgShareOutline from 'components/reusable/svg/svg_share_outline';
import SvgChatOutline from 'components/reusable/svg/svg_chat_outline';
import SvgGearOutline from 'components/reusable/svg/svg_gear_outline';
import SvgWaitlistOutline from 'components/reusable/svg/svg_waitlist_outline';
import SvgQuestionMarkCircledOutline from 'components/reusable/svg/svg_question_mark_circled_outline';
import SvgCourtHouseSolid from 'components/reusable/svg/svg_icons_court_house_solid';
import SvgExitOutline from 'components/reusable/svg/svg_exit_outline';
import SvgLinkOutline from 'components/reusable/svg/svg_link_outline';
import Settings from 'helpers/settings';

const FlexBlock = styled.div`
  display: flex;
  padding: ${({ padding = 0 }) => padding};
  box-shadow: ${({ boxShadow = 'none' }) => boxShadow};
`;

export const Column = styled(FlexBlock)`
  flex-direction: column;
  padding: ${props => (props.isMobile ? 0 : '0 10px 10px 10px')};
`;

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
`;

class HamburgerMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invite_friend_active: false,
      is_share_modal_shown: false
    };
  }

  toggleShareModal = () => {
    this.setState({ is_share_modal_shown: !this.state.is_share_modal_shown });
  };

  render() {
    const currentUser = AuthHelper.currentUser();
    const isMobile = ApplicationHelper.isMobile;

    const mobileItems = [
      {
        label: 'Send Booking Offer',
        icon: <SvgDollarCircledOutline size={20} />,
        to: routes.newBookingOffer.redirectUrl(),
        new: true,
        condition: AuthHelper.canCreateOffers
      },
      {
        label: 'Waitlist',
        icon: <SvgWaitlistOutline size={20} />,
        to: routes.waitlist.redirectUrl(),
        condition: currentUser.waitlisted
      },
      {
        label: 'Social Media Booking Link',
        icon: <SvgLinkOutline size={20} />,
        to: routes.profileShare.redirectUrl(),
        new: true,
        condition: AuthHelper.bookable
      },
      {
        label: 'Payments and Payouts',
        icon: <SvgCreditCardOutline size={20} />,
        to: routes.paymentSettings.redirectUrl(),
        condition: true
      },
      // {
      //   label: 'Refer a Friend',
      //   icon: <SvgCourtHouseSolid size={20} />,
      //   onClick: () => this.setState({ invite_friend_active: true }),
      //   condition: true
      // },
      {
        label: 'How it Works',
        icon: <SvgQuestionMarkCircledOutline size={20} />,
        to: routes.faq.redirectUrl(),
        condition: true
      },
      {
        label: 'Help Center',
        icon: <SvgChatOutline size={20} />,
        to: routes.help.redirectUrl(),
        condition: true
      },
      {
        label: 'Settings',
        icon: <SvgGearOutline size={20} />,
        to: routes.settings.redirectUrl(),
        condition: true
      },
      {
        label: 'Logout',
        icon: <SvgExitOutline size={20} />,
        onClick: () => {
          localStorage.setItem(
            'active_mini_chat_window_user_ids',
            JSON.stringify([])
          );
          SCTrack.all('Logout', {
            profile_id: AuthHelper.logged_in_profileID
          });
          AuthHelper.logout();
          window.location = '/';
        },
        condition: true
      }
    ];

    const desktopItems = [
      {
        label: 'Send Booking Offer',
        icon: <SvgDollarCircledOutline size={20} />,
        to: routes.newBookingOffer.redirectUrl(),
        new: true,
        condition: AuthHelper.canCreateOffers
      },
      {
        label: 'Social Media Booking Link',
        icon: <SvgLinkOutline size={20} />,
        to: routes.profileShare.redirectUrl(),
        new: true,
        condition: AuthHelper.bookable
      },
      {
        label: 'Share Portfolio',
        icon: <SvgShareOutline size={20} />,
        onClick: this.toggleShareModal,
        condition: true
      },
      {
        label: 'My favorites',
        icon: <SvgHeartOutline size={20} />,
        to: routes.boards.redirectUrl(),
        onClick: this.props.onClose,
        condition: true
      },
      {
        label: 'Payments and Payouts',
        icon: <SvgCreditCardOutline size={20} />,
        to: routes.paymentSettings.redirectUrl(),
        onClick: this.props.onClose,
        condition: true
      },
      // {
      //   label: 'Refer a Friend',
      //   icon: <SvgCourtHouseSolid size={20} />,
      //   onClick: () => this.setState({ invite_friend_active: true }),
      //   condition: true
      // },
      {
        label: 'How it Works',
        icon: <SvgQuestionMarkCircledOutline size={20} />,
        onClick: this.props.onClose,
        to: routes.faq.redirectUrl(),
        condition: true
      },
      {
        label: 'Logout',
        icon: <SvgExitOutline size={20} />,
        onClick: () => {
          localStorage.setItem(
            'active_mini_chat_window_user_ids',
            JSON.stringify([])
          );
          SCTrack.all('Logout', {
            profile_id: AuthHelper.logged_in_profileID
          });
          AuthHelper.logout();
          window.location = '/';
        },
        condition: true
      }
    ];

    const closeInvitePopUp = () => {
      this.setState({
        invite_friend_active: false
      });
    };

    return (
      <ContentContainer>
        <HamburgerMenuHeader />
        <HamburgerMenuQr />

        <Column isMobile={isMobile}>
          <HamburgerMenuItems items={isMobile ? mobileItems : desktopItems} />
        </Column>

        {this.state.invite_friend_active && (
          <InviteFriendPopup hidePopup={closeInvitePopUp} />
        )}

        {this.state.is_share_modal_shown && (
          <SharePortfolioModal
            user={currentUser}
            isCurrentUser={true}
            onClose={this.toggleShareModal}
          />
        )}
      </ContentContainer>
    );
  }
}

export default withRouter(HamburgerMenu);
