const initialState = {
  data: [],
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'REVIEWS_FETCH_REQUESTED':
      return { ...state, loading: true };

    case 'REVIEWS_FETCH_SUCCEEDED':
      return { ...state, loading: false, data: action.response };

    case 'REVIEWS_FETCH_FAILED':
      return initialState;

    default:
      return state;
  }
};
