import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import MobileHeader from 'components/complex/mobile_header';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Flex from 'components/reusable/flex';

function HelpHeader() {
  const history = useHistory();

  return (
    <MobileHeader
      leftIcon={svgIcons.arrowBack(colors.gray[300])}
      onLeftIconClick={history.goBack}
    >
      <LogoWrapper>
        <Text size={15} color={colors.light}>
          S
        </Text>
      </LogoWrapper>

      <Indent right={8}></Indent>
      <Text size={16} weight={500}>
        HELP CENTER
      </Text>
    </MobileHeader>
  );
}

const LogoWrapper = styled(Flex)`
  width: 24px;
  height: 24px;
  background-color: ${colors.gray[700]};
  border-radius: 50%;
`;

export default HelpHeader;
