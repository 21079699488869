import { useMemo } from 'react';

import {
  useSharedFilesSelector,
  useSharedLinksSelector,
  useSharedPhotosSelector,
  useSharedVideosSelector
} from 'store/sagas/selectors';
import { fileTypesEnum } from 'components/modals/v2/chat_shared_modal/file_types_enum';

const useSharedFiles = (conversationId, activeTab) => {
  const sharedPhotosSlice = useSharedPhotosSelector();
  const sharedVideosSlice = useSharedVideosSelector();
  const sharedFilesSlice = useSharedFilesSelector();
  const sharedLinksSlice = useSharedLinksSelector();

  const data = useMemo(() => {
    let messages = [],
      meta = {};

    if (activeTab === fileTypesEnum.photos) [messages, meta] = [sharedPhotosSlice.list, sharedPhotosSlice.meta];
    if (activeTab === fileTypesEnum.videos) [messages, meta] = [sharedVideosSlice.list, sharedVideosSlice.meta];
    if (activeTab === fileTypesEnum.files) [messages, meta] = [sharedFilesSlice.list, sharedFilesSlice.meta];
    if (activeTab === fileTypesEnum.links) [messages, meta] = [sharedLinksSlice.list, sharedLinksSlice.meta];

    if (messages[0] && messages[0].conversation_id !== conversationId) {
      messages = [];
      meta = {};
    }

    return [messages, meta];
  }, [
    conversationId,
    activeTab,
    sharedPhotosSlice.list,
    sharedVideosSlice.list,
    sharedFilesSlice.list,
    sharedLinksSlice.list
  ]);

  return data;
};

export default useSharedFiles;
