import React, { memo } from 'react';
import styled from 'styled-components';

import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';

export const OrWrapper = styled.div`
  transform: translateY(-50%);
  width: fit-content;
  padding: 0 14px;
  background: ${colors.light};
  margin: 0 auto;
`;

export const RowDivider = styled.div`
  width: 100%;
  height: 1px;
  border-top: 1px solid ${colors.gray[100]};
`;

const SignUpOrSection = ({ label = 'or' }) => {
  return (
    <>
      <RowDivider />

      <OrWrapper>
        <Text textAlign="center" color={colors.gray[400]} size={12}>
          {label}
        </Text>
      </OrWrapper>
    </>
  );
};

export default memo(SignUpOrSection, () => true);
