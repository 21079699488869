import { useMemo } from 'react';

const getFileName = (src = '') =>
  src
    .split('.')
    .slice(-2)[0]
    .split('/')
    .slice(-1)[0];

const useImageAlt = ({ initialAlt, src }) => {
  return useMemo(() => {
    if (initialAlt) return initialAlt;
    const alt = getFileName(src);
    return alt;
  }, [initialAlt, src]);
};

export default useImageAlt;
