import { ProjectActionTypes } from 'helpers/constants/action_types/project';

const initialState = {
  data: null,
  isLoading: false,
  isLoaded: false,
  lastAction: ''
};

export const projectReducer = (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case ProjectActionTypes.FETCH:
      return { ...newState, isLoading: true, isLoaded: false };

    case ProjectActionTypes.FETCH_SUCCESS:
      return {
        ...newState,
        data: action.payload,
        isLoading: false,
        isLoaded: true
      };

    case ProjectActionTypes.FETCH_FAILURE:
      return {
        ...newState,
        data: initialState.data,
        isLoading: false,
        isLoaded: true
      };

    case ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID:
      return { ...newState, isLoading: true, isLoaded: false };

    case ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID_SUCCESS:
      return {
        ...newState,
        data: action.payload,
        isLoading: false,
        isLoaded: true
      };

    case ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID_FAILURE:
      return {
        ...newState,
        data: initialState.data,
        isLoading: false,
        isLoaded: true
      };

    case ProjectActionTypes.UPDATE:
      return { ...newState, isLoading: true };

    case ProjectActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case ProjectActionTypes.UPDATE_FAILURE:
      return { ...newState, isLoading: false };

    default:
      return state;
  }
};
