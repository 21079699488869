export const modelInfPartBreakpoints = () => [
  {
    min: 250,
    label: 'Top Campaign Models',
    message: 'Hourly projects for top campaign models.'
  },
  {
    min: 200,
    label: 'Top Editorial Models',
    message: 'Hourly projects for top editorial models.',
    hideOnScale: true
  },
  {
    min: 175,
    label: 'Editorial | Runway Modals',
    message: 'Hourly projects for editorial models.'
  },
  {
    min: 150,
    label: 'Editorial | Runway Models',
    message: 'Hourly projects for experienced models.'
  },
  {
    min: 125,
    label: 'Mid-Tier Talent',
    message: 'Hourly projects for experienced models.'
  },
  {
    min: 115,
    label: 'New Face talent',
    message: 'Small hourly project.'
  },
  {
    min: 100,
    label: 'New Face talent',
    message: 'Small hourly project.'
  }
];

export const stylistsMakeUpManicRetBreakpoints = ({ talentsType }) => [
  {
    min: 300,
    label: `Top campaign ${talentsType} on the platform`,
    message: 'Best for projects requiring complex artistry.'
  },
  {
    min: 250,
    label: `Top campaign ${talentsType}`,
    message: 'Best for projects requiring complex artistry.'
  },
  {
    min: 175,
    label: `Top ${talentsType}`,
    message: 'Best for projects with more than one model.'
  },
  {
    min: 150,
    label: `Most Popular ${talentsType}`,
    message: 'Top talent for executing quick projects.'
  },
  {
    min: 125,
    label: `Mid-tier ${talentsType}`,
    message: 'Great for executing quick projects.'
  },
  {
    min: 115,
    label: `Emerging ${talentsType}`,
    message: 'Raise rate to access top talent on platform.'
  },
  {
    min: 100,
    label: 'Junior talent',
    message: 'At this rate, your job may not be approved. Try raising the rate.'
  }
];
