import React from 'react';
import styled from 'styled-components';

import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import CopyLink from 'components/complex/copy_link';
import Button from 'components/reusable/button';
import SharePortfolioOptions from 'components/modals/v2/share_portfolio/components/share_portfolio_options';
import SharePortfolioLabels from 'components/modals/v2/share_portfolio/components/share_portfolio_labels';

function SharePortfolioModalMobile({
  libURL,
  showCopyHint,
  onClose,
  options,
  onCopyText,
  title
}) {
  return (
    <Wrapper dir="column" wrap="nowrap" ai="flex-start">
      <SharePortfolioLabels title={title} showCopyHint={showCopyHint} />
      <Indent bottom={24} />
      <SharePortfolioOptions options={options} />
      <Indent bottom={24} />
      <CopyLink url={libURL} onCopy={onCopyText} />
      <Indent bottom={24} />

      <Button
        height={40}
        color={colors.gray[700]}
        width="100%"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  max-width: 480px;
  width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

export default SharePortfolioModalMobile;
