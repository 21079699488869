import React, { useCallback } from 'react';

import AuthHelper from 'helpers/auth_helper';
import StringHelpers from 'helpers/string_helpers';

const useSubmit = ({ setError, email, password, rootContext, onClose }) => {
  const onLoginError = useCallback(
    err => {
      rootContext.setLoading(false);
      if (JSON.parse(err.responseText)) return setError(err.responseJSON.message);
      setError('We are having problems processing your request, please try again later.');
    },
    [rootContext.disableLoad, setError]
  );

  const onLoginSuccess = useCallback(() => {
    rootContext.setLoading(false);
    onClose();
    AuthHelper.performLoginRedirect();
  }, []);

  const onPerformLogin = useCallback(
    params => {
      rootContext.setLoading(true);
      setError('');
      params.client = 'desktop';
      AuthHelper.performLogin(params, onLoginSuccess, onLoginError);
    },
    [setError, rootContext.enableLoad]
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (!StringHelpers.validateEmail(email)) return setError('Please provide a valid email');
      onPerformLogin({ email, password });
    },
    [setError, onPerformLogin, email, password]
  );

  return handleSubmit;
};

export default useSubmit;
