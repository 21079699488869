import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment-es6';

import { inputStyles } from 'components/reusable/input/components/TextInput';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import SvgCalendarOutline from 'components/reusable/svg/svg_calendar_outline';
import { modalEnum } from 'components/modals/v2/modal';
import { ModalContext } from 'contexts/contexts';
import DateHelpers from 'helpers/date_helpers';

/**
 * @todo missing implementation for desktop
 * @param onChange
 * @param value
 * @param placeholder
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
function DatePicker({ onChange, value, placeholder, ...rest }) {
  const modalContext = useContext(ModalContext);

  const onClick = useCallback(() => {
    modalContext.onOpenModal(modalEnum.datePicker, {
      title: 'Shoot Date(s)',
      onChange,
      value
    });
  }, [onChange, value]);

  const timestamp = DateHelpers.getTimeStampFromDayCode(value);
  const date = DateHelpers.unixToDate(timestamp);

  return (
    <Wrapper type="button" onClick={onClick}>
      {placeholder && (!value || value.length === 0) ? (
        <Text color={colors.gray[300]}>{placeholder}</Text>
      ) : null}

      {value && value.length > 0 && (
        <Text color={colors.gray[700]}>
          {value.length > 1
            ? `${value.length} dates selected`
            : DateHelpers.timeStampToLabel(date, false, true, false, true)}
        </Text>
      )}

      <IconWrapper>
        <SvgCalendarOutline color={colors.light} size={20} />
      </IconWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.button`
  ${inputStyles};
  display: flex;
  background-color: transparent;
  align-items: center;
  padding-right: 48px;
  position: relative;
  cursor: pointer;
`;

export const IconWrapper = styled.div`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background-color: ${colors.gray[500]};
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

export default DatePicker;
