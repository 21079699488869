import React from 'react';

import Text from 'components/reusable/text';
import Flex from 'components/reusable/flex';

function ChatSharedEmpty({ messageType }) {
  return (
    <Flex dir="column" flex={1}>
      <Text size={16}>You haven't shared any {messageType}s</Text>
      <Text size={16}>with this user yet.</Text>
    </Flex>
  );
}

export default ChatSharedEmpty;
