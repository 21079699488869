import React from 'react';
import styled, { css } from 'styled-components';

import Image from 'components/reusable/image';
import AmPm from './am_pm';

const Wrap = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`;

const Icon = styled(Image)`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const PassIcon = styled(Image)`
  position: absolute;
  top: 12px;
  right: 16px;
`;

const InputComponent = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 8px 8px 16px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.24);
  ${({ error }) => error && 'box-shadow: 0px 0px 4px rgba(255, 59, 48, 0.8);'}
  border-radius: 30px;
  border: none;
  padding: 12px 16px;
  font-family: 'Gotham Pro';
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  ::placeholder {
    color: #d0cece;
    font-size: 1rem;
  }
  &:focus-visible {
    border: 1px solid #d9d9d9;
    ${({ error }) => !error && 'box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.8);'}
  }
  &:focus-visible {
    outline: none;
    border: none;
  }

  ${props =>
    props.outlined &&
    css`
      border: 1px solid #5e5a5a;
      border-radius: 30px;
      box-shadow: none;
    `}

  ${props =>
    props.placeholderCenter &&
    css`
      ::-webkit-input-placeholder {
        text-align: center;
      }

      :-moz-placeholder {
        text-align: center;
      }

      ::-moz-placeholder {
        text-align: center;
      }

      :-ms-input-placeholder {
        text-align: center;
      }
    `}
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${props =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid #d0cece;
      box-shadow: none;
      border-radius: 0;
    `}
  @media (max-width: 500px) {
    font-size: 1rem;
    ::placeholder {
      font-size: 1rem;
    }
  }
`;

const DefaultDateInputComponent = styled(InputComponent).attrs({
  type: 'date'
})`
  &::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
  }
`;

const renderInput = props => {
  if (props.type === 'input') return <InputComponent {...props} />;
  if (props.type === 'number') return <InputComponent {...props} />;
  if (props.type === 'custom-date')
    return (
      <Wrap>
        <InputComponent {...props} />
        <Icon
          onClick={props.imageClick}
          src="/images/icons/svg/date-icon.svg"
        />
      </Wrap>
    );
  if (props.type === 'date')
    return (
      <Wrap>
        <DefaultDateInputComponent {...props} />
        <Icon src="/images/icons/svg/date-icon.svg" />
      </Wrap>
    );
  if (props.type === 'time')
    return (
      <Wrap>
        <InputComponent {...props} />
        <AmPm value={props.value} onChange={props.onChange} />
      </Wrap>
    );
  if (props.type === 'password')
    return (
      <Wrap>
        <InputComponent {...props} id="password-input" />
        {props.isPassVisible ? (
          <PassIcon
            onClick={props.imageClick}
            src="/images/icons/svg/hide-eye.svg"
          />
        ) : (
          <PassIcon
            onClick={props.imageClick}
            src="/images/icons/svg/show-eye.svg"
          />
        )}
      </Wrap>
    );
};

const Input = ({ id, name, error = false, textAlign = 'center', ...props }) => {
  const componentProps = {
    name,
    id: id || name,
    error,
    textAlign,
    ...props
  };
  return renderInput(componentProps);
};

export default Input;
