import { useCallback, useEffect, useState } from 'react';

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

const useMobileDetect = () => {
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const handleWindowResize = useCallback(
    () => setWindowSize(getWindowSize()),
    []
  );
  const isMobile = windowSize.innerWidth < 501;

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return isMobile;
};

export default useMobileDetect;
