import React, { useCallback } from 'react';
import styled from 'styled-components';

import { localStorageEnum } from 'helpers/local_storage_helpers';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';

const Wrapper = styled(Flex)`
  width: 100%;
  padding: 24px 16px 16px;
  position: relative;
`;

const JobManagementFaqModal = ({ onClose }) => {
  const handleClose = useCallback(() => {
    localStorage.setItem(localStorageEnum.jobManagementFAQ, 'true');
    onClose();
  }, [onClose]);

  return (
    <Wrapper dir="column" wrap="nowrap">
      <Text size={18} weight={500}>
        How It Works
      </Text>
      <Indent bottom={24} />

      <img
        src="/images/screens/job-management-help-pic.png"
        alt="job-management-help"
      />
      <Indent bottom={24} />

      <Flex gap={8} jc="space-between" wrap="nowrap">
        {svgIcons.circleArrow2Right(colors.gray[300])}
        <Text size={14} textAlign="center">
          Swipe right to add an applicant to the shortlist or left to pass.
        </Text>
        {svgIcons.circleArrow2Left(colors.gray[300])}
      </Flex>
      <Indent bottom={24} />

      <Button type="primary" width="100%" height={40} onClick={handleClose}>
        Got It
      </Button>
    </Wrapper>
  );
};

export default JobManagementFaqModal;
