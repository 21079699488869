import { useCallback, useState } from 'react';
import get from 'lodash.get';

const usePlacementDefinition = wrapperRef => {
  const wrapperNode = get(wrapperRef, 'current');
  const [xOffset, setXOffset] = useState(0);

  const definePlacementToWrapper = useCallback((node, wrapperNode) => {
    const rect = node.getBoundingClientRect();
    const wrapperRect = wrapperNode.getBoundingClientRect();
    const leftOverlapped = rect.x < wrapperRect.x;

    if (leftOverlapped) {
      const xOffset = parseInt(10 + wrapperRect.x - rect.x);
      setXOffset(xOffset);
    }
  }, []);

  const definePlacementToWindow = useCallback(node => {
    const rect = node.getBoundingClientRect();
    const leftOverlapped = rect.x < 0;
    const rightX = rect.x + rect.width;
    const rightOverlapped = rightX > window.innerWidth;

    if (leftOverlapped) {
      const xOffset = parseInt(10 - rect.x);
      setXOffset(xOffset);
    } else if (rightOverlapped) {
      const xOffset = -parseInt(rightX - window.innerWidth + 10);
      setXOffset(xOffset);
    }
  }, []);

  const definePlacement = useCallback(node => {
    if (!node) return;
    if (wrapperNode) return definePlacementToWrapper(node, wrapperNode);
    return definePlacementToWindow(node);
  }, []);

  return { xOffset, definePlacement };
};

export default usePlacementDefinition;
