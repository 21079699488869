import { useCallback, useEffect } from 'react';

export const btnListenerEnum = {
  esc: 'Escape',
  arrL: 'ArrowLeft',
  arrR: 'ArrowRight'
};

const useBtnEscape = (btn, eventHandler) => {
  const handleEscapeKey = useCallback(
    e => {
      if (e.key === btn) eventHandler();
    },
    [eventHandler]
  );

  useEffect(() => {
    document.addEventListener('keyup', handleEscapeKey);
    return () => document.removeEventListener('keyup', handleEscapeKey);
  }, [eventHandler]);
};

export default useBtnEscape;
