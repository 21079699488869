import React from 'react';
import { Route, withRouter } from 'react-router-dom';

/**
 * @deprecated: Seems like it does that same thing. Not much sence in keeping an additional indermediary. Use v2 instead
 */
const GeneralRouteV1 = ({
  component: Component,
  render: renderFunction,
  ...rest
}) => {
  const handleRender = props => {
    if (Component) {
      return <Component {...props} />;
    } else if (renderFunction) {
      return renderFunction(props);
    }

    return <div />;
  };

  return (
    <Route
      {...rest}
      render={props => {
        return handleRender(props);
      }}
    />
  );
};

const GeneralRouteV2 = Route;

export default withRouter(GeneralRouteV2);
