import React from 'react';

import { colors } from 'helpers/colors';

function SvgCreditCardOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M1.66663 5.41668C1.66663 4.86414 1.88612 4.33424 2.27682 3.94354C2.66752 3.55284 3.19743 3.33334 3.74996 3.33334H16.25C16.8025 3.33334 17.3324 3.55284 17.7231 3.94354C18.1138 4.33424 18.3333 4.86414 18.3333 5.41668V13.75C18.3333 14.3025 18.1138 14.8324 17.7231 15.2231C17.3324 15.6138 16.8025 15.8333 16.25 15.8333H3.74996C3.19743 15.8333 2.66752 15.6138 2.27682 15.2231C1.88612 14.8324 1.66663 14.3025 1.66663 13.75V5.41668ZM3.74996 4.37501C3.47369 4.37501 3.20874 4.48476 3.01339 4.68011C2.81804 4.87546 2.70829 5.14041 2.70829 5.41668V6.45834H17.2916V5.41668C17.2916 5.14041 17.1819 4.87546 16.9865 4.68011C16.7912 4.48476 16.5262 4.37501 16.25 4.37501H3.74996ZM17.2916 8.54168H2.70829V13.75C2.70829 14.0263 2.81804 14.2912 3.01339 14.4866C3.20874 14.6819 3.47369 14.7917 3.74996 14.7917H16.25C16.5262 14.7917 16.7912 14.6819 16.9865 14.4866C17.1819 14.2912 17.2916 14.0263 17.2916 13.75V8.54168Z"
        fill={color}
      />
      <path
        d="M3.75 11.6667C3.75 11.3904 3.85975 11.1254 4.0551 10.9301C4.25045 10.7347 4.5154 10.625 4.79167 10.625H5.83333C6.1096 10.625 6.37455 10.7347 6.5699 10.9301C6.76525 11.1254 6.875 11.3904 6.875 11.6667V12.7083C6.875 12.9846 6.76525 13.2496 6.5699 13.4449C6.37455 13.6403 6.1096 13.75 5.83333 13.75H4.79167C4.5154 13.75 4.25045 13.6403 4.0551 13.4449C3.85975 13.2496 3.75 12.9846 3.75 12.7083V11.6667Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgCreditCardOutline;
