import React from 'react';

import { colors } from 'helpers/colors';

function SvgSaveOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.54238 12.2438C7.79513 12.4988 8.20509 12.4988 8.45783 12.2438L11.6747 8.99756C11.9272 8.74274 11.9272 8.32977 11.6747 8.07495C11.422 7.8199 11.012 7.8199 10.7593 8.07495L8.64722 10.2063V0.652592C8.64722 0.292569 8.35789 0 8.00011 0C7.64233 0 7.353 0.292569 7.353 0.652592V10.2063L5.24094 8.07495C4.9882 7.8199 4.57824 7.8199 4.3255 8.07495C4.07298 8.32977 4.07298 8.74274 4.3255 8.99756L7.54238 12.2438Z"
        fill={color}
      />
      <path
        d="M0 9.9275C0 9.56747 0.28933 9.27491 0.647112 9.27491C1.00489 9.27491 1.29422 9.56747 1.29422 9.9275V15.4924C1.29422 15.8115 1.41982 16.1173 1.64324 16.3428C1.86665 16.5682 2.16957 16.6948 2.48533 16.6948H13.5147C13.8304 16.6948 14.1334 16.5682 14.3568 16.3428C14.5802 16.1173 14.7058 15.8115 14.7058 15.4924V9.9275C14.7058 9.56747 14.9951 9.27491 15.3529 9.27491C15.7107 9.27491 16 9.56747 16 9.9275V15.4924C16 16.1574 15.7383 16.7951 15.2722 17.2654C14.8062 17.7357 14.174 18 13.5147 18H2.48533C1.82605 18 1.19385 17.7357 0.72779 17.2654C0.261746 16.7951 0 16.1574 0 15.4924V9.9275Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgSaveOutline;
