import React, { memo, useCallback, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import usePreventScrollBouncing from './hooks/use_prevent_scroll_bouncing';
import useScrollHandler from './hooks/use_scroll_handler';

const Wrapper = styled.div`
  overflow-anchor: none;
`;

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px 0;
  flex: 1;
  grid-column: 1 / -1; // span all columns
  ${({ isReverse }) =>
    isReverse
      ? css`
          position: absolute;
          right: 0;
          left: 0;
          top: 0;
        `
      : ''}
`;

/**
 * @description react-infinite-scroller has a bug with determining reverse scrolling position which caused screen bouncing. This component works without this issue however it is not completed yet
 * @param Loader
 * @param children items itself
 * @param hasMore boolean
 * @param loadMore func to append items
 // * @param initialLoad
 // * @param pageStart
 * @param isReverse
 */
function InfiniteScroll({ loader: Loader, children, className, hasMore, loadMore, isReverse, ...props }, overflowRef) {
  const wrapperRef = useRef(null);
  const prevScrollOffsetRef = useRef(null);
  const [loading, setLoading] = useState(false);

  const getParent = useCallback(() => {
    if (overflowRef) return overflowRef.current;
    if (wrapperRef.current) return wrapperRef.current.parentNode;
    return null;
  }, []);

  usePreventScrollBouncing({
    isReverse,
    children,
    getParent,
    prevScrollOffsetRef
  });

  useScrollHandler({
    isReverse,
    hasMore,
    getParent,
    setLoading,
    loading,
    prevScrollOffsetRef,
    loadMore
  });

  const buildLoader = () =>
    loading ? <LoadingWrapper isReverse={isReverse}>{Loader ? <Loader /> : null}</LoadingWrapper> : null;

  return (
    <Wrapper className={className} ref={wrapperRef} {...props}>
      {isReverse ? (
        <>
          {buildLoader()}
          {children}
        </>
      ) : (
        <>
          {children}
          {buildLoader()}
        </>
      )}
    </Wrapper>
  );
}

export default memo(React.forwardRef(InfiniteScroll));
