import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { colors } from 'helpers/colors';
import styled from 'styled-components';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import useAdaptiveDesign from 'hooks/use_adaptive_design';
import SvgThreeDotsSolid from 'components/reusable/svg/svg_three_dots_solid';
import Dropdown from 'components/reusable/dropdown';
import Text from 'components/reusable/text';

function ChatIconActions({ isSkeleton, actions }) {
  const Component = useAdaptiveDesign(DesktopChatSharedRowActions, MobileChatSharedRowActions);
  return <Component isSkeleton={isSkeleton} actions={actions} />;
}

const MobileChatSharedRowActions = ({ isSkeleton, actions }) => {
  const trigger = (
    <DotsButton>
      <SvgThreeDotsSolid size={18} color={colors.gray[300]} />
    </DotsButton>
  );

  if (isSkeleton) return <Skeleton height={24} width={24} />;
  if (actions.length === 0) return null;

  if (actions.length === 1) {
    const action = actions[0];
    return (
      <StyledButton type="semantic-wrapper" onClick={action.onClick}>
        <action.Icon size={18} color={colors.gray[300]} />
      </StyledButton>
    );
  }

  return (
    <Dropdown trigger={trigger} overlayDirection="top-left">
      {actions.map((action, i) => {
        if (!action.condition) return null;

        return (
          <DropdownButton key={i} type="semantic-wrapper" onClick={action.onClick}>
            <Text size={14}>{action.label}</Text>
            <action.Icon size={18} color={colors.gray[300]} />
          </DropdownButton>
        );
      })}
    </Dropdown>
  );
};

const DesktopChatSharedRowActions = ({ isSkeleton, actions }) => (
  <Flex dir="row">
    {actions.map((action, i) => {
      if (isSkeleton && !action.skeletonCondition) return null;
      if (isSkeleton) return <Skeleton key={i} height={24} width={24} />;
      if (!action.condition) return null;

      return (
        <StyledButton key={i} type="semantic-wrapper" onClick={action.onClick}>
          <action.Icon size={18} color={colors.gray[300]} />
        </StyledButton>
      );
    })}
  </Flex>
);

export const DropdownButton = styled.div`
  border-radius: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  min-width: 160px;
`;

const StyledButton = styled(Button)`
  padding: 2px 4px;
`;

export const DotsButton = styled.div`
  padding: 2px 4px;
  margin-right: -8px;
  transform: rotate(90deg);
`;

export default ChatIconActions;
