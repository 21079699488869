import React from 'react';

import { AbContext } from 'contexts/contexts';

export const guestVariations = {
  control: 'Control',
  a: 'Guest posting flow'
};

export const variations = {
  lp: {
    control: 'CONTROL',
    a: 'AUG2023'
  }
};

const withAbProvider = Component => props => {
  const abProps = {
    guestVariation: guestVariations.a,
    lpVariation: variations.lp.a
  };

  return (
    <AbContext.Provider value={abProps}>
      <Component {...props} />
    </AbContext.Provider>
  );
};

export default withAbProvider;
