import React, { useCallback, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import withInputWrapper from '../hocs/withInputWrapper';
import { getDimention } from 'helpers/style';
import Button from 'components/reusable/button';
import svgIcons from 'helpers/svgIcons';

export const inputStyles = css`
  position: relative;
  height: ${({ height }) => getDimention(height) || '48px'};
  width: ${({ width }) => getDimention(width) || '100%'};
  font-family: Gotham Pro;
  letter-spacing: 0.5px;
  padding: 0 20px;
  outline: none;
  border-radius: 30px;
  border: none;
  font-size: 1rem;
  line-height: 140%;
  font-weight: 400;
  color: ${colors.gray[700]};
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.24);

  ::placeholder {
    color: ${colors.gray[400]};
  }
  &[type='time']::-webkit-calendar-picker-indicator {
    background: none;
  }

  @media (max-width: 768px) {
    border-radius: 24px;
    padding: 0 16px;
    font-size: 1rem;
    letter-spacing: 0;
    outline: none;
    border: none;
    -webkit-appearance: none;
    box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.24);
  }

  // TODO disable this
  ${props => props.css}
`;

const InputComponent = styled.input`
  ${inputStyles}
`;

const StyledButton = styled(Button)`
  position: absolute;
  right: 18;
  top: 50%;
  transform: translateY(-50%);
`;

const Input = ({
  className,
  value,
  onChange,
  name,
  width,
  id,
  height,
  placeholder,
  type: initialType,
  children,
  ...props
}) => {
  const isPasswordField = initialType === 'password';
  const [type, setType] = useState(initialType);

  const handleChange = useCallback(
    e => {
      let v = e.target.value;
      if (onChange) onChange(v, e);
    },
    [onChange]
  );

  const togglePassword = useCallback(() => {
    if (type === 'password') return setType('text');
    setType('password');
  }, [type]);

  const inputComponent = (
    <InputComponent
      type={type}
      name={name}
      id={id || name}
      className={className}
      height={height}
      placeholder={placeholder}
      width={width}
      onChange={handleChange}
      value={value}
      {...props}
    />
  );

  if (isPasswordField)
    return (
      <>
        {inputComponent}
        <StyledButton onClick={togglePassword} type="semantic-wrapper">
          {svgIcons.eye(colors.gray[300], { width: 18, height: 12 })}
        </StyledButton>
      </>
    );

  return inputComponent;
};

export default withInputWrapper(Input);
