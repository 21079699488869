import React, { memo } from 'react';
import styled from 'styled-components';

import LoadingComponent from 'components/reusable/loading/LoadingComponent';

const LoadingScreen = ({ loading, shaded, loading_msg }) => {
  return (
    <Container $loading={loading} $shaded={shaded}>
      <LoadingComponent loading_msg={loading_msg} />
    </Container>
  );

  // return (
  //   <Container $loading={this.props.loading} $shaded={this.props.shaded}>
  //     <div className="LoadingNotification is-anim is-visible">
  //       <div className="LoadingNotification-anim">
  //         <svg className="circular">
  //           <circle
  //             className="path"
  //             cx="80"
  //             cy="80"
  //             r="20"
  //             fill="none"
  //             strokeWidth="1"
  //             strokeMiterlimit="10"
  //           />
  //           <p
  //             style={{
  //               color: '#e64b41',
  //               fontSize: '1.375rem',
  //               position: 'relative',
  //               top: '-100px',
  //               fontFamily: 'Avenir-Roman'
  //             }}
  //           >
  //             S
  //           </p>
  //         </svg>
  //       </div>
  //     </div>
  //   </Container>
  // );
};

const Container = styled.div`
  z-index: 3000;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background-color: ${props => (props.$shaded ? 'rgba(0,0,0,0.15)' : 'transparent')};
  transition: opacity 0.3s ease-out;
  opacity: ${props => (props.$loading ? 1 : 0)};
  pointer-events: ${props => !props.$loading && 'none'};
`;

export default memo(LoadingScreen);
