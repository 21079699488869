import { useCallback } from 'react';

import { useModalContext } from 'hooks/use_context';
import { modalEnum } from 'components/modals/v2/modal';
import AuthHelper from 'helpers/auth_helper';

const useSharedFileOpen = (messages, handleDeleteSharedMsg, onClose) => {
  const modalContext = useModalContext();

  const onDestroy = useCallback(
    (messageID, messageType) => () => {
      const result = handleDeleteSharedMsg(messageID, messageType);
      if (!result) return false;
      if (onClose) onClose();
      alert('The file has been successfully deleted');
    },
    [handleDeleteSharedMsg, onClose]
  );

  const handleOpenAlbum = useCallback(
    (message, messageType) => {
      if (!message || !messageType) return false;
      const initialIndex = messages.findIndex(m => m.id === message.id);
      const isMine = message.swipecast_user_id === AuthHelper.logged_in_profileID;
      const commonModalProps = {
        initialIndex,
        cursorBehaviour: false,
        onDestroy: isMine ? onDestroy(message.id, messageType) : null
      };

      if (messageType === 'image') {
        const images = messages.map(m => ({ src: m.message }));
        const label = 'Photo';
        modalContext.onOpenModal(modalEnum.albumPreview, { ...commonModalProps, assets: images, label });
      } else if (messageType === 'video') {
        const videos = messages.map(m => ({ video_preview: m.video_preview, video_url: m.video_url }));
        const label = 'Video';
        modalContext.onOpenModal(modalEnum.albumPreview, { ...commonModalProps, assets: videos, label });
      } else {
        console.log('Not implemented');
      }
    },
    [messages]
  );

  return handleOpenAlbum;
};

export default useSharedFileOpen;
