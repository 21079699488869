export const findDuplicates = arr => {
  let sorted_arr = arr.slice().sort(); // You can define the comparing function here.
  // JS by default uses a crappy string compare.
  // (we use slice to clone the array so the
  // original array won't be modified)
  let results = [];
  for (let i = 0; i < sorted_arr.length - 1; i++) {
    if (sorted_arr[i + 1] == sorted_arr[i]) {
      results.push(sorted_arr[i]);
    }
  }
  return results;
};

export const isObject = obj =>
  obj === Object(obj) &&
  !Array.isArray(obj) &&
  typeof obj !== 'function' &&
  obj !== null;

export const isLowerCase = string =>
  string === string.toLowerCase() && string !== string.toUpperCase();

export const snakeize = string =>
  string
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();

export const camelize = string =>
  string.replace(/([-_][a-z])/gi, newString =>
    newString
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

export const sample = (array, quantity = 1) => {
  const uniqArray = [...new Set(array)];
  const shuffled = uniqArray.sort(() => 0.5 - Math.random());
  return shuffled.slice(0, quantity);
};

export default function underscore(object, snake = true) {
  const convert = snake ? snakeize : camelize;

  if (isObject(object)) {
    const temp = {};

    Object.keys(object).forEach(key => {
      temp[convert(key)] = underscore(object[key], snake);
    });

    return temp;
  } else if (Array.isArray(object)) {
    return object.map(i => underscore(i, snake));
  }

  return object;
}
