import React, { useCallback } from 'react';

import TextInput from 'components/reusable/input/components/TextInput';
import FormatHelpers from 'helpers/format_helpers';

function MoneyInput({ value, onChange, placeholder, className }) {
  const handleChange = useCallback(
    v => {
      const numericValue = parseInt(v.replace(/[^0-9]/g, ''), 10);
      if (v === '$') {
        onChange(0);
      } else if (!isNaN(numericValue)) {
        onChange(numericValue);
      }
    },
    [onChange]
  );

  return (
    <TextInput
      className={className}
      value={value ? `$${FormatHelpers.numberWithCommas(value)}` : ''}
      onChange={handleChange}
      placeholder={placeholder}
    />
  );
}

export default MoneyInput;
