import { useCallback, useState } from 'react';

const useWidth = () => {
  const [width, setWidth] = useState(null);
  const setNodeWidth = useCallback(node => {
    if (node !== null) {
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  return { setNodeWidth, width };
};

export default useWidth;
