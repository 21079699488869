import React, { useCallback } from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';

const Wrapper = styled(Flex)`
  max-width: 480px;
  width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

const InfoModal = ({ modalProps = {}, onClose }) => {
  const handleClose = useCallback(() => {
    if (typeof modalProps.callback === 'function') modalProps.callback();
    onClose();
  }, [modalProps, onClose]);

  return (
    <Wrapper dir="column" wrap="nowrap">
      <Text size={24} weight={500}>
        {modalProps.title || 'Sample Title'}
      </Text>
      <Indent bottom={24} />

      <Text size={14} textAlign="center">
        {modalProps.text || 'Sample text'}
      </Text>
      <Indent bottom={40} />

      <Button type="primary" height={40} width={160} onClick={handleClose}>
        OK
      </Button>
    </Wrapper>
  );
};

export default InfoModal;
