import React from 'react';
import { colors } from 'helpers/colors';

function SvgCrossMarkSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.60054 2.60054C2.7346 2.46649 2.95196 2.46649 3.08601 2.60054L6 5.51453L8.91399 2.60054C9.04804 2.46649 9.2654 2.46649 9.39946 2.60054C9.53351 2.7346 9.53351 2.95196 9.39946 3.08601L6.48547 6L9.39946 8.91398C9.53351 9.04804 9.53351 9.2654 9.39946 9.39946C9.2654 9.53351 9.04804 9.53351 8.91399 9.39946L6 6.48547L3.08601 9.39946C2.95196 9.53351 2.7346 9.53351 2.60054 9.39946C2.46649 9.2654 2.46649 9.04804 2.60054 8.91398L5.51453 6L2.60054 3.08601C2.46649 2.95196 2.46649 2.7346 2.60054 2.60054Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgCrossMarkSolid;
