import React from 'react';
import styled from 'styled-components';

import Text from 'components/reusable/text';
import Link from 'components/reusable/link';
import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';

function HelpContactUsItem({ href, icon, label, linkLabel }) {
  return (
    <StyledLink href={href}>
      {icon}
      <Text size={12}>{label}</Text>
      <Flex flex={1} />
      <Text color={colors.gray[700]} size={12} weight={500}>
        {linkLabel}
      </Text>
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  padding: 24px 16px;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  box-shadow: 0px 6px 12px 0px rgba(31, 30, 30, 0.06),
    0px 0px 8px 0px rgba(31, 30, 30, 0.04);
  border-radius: 8px;
`;

export default HelpContactUsItem;
