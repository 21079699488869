import { useCallback } from 'react';

const useSharedFilesPagination = ({ activeTab, handleFetchMessages, meta }) => {
  const nextPage = (meta?.page || 1) + 1;
  const hasMoreFiles = meta?.pages && meta?.page ? meta.pages > meta.page : false;

  const handleAppendFiles = useCallback(async () => {
    await handleFetchMessages(activeTab, true, nextPage);
  }, [activeTab, handleFetchMessages, nextPage]);

  return {
    handleAppendFiles,
    hasMoreFiles
  };
};

export default useSharedFilesPagination;
