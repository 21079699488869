export const JobActionTypes = {
  FETCH: '@@job/FETCH',
  FETCH_FAILURE: '@@job/FETCH_FAILURE',
  FETCH_SUCCESS: '@@job/FETCH_SUCCESS',

  FETCH_BY_SECRET_ALIAS_ID: '@@job/FETCH_BY_SECRET_ALIAS_ID',
  FETCH_BY_SECRET_ALIAS_ID_FAILURE: '@@job/FETCH_BY_SECRET_ALIAS_ID_FAILURE',
  FETCH_BY_SECRET_ALIAS_ID_SUCCESS: '@@job/FETCH_BY_SECRET_ALIAS_ID_SUCCESS',

  UPDATE: '@@job/UPDATE',
  UPDATE_FAILURE: '@@job/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@job/UPDATE_SUCCESS',

  TOGGLE_TALENT_SELECTION: '@@job/TOGGLE_TALENT_SELECTION',
  TOGGLE_TALENT_SELECTION_FAILURE: '@@job/TOGGLE_TALENT_SELECTION_FAILURE',
  TOGGLE_TALENT_SELECTION_SUCCESS: '@@job/TOGGLE_TALENT_SELECTION_SUCCESS',

  CANCEL: '@@job/CANCEL',
  CANCEL_FAILURE: '@@job/CANCEL_FAILURE',
  CANCEL_SUCCESS: '@@job/CANCEL_SUCCESS',

  UPDATE_APPLICATION: '@@job/UPDATE_APPLICATION',
  UPDATE_APPLICATION_FAILURE: '@@job/UPDATE_APPLICATION_FAILURE',
  UPDATE_APPLICATION_SUCCESS: '@@job/UPDATE_APPLICATION_SUCCESS',

  SELECT: '@@job/SELECT',
  SELECT_FAILURE: '@@job/SELECT_FAILURE',
  SELECT_SUCCESS: '@@job/SELECT_SUCCESS',

  DESELECT: '@@job/DESELECT',
  DESELECT_FAILURE: '@@job/DESELECT_FAILURE',
  DESELECT_SUCCESS: '@@job/DESELECT_SUCCESS',

  RETRY_PAYMENT: '@@job/RETRY_PAYMENT',
  RETRY_PAYMENT_FAILURE: '@@job/RETRY_PAYMENT_FAILURE',
  RETRY_PAYMENT_SUCCESS: '@@job/RETRY_PAYMENT_SUCCESS'
};
