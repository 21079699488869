import React from 'react';
import styled from 'styled-components';

import LoadingIndicator from 'components/reusable/loading/LoadingIndicator';
import { colors } from 'helpers/colors';

/**
 * @desc This differs from LoadingScreen in the fact that this component is meant
 * to be called by react lazy, not directly. While LoadingScreen can be
 * called directly.
 * @param className
 * @progress string - brief message up to 10 symbols
 * @returns {JSX.Element}
 * @constructor
 */
function LoadingComponent({ className, loading_msg }) {
  return (
    <V2Wrapper className={className}>
      <LoadingIndicator />
      <StyledProgress $loading_msg={loading_msg}>{loading_msg}</StyledProgress>
    </V2Wrapper>
  );
}

const detectFontSize = ({ $loading_msg }) => {
  if (!$loading_msg) return '0px';
  if ($loading_msg.length > 8) return '0.8rem';
  return '1rem';
};

const StyledProgress = styled.span`
  font-weight: 600;
  font-size: ${detectFontSize};
  color: ${colors.primary[500]};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: ${({ $loading_msg }) => ($loading_msg ? 'inline' : 'none')};
`;

const V2Wrapper = styled.div`
  z-index: 3000;
  // position: fixed;
  height: 100%;
  width: 100%;
  inset: 0;
  background-color: rgba(0, 0, 0, 0);
  transition: opacity 0.3s ease-out;
  opacity: 1;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    // loadable routes not centering the loader
    position: fixed;
    inset: 0;
  }
`;

export default LoadingComponent;
