import React from 'react';

import HelpContactUsTemplate from 'components/views/help/help_v3/help_contact_us/help_contact_us_template';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Settings from 'helpers/settings'

function HelpMessageUs() {
  const items = [
    {
      label: 'Andrea',
      href: `mailto:${Settings.support.email}`,
      linkLabel: Settings.support.email,
      icon: svgIcons.questionAnswer(colors.gray[700], { width: 20 })
    },
    {
      label: 'Text us',
      href: `sms://${Settings.support.whatsappNumber}`,
      linkLabel: Settings.support.whatsappLabel,
      icon: svgIcons.whatsApp({ color: colors.whatsapp, width: 20 })
    }
  ];

  return <HelpContactUsTemplate items={items} label="Message Us" />;
}

export default HelpMessageUs;
