import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import photosActions from 'store/resources/sharedPhotosV2/actions';
import videosActions from 'store/resources/sharedVideosV2/actions';
import filesActions from 'store/resources/sharedFilesV2/actions';
import linksActions from 'store/resources/sharedLinksV2/actions';
import { fileTypesEnum } from 'components/modals/v2/chat_shared_modal/file_types_enum';

const useGetSharedFilesCount = conversationId => {
  const dispatch = useDispatch();

  const handleFetchCount = useCallback(
    async activeTab => {
      if (activeTab !== fileTypesEnum.photos)
        await dispatch(
          photosActions.resourceFetchRequest(
            `/v2/conversations/${conversationId}/messages?per_page=1&page=1&message_type=image`
          )
        );
      if (activeTab !== fileTypesEnum.videos)
        await dispatch(
          videosActions.resourceFetchRequest(
            `/v2/conversations/${conversationId}/messages?per_page=1&page=1&message_type=video`
          )
        );
      if (activeTab !== fileTypesEnum.files)
        await dispatch(
          filesActions.resourceFetchRequest(
            `/v2/conversations/${conversationId}/messages?per_page=1&page=1&message_type=file`
          )
        );
      if (activeTab !== fileTypesEnum.links)
        await dispatch(
          linksActions.resourceFetchRequest(
            `/v2/conversations/${conversationId}/messages?per_page=1&page=1&message_type=link`
          )
        );
    },
    [conversationId]
  );

  return handleFetchCount;
};

export default useGetSharedFilesCount;
