import React, { useState, useEffect, useMemo } from 'react';
import { reduxForm } from 'redux-form';

import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';
import { validate } from './helpers/validate';
import useSubmit from './hooks/use_submit';
import SignUpLogoContent from 'components/modals/v2/auth/sign_up_logo_modal/components/sign_up_logo_content';
import useLabel from 'components/modals/v2/auth/sign_up_logo_modal/hooks/use_label';

const accept = '.png, .jpg, .jpeg';
const SignUpLogo = ({ onClose, handleSubmit, ...props }) => {
  const [error, setError] = useState('');
  const isSubmitDisabled = props.invalid || props.submitting || props.pristine;
  const onSubmit = useSubmit({ onClose, setError });
  const label = useLabel();

  useEffect(() => {
    props.reset();
  }, []);

  const componentProps = {
    onSubmit: handleSubmit(onSubmit),
    isSubmitDisabled,
    accept,
    label,
    error
  };

  return <SignUpLogoContent {...componentProps} />;
};

export default reduxForm({
  form: signUpStepsData.image.form,
  validate,
  initialValues: {
    [signUpStepsData.imagePostpone.field]: false
  }
})(SignUpLogo);