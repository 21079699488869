import { useCallback } from 'react';

import FormatHelpers from 'helpers/format_helpers';

// TODO try to eliminate duplication around components which use this hook
const useChangeSliderRate = ({ onChangeRate, max, breakpointsArray }) => {
  const onChangeSliderRate = useCallback(
    v => {
      const roundTo = max >= 500 ? 50 : 5;
      let value = FormatHelpers.round(v, roundTo);
      const deviation = 0.005 * max;
      const stickToGridValue = breakpointsArray.find(bp => {
        return value > bp - deviation && value < bp + deviation;
      });

      if (stickToGridValue) value = stickToGridValue;
      onChangeRate(value);
    },
    [onChangeRate, max, breakpointsArray]
  );

  return onChangeSliderRate;
};

export default useChangeSliderRate;
