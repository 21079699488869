import { DirectUpload } from '@rails/activestorage';

import Settings from 'helpers/settings';
import AuthHelper from 'helpers/auth_helper';

class SpaceUploader {
  constructor(file) {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthHelper.currentAuthToken()}`,
      credentials: 'include'
    };

    // https://edgeguides.rubyonrails.org/active_storage_overview.html#direct-uploads
    this.upload = new DirectUpload(
      file,
      `${Settings.SWIPECAST_BACKEND}/rails/active_storage/direct_uploads`,
      this,
      headers
    );
  }

  uploadFile() {
    this.onStartProcessing();

    this.upload.create((error, blob) => {
      if (error) {
        this.onError(error);
      } else {
        this.onSuccess(blob);
      }
    });
  }

  onStartProcessing(e) {
    console.log('onStartProcessing - Not implemented ');
  }

  onSuccess(e) {
    console.log('onSuccess - Not implemented ');
  }

  onError(e) {
    console.log('onError - Not implemented');
  }

  onProgress(progress, event) {
    console.log('onProgress - Not implemented');
  }

  // binds directUploadDidProgress event handler. is called by DirectUpload class
  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', e => {
      const progress = Math.round((e.loaded / e.total) * 100);
      this.onProgress(progress, e);
    });
  }
}

export default SpaceUploader;
