import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Image from 'components/reusable/image';
import useSendMetric from '../hooks/use_send_metric';
import Settings from 'helpers/settings';

const Container = styled(Flex)`
  width: 100%;
  padding: 64px 0;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;

const DownloadAppBlock = ({ isMobile, page }) => {
  const onDownloadClick = useSendMetric({ page: page, label: 'Download App' });

  return (
    <Container
      dir={isMobile ? 'column' : 'row'}
      jc="space-between"
      ai="flex-start"
    >
      <Flex dir="column" ai="flex-start">
        <Text
          size={isMobile ? 32 : 40}
          letterSpacing={isMobile ? 10 : 20}
          textTransform="uppercase"
          color={colors.light}
        >
          Swipecast
        </Text>
        <Indent bottom={isMobile ? 24 : 32} />
        <Text size={20} color={colors.light}>
          Discover Talent. Meet Creatives. Book Projects.
        </Text>
      </Flex>
      <Indent bottom={isMobile && 40} />

      <a href={Settings.appURL} onClick={onDownloadClick}>
        <Image
          src="/images/footer_download_apple.png"
          width={isMobile ? 194 : 216}
          height={isMobile ? 72 : 80}
        />
      </a>
    </Container>
  );
};

export default DownloadAppBlock;
