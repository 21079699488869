import React from 'react';

import { colors } from 'helpers/colors';

function SvgPhoneOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.11118 1.4943C4.04477 1.40887 3.96094 1.33855 3.86526 1.288C3.76959 1.23746 3.66425 1.20786 3.55625 1.20115C3.44825 1.19445 3.34006 1.2108 3.23887 1.24912C3.13768 1.28745 3.0458 1.34686 2.96933 1.42342L1.80611 2.58777C1.26275 3.13226 1.0625 3.90286 1.29987 4.57897C2.28506 7.37744 3.88764 9.9182 5.98876 12.0128C8.08337 14.1139 10.6241 15.7165 13.4226 16.7017C14.0987 16.939 14.8693 16.7388 15.4138 16.1954L16.577 15.0322C16.6536 14.9557 16.713 14.8639 16.7513 14.7627C16.7896 14.6615 16.806 14.5533 16.7993 14.4453C16.7926 14.3373 16.763 14.232 16.7124 14.1363C16.6619 14.0406 16.5916 13.9568 16.5061 13.8904L13.9108 11.8722C13.8195 11.8014 13.7134 11.7522 13.6004 11.7285C13.4873 11.7047 13.3704 11.7069 13.2583 11.7349L10.7946 12.3503C10.4658 12.4325 10.1213 12.4281 9.79459 12.3376C9.46792 12.2471 9.17025 12.0736 8.93056 11.8339L6.16763 9.06986C5.92775 8.83028 5.75401 8.53267 5.66332 8.20599C5.57263 7.87932 5.56809 7.53473 5.65014 7.20578L6.26663 4.74209C6.29465 4.63004 6.29685 4.51309 6.27308 4.40006C6.2493 4.28703 6.20017 4.18088 6.12938 4.08961L4.11118 1.4943ZM2.11998 0.575193C2.31684 0.378266 2.55335 0.225473 2.81379 0.12696C3.07424 0.0284473 3.35266 -0.0135314 3.63057 0.00381145C3.90848 0.0211543 4.17952 0.097422 4.4257 0.22755C4.67188 0.357678 4.88755 0.538689 5.05841 0.758563L7.07661 3.35275C7.44672 3.82861 7.57722 4.44847 7.43097 5.03346L6.81561 7.49715C6.7838 7.62475 6.78552 7.75842 6.8206 7.88516C6.85569 8.01191 6.92295 8.12743 7.01586 8.22051L9.77991 10.9846C9.8731 11.0777 9.98881 11.145 10.1158 11.1801C10.2427 11.2152 10.3766 11.2168 10.5044 11.1848L12.967 10.5694C13.2556 10.4973 13.557 10.4917 13.8481 10.5531C14.1393 10.6144 14.4127 10.7412 14.6477 10.9238L17.2419 12.942C18.1745 13.6676 18.26 15.0457 17.4252 15.8793L16.262 17.0425C15.4295 17.875 14.1853 18.2406 13.0255 17.8323C10.0569 16.7878 7.36153 15.0883 5.1394 12.8599C2.91116 10.6381 1.21168 7.94315 0.167026 4.97496C-0.240214 3.81624 0.125402 2.57089 0.957881 1.73841L2.1211 0.575193H2.11998Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgPhoneOutline;
