import loadImage from 'blueimp-load-image';
import ImageHelpers from 'helpers/image_helpers';
import { initializeAlbums } from 'components/views/logged_out/SignUp/Helpers';
import AuthHelper from 'helpers/auth_helper';

const $ = window.jQuery;

const ImageUploadHelpers = {
  uploadImageFromGoogle: async google_token_id => {
    const oauthResponse = await fetch(
      `https://www.googleapis.com/oauth2/v3/tokeninfo?id_token=${google_token_id}`
    );
    if (oauthResponse.ok) {
      const oauthJson = await oauthResponse.json();
      if (oauthJson.picture) {
        const img = oauthJson.picture;
        const base64 = await ImageHelpers.urlToBase64(img);
        if (base64) {
          const images = [{ image: base64 }];
          const result = await initializeAlbums(images);
          if (result !== 'success') console.log(result);
          else await AuthHelper.loadFromBackend();
        }
      }
    }
  },

  generateCropInfoDictFromCroppie: function(
    croppie_data,
    image_width,
    image_height
  ) {
    let crop = {
      topLeftX: parseInt(croppie_data.points[0]),
      topLeftY: parseInt(croppie_data.points[1]),
      bottomRightX: parseInt(croppie_data.points[2]),
      bottomRightY: parseInt(croppie_data.points[3]),
      width: image_width,
      height: image_height
    };
    crop['box_width'] = crop['bottomRightX'] - crop['topLeftX'];
    crop['box_height'] = crop['bottomRightY'] - crop['topLeftY'];
    crop['boxWidth'] =
      ((crop['bottomRightX'] - crop['topLeftX']) * 100) / crop['width'];
    crop['boxHeight'] =
      ((crop['bottomRightY'] - crop['topLeftY']) * 100) / crop['height'];
    crop['offsetTop'] =
      (crop['topLeftY'] * 100) / (crop['bottomRightY'] - crop['topLeftY']);
    crop['offsetLeft'] =
      (crop['topLeftX'] * 100) / (crop['bottomRightX'] - crop['topLeftX']);
    return crop;
  },

  fetchImage: function(url) {
    let myimage = new Image();
    return (myimage.src = url);
  },

  generateUncroppedFromInputFile: function(input_file, onDoneLoading) {
    let image, reader;
    let uploaded = $.Deferred();
    if (input_file.type.match(/image.*/)) {
      reader = new FileReader();
      image = new Image();
      reader.onload = function(e) {
        image.onload = function() {
          let options = {
            canvas: true,
            maxHeight: 2000
          };
          return loadImage.parseMetaData(input_file, data => {
            if (data.exif) {
              options.orientation = data.exif.get('Orientation');
              return loadImage(
                input_file,
                canvas => {
                  uploaded.resolve();
                  return (this.canvasifiedImage = onDoneLoading(
                    canvas.toDataURL()
                  ));
                },
                options
              );
            } else {
              uploaded.resolve();
              return onDoneLoading(e.target.result);
            }
          });
        };
        return (image.src = reader.result);
      };
      return reader.readAsDataURL(input_file);
    }
  },

  dataURItoBlob: function(dataURI) {
    'use strict';
    let byteString, content, i, mimestring;
    byteString = void 0;
    mimestring = void 0;
    if (dataURI.split(',')[0].indexOf('base64') !== -1) {
      byteString = atob(dataURI.split(',')[1]);
    } else {
      byteString = decodeURI(dataURI.split(',')[1]);
    }
    mimestring = dataURI
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];
    content = new Array();
    i = 0;
    while (i < byteString.length) {
      content[i] = byteString.charCodeAt(i);
      i++;
    }
    return new Blob([new Uint8Array(content)], {
      type: mimestring
    });
  },

  getUploadURL: function(type = 'fullSize') {
    let url = $.Deferred();
    $.ajax({
      method: 'POST',
      url:
        type === 'fullSize' ? '/image/uploadImage' : '/image/updateThumbnail',
      dataType: 'JSON',
      success: data => {
        return url.resolve(data);
      },
      error: data => {
        return url.resolve(data);
      }
    });
    return url;
  }
};

export default ImageUploadHelpers;
