import React from 'react';
import styled from 'styled-components';

import Error from 'components/reusable/error';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';

const Wrapper = styled.div`
  position: relative;
  width: inherit;
`;

const ErrorWrapper = styled.div`
  margin-left: 20px;
  margin-top: 2px;
`;

const withInputWrapper = Component => props => {
  const { label, showError, error, children } = props;

  return (
    <>
      {label && (
        <Indent bottom={8}>
          <Text size={12}>{label}</Text>
        </Indent>
      )}
      <Wrapper>
        <Component {...props} />
        {children}
        {showError && (
          <ErrorWrapper>
            <Error>{error}</Error>
          </ErrorWrapper>
        )}
      </Wrapper>
    </>
  );
};

export default withInputWrapper;
