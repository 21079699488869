import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (min-width: 769px) {
    height: calc(100% - 80px);
  }
`;
