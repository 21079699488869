import React from 'react';
import styled from 'styled-components';

import ShareOfferLinks from 'components/modals/v2/share_offer_modal/components/share_offer_links';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Divider from 'components/reusable/divider';
import Indent from 'components/reusable/indent';
import SvgSuitcaseSheetOutline from 'components/reusable/svg/svg_suitcase_sheet_outline';
import LocationHelpers from 'helpers/location_helpers';
import useOfferDatesLabel from 'components/views/logged_in/booking_offers/booking_offer_view/hooks/use_offer_dates_label';

function ShareOfferDesktop({ offer, title, links, onClose }) {
  const locationLabel = LocationHelpers.getLocationLabel(offer);
  const datesLabel = useOfferDatesLabel(offer);

  return (
    <Wrapper>
      <Flex dir="row" ai="flex-start" wrap="no-wrap">
        <LeftSectionWrapper>
          <Text size={24} weight={500}>
            {title}
          </Text>

          <Indent top={24} />
          <Divider />

          <Indent top={20} />
          <Flex dir="row" gap={24} ai="flex-start" jc="flex-start">
            <SvgSuitcaseSheetOutline size={64} color={colors.primary[500]} />
            <div>
              <Text weight={500} size={18} lines={1}>
                SPECIAL BOOKING OFFER
              </Text>
              <Indent top={8} />
              <Text size={12}>{locationLabel}</Text>
              <Indent top={4} />
              <Text size={12}>{datesLabel}</Text>
            </div>
          </Flex>
        </LeftSectionWrapper>

        <RightSectionWrapper>
          <ShareOfferLinks links={links} />
        </RightSectionWrapper>
      </Flex>

      <Indent top={40} />
      <Flex>
        <Button type="gray-shadow" width={160} height={40} onClick={onClose}>
          Cancel
        </Button>
      </Flex>
    </Wrapper>
  );
}

const LeftSectionWrapper = styled.div`
  width: 60%;
  border-right: 1px solid ${colors.gray[100]};
  height: 100%;
  min-height: 100%;
`;

const RightSectionWrapper = styled.div`
  width: 40%;
  margin-left: 40px;
`;

const Wrapper = styled.div`
  width: 752px;
  padding: 56px 40px 40px;
`;

export default ShareOfferDesktop;
