/* eslint-disable max-len */
import React from 'react';

import { colors } from 'helpers/colors';

function SvgLinkOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9154 7.45232C11.4152 7.69097 11.8505 8.04611 12.1847 8.48788C12.5188 8.92965 12.7421 9.44517 12.8357 9.99112C12.9293 10.5371 12.8905 11.0975 12.7226 11.6254C12.5547 12.1532 12.2626 12.6331 11.8708 13.0246L8.40924 16.4861C7.76007 17.1353 6.87961 17.5 5.96155 17.5C5.04349 17.5 4.16303 17.1353 3.51386 16.4861C2.8647 15.837 2.5 14.9565 2.5 14.0385C2.5 13.1204 2.8647 12.2399 3.51386 11.5908L4.8654 10.2392M15.1346 9.76077L16.4861 8.40924C17.1353 7.76007 17.5 6.87961 17.5 5.96155C17.5 5.04349 17.1353 4.16303 16.4861 3.51386C15.837 2.8647 14.9565 2.5 14.0385 2.5C13.1204 2.5 12.2399 2.8647 11.5908 3.51386L8.12924 6.97539C7.73742 7.36693 7.44529 7.84678 7.27738 8.37464C7.10947 8.90249 7.07069 9.46293 7.16429 10.0089C7.2579 10.5548 7.48115 11.0703 7.81531 11.5121C8.14946 11.9539 8.58475 12.309 9.08462 12.5477"
        stroke={color}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgLinkOutline;
