import React from 'react';
import { Link as LinkComponent } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import { _getFontSize } from 'components/reusable/text';

const Link = ({
  activeColor = colors.primary[500],
  target,
  weight = 500,
  blank,
  to,
  size,
  href,
  onClick,
  color = colors.primary[500],
  textTransform,
  ...props
}) => {
  const handledTarget = blank ? '_blank' : target;

  const commonProps = {
    $activeColor: activeColor,
    $textTransform: textTransform,
    $color: color,
    $weight: weight,
    size,
    onClick, // react dom links, a tags and spans can have its own click handlers
    ...props
  };

  if (to) return <StyledReactDomLink target={handledTarget} to={to} {...commonProps} />;
  if (href) return <StyledLink target={handledTarget} href={href} {...commonProps} />;
  return <StyledBtn {...commonProps} />;
};

const styles = css`
  ${_getFontSize};
  text-transform: ${({ $textTransform }) => $textTransform};
  display: inline;
  font-family: 'Gotham Pro', sans-serif;
  color: ${({ $color }) => $color};
  font-weight: ${({ $weight }) => $weight};
  transition: all 0.3s;
  ${({ onClick, to, href }) => (href || onClick || to ? 'cursor: pointer;' : null)}

  &:hover,
  &.active {
    color: ${({ $activeColor }) => $activeColor};
  }
`;

const StyledReactDomLink = styled(LinkComponent)`
  ${styles}
`;

const StyledLink = styled.a`
  ${styles}
`;

const StyledBtn = styled.span`
  ${styles}
`;

export default Link;
