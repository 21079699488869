import React from 'react';

import { DragWrapper } from 'components/reusable/input/components/ImageInput/image_input_v2/components/cropper_preview';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import Flex from 'components/reusable/flex';
import InputComponent from 'components/reusable/input/components/ImageInput/image_input_v2/components/input_component';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  .cr-slider {
    background: ${colors.gray[300]};
    border: none;
    border-radius: 2px;
  }
`;

const ImageInputEmptyPreview = ({ handleChange, accept }) => {
  return (
    <Wrapper>
      <DragWrapper>
        {svgIcons.pics(colors.gray[200])}
        <Indent top={18} />
        <Text color={colors.gray[400]} size={16}>
          Drag your photo here
        </Text>

        <Flex>
          <Text color={colors.gray[400]} size={16}>
            or{' '}
          </Text>
          <Indent right={4} />
          <Text size={16} weight={500} color={colors.primary[500]}>
            click for browse
          </Text>
        </Flex>

        <Indent top={8} />
        <Text size={12} color={colors.gray[400]}>
          jpg, png
        </Text>
      </DragWrapper>

      <InputComponent onChange={handleChange} type="file" accept={accept} />
    </Wrapper>
  );
};

export default ImageInputEmptyPreview;
