import AuthHelper from 'helpers/auth_helper.js';
import Settings from 'helpers/settings';
import SpaceUploader from 'classes/sc_uploader';

const $ = window.jQuery;

const GeneralHelpers = {
  isAdmin() {
    return AuthHelper.is_admin;
  },
  // this function is used to subdivide an array into an array of
  // subarrays of length `size`. The function adds null elements to
  // the last subarray to ensure it also has length size
  chunks(array, size = 1) {
    const clonedArray = [...array];
    const results = [];
    while (clonedArray.length) {
      const subArray = clonedArray.splice(0, size);
      for (let i = 0; i < subArray.length % size; i += 1) {
        subArray.push(null);
      }
      results.push(subArray);
    }
    return results;
  },

  uploadFile(file) {
    return new Promise((resolve, reject) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('api_key', 354895638198347);
      formData.append('upload_preset', 'qlinr93m');
      $.ajax({
        method: 'POST',
        url: 'https://swipecast.net/v1_1/imagen/image/upload',
        data: formData,
        cache: false,
        contentType: false,
        processData: false,
        beforeSend() {}
      })
        .done(data => {
          resolve(data);
        })
        .fail(err => {
          reject(err);
        });
    });
  },
  changeFavicon(newIcon) {
    const link = document.querySelector("link[rel*='icon']");
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = newIcon;
  }
};

export default GeneralHelpers;
