import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import { Wrapper, CloseBtn } from './styled';
import Input from 'components/reusable/input/components/TextInput';
import useSearch from './hooks/use_search';
import usePredictions from './hooks/use_predictions';
import Predictions from './components/predictions';
import withInputWrapper from '../../hocs/withInputWrapper';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import useMounted from 'components/reusable/input/components/LocationInput/hooks/use_mounted';

const LocationInput = ({
  onFocus,
  clerable = false,
  resetOnEditing = false,
  absolutePredictions,
  placeholder,
  onChange,
  value,
  error,
  css,
  className
}) => {
  const isMounted = useMounted();
  const parentInputValue = useMemo(() => (value ? value.input_address : null), [
    value
  ]);

  const [predictions, changePredictions] = useState([]);
  const [isPredictionsShown, changePredictionsShown] = useState(false);
  const [searchInputValue, changeSearchInput] = useState(parentInputValue);
  const onClear = useCallback(() => onChange(null), [onChange]);
  const mapRef = useRef(null);

  const { onSearch } = useSearch({
    changePredictionsShown,
    changePredictions,
    changeSearchInput
  });

  const { onPredictionClick } = usePredictions({
    mapRef,
    changeSearchInput,
    changePredictionsShown,
    onChange
  });

  useLayoutEffect(() => {
    const edited = parentInputValue !== searchInputValue;
    if (resetOnEditing && isMounted && edited) onChange(null);
  }, [searchInputValue]);

  useLayoutEffect(() => {
    if (searchInputValue !== parentInputValue)
      changeSearchInput(parentInputValue);
  }, [parentInputValue]);

  return (
    <Wrapper>
      <div className="map" ref={mapRef} />

      <Input
        onFocus={onFocus}
        style={{ zIndex: 3 }}
        error={error}
        placeholder={placeholder || 'Enter City'}
        value={searchInputValue || ''}
        className={className}
        onChange={onSearch}
        css={css}
      />

      {clerable && (
        <CloseBtn type={'semantic-wrapper'} onClick={onClear}>
          {svgIcons.close(colors.gray[300], { width: 20, height: 20 })}
        </CloseBtn>
      )}

      <Predictions
        absolutePredictions={absolutePredictions}
        predictions={predictions}
        isShown={isPredictionsShown}
        onClick={onPredictionClick}
      />
    </Wrapper>
  );
};

export default withInputWrapper(LocationInput);
