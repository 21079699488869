import AuthHelper from 'helpers/auth_helper';
import Settings from 'helpers/settings';

const $ = window.jQuery;

export const createSession = async ({
  google_token_id,
  instagram_auth_code,
  instagram_token,
  redirect_uri
}) => {
  /*
   * IMPORTANT: Calling AuthHelper.logout() and using the absolute URL in
   * the login URL are mandatory here.
   * That's a double security measure to ensure that the token is not
   * sent to the backend when trying to login (assuming there's any in LS)
   * See: InitHelper.ajaxInit() for more info
   */
  AuthHelper.logout();

  try {
    const data = await $.ajax({
      method: 'POST',
      url: `${Settings.SWIPECAST_BACKEND}/login`,
      dataType: 'JSON',
      data: {
        instagram_auth_code,
        instagram_token,
        redirect_uri,
        google_token_id,
        client: 'desktop'
      }
    });

    // window.dataLayer = window.dataLayer || [];
    // window.dataLayer.push({
    //   event: 'signup',
    //   userId: data.user.swipecast_user_id,
    //   userType:
    //     data.user.intent_role === 'talent' && data.user.profiles.length > 0
    //       ? data.user.profiles[0].profile_type
    //       : 'Customer'
    // });
    AuthHelper.processLoggedInData(data);
    return { error: false };
  } catch (err) {
    const message = err.responseJSON
      ? err.responseJSON.message
      : 'Failed to login a user';
    return { error: true, message };
  }
};
