import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileLoadingOutline({ size = 30, color = colors.gray[700], secondaryColor = colors.gray[300] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43987 29.8582 10.2598C30.612 12.0796 31 14.0302 31 16C31 17.9698 30.612 19.9204 29.8582 21.7403C29.1044 23.5601 27.9995 25.2137 26.6066 26.6066C25.2137 27.9995 23.5601 29.1044 21.7402 29.8582C19.9204 30.612 17.9698 31 16 31C14.0302 31 12.0796 30.612 10.2597 29.8582C8.43986 29.1044 6.78627 27.9995 5.39339 26.6066C4.00052 25.2137 2.89562 23.5601 2.1418 21.7402C1.38798 19.9204 0.999999 17.9698 1 16C1 14.0302 1.38799 12.0796 2.14181 10.2597C2.89563 8.43986 4.00052 6.78627 5.3934 5.39339C6.78628 4.00051 8.43987 2.89562 10.2598 2.1418C12.0796 1.38798 14.0302 0.999999 16 1L16 1Z"
        stroke={secondaryColor}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 1C17.9698 1 19.9204 1.38799 21.7403 2.14181C23.5601 2.89563 25.2137 4.00052 26.6066 5.3934C27.9995 6.78628 29.1044 8.43987 29.8582 10.2598C30.612 12.0796 31 14.0302 31 16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileLoadingOutline;
