import { useCallback } from 'react';

const useErrorHandler = onFailure => {
  const handleError = useCallback(
    googleOauth => {
      try {
        if (googleOauth.error === 'idpiframe_initialization_failed')
          return console.log(googleOauth.details);
        const error = googleOauth.error.replace(/_/g, ' ');
        const error_message = `${error.charAt(0).toUpperCase()}${error.slice(
          1
        )}`;
        onFailure(error_message);
      } catch {
        onFailure('Service is unavailable');
      }
    },
    [onFailure]
  );

  return handleError;
};

export default useErrorHandler;
