import AuthHelper from 'helpers/auth_helper';
import Settings from 'helpers/settings';
import UserHelpers from 'helpers/user_helpers';
import SCTrack from 'classes/sc_track';
import ImageHelpers from 'helpers/image_helpers';
import { createSession } from 'helpers/api/session';
import { createUser } from 'helpers/api/user';
import ApplicationHelper from 'helpers/application_helper';
import { LocalStorage } from 'classes/sc_localstorage';

const $ = window.jQuery;

function initializeAlbums(images) {
  const urlPromises = images.map(
    img =>
      new Promise((resolve, reject) =>
        ImageHelpers.uploadImageSimple(img.image)
          .then(res => {
            const r = {
              type: 'image',
              url: res.secure_url,
              cropped_url: img.crop
                ? ImageHelpers.cropUrl(res.secure_url, img.crop)
                : null
            };
            resolve(r);
          })
          .catch(err => {
            console.log(`image upload promise failed: ${err}`);
            reject(err);
          })
      )
  );
  return Promise.all(urlPromises).then(responses => {
    const portfolioContent = responses.slice(0, urlPromises.length);
    const loadUserPromise = new Promise((resolve, reject) => {
      UserHelpers.loadUser(AuthHelper.logged_in_profileID, user => {
        resolve(user);
      });
    });
    return loadUserPromise.then(
      user => {
        const portfolioId = user.albums.filter(a => a.name === 'Portfolio')[0]
          .swipecast_album_id;
        const updateAlbumPromise = new Promise((resolve, reject) => {
          $.ajax({
            method: 'PUT',
            url: `/album/${portfolioId}`,
            data: JSON.stringify({
              album: {
                content: portfolioContent
              }
            }),
            dataType: 'JSON',
            contentType: 'application/json',
            success: data => {
              resolve(data);
              return 'success';
            },
            error: err => {
              console.log(err);
              reject(err);
            }
          });
        });
        return updateAlbumPromise.then(
          resolvedData => {
            return 'success';
          },
          rejected => {
            return rejected;
          }
        );
      },
      rejected => {}
    );
  });
}

function submitReferralCode(swipecast_user_id, referral_code = '') {
  if (!referral_code.trim() || !swipecast_user_id) return 'success';

  const submitReferralPromise = new Promise((resolve, reject) =>
    $.ajax({
      method: 'POST',
      url: `/user/${swipecast_user_id}/submit_referral_code`,
      data: JSON.stringify({
        referral_code
      }),
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        resolve(data);
      },
      error: err => {
        reject(err);
      }
    })
  );
  return submitReferralPromise.then(
    () => {
      return 'success';
    },
    err => {
      return err;
    }
  );
}

async function signUp(fields) {
  const {
    user_type,
    email,
    password,
    intent_role,
    placeID,
    SignUpType
  } = fields;
  let profiles;
  if (user_type === 'Female Model') {
    profiles = {
      Model: {
        gender: 'Female'
      }
    };
  } else if (user_type === 'Male Model') {
    profiles = {
      Model: {
        gender: 'Male'
      }
    };
  } else if (user_type) {
    profiles = { [user_type]: null };
  }

  const user = {
    user_type,
    email,
    password,
    gmaps_place_id: placeID,
    intent_role: intent_role || 'client',
    registration_source: ApplicationHelper.isMobile ? 'mr' : 'desktop',
    swipecast_offer_id: LocalStorage.fetchOfferID(),
    profiles,
    ...fields
  };
  if (navigator && navigator.userAgent) {
    user.signup_user_agent = navigator.userAgent;
  }
  if (SignUpType === 'noProfiles') {
    user.profiles = { Other: null };
  }
  Object.keys(user).forEach(k => {
    const value = user[k];
    if ([undefined, null, ''].includes(value)) delete user[k];
  });

  // Create the user
  const createUserPromise = new Promise((resolve, reject) =>
    $.ajax({
      method: 'POST',
      url: '/user',
      data: JSON.stringify({
        user
      }),
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        resolve(data);
      },
      error: err => {
        reject(err);
      }
    })
  );

  return createUserPromise.then(
    () => {
      LocalStorage.removeOfferID();
      LocalStorage.removeReferralCode();
      const loginPromise = new Promise((resolve, reject) => {
        /**
         * IMPORTANT: Calling AuthHelper.logout() and using the absolute URL in
         * the login URL are mandatory here.
         * That's a double security measure to ensure that the token is not
         * sent to the backend when trying to login (assuming there's any in LS)
         * See: InitHelper.ajaxInit() for more info
         */
        AuthHelper.logout();
        $.ajax({
          method: 'POST',
          url: `${Settings.SWIPECAST_BACKEND}/login`,
          dataType: 'JSON',
          data: {
            email,
            password,
            client: 'desktop'
          },
          success: data => {
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //   event: 'signup',
            //   userId: data.user.swipecast_user_id,
            //   userType:
            //     data.user.intent_role === 'talent' &&
            //     data.user.profiles.length > 0
            //       ? data.user.profiles[0].profile_type
            //       : 'Customer'
            // });
            AuthHelper.processLoggedInData(data);
            SCTrack.all('Sign Up - Finish');
            // we don't need to submit referral code here because it's already done during user creation
            // if (fields.referral_code)
            //   submitReferralCode(
            //     AuthHelper.logged_in_profileID,
            //     fields.referral_code
            //   );
            resolve(data);
          },
          error: err => {
            reject(err);
          }
        });
      });
      return loginPromise.then(
        () => {
          return 'success';
        },
        error => {
          const { message } = error.responseJSON;
          return message;
        }
      );
    },
    error => {
      const { message } = error.responseJSON;
      return message;
    }
  );
}

const signUpWithOauth = async (
  fields,
  { google_token_id, instagram_auth_code, redirect_uri }
) => {
  const user = {
    google_token_id,
    instagram_auth_code,
    swipecast_offer_id: LocalStorage.fetchOfferID(),
    redirect_uri,
    ...fields
  };
  Object.keys(user).forEach(k => {
    const value = user[k];
    if ([undefined, null, ''].includes(value)) delete user[k];
  });

  if (navigator && navigator.userAgent)
    user.signup_user_agent = navigator.userAgent;

  const userResponse = await createUser(user);
  if (userResponse.error) return { error: true, message: userResponse.message };

  LocalStorage.removeOfferID();
  LocalStorage.removeReferralCode();
  const sessionResponse = await createSession({
    ...user,
    instagram_token: userResponse.result.instagram_token
  });
  if (sessionResponse.error)
    return { error: true, message: sessionResponse.message };

  // we don't need to submit referral code here because it's already done during user creation
  // if (fields.referral_code)
  //   submitReferralCode(AuthHelper.logged_in_profileID, fields.referral_code);
  return { error: false };
};

export { signUp, initializeAlbums, submitReferralCode, signUpWithOauth };
