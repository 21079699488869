import React from 'react';
import styled, { css } from 'styled-components';

import Link from 'components/reusable/link';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import Flex from 'components/reusable/flex';

/**
 * @param hoverEffects hover effects can work incorrectly with specific icons.
 * in such cases you should style it manually if required
 * @param dotted
 * @param active
 * @param onClick
 * @param label
 * @param href
 * @param to
 * @param Icon
 * @returns {JSX.Element}
 * @constructor
 */
function HeaderButton({
  hoverEffects = true,
  active = false,
  counter = 0,
  onClick,
  label,
  href,
  to,
  Icon
}) {
  const color = active ? colors.primary[500] : colors.gray[700];

  return (
    <Wrapper>
      {counter > 0 && (
        <NotificationContainer wrap="nowrap">
          <NotificationDot />
          <Indent right={4} />
          <Text size={12} color={colors.primary[500]}>
            {counter}
          </Text>
        </NotificationContainer>
      )}

      {/* $hoverEffects - https://styled-components.com/docs/api#transient-props */}
      <StyledLink
        $hoverEffects={hoverEffects}
        to={to}
        href={href}
        onClick={onClick}
      >
        {Icon && (
          <IconWrapper>
            <Icon color={color} width={24} size={24} />
          </IconWrapper>
        )}

        <StyledText
          color={color}
          weight={500}
          size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}
        >
          {label}
        </StyledText>
      </StyledLink>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`;

const StyledLink = styled(Link)`
  ${({ $hoverEffects }) =>
    !$hoverEffects
      ? ''
      : css`
          &:hover svg path {
            fill: ${colors.primary[500]};
          }
        `}
`;

const IconWrapper = styled.span`
  display: none;

  @media (max-width: 1380px) {
    display: inline;
  }
`;

const StyledText = styled(Text)`
  display: inline;

  @media (max-width: 1380px) {
    display: none;
  }
  &:hover {
    color: ${colors.primary[500]};
  }
`;

const NotificationContainer = styled(Flex)`
  position: absolute;
  top: -5px;
  left: 100%;
  @media (max-width: 1380px) {
    top: -3px;
    left: 60%;
  }
`;

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${colors.primary[500]};
  @media (max-width: 1380px) {
    width: 10px;
    height: 10px;
    border: 2px solid ${colors.light};
  }
`;

export default HeaderButton;
