import styled, { css } from 'styled-components';

import Image from 'components/reusable/image';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';

const wrapperStyles = css`
  width: ${props => `${props.width}px`};
  height: ${props => `${props.width}px`};
  border-radius: 50%;
`;

export const Wrapper = styled.div`
  position: relative;
  ${wrapperStyles}
`;

export const StyledImage = styled(Image)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  inset: 0;
`;

export const DefaultAvatarWrapper = styled.div`
  background-color: ${colors.gray[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  ${wrapperStyles}
`;

export const DefaultAvatarInnerWrapper = styled.div`
  width: ${props => `calc(${props.width}px / 1.3)`};
  height: ${props => `calc(${props.width}px / 1.3)`};
  border-radius: inherit;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-color: ${colors.light};
  border-style: solid;
  border-width: ${getInnerBorderWidth}px;
`;

export const DefaultAvtarText = styled(Text)`
  line-height: 1.1em;
  height: 1.1em;
  margin-bottom: -0.1em;
`;

function getInnerBorderWidth(props) {
  if (props.width < 80) return 1;
  if (props.width < 160) return 2;
  if (props.width < 320) return 3;
  return 4;
}
