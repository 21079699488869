import { useCallback } from 'react';

import RATES from 'helpers/job/rates/rates_helper';
import dayRates from 'helpers/job/rates/day_rates_helper';
import hourlyRates from 'helpers/job/rates/hourly_rates_helper';
import GeneralHelpers from 'helpers/general_helpers';
import Settings from 'helpers/settings';

const useSubmit = ({
  setError,
  onChangeTalent,
  category,
  onClose,
  rate,
  frequency,
  hours
}) => {
  const validate = useCallback(
    ({ frequency, hours, rate }) => {
      if (!category) return { valid: false, message: 'Unknown category' };
      const rates = frequency === RATES.DAYRATE ? dayRates : hourlyRates;
      const { lookingFor } = category;
      const currentRate = rates[lookingFor];
      const minHours = GeneralHelpers.isAdmin()
        ? 1
        : Settings.minHoursForHourly;

      if (!frequency || !frequency.length) {
        return { valid: false, message: 'Frequency missing' };
      } else if (frequency === RATES.HOURLY && !hours) {
        return { valid: false, message: 'Please provide the hours quantity' };
      } else if (frequency === RATES.HOURLY && hours && hours < minHours) {
        return {
          valid: false,
          message: `Hours quantity is too low. Minimum # of hours: ${minHours}`
        };
      } else if (
        GeneralHelpers.isAdmin() ? false : !rate || rate < currentRate.min
      ) {
        return { valid: false, message: 'The rate is too low' };
      } else if (rate && rate > currentRate.max) {
        return { valid: false, message: 'The rate is too high' };
      }

      return { valid: true };
    },
    [category]
  );

  const onSubmit = useCallback(() => {
    setError('');
    const value = { rate, frequency, hours };
    const { valid, message } = validate(value);
    if (valid) {
      onChangeTalent(category.singularTitle, value);
      onClose();
    } else {
      setError(message);
    }
  }, [
    setError,
    onChangeTalent,
    category.singularTitle,
    onClose,
    rate,
    frequency,
    hours
  ]);

  return onSubmit;
};

export default useSubmit;
