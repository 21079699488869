import React, { useEffect, useState } from 'react';

import useRates from 'components/views/logged_in/jobs/create_job/create_job_v2/public_posting/components/steps/create_project_rate/components/create_project_rate_item_v2/hooks/use_rates';
import RATES from 'helpers/job/rates/rates_helper';
import useBreakpoints from 'components/views/logged_in/jobs/create_job/create_job_v2/public_posting/components/steps/create_project_rate/components/create_project_rate_item_v2/hooks/use_breakpoints';
import JobRateEditContent from './components/job_rate_edit_content/job_rate_edit_content';
import JobRateEditTransition from './components/job_rate_edit_transition';
import useRateTransition from './hooks/use_rate_transition';
import useInitialValues from './hooks/use_initial_values';
import hourlyRates from 'helpers/job/rates/hourly_rates_helper';
import useChangeSliderRate from './hooks/use_change_slider_rate';
import useSubmit from './hooks/use_submit';

function JobRateEditModal({ onClose, modalProps }) {
  // dev purposes
  // const category = {
  //   filters: {
  //     gender: ['Male'],
  //     type: ['Model'],
  //   },
  //   href: "/network/male_models",
  //   id: "male",
  //   image: "/images/categories/male_model.jpeg",
  //   jobFilters: {
  //     gender: ['Male'],
  //     looking_for: ['Model']
  //   },
  //   lookingFor: "Model",
  //   lookingForData: {gender: 'Male'},
  //   major: true,
  //   optImage: "/images/categories/male_model.webp",
  //   preview: [
  //     "/images/searchResults/v2/male_models/1.png",
  //     "/images/searchResults/v2/male_models/2.png",
  //     "/images/searchResults/v2/male_models/3.png",
  //     "/images/searchResults/v2/male_models/4.png",
  //     "/images/searchResults/v2/male_models/5.png",
  //     "/images/searchResults/v2/male_models/6.png",
  //     "/images/searchResults/v2/male_models/7.png",
  //     "/images/searchResults/v2/male_models/8.png"
  //   ],
  //   publicPosting: true,
  //   singularTitle: "Male Model",
  //   squareImg: "/images/categories/male_model_square.png",
  //   title: "Male Models",
  // }
  //
  // const onChangeTalent = () => {}
  // const value = { quantity: 1, rate: 200, frequency: 'HOURLY', hours: 4 }

  const { onChangeTalent, category, value } = modalProps;

  const initialValues = useInitialValues(category, value);
  const [frequency, setFrequency] = useState(initialValues.initialFrequency);
  const [rate, setRate] = useState(initialValues.initialRate);
  const [hours, setHours] = useState(initialValues.initialHours);
  const pureLookingFor = category?.lookingFor;
  const isHourlyRate = frequency === RATES.HOURLY;
  const frequencyLabel = isHourlyRate ? 'hourly rate' : 'day rate';
  const [error, setError] = useState('');
  const isHourlyAvailable = !!hourlyRates[category?.lookingFor];

  useEffect(() => {
    setError('');
  }, [rate, hours, frequency]);

  const rateTransition = useRateTransition({
    setFrequency,
    frequency,
    category,
    setHours,
    setRate
  });

  const ratesContext = useRates({
    onChangeTalent,
    pureLookingFor,
    isHourlyRate
  });

  const changeSliderRate = useChangeSliderRate({
    setRate,
    ratesContext
  });

  const breakpointsContext = useBreakpoints({
    breakpoints: ratesContext.talentRates.breakpoints,
    rate
  });
  const onSubmit = useSubmit({
    setError,
    onChangeTalent,
    category,
    onClose,
    rate,
    frequency,
    hours
  });

  const rateEditTransitionProps = {
    rateTransition,
    onClose
  };

  const rateEditContentProps = {
    breakpointsContext,
    isHourlyAvailable,
    changeSliderRate,
    frequencyLabel,
    rateTransition,
    ratesContext,
    isHourlyRate,
    setHours,
    onSubmit,
    category,
    setRate,
    onClose,
    hours,
    error,
    rate
  };

  if (rateTransition.value)
    return <JobRateEditTransition {...rateEditTransitionProps} />;

  return <JobRateEditContent {...rateEditContentProps} />;
}

export default JobRateEditModal;
