import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import svgIcons from 'helpers/svgIcons';
import LeftPartition from './components/share_left_partition';
import AuthHelper from 'helpers/auth_helper';
import RouterHelper from 'helpers/router_helper';
import ShareRightPartition from 'components/modals/v2/share_referral_modal/components/share_right_partition';
import SvgWhatsAppSolid from 'components/reusable/svg/share_buttons/svg_whats_app_solid';

const Wrapper = styled.div`
  box-shadow: 0px 0px 80px rgba(31, 30, 30, 0.08);
  border-radius: 30px;
`;

const BottomWrapper = styled(Flex)`
  height: 120px;
  @media (max-width: 768px) {
    height: auto;
    padding-bottom: 16px;
  }
`;

function ShareReferralModal({ onClose }) {
  const referralCode = useMemo(
    () => AuthHelper.currentUser().referral_code,
    []
  );

  const referralLink = useMemo(
    () => RouterHelper.getReferralLink(referralCode),
    [referralCode]
  );

  const onCopyReferralCode = useCallback(async () => {
    await navigator.clipboard.writeText(referralCode);
    window.alert('The code was copied to clipboard');
  }, [referralCode]);

  const onCopyReferralLink = useCallback(async () => {
    await navigator.clipboard.writeText(referralLink);
    window.alert('The referral link was copied to clipboard');
  }, [referralLink]);

  const sendOptions = useMemo(() => {
    const text = 'Hey check out this cool app for booking models!';
    return [
      {
        label: 'Text Message',
        icon: svgIcons.sms(),
        href: `sms:?&body=${text} ${referralLink}`
      },
      {
        label: 'WhatsApp',
        icon: <SvgWhatsAppSolid size={22} />,
        href: `https://wa.me/?text=${text} ${referralLink}`
      },
      {
        label: 'E-Mail',
        icon: svgIcons.mail(),
        href: `mailto:?subject=${text}&body=${referralLink}`
      },
      {
        label: 'Copy Link',
        icon: svgIcons.copyLinkV2(),
        onClick: onCopyReferralLink
      }
    ];
  }, [onCopyReferralLink, referralLink]);

  return (
    <Wrapper>
      <Flex jc="flex-start" dir="row" wrap="no-wrap" ai>
        {/*<LeftPartition*/}
        {/*  referralCode={referralCode}*/}
        {/*  onCopyReferralCode={onCopyReferralCode}*/}
        {/*/>*/}

        <ShareRightPartition sendOptions={sendOptions} />
      </Flex>

      <BottomWrapper>
        <Button
          onClick={onClose}
          width={160}
          height={40}
          color={colors.gray[600]}
          bgColor={colors.gray[100]}
        >
          Cancel
        </Button>
      </BottomWrapper>
    </Wrapper>
  );
}

export default ShareReferralModal;
