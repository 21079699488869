import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import React from 'react';
import styled from 'styled-components';

const BestRangeComponent = styled.div`
  width: 100%;
  height: 8px;
  border: 1px solid ${colors.gray[200]};
  border-bottom: none;
`;

const SquareWrapper = styled.div`
  width: 100%;
  height: 7px;
  overflow: hidden;
`;

const StyledText = styled(Text)`
  text-align: center;
`;

const Wrapper = styled.div`
  position: absolute;
  bottom: calc(100% + 4px);
  width: ${({ width }) => `${width}%`};
  left: ${({ left }) => `${left}%`};
`;

const BestRange = ({ endsAt, startsAt }) => {
  if (!endsAt || !startsAt) return null;
  const width = endsAt - startsAt;

  return (
    <Wrapper left={startsAt} width={width}>
      <StyledText size={12} weight={400}>
        Best Range
      </StyledText>

      <SquareWrapper>
        <BestRangeComponent />
      </SquareWrapper>
    </Wrapper>
  );
};

export default BestRange;
