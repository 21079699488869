import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import { routes } from 'helpers/router_helper';

const Wrapper = styled(Flex)`
  min-width: 400px;
  padding: 40px 56px;
  @media (max-width: 500px) {
    width: 100%;
    min-width: auto;
  }
`;

const CreateBankAccountModal = ({ onClose, modalProps }) => {
  const history = useHistory();
  const isSuccess = modalProps.type === 'success';
  const image = isSuccess
    ? svgIcons.successCircled(colors.secondary[500], colors.secondary[50])
    : svgIcons.errorCircled(colors.primary[500], colors.primary[50]);

  const title = isSuccess ? 'Thanks!' : 'Error';

  const text = isSuccess
    ? 'We have successfully stored your bank account details'
    : modalProps.message;

  const buttonText = isSuccess ? 'Continue' : 'OK';

  const handleClose = () => {
    onClose();
    isSuccess && history.push(routes.paymentSettings.redirectUrl());
  };

  return (
    <Wrapper dir="column">
      {image}
      <Indent bottom={24} />

      <Text>{title}</Text>
      <Indent bottom={16} />

      <Text color={colors.gray[400]} textAlign="center">
        {text}
      </Text>
      <Indent bottom={40} />

      <Button type="primary" height={40} width={160} onClick={handleClose}>
        {buttonText}
      </Button>
    </Wrapper>
  );
};

export default CreateBankAccountModal;
