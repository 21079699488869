import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';

const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const StyledButton = styled(Button)`
  width: 160px;
  flex: 1;
  @media (max-width: 768px) {
    width: unset;
  }
`;

const ButtonsToolbar = ({ isSubmitDisabled, handleSubmit }) => {
  return (
    <ButtonsWrapper>
      <StyledButton
        height={40}
        bgColor={colors.gray[100]}
        color={colors.gray[600]}
        onClick={handleSubmit}
      >
        Skip
      </StyledButton>

      <Indent right={16} />

      <StyledButton
        isDisabled={isSubmitDisabled}
        height={40}
        bgColor={isSubmitDisabled ? colors.gray[200] : colors.primary[500]}
        color={colors.light}
        onClick={handleSubmit}
      >
        Submit
      </StyledButton>
    </ButtonsWrapper>
  );
};

export default ButtonsToolbar;