/**
 * 404 component
 */
import React from 'react';

const DeadEnd = () => {
  return (
    <div style={{ flex: 1, width: '100%' }}>
      <div
        style={{
          fontFamily: 'Avenir-Medium',
          marginTop: '100',
          fontSize: '25px',
          textAlign: 'center'
        }}
      >
        This page is not available
      </div>
      <div
        style={{
          fontFamily: 'Avenir-Medium',
          marginTop: '50',
          color: '#999',
          fontSize: '1.125rem',
          textAlign: 'center'
        }}
      >
        The link you followed may be broken, or the page may have been removed
      </div>
    </div>
  );
};

export default DeadEnd;
