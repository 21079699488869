import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import Button from 'components/reusable/button';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import { styleDimensions } from 'helpers/style';
import sc_ga4, { actions } from 'classes/sc_ga4';
import SvgAppleEmailSolid from 'components/reusable/svg/share_buttons/svg_apple_email_solid';
import SvgAppleSmsSolid from 'components/reusable/svg/share_buttons/svg_apple_sms_solid';
import SvgWhatsAppSolid from 'components/reusable/svg/share_buttons/svg_whats_app_solid';
import RouterHelper from 'helpers/router_helper';

const BackgroundWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  animation: fadein 0.2s;
  background: rgba(255, 255, 255, 0.75);
  height: calc(100% - ${styleDimensions.mobileBarHeight});
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const Flex = styled.div`
  display: flex;
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

const Container = styled(Column)`
  position: relative;
  bottom: 0;
  padding: 24px 16px 16px 16px;
  background-color: ${colors.light};
  font-family: 'Gotham Pro';
  font-weight: 400;
  color: ${colors.gray[700]};
  box-shadow: 0px 0px 80px rgba(31, 30, 30, 0.08);
  border-radius: 30px;
  width: 460px;
  @media (max-width: 768px) {
    position: absolute;
    width: 100%;
    border-radius: 20px 20px 0px 0px;
  }
`;

const Row = styled(Flex)`
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid ${colors.gray[100]};
`;

const ButtonWrap = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

const SharePortfolioModal = props => {
  const { user, isCurrentUser } = props;
  const url = RouterHelper.getLIBReferralLink(user);

  const sendShareMetric = useCallback(
    method => () => {
      sc_ga4.sendMetric(actions.share, {
        method,
        item_id: url,
        content_type: 'user'
      });
    },
    [url]
  );

  const copyText = useCallback(() => {
    sendShareMetric('clipboard')();
    navigator.clipboard.writeText(url).then(() => {
      window.alert('Copied to clipboard');
    });
  }, [url]);

  return (
    <BackgroundWrap>
      <Container>
        <Indent bottom={8}>
          <Text
            size={18}
            lineHeight="120%"
            weight={500}
            textTransform="uppercase"
          >
            Share {isCurrentUser ? 'My' : `${user.first_name}'s`} portfolio
          </Text>
        </Indent>

        <a href={`sms:?&body=${url}`} onClick={sendShareMetric('sms')}>
          <Row>
            <Text size={14} lineHeight="140%">
              Text Message
            </Text>
            <SvgAppleSmsSolid size={24} />
          </Row>
        </a>
        <a
          href={`https://wa.me/?text=${url}`}
          onClick={sendShareMetric('whatsapp')}
        >
          <Row>
            <Text size={14} lineHeight="140%">
              WhatsApp
            </Text>
            <SvgWhatsAppSolid size={24} />
          </Row>
        </a>
        <a href={`mailto:?body=${url}`} onClick={sendShareMetric('mail')}>
          <Row>
            <Text size={14} lineHeight="140%">
              E-Mail
            </Text>
            <SvgAppleEmailSolid size={24} />
          </Row>
        </a>
        <Row style={{ borderBottom: 'none' }} onClick={copyText}>
          <Text size={14} lineHeight="140%">
            Copy Link
          </Text>
          <Indent right={2}>{svgIcons.copyLinkV2({ width: 22 })}</Indent>
        </Row>

        <ButtonWrap>
          <Button
            height={40}
            width="100%"
            type="gray-shadow"
            onClick={() => props.onClose()}
          >
            Cancel
          </Button>
        </ButtonWrap>
      </Container>
    </BackgroundWrap>
  );
};

export default SharePortfolioModal;
