import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import photosActions from 'store/resources/sharedPhotosV2/actions';
import videosActions from 'store/resources/sharedVideosV2/actions';
import filesActions from 'store/resources/sharedFilesV2/actions';
import linksActions from 'store/resources/sharedLinksV2/actions';
import { fileTypesEnum } from 'components/modals/v2/chat_shared_modal/file_types_enum';
import useGetSharedTabType from 'components/modals/v2/chat_shared_modal/hooks/use_get_shared_tab_type';

const limit = 12;

const useGetSharedFiles = conversationId => {
  const dispatch = useDispatch();
  const handleGetSharedTabType = useGetSharedTabType();

  const getAction = useCallback(activeTab => {
    switch (activeTab) {
      case fileTypesEnum.photos:
        return photosActions;
      case fileTypesEnum.videos:
        return videosActions;
      case fileTypesEnum.files:
        return filesActions;
      case fileTypesEnum.links:
        return linksActions;
      default:
        return null;
    }
  }, []);

  const handleFetchMessages = useCallback(
    async (activeTab, append = false, page = 1) => {
      const actionCreator = getAction(activeTab);
      if (!actionCreator) return false;
      const msgType = handleGetSharedTabType(activeTab);
      const url = `/v2/conversations/${conversationId}/messages?per_page=${limit}&page=${page}&message_type=${msgType}`;

      if (append) await dispatch(actionCreator.resourceAppendRequest(url));
      else await dispatch(actionCreator.resourceFetchRequest(url));
    },
    [handleGetSharedTabType, conversationId, getAction]
  );

  return handleFetchMessages;
};

export default useGetSharedFiles;
