import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';

const Container = styled.div`
  position: relative;
  z-index: 9; // 8 rounded images overlap
  width: 22px;
  height: 15px;
  @media (min-width: 769px) {
    display: none;
  }
`;

const Line = styled.div`
  width: 100%;
  position: absolute;
  height: 2px;
  background-color: ${colors.gray[300]};
  border-radius: 1px;
  transition: all 0.3s;
`;

const Line1 = styled(Line)`
  top: 0;
  transform: translateY(-50%);
  ${({ active }) =>
    active &&
    `
      top: 50%;
      transform: rotate(45deg);
    `}
`;
const Line2 = styled(Line)`
  width: 70%;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  ${({ active }) => active && `display: none;`}
`;
const Line3 = styled(Line)`
  top: 100%;
  ${({ active }) =>
    active &&
    `
      top: 50%;
      transform: rotate(-45deg);
    `}
`;

const HamburgerButton = ({ active, setActive }) => {
  return (
    <Container onClick={setActive}>
      <Line1 active={active} />
      <Line2 active={active} />
      <Line3 active={active} />
    </Container>
  );
};

export default HamburgerButton;
