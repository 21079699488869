import { useCallback } from 'react';

const useTimePicker = ({ onChange, value }) => {
  const [hours, minutes] = value.split(':');
  const isPmSelected = hours >= 12;

  const onChangeAmPm = useCallback(
    isAMClicked => {
      const isPMClicked = !isAMClicked;
      const isAmSelected = !isPmSelected;
      let newHours;

      if (isAMClicked && isPmSelected) {
        newHours = `${parseInt(hours) - 12}`;
      } else if (isPMClicked && isAmSelected) {
        newHours = `${parseInt(hours) + 12}`;
      } else {
        return;
      }

      if (newHours.length === 1) newHours = `0${newHours}`;
      const val = `${newHours}:${minutes}`;
      onChange(val);
    },
    [isPmSelected, minutes, onChange, minutes]
  );

  const buildOnChangeAmPm = isAMClicked =>
    useCallback(() => onChangeAmPm(isAMClicked), [onChangeAmPm]);

  return {
    isPmSelected,
    buildOnChangeAmPm
  };
};

export default useTimePicker;
