import React, { useCallback, useContext, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Links, Buttons } from '../../styled';
import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import OutlinedButton from '../outlined_button';
import HeaderButton from '../header_button/header_button_old';
import HamburgerButton from '../hamburger_button';
import Flex from 'components/reusable/flex';
import { routes } from 'helpers/router_helper';
import sc_ga4, { actions } from 'classes/sc_ga4';
import { sections } from 'components/views/logged_out/landing_page/helpers/sections';
import { ModalContext, RootContext } from 'contexts/contexts';
import { modalEnum } from 'components/modals/v2/modal';

const MobileButtonsContainer = styled(Flex)`
  @media (min-width: 769px) {
    display: none;
  }
`;

const LoggedOutHeaderRightItems = ({ pressTab, isMenuOpen, onOpen }) => {
  const modalContext = useContext(ModalContext);
  const location = useLocation();
  const history = useHistory();
  const onSignUp = useCallback(
    () => modalContext.onOpenModal(modalEnum.signUp),
    []
  );
  const onSignIn = useCallback(
    () => modalContext.onOpenModal(modalEnum.signIn),
    []
  );

  const alternativeStyle = useMemo(() => {
    return location.pathname.search('/position') === -1;
  }, [location.pathname]);

  const onCreateProject = useCallback(() => {
    const page = window.location.pathname;
    sc_ga4.sendMetric(actions.projectCreateFunnel.init, {
      guest_posting_flow: true,
      section: sections.header,
      is_booking: false,
      page
    });
    history.push(routes.createProject.redirectUrl());
  }, [history]);

  return (
    <>
      <Links>
        <HeaderButton
          action={() => pressTab('faq')}
          path={routes.faq.redirectUrl()}
          label="How it works"
          icon={() => svgIcons.headerFaq(colors.gray[600])}
        />
        <HeaderButton
          action={onSignIn}
          label="Log In"
          icon={() => svgIcons.headerLogin(colors.gray[600])}
        />
        <HeaderButton
          action={() => pressTab('contact')}
          path="/contact"
          label="Contact Us"
          icon={() => svgIcons.headerContact(colors.gray[600])}
        />

        <Buttons>
          <OutlinedButton
            onClick={onSignUp}
            children="Sign Up"
            color={alternativeStyle ? colors.primary[500] : colors.gray[700]}
            width={136}
          />
          <OutlinedButton
            children="Post a Job"
            color={alternativeStyle ? colors.gray[700] : colors.primary[500]}
            width={136}
            onClick={onCreateProject}
          />
        </Buttons>
      </Links>

      <MobileButtonsContainer>
        <HamburgerButton active={isMenuOpen} setActive={onOpen} />
      </MobileButtonsContainer>
    </>
  );
};

export default LoggedOutHeaderRightItems;
