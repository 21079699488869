import styled from 'styled-components';

import { colors } from 'helpers/colors';

const beforeFirstMonthDayOffset = ({ firstDayOfMonthWeekday }) => {
  if (firstDayOfMonthWeekday === 0) return `display: none`;
  return `grid-column: 1/${firstDayOfMonthWeekday + 1}`;
};

export const BeforeFirstMonthDayOffset = styled.div`
  ${beforeFirstMonthDayOffset};
  grid-row: 2;
`;

export const WeekDay = styled.div`
  width: ${({ cellWidth }) => cellWidth};
  height: ${({ cellWidth }) => cellWidth};
  color: ${colors.gray[700]};
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  font-size: 12px;
  font-family: Gotham Pro;
  font-weight: 500;

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Wrapper = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'min-content')};
  flex-direction: column;
  border-radius: 20px;
  padding: 20px;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
`;

export const NextMonthArrow = styled.i`
  right: 30px;
  top: 8px;
  position: absolute;
  color: ${colors.gray[300]};
  font-size: 26px;
  padding: 10px;
  cursor: pointer;
`;

export const PrevMonthArrow = styled.i`
  left: 30px;
  top: 8px;
  position: absolute;
  color: ${colors.gray[300]};
  font-size: 26px;
  padding: 10px;
  cursor: pointer;
`;

export const CalendarGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(7, 1fr);
  gap: ${({ $gap }) => $gap || '4px'};
  grid-column-start: span 3;
`;
