import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Flex from 'components/reusable/flex';
import Link from 'components/reusable/link';
import SvgArrowRightSolid from 'components/reusable/svg/svg_arrow_right_solid';

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid ${colors.gray[100]};
  padding: 10px 16px 10px 16px;
  &:last-child {
    border-bottom: none;
  }
`;

const IconWrapper = styled(Flex)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const MenuItems = ({ items }) => {
  return (
    <Flex dir="column">
      {items.map((item, index) => {
        return (
          item.condition && (
            <ItemWrapper key={index}>
              <StyledLink to={item.to} href={item.href} onClick={item.onClick}>
                <IconWrapper>{item.icon}</IconWrapper>

                <Text size={14} lineHeight="140%" color={colors.gray[700]}>
                  {item.label}
                </Text>

                {item.new && (
                  <StyledNewLabel
                    weight={500}
                    size={10}
                    color={colors.primary[500]}
                  >
                    New
                  </StyledNewLabel>
                )}
              </StyledLink>

              <StyledLink to={item.to} href={item.href} onClick={item.onClick}>
                <SvgArrowRightSolid size={16} color={colors.gray[300]} />
              </StyledLink>
            </ItemWrapper>
          )
        );
      })}
    </Flex>
  );
};

const StyledNewLabel = styled(Text)`
  transform: translateY(-25%);
  margin-left: 4px;
`;

export default MenuItems;
