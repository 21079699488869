import React from 'react';

import { colors } from 'helpers/colors';

function SvgArrowRightSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg
      height={size}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 15L8.5 8L1.5 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgArrowRightSolid;
