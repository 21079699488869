import React from 'react';
import { Link } from 'react-router-dom';

import { colors } from 'helpers/colors';
import { routes } from 'helpers/router_helper';
import svgIcons from 'helpers/svgIcons';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import { Container, AdaptiveItem, AdaptiveText, SocialLink } from './styled';
import useSendMetric from '../../hooks/use_send_metric';

const DefaultBlock = ({ hasExtraBlock, page }) => {
  const onInstagramClick = useSendMetric({ page, label: 'Instagram' });
  const onFacebookClick = useSendMetric({ page, label: 'Facebook' });
  const onTwitterClick = useSendMetric({ page, label: 'Twitter' });

  return (
    <Container border={hasExtraBlock}>
      <AdaptiveItem row="3" column="1">
        <AdaptiveText color={colors.light} weight={300}>
          Swipecast Inc. © {new Date().getFullYear()}
        </AdaptiveText>
      </AdaptiveItem>

      <AdaptiveItem mobile="none">
        <Indent left={16} right={16}>
          <AdaptiveText color={colors.light} weight={300}>
            |
          </AdaptiveText>
        </Indent>
      </AdaptiveItem>

      <AdaptiveItem row="1" column="2">
        <a href="mailto:peter@swipecast.com">
          <AdaptiveText color={colors.light} weight={300}>
            Investors
          </AdaptiveText>
        </a>
      </AdaptiveItem>

      <AdaptiveItem mobile="none">
        <Indent left={16} right={16}>
          <AdaptiveText color={colors.light} weight={300}>
            |
          </AdaptiveText>
        </Indent>
      </AdaptiveItem>

      <AdaptiveItem row="2" colun="2">
        <Link to={routes.about.policy.redirectUrl()}>
          <AdaptiveText color={colors.light} weight={300}>
            Privacy Policy
          </AdaptiveText>
        </Link>
      </AdaptiveItem>

      <AdaptiveItem mobile="none">
        <Indent left={16} right={16}>
          <AdaptiveText color={colors.light} weight={300}>
            |
          </AdaptiveText>
        </Indent>
      </AdaptiveItem>

      <AdaptiveItem row="3" column="2">
        <Link to={routes.about.terms.redirectUrl()}>
          <AdaptiveText color={colors.light} weight={300}>
            Terms of Service
          </AdaptiveText>
        </Link>
      </AdaptiveItem>

      <AdaptiveItem mobile="none">
        <Indent left={16} right={16}>
          <AdaptiveText color={colors.light} weight={300}>
            |
          </AdaptiveText>
        </Indent>
      </AdaptiveItem>

      <AdaptiveItem row="1" column="1">
        <Indent right={8}>
          <AdaptiveText color={colors.light} weight={300}>
            Follow Us:
          </AdaptiveText>
        </Indent>
      </AdaptiveItem>

      <AdaptiveItem row="2" column="1">
        <Flex jc="flex-start">
          <Indent right={16}>
            <SocialLink
              target="blank"
              href="https://instagram.com/swipecast/"
              onClick={onInstagramClick}
            >
              {svgIcons.instagram(colors.light, { width: 24, height: 24 })}
            </SocialLink>
          </Indent>
          <Indent right={16}>
            <SocialLink
              target="blank"
              href="https://www.facebook.com/pages/Swipecast/354337804765558?fref=photo"
              onClick={onFacebookClick}
            >
              {svgIcons.facebook(colors.light, { width: 24, height: 24 })}
            </SocialLink>
          </Indent>
          <SocialLink
            target="blank"
            href="https://twitter.com/swipecast"
            onClick={onTwitterClick}
          >
            {svgIcons.twitter(colors.light, { width: 24, height: 24 })}
          </SocialLink>
        </Flex>
      </AdaptiveItem>
    </Container>
  );
};

export default DefaultBlock;
