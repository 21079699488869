import React from 'react';

import VideoPlayer from 'components/reusable/video_player';

function ProfileAlbumVideo({ video_url, video_preview }) {
  return (
    <VideoPlayer
      // controls={false}
      // loop={true}
      width="100%"
      height="100%"
      // thumbnail={video_preview} // this stops the initial playing
      url={video_url}
    />
  );
}

export default ProfileAlbumVideo;
