import DateHelpers from 'helpers/date_helpers';

const useOfferDatesLabel = offer => {
  if (!offer) return null;

  const beginDate = DateHelpers.timeStampToLabel(
    offer.begin_date,
    false,
    true,
    false,
    true
  );
  const endDate = DateHelpers.timeStampToLabel(
    offer.end_date,
    false,
    true,
    false,
    true
  );

  const datesLabel = `${
    beginDate === endDate ? beginDate : `${beginDate} - ${endDate}`
  } (${offer.days} day${offer.days === 1 ? '' : 's'})`;

  return datesLabel;
};

export default useOfferDatesLabel;
