import React from 'react';

import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import styled from 'styled-components';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';

const Wrapper = styled(Flex)`
  padding: 40px 56px;
  flex-direction: column;
`;
const SectionWrapper = styled.div`
  display: flex;
  padding: 16px 24px;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
  margin-bottom: 24px;
  width: 100%;
  border-radius: 16px;
`;
const SectionCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
const NumberWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 45px;
`;
const StyledButton = styled(Button)`
  display: flex;
  min-height: 40px;
  border-radius: 20px;
`;

function HowItWorksModalContentDesktop({ onClose, list }) {
  return (
    <Wrapper>
      <Indent bottom={32}>
        <Text textAlign="center" size={24} weight={500}>
          How it Works?
        </Text>
      </Indent>

      {list.map(({ label, description }, i) => (
        <SectionWrapper key={label}>
          <NumberWrapper>
            <Text size={72} lineHeight="1em" weight={400}>
              {i + 1}
            </Text>
          </NumberWrapper>

          <Indent right={24} />

          <SectionCol>
            <Text weight={500} size={18}>
              {label}
            </Text>
            <Indent top={8} />
            <Text weight={400} size={16}>
              {description}
            </Text>
          </SectionCol>
        </SectionWrapper>
      ))}

      <Indent bottom={16} />

      <StyledButton width={160} type="gray-shadow" onClick={onClose}>
        Cancel
      </StyledButton>
    </Wrapper>
  );
}

export default HowItWorksModalContentDesktop;
