import React from 'react';
import styled from 'styled-components';

import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import ShareOfferLinks from 'components/modals/v2/share_offer_modal/components/share_offer_links';

function ShareOfferMobile({ title, links, onClose }) {
  return (
    <Wrapper dir="column" wrap="nowrap" ai="flex-start">
      <Text size={18} weight={500}>
        {title}
      </Text>

      <Indent bottom={16} />
      <ShareOfferLinks links={links} />
      <Indent bottom={24} />

      <Button type="gray-shadow" width="100%" height={40} onClick={onClose}>
        Cancel
      </Button>
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

export default ShareOfferMobile;
