import get from 'lodash.get';

import Settings from './settings';
import { routes } from 'helpers/router_helper';

const profileTypes = {
  model: 'Model',
  photo: 'Photographer',
  makeUp: 'Make-up Artist',
  hair: 'Hair Stylist',
  video: 'Videographer',
  influencer: 'Influencer',
  stylist: 'Stylist',
  prop: 'Prop Stylist',
  manicurist: 'Manicurist',
  parts: 'Hand / Foot Model',
  studio: 'Rental Studio',
  retoucher: 'Retoucher',
  producer: 'Producer',
  creative: 'Creative Director',
  casting: 'Casting Director',
  designer: 'Designer',
  eComManager: 'E-Commerce Manager',
  studioManager: 'Studio Manager',
  other: 'Other'
};

// profiles.profile_type
// https://docs.google.com/spreadsheets/d/1GI9ft-BgtTGO0NkuHQQ_rMAs4_0wUUBQYHzGMQNXMmg/edit#gid=0
export const bothRolesProfiles = [
  profileTypes.photo,
  profileTypes.hair,
  profileTypes.makeUp,
  profileTypes.stylist,
  profileTypes.influencer,
  profileTypes.video,
  profileTypes.manicurist,
  profileTypes.studio,
  profileTypes.prop,
  profileTypes.parts,
  profileTypes.retoucher,
  profileTypes.model
];

// profiles.profile_type
// https://docs.google.com/spreadsheets/d/1GI9ft-BgtTGO0NkuHQQ_rMAs4_0wUUBQYHzGMQNXMmg/edit#gid=0
export const clientProfiles = [
  profileTypes.designer,
  profileTypes.creative,
  profileTypes.casting,
  profileTypes.eComManager,
  profileTypes.producer,
  profileTypes.studioManager,
  profileTypes.other
];

// profiles.profile_type
// https://docs.google.com/spreadsheets/d/1GI9ft-BgtTGO0NkuHQQ_rMAs4_0wUUBQYHzGMQNXMmg/edit#gid=0
export const talentProfiles = [];

const profileCategoriesSignUpV2 = [
  profileTypes.designer,
  profileTypes.photo,
  profileTypes.casting,
  profileTypes.eComManager,
  profileTypes.creative,
  profileTypes.producer,
  profileTypes.hair,
  profileTypes.makeUp,
  profileTypes.stylist,
  `Female ${profileTypes.model}`,
  `Male ${profileTypes.model}`,
  profileTypes.influencer,
  profileTypes.studioManager,
  profileTypes.video,
  profileTypes.manicurist,
  profileTypes.studio,
  profileTypes.prop,
  profileTypes.parts,
  profileTypes.retoucher,
  'Other'
];

const categories = {
  female_models: {
    major: true, // displayed on public posting primary list
    publicPosting: isCatagoryForPosting(profileTypes.model), // available for PP
    id: 'female',
    title: 'Female Models',
    singularTitle: 'Female Model',
    lookingFor: profileTypes.model,
    lookingForData: { gender: 'Female' },
    jobFilters: {
      // all jobs search filters
      looking_for: [profileTypes.model],
      gender: ['Female']
    },
    filters: {
      type: [profileTypes.model],
      gender: ['Female']
    },
    href: '/network/female_models', // url for search view
    // cwebp -q 80 images/categories/female_model.jpeg -o images/categories/female_model.webp
    optImage: '/images/categories/female_model.webp',
    image: '/images/categories/female_model.jpeg',
    squareImg: '/images/categories/female_model_square.png',
    preview: [
      '/images/searchResults/v2/female_models/1.png',
      '/images/searchResults/v2/female_models/2.png',
      '/images/searchResults/v2/female_models/3.png',
      '/images/searchResults/v2/female_models/4.png',
      '/images/searchResults/v2/female_models/5.png',
      '/images/searchResults/v2/female_models/6.png',
      '/images/searchResults/v2/female_models/7.png',
      '/images/searchResults/v2/female_models/8.png'
    ]
  },
  male_models: {
    major: true,
    publicPosting: isCatagoryForPosting(profileTypes.model),
    id: 'male',
    title: 'Male Models',
    singularTitle: 'Male Model',
    lookingFor: profileTypes.model,
    lookingForData: { gender: 'Male' },
    jobFilters: {
      looking_for: [profileTypes.model],
      gender: ['Male']
    },
    filters: {
      type: [profileTypes.model],
      gender: ['Male']
    },
    href: '/network/male_models',
    optImage: '/images/categories/male_model.webp',
    image: '/images/categories/male_model.jpeg',
    squareImg: '/images/categories/male_model_square.png',
    preview: [
      '/images/searchResults/v2/male_models/1.png',
      '/images/searchResults/v2/male_models/2.png',
      '/images/searchResults/v2/male_models/3.png',
      '/images/searchResults/v2/male_models/4.png',
      '/images/searchResults/v2/male_models/5.png',
      '/images/searchResults/v2/male_models/6.png',
      '/images/searchResults/v2/male_models/7.png',
      '/images/searchResults/v2/male_models/8.png'
    ]
  },
  influencers: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.influencer),
    id: 'Influencer',
    title: 'Influencers',
    singularTitle: 'Influencer',
    lookingFor: profileTypes.influencer,
    jobFilters: { looking_for: [profileTypes.influencer] },
    filters: { type: [profileTypes.influencer] },
    href: '/network/influencers',
    optImage: '/images/categories/influencer.webp',
    image: '/images/categories/influencer.jpeg',
    squareImg: '/images/categories/influencer_square.png',
    preview: [
      '/images/searchResults/v2/influencers/1.png',
      '/images/searchResults/v2/influencers/2.png',
      '/images/searchResults/v2/influencers/3.png',
      '/images/searchResults/v2/influencers/4.png',
      '/images/searchResults/v2/influencers/5.png',
      '/images/searchResults/v2/influencers/6.png',
      '/images/searchResults/v2/influencers/7.png',
      '/images/searchResults/v2/influencers/8.png'
    ]
  },
  photographers: {
    major: true,
    publicPosting: isCatagoryForPosting(profileTypes.photo),
    id: 'Photographer',
    title: 'Photographers',
    singularTitle: 'Photographer',
    lookingFor: profileTypes.photo,
    jobFilters: { looking_for: [profileTypes.photo] },
    filters: {
      type: [profileTypes.photo]
    },
    href: '/network/photographers',
    optImage: '/images/categories/photographer.webp',
    image: '/images/categories/photographer.jpeg',
    squareImg: '/images/categories/photographer_square.png',
    preview: [
      '/images/searchResults/v2/photographers/1.png',
      '/images/searchResults/v2/photographers/2.png',
      '/images/searchResults/v2/photographers/3.png',
      '/images/searchResults/v2/photographers/4.png',
      '/images/searchResults/v2/photographers/5.png',
      '/images/searchResults/v2/photographers/6.png',
      '/images/searchResults/v2/photographers/7.png',
      '/images/searchResults/v2/photographers/8.png'
    ]
  },
  make_up_artists: {
    major: true,
    publicPosting: isCatagoryForPosting(profileTypes.makeUp),
    id: 'Make-up Artist',
    title: 'Make-up Artists',
    singularTitle: 'Make-up Artist',
    lookingFor: profileTypes.makeUp,
    jobFilters: { looking_for: [profileTypes.makeUp] },
    filters: {
      type: [profileTypes.makeUp]
    },
    href: '/network/make_up_artists',
    optImage: '/images/categories/makeup_square.png', // TODO missing. replace with a real square makeup.webp
    image: '/images/categories/makeup_square.png', // TODO missing. replace with a real square makeup.jpg
    squareImg: '/images/categories/makeup_square.png',
    preview: [
      '/images/searchResults/v2/make_up_artists/1.png',
      '/images/searchResults/v2/make_up_artists/2.png',
      '/images/searchResults/v2/make_up_artists/3.png',
      '/images/searchResults/v2/make_up_artists/4.png',
      '/images/searchResults/v2/make_up_artists/5.png',
      '/images/searchResults/v2/make_up_artists/6.png',
      '/images/searchResults/v2/make_up_artists/7.png',
      '/images/searchResults/v2/make_up_artists/8.png'
    ]
  },
  hair_stylists: {
    major: true,
    publicPosting: isCatagoryForPosting(profileTypes.hair),
    id: 'Hair Stylist',
    title: 'Hair Stylists',
    singularTitle: 'Hair Stylist',
    lookingFor: profileTypes.hair,
    jobFilters: { looking_for: [profileTypes.hair] },
    filters: {
      type: [profileTypes.hair]
    },
    href: '/network/hair_stylists',
    optImage: '/images/categories/hair.webp',
    image: '/images/categories/hair.jpeg',
    squareImg: '/images/categories/hair_square.png',
    preview: [
      '/images/searchResults/v2/hair_stylists/1.png',
      '/images/searchResults/v2/hair_stylists/2.png',
      '/images/searchResults/v2/hair_stylists/3.png',
      '/images/searchResults/v2/hair_stylists/4.png',
      '/images/searchResults/v2/hair_stylists/5.png',
      '/images/searchResults/v2/hair_stylists/6.png',
      '/images/searchResults/v2/hair_stylists/7.png',
      '/images/searchResults/v2/hair_stylists/8.png'
    ]
  },
  stylists: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.stylist),
    id: 'Stylist',
    title: 'Stylists',
    singularTitle: 'Stylist',
    lookingFor: profileTypes.stylist,
    jobFilters: { looking_for: [profileTypes.stylist] },
    filters: {
      type: [profileTypes.stylist]
    },
    href: '/network/stylists',
    optImage: '/images/categories/stylist.webp',
    image: '/images/categories/stylist.jpeg',
    squareImg: '/images/categories/stylist_square.png',
    preview: [
      '/images/searchResults/v2/stylists/1.png',
      '/images/searchResults/v2/stylists/2.png',
      '/images/searchResults/v2/stylists/3.png',
      '/images/searchResults/v2/stylists/4.png',
      '/images/searchResults/v2/stylists/5.png',
      '/images/searchResults/v2/stylists/6.png',
      '/images/searchResults/v2/stylists/7.png',
      '/images/searchResults/v2/stylists/8.png'
    ]
  },
  creative_directors: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.creative),
    id: 'Creative Director',
    title: 'Creative Directors',
    singularTitle: 'Creative Director',
    lookingFor: profileTypes.creative,
    jobFilters: { looking_for: [profileTypes.creative] },
    filters: {
      type: [profileTypes.creative, 'Art Director']
    },
    href: '/network/creative_directors',
    optImage: '/images/categories/creative_director.webp',
    image: '/images/categories/creative_director.jpeg',
    squareImg: '/images/categories/creative_director_square.png',
    preview: [
      '/images/searchResults/v2/creative_directors/1.png',
      '/images/searchResults/v2/creative_directors/2.png',
      '/images/searchResults/v2/creative_directors/3.png',
      '/images/searchResults/v2/creative_directors/4.png',
      '/images/searchResults/v2/creative_directors/5.png',
      '/images/searchResults/v2/creative_directors/6.png',
      '/images/searchResults/v2/creative_directors/7.png',
      '/images/searchResults/v2/creative_directors/8.png'
    ]
  },
  casting_directors: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.casting),
    id: 'Casting Director',
    title: 'Casting Directors',
    singularTitle: 'Casting Director',
    lookingFor: profileTypes.casting,
    jobFilters: { looking_for: [profileTypes.casting] },
    filters: {
      type: [profileTypes.casting]
    },
    href: '/network/casting_directors',
    optImage: '/images/categories/casting_director.webp',
    image: '/images/categories/casting_director.jpeg',
    squareImg: '/images/categories/casting_director_square.png',
    preview: [
      '/images/searchResults/v2/casting_directors/1.png',
      '/images/searchResults/v2/casting_directors/2.png',
      '/images/searchResults/v2/casting_directors/3.png',
      '/images/searchResults/v2/casting_directors/4.png',
      '/images/searchResults/v2/casting_directors/5.png',
      '/images/searchResults/v2/casting_directors/6.png',
      '/images/searchResults/v2/casting_directors/7.png',
      '/images/searchResults/v2/casting_directors/8.png'
    ]
  },
  designers: {
    publicPosting: isCatagoryForPosting(profileTypes.designer),
    id: 'Designer',
    title: 'Designers',
    singularTitle: 'Designer',
    lookingFor: profileTypes.designer,
    jobFilters: { looking_for: [profileTypes.designer] },
    filters: {
      type: [profileTypes.designer]
    },
    href: '/network/designers',
    optImage: '/images/categories/designer.webp',
    image: '/images/categories/designer.jpg',
    squareImg: '/images/categories/designer.jpg' // TODO missing. replace with a real square designer_square.png
  },
  producers: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.producer),
    id: 'Producer',
    title: 'Producers',
    singularTitle: 'Producer',
    lookingFor: profileTypes.producer,
    jobFilters: { looking_for: [profileTypes.producer] },
    filters: {
      type: [profileTypes.producer]
    },
    href: '/network/producers',
    optImage: '/images/categories/producer.webp',
    image: '/images/categories/producer.jpeg',
    squareImg: '/images/categories/producer_square.png',
    preview: [
      '/images/searchResults/v2/producers/1.png',
      '/images/searchResults/v2/producers/2.png',
      '/images/searchResults/v2/producers/3.png',
      '/images/searchResults/v2/producers/4.png',
      '/images/searchResults/v2/producers/5.png',
      '/images/searchResults/v2/producers/6.png',
      '/images/searchResults/v2/producers/7.png',
      '/images/searchResults/v2/producers/8.png'
    ]
  },
  videographers: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.video),
    id: 'Videographer',
    title: 'Videographers',
    singularTitle: 'Videographer',
    lookingFor: profileTypes.video,
    jobFilters: { looking_for: [profileTypes.video] },
    filters: {
      type: [profileTypes.video]
    },
    href: '/network/videographers',
    optImage: '/images/categories/videographer.webp',
    image: '/images/categories/videographer.jpeg',
    squareImg: '/images/categories/videographer_square.png',
    preview: [
      '/images/searchResults/v2/videographers/1.png',
      '/images/searchResults/v2/videographers/2.png',
      '/images/searchResults/v2/videographers/3.png',
      '/images/searchResults/v2/videographers/4.png',
      '/images/searchResults/v2/videographers/5.png',
      '/images/searchResults/v2/videographers/6.png',
      '/images/searchResults/v2/videographers/7.png',
      '/images/searchResults/v2/videographers/8.png'
    ]
  },
  rental_studios: {
    major: true,
    publicPosting: isCatagoryForPosting(profileTypes.studio),
    id: 'Rental Studio',
    title: 'Rental Studios',
    singularTitle: 'Rental Studio',
    lookingFor: profileTypes.studio,
    jobFilters: { looking_for: [profileTypes.studio] },
    filters: {
      type: [profileTypes.studio]
    },
    href: '/network/rental_studios',
    optImage: '/images/categories/rental_studio.webp',
    image: '/images/categories/rental_studio.jpeg',
    squareImg: '/images/categories/rental_studio_square.png',

    preview: [
      '/images/searchResults/v2/rental_studios/1.png',
      '/images/searchResults/v2/rental_studios/2.png',
      '/images/searchResults/v2/rental_studios/3.png',
      '/images/searchResults/v2/rental_studios/4.png',
      '/images/searchResults/v2/rental_studios/5.png',
      '/images/searchResults/v2/rental_studios/6.png',
      '/images/searchResults/v2/rental_studios/7.png',
      '/images/searchResults/v2/rental_studios/8.png'
    ]
  },
  manicurists: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.manicurist),
    id: 'Manicurist',
    title: 'Manicurists',
    singularTitle: 'Manicurist',
    lookingFor: profileTypes.manicurist,
    jobFilters: { looking_for: [profileTypes.manicurist] },
    filters: {
      type: [profileTypes.manicurist]
    },
    href: '/network/manicurists',
    optImage: '/images/categories/manicurist.webp',
    image: '/images/categories/manicurist.jpeg',
    squareImg: '/images/categories/manicurist_square.png',
    preview: [
      '/images/searchResults/v2/manicurists/1.png',
      '/images/searchResults/v2/manicurists/2.png',
      '/images/searchResults/v2/manicurists/3.png',
      '/images/searchResults/v2/manicurists/4.png',
      '/images/searchResults/v2/manicurists/5.png',
      '/images/searchResults/v2/manicurists/6.png',
      '/images/searchResults/v2/manicurists/7.png',
      '/images/searchResults/v2/manicurists/8.png'
    ]
  },
  prop_stylists: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.prop),
    id: 'Prop Stylist',
    title: 'Prop Stylists',
    singularTitle: 'Prop Stylist',
    lookingFor: profileTypes.prop,
    jobFilters: { looking_for: [profileTypes.prop] },
    filters: {
      type: [profileTypes.prop]
    },
    href: '/network/prop_stylists',
    optImage: '/images/categories/prop_stylist.webp',
    image: '/images/categories/prop_stylist.jpeg',
    squareImg: '/images/categories/prop_stylist_square.png',
    preview: [
      '/images/searchResults/v2/designers/1.png',
      '/images/searchResults/v2/designers/2.png',
      '/images/searchResults/v2/designers/3.png',
      '/images/searchResults/v2/designers/4.png',
      '/images/searchResults/v2/designers/5.png',
      '/images/searchResults/v2/designers/6.png',
      '/images/searchResults/v2/designers/7.png',
      '/images/searchResults/v2/designers/8.png'
    ]
  },
  retouchers: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.retoucher),
    id: 'Retoucher',
    title: 'Retouchers',
    singularTitle: 'Retoucher',
    lookingFor: profileTypes.retoucher,
    jobFilters: { looking_for: [profileTypes.retoucher] },
    filters: {
      type: [profileTypes.retoucher]
    },
    href: '/network/retouchers',
    optImage: '/images/categories/retoucher.webp',
    image: '/images/categories/retoucher.jpeg',
    squareImg: '/images/categories/retoucher_square.png',
    preview: [
      '/images/searchResults/v2/retouchers/1.png',
      '/images/searchResults/v2/retouchers/2.png',
      '/images/searchResults/v2/retouchers/3.png',
      '/images/searchResults/v2/retouchers/4.png',
      '/images/searchResults/v2/retouchers/5.png',
      '/images/searchResults/v2/retouchers/6.png',
      '/images/searchResults/v2/retouchers/7.png',
      '/images/searchResults/v2/retouchers/8.png'
    ]
  },
  hand_foot_models: {
    major: false,
    publicPosting: isCatagoryForPosting(profileTypes.parts),
    id: 'Hand / Foot Model',
    title: 'Parts Models',
    singularTitle: 'Parts Model',
    lookingFor: profileTypes.parts,
    jobFilters: { looking_for: [profileTypes.parts] },
    filters: {
      type: [profileTypes.parts]
    },
    href: '/network/hand_foot_models',
    optImage: '/images/categories/parts_model.webp',
    image: '/images/categories/parts_model.jpeg',
    squareImg: '/images/categories/parts_model_square.png',
    preview: [
      '/images/searchResults/v2/parts_models/1.png',
      '/images/searchResults/v2/parts_models/2.png',
      '/images/searchResults/v2/parts_models/3.png',
      '/images/searchResults/v2/parts_models/4.png',
      '/images/searchResults/v2/parts_models/5.png',
      '/images/searchResults/v2/parts_models/6.png',
      '/images/searchResults/v2/parts_models/7.png',
      '/images/searchResults/v2/parts_models/8.png'
    ]
  },
  all: {
    id: 'all',
    title: 'All Members',
    jobFilters: {},
    filters: {
      type: [
        profileTypes.model,
        profileTypes.photo,
        profileTypes.influencer,
        profileTypes.designer,
        profileTypes.creative,
        profileTypes.casting,
        profileTypes.stylist,
        profileTypes.hair,
        profileTypes.makeUp,
        profileTypes.prop,
        profileTypes.producer,
        profileTypes.video,
        profileTypes.studio,
        profileTypes.manicurist,
        profileTypes.retoucher,
        profileTypes.parts
      ]
    },
    href: routes.network.talents.redirectUrl('all')
  }
};

const majorPPCategories = Object.values(categories).filter(
  c => c.publicPosting && c.major
);
const manorPPCategories = Object.values(categories).filter(
  c => c.publicPosting && !c.major
);
const ppCategories = [...majorPPCategories, ...manorPPCategories];
const getCategoryBySingularTitle = l =>
  ppCategories.find(c => c.singularTitle === l);

const getCategoryByLookingFor = (lookingFor, lookingForData = {}) => {
  const result = ppCategories.find(category => {
    const isValueMatch = category.lookingFor === lookingFor;

    const isDataMatch =
      category.lookingForData && lookingForData
        ? Object.entries(category.lookingForData).reduce(
            (isPrevMatch, [key, val]) => {
              if (!isPrevMatch) return false;
              return val === lookingForData[key];
            },
            true
          )
        : true;

    return isValueMatch && isDataMatch;
  });

  if (result) return result;

  // direct booking for legacy types
  const underscoredProfile = lookingFor.toLowerCase().replace(/ /g, '_');
  const defaultValue = {
    lookingFor: underscoredProfile,
    lookingForData: underscoredProfile
  };
  return defaultValue;
};

const getCategoryThumbnails = talents => {
  const images = talents.map(t => getCategoryBySingularTitle(t).preview).flat();
  const shuffledImages = images.sort(() => 0.5 - Math.random());
  return shuffledImages;
};

const looking_for_categories = [
  {
    id: profileTypes.photo,
    title: profileTypes.photo
  },
  {
    id: profileTypes.creative,
    title: profileTypes.creative
  },
  {
    id: profileTypes.producer,
    title: profileTypes.producer
  },
  {
    id: profileTypes.hair,
    title: profileTypes.hair
  },
  {
    id: profileTypes.makeUp,
    title: profileTypes.makeUp
  },
  {
    id: profileTypes.stylist,
    title: profileTypes.stylist
  },
  {
    id: 'female',
    title: 'Female Model'
  },
  {
    id: 'male',
    title: 'Male Model'
  },
  {
    id: profileTypes.influencer,
    title: profileTypes.influencer
  },
  {
    id: profileTypes.video,
    title: profileTypes.video
  },
  {
    id: profileTypes.manicurist,
    title: profileTypes.manicurist
  },
  {
    id: profileTypes.studio,
    title: profileTypes.studio
  }
];

/**
 *
 * @param male_model, female_model... (one of keys for the categories constant)
 * @returns {*&{instagram_followers: {displayType: string, min: number, max: number, type: string, title: string}}}
 */
const getStatsByCategory = category => {
  const globalCategory = Globals.categories[category];
  const gender = get(globalCategory, 'filters.gender', {});
  const stats = {
    ...Globals.statDetails[gender],
    ...Globals.statDetails.General
  };
  return stats;
};

const Globals = {
  getCategoryThumbnails,
  getCategoryByLookingFor,
  getCategoryBySingularTitle,
  majorPPCategories,
  manorPPCategories,
  ppCategories,
  getStatsByCategory,
  profileCategoriesSignUpV2,
  talent_categories: [
    profileTypes.model,
    profileTypes.photo,
    profileTypes.stylist,
    profileTypes.creative,
    profileTypes.casting,
    profileTypes.producer,
    profileTypes.hair,
    profileTypes.makeUp,
    profileTypes.influencer
  ],
  pusher_app_cluster: Settings.SWIPECAST_PUSHER_APP_CLUSTER,
  statDetails: {
    General: {
      instagram_followers: {
        type: 'range',
        min: 0,
        max: 10000000,
        title: 'Social',
        displayType: 'abbr'
      }
    },
    Female: {
      height: {
        type: 'range',
        min: 66,
        max: 80,
        increment: 0.5,
        title: 'Height',
        displayType: 'ftIn'
      },
      bust: {
        type: 'range',
        min: 27,
        max: 47,
        increment: 0.5,
        title: 'Bust',
        displayType: 'inCm'
      },
      waist: {
        type: 'range',
        min: 19,
        max: 45,
        increment: 0.5,
        title: 'Waist',
        displayType: 'inCm'
      },
      hip: {
        type: 'range',
        min: 31,
        max: 50,
        increment: 0.5,
        title: 'Hips',
        displayType: 'inCm'
      },
      shoe: {
        type: 'range',
        min: 5.5,
        max: 13,
        increment: 0.5,
        title: 'Shoe Size',
        displayType: 'USEU'
      },
      dress: {
        type: 'range',
        min: 0,
        max: 20,
        increment: 0.5,
        title: 'Dress',
        displayType: 'USEU'
      },
      ethnicity: {
        type: 'list',
        values: ['White', 'Black', 'Asian', 'Hispanic', 'Mixed', 'Other'],
        title: 'Ethnicity',
        displayType: 'asIs'
      },
      hair_color: {
        type: 'list',
        values: ['Black', 'Blond', 'Brown', 'Chestnut', 'Grey', 'Red'],
        title: 'Hair Color',
        displayType: 'asIs'
      },
      eye_color: {
        type: 'list',
        values: ['Amber', 'Blue', 'Brown', 'Grey', 'Green', 'Hazel'],
        title: 'Eyes Color',
        displayType: 'asIs'
      },
      cup_size: {
        type: 'list',
        values: ['AA', 'A', 'B', 'C', 'D', 'E(DD)'],
        title: 'Cup Size',
        displayType: 'asIs'
      },
      tags: {
        type: 'list_ids',
        title: 'Tags',
        displayType: 'asIs',
        values: [
          { id: 'plus_size', title: 'Plus Size' },
          { id: 'mature', title: 'Mature' },
          { id: 'tattoos', title: 'Tattoos' },
          { id: 'piercings', title: 'Piercings' }
        ]
      }
    },
    Male: {
      height: {
        type: 'range',
        min: 66,
        max: 80,
        increment: 0.5,
        title: 'Height',
        displayType: 'ftIn'
      },
      waist: {
        type: 'range',
        min: 22,
        max: 45,
        increment: 0.5,
        title: 'Waist',
        displayType: 'inCm'
      },
      chest: {
        type: 'range',
        min: 31,
        max: 55,
        increment: 0.5,
        title: 'Chest',
        displayType: 'inCm'
      },
      // hip: { type: 'range', min: 28, max: 36, increment: 0.5, title: 'Hips', displayType: 'inCm' },
      shoe: {
        type: 'range',
        min: 7,
        max: 17,
        increment: 0.5,
        title: 'Shoe Size',
        displayType: 'USEU'
      },
      ethnicity: {
        type: 'list',
        values: ['White', 'Black', 'Asian', 'Hispanic', 'Mixed', 'Other'],
        title: 'Ethnicity',
        displayType: 'asIs'
      },
      eye_color: {
        type: 'list',
        values: ['Amber', 'Blue', 'Brown', 'Grey', 'Green', 'Hazel'],
        title: 'Eyes Color',
        displayType: 'asIs'
      },
      hair_color: {
        type: 'list',
        values: ['Black', 'Blond', 'Brown', 'Chestnut', 'Grey', 'Red'],
        title: 'Hair Color',
        displayType: 'asIs'
      }
    }
  },

  looking_for_categories,
  categories,
  profileTypes,
  ppCategoryLabels: looking_for_categories.map(c => c.title),
  categoryOpts: Object.entries(categories).map(([k, v]) => ({
    label: v.title,
    value: k
  }))
};

function isCatagoryForPosting(profileType) {
  return [...bothRolesProfiles, ...talentProfiles].includes(profileType);
}

export default Globals;
