import React from 'react';

import useGuestPostingSequence from './hooks/use_guest_posting_sequence';
import useCurrentStep from './hooks/use_current_step';
import GuestOnboardingHeaderDesktop from './guest_onboarding_header_desktop';
import GuestOnboardingHeaderMobile from './guest_onborading_header_mobile';
import ApplicationHelper from 'helpers/application_helper';

function GuestOnboardingHeader() {
  const sequence = useGuestPostingSequence();
  const currentStep = useCurrentStep(sequence);

  return ApplicationHelper.isMobile ? (
    <GuestOnboardingHeaderMobile currentStep={currentStep} />
  ) : (
    <GuestOnboardingHeaderDesktop sequence={sequence} />
  );
}

export default GuestOnboardingHeader;
