import { useMemo } from 'react';

// part of legacy code but seems like its working fine, not sure why :)
const useLegacyData = ({ month, year, initialDate }) => {
  return useMemo(() => {
    const displayed_y_m_combo = month + 12 * year;
    const initial_month_y_m_combo =
      initialDate.getMonth() + 12 * initialDate.getFullYear();
    const today_y_m_combo =
      new Date().getMonth() + 12 * new Date().getFullYear();

    const isPrevMonthAvailable =
      displayed_y_m_combo > today_y_m_combo &&
      displayed_y_m_combo > initial_month_y_m_combo;
    const isNextMonthAvailable = displayed_y_m_combo < today_y_m_combo + 6;

    return {
      isNextMonthAvailable,
      isPrevMonthAvailable
    };
  }, [month, year]);
};

export default useLegacyData;
