import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import { isMobileWidth } from 'helpers/style';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: ${({ left }) => left && `${left}%`};
  transform: translateY(100%);
`;

const StyledText = styled(Text)`
  transform: translateX(-50%);
`;

const Breakpoint = ({ isReached, label, left, ...props }) => {
  const color = isReached ? colors.secondary[500] : colors.gray[500];
  return (
    <Wrapper left={left}>
      <svg
        width="2"
        height="16"
        viewBox="0 0 2 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path opacity="0.99" d="M1 0V16" stroke={color} />
      </svg>

      <StyledText size={10} weight={400}>
        {label}
      </StyledText>
    </Wrapper>
  );
};

export default Breakpoint;
