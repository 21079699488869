import { useCallback, useState } from 'react';

import RATES from 'helpers/job/rates/rates_helper';
import dayRatesHelper from 'helpers/job/rates/day_rates_helper';
import Settings from 'helpers/settings';
import hourlyRates from 'helpers/job/rates/hourly_rates_helper';

const useRateTransition = ({
  setFrequency,
  frequency,
  category,
  setHours,
  setRate
}) => {
  const [rateTransition, setRatesTransition] = useState(false);

  const switchToHourlyRate = useCallback(() => {
    setFrequency(RATES.HOURLY);
    setHours(Settings.minHoursForHourly);
    setRate(hourlyRates[category.lookingFor].default);
    setRatesTransition(false);
  }, [setRatesTransition, frequency, setFrequency, category]);

  const switchToDayRate = useCallback(() => {
    setFrequency(RATES.DAYRATE);
    setHours(null);
    setRate(dayRatesHelper[category.lookingFor].default);
    setRatesTransition(false);
  }, [setRatesTransition, frequency, setFrequency, category]);

  const onChangeRatesTransition = useCallback(
    showTransition => () => {
      if (frequency === RATES.HOURLY) {
        switchToDayRate();
        setRatesTransition(false);
      } else {
        setRatesTransition(showTransition);
      }
    },
    [setRatesTransition, frequency, switchToDayRate]
  );

  return {
    switchToHourlyRate,
    value: rateTransition,
    onChangeRatesTransition
  };
};

export default useRateTransition;
