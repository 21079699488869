import React from 'react';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import styled from 'styled-components';
import Button from 'components/reusable/button';
import { colors } from 'helpers/colors';

const SendOptionButton = styled(Button)`
  width: 100%;
`;

const Wrapper = styled.div`
  padding: 56px 40px 0;
  width: 328px;
  min-width: 328px;
  > :not(:first-child) {
    padding-top: 16px;
    border-top: 1px solid ${colors.gray[100]};
  }
  > :not(:last-child) {
    padding-bottom: 16px;
  }
  > * {
    border-radius: 0;
  }
  @media (max-width: 768px) {
    padding: 24px 16px;
    width: 100%;
  }
`;

const StyledText = styled(Text)`
  @media (min-width: 769px) {
    display: none;
  }
`;

function ShareRightPartition({ sendOptions }) {
  return (
    <Wrapper>
      <StyledText weight={500} size={18}>
        REFER A FRIEND
      </StyledText>
      {sendOptions.map(o => {
        const children = (
          <Flex flex={1} jc="space-between">
            <Text size={16}>{o.label}</Text>
            {o.icon}
          </Flex>
        );

        return o.href ? (
          <div>
            <a href={o.href}>
              <Text size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}>
                {children}
              </Text>
            </a>
          </div>
        ) : (
          <SendOptionButton type={'semantic-wrapper'} onClick={o.onClick}>
            {children}
          </SendOptionButton>
        );
      })}
    </Wrapper>
  );
}

export default ShareRightPartition;
