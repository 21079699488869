import { ActionTypes, ActionsTypes } from './actions'

export const initState = {
  isLoading: false,
  list: [],
  details: {},
  errors: {},
  meta: {},
}

export interface InitStateTypes {
  isLoading: boolean;
  list: Array<{ [key: string]: any }>;
  details: { [key: string]: any };
  errors: { [key: string]: any };
}

/**
 @description PLEASE do not add additional reducers.
 You should only use basic CRUD operations on the backend
 Check SOLID for interface segregation
 If you think about it, that means that you are doing something wrong
 also don't ever add dependencies (other entities) to a serializer
 */

const buildResourceReducer = (actions: ActionsTypes, initial = initState) => {
  const resourceReducer = (state = initial, { type, payload: actionPayload }: ActionTypes) => {
    const meta = actionPayload && actionPayload.meta || {}
    let payload
    if (!actionPayload || !actionPayload.data) {
      payload = {}
    } else {
      payload = actionPayload && actionPayload.data ? actionPayload.data : actionPayload // serializer handling
    }

    switch (type) {
      // append
      case actions.RESOURCE_APPEND_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.RESOURCE_APPEND_SUCCESS:
        const existingResources = [...state.list]
        const nonExistedResources = payload.filter((pi) => {
          const existingIndex = state.list.findIndex((si) => si.id === pi.id)
          existingResources[existingIndex] = pi
          const isPresent = existingIndex !== -1
          return !isPresent
        })

        return {
          ...state,
          list: [...existingResources, ...nonExistedResources],
          meta: { ...state.meta, ...meta },
          isLoading: false,
        }
      case actions.RESOURCE_APPEND_FAILURE:
        return {
          ...state,
          isLoading: false,
          errors: payload,
        }

      // index
      case actions.RESOURCE_FETCH_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.RESOURCE_FETCH_SUCCESS:
        return {
          ...state,
          list: payload,
          meta: { ...state.meta, ...meta },
          isLoading: false,
        }
      case actions.RESOURCE_FETCH_FAILURE:
        return {
          ...state,
          isLoading: false,
          errors: payload,
        }

      // show
      case actions.RESOURCE_GET_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.RESOURCE_GET_SUCCESS:
        return {
          ...state,
          details: payload,
          isLoading: false,
        }
      case actions.RESOURCE_GET_FAILURE:
        return {
          ...state,
          isLoading: false,
          errors: payload,
        }

      // create
      case actions.RESOURCE_CREATE_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.RESOURCE_CREATE_FAILURE:
        return {
          ...state,
          isLoading: false,
          errors: payload,
        }
      case actions.RESOURCE_CREATE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          details: payload,
          list: [...state.list, payload],
        }

      // update
      case actions.RESOURCE_UPDATE_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.RESOURCE_UPDATE_FAILURE:
        return {
          ...state,
          isLoading: false,
          errors: payload,
        }
      case actions.RESOURCE_UPDATE_SUCCESS: {
        const listToUpdate = Array.isArray(payload) ? payload : [payload]
        const idsToUpdate = listToUpdate.map(({ id }) => id)
        const unmodifiedList = state.list.filter((e: any) => !idsToUpdate.includes(e.id))

        return {
          ...state,
          isLoading: false,
          details: { ...state.details, ...payload },
          list: [...unmodifiedList, ...listToUpdate], // TODO add placing at particular index if necessary
        }
      }

      // delete
      case actions.RESOURCE_DELETE_REQUEST:
        return {
          ...state,
          isLoading: true,
        }
      case actions.RESOURCE_DELETE_SUCCESS: {
        const listToDelete = Array.isArray(payload) ? payload : [payload]
        const idsToDelete = listToDelete.map(({ id }) => id)
        const restList = state.list.filter((e: any) => !idsToDelete.includes(e.id))

        return {
          ...state,
          list: restList,
          details: {},
          isLoading: false,
        }
      }

      case actions.RESOURCE_DELETE_FAILURE:
        return {
          ...state,
          isLoading: false,
          errors: payload,
        }

      case actions.RESOURCE_RESET_SUCCESS:
        return initState

      default:
        return state
    }
  }

  return resourceReducer
}

export default buildResourceReducer
