import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import 'font-awesome/css/font-awesome.css';

import register from './registerServiceWorker';
import App from './App';

const container = document.getElementById('main_container');

// react 17
// ReactDOM.render(<App />, container);

// react 18
const root = createRoot(container);
root.render(<App />);

register(); // necessary for desktop notifications
// unregister();
