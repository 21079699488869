import React from 'react';

class DemiSlider extends React.Component {
  state = {
    index: 0
  };

  render() {
    if (!this.props.images) {
      return <div>{''}</div>;
    } else {
      return (
        <div
          style={{
            width: '100%'
          }}
        >
          <div
            style={{
              width: '100%',
              textAlign: 'center'
            }}
          >
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
                width: '10%'
              }}
            >
              <img
                src="/images/icons/icon-left-carat.png"
                style={{
                  opacity: this.state.index === 0 ? '0' : '0.3',
                  background: 'black',
                  width: '40%',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  this.setState({
                    index: this.state.index - 1
                  });
                }}
              />
            </div>
            <div
              style={{
                display: 'inline-block',
                textAlign: 'center',
                width: '80%'
              }}
            >
              <img
                src={this.props.images[this.state.index]}
                style={{
                  maxHeight: '600px'
                }}
                onClick={() => {
                  this.setState({
                    index: (this.state.index + 1) % this.props.images.length
                  });
                }}
              />
            </div>
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
                width: '10%'
              }}
            >
              <img
                src="/images/icons/icon-right-carat.png"
                style={{
                  opacity:
                    this.state.index === this.props.images.length - 1
                      ? '0'
                      : '0.3',
                  background: 'black',
                  width: '40%',
                  cursor: 'pointer'
                }}
                onClick={() => {
                  this.setState({
                    index: this.state.index + 1
                  });
                }}
              />
            </div>
          </div>
          <div
            style={{
              width: '100%',
              textAlign: 'center'
            }}
          >
            {this.props.images.map((image, i) => {
              return (
                <div
                  style={{
                    display: 'inline-block'
                  }}
                >
                  <img
                    src={image}
                    onClick={() => {
                      this.setState({
                        index: i
                      });
                    }}
                    style={{
                      maxHeight: '100px',
                      opacity: i === this.state.index ? '1' : '0.5',
                      cursor: 'pointer'
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
}

class DemiScott extends React.Component {
  render() {
    return (
      <div
        style={{
          minHeight: 'calc(100vh - 125px)',
          color: 'black'
        }}
      >
        <div
          style={{
            width: '90%',
            margin: 'auto',
            marginTop: '30px'
          }}
        >
          <div>
            <iframe
              width="100%"
              style={{
                minHeight: '450px'
              }}
              src="https://player.vimeo.com/video/201740117"
              frameBorder="0"
              allowFullScreen={true}
            />
            <div
              style={{
                marginBottom: '40px'
              }}
            >
              <h1
                style={{
                  fontFamily: 'Avenir-Black',
                  letterSpacing: '2px',
                  marginTop: '40px',
                  fontSize: '34px'
                }}
              >
                NEW SPACE - NICK KNIGHT & DEMI HANNAH SCOTT
              </h1>
              <p
                style={{
                  fontFamily: 'Avenir-Light',
                  fontSize: '1.4em'
                }}
              >
                'New Space', Nick Knight’s groundbreaking new film in
                collaboration with Beats by Dre, features unorthodox fresh face
                Demi Hannah Scott and her svelte, aerodynamic physique. The
                kaleidoscopic exuberance of the video, which marries 3D
                animation with fashion film and images in a dizzying ricochet
                between forms, captures the vertiginous speed and ever-growing
                multitude of mediums with which we communicate and express
                ourselves.
              </p>
            </div>
            <div
              style={{
                width: '70%',
                margin: 'auto',
                marginBottom: '60px'
              }}
            >
              {/*<DemiSlider*/}
              {/*  images={[*/}
              {/*    'https://s30.postimg.org/wizfkrw29/demi1.jpg',*/}
              {/*    'https://s24.postimg.org/ldjq4hxtx/demi2.jpg',*/}
              {/*    'https://s28.postimg.org/kw3gomkvh/demi3.jpg',*/}
              {/*    'https://s30.postimg.org/ey46ce90h/demi4.jpg',*/}
              {/*    'https://s27.postimg.org/m1o683h2r/demi5.jpg',*/}
              {/*    'https://s24.postimg.org/y8xvisrr9/demi6.jpg'*/}
              {/*  ]}*/}
              {/*/>*/}
            </div>
            <div
              style={{
                marginBottom: '40px'
              }}
            >
              <p
                style={{
                  fontFamily: 'Avenir-Light',
                  fontSize: '1em'
                }}
              >
                <b>{'Credits: '}</b>
                Nick Knight (Director), Demi Hannah Scott (Model), Beats by Dre
                (Retailer), Omar Johnson, Younji Ku (Filmmaker), Jon Emmony (Art
                Director), NOLIFE (Musician), Tino Kamal (Rapper)
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { DemiSlider, DemiScott };
