import React from 'react';
import NotificationSnapshotContainer from 'components/views/logged_in/notifications/notification_components/notification_snapshot_container';
import styled from 'styled-components';

const NotificationContainer = styled.div`
  flex: 1;
  width: 100%;
`;

const NotificationList = props => (
  <NotificationContainer>
    {props.notifications.length > 0 ? (
      props.notifications.map((notification, index) => {
        let notificationImage;
        if (notification && notification.image_url) {
          notificationImage = notification.image_url;
        } else {
          notificationImage = '-';
        }
        return (
          <NotificationSnapshotContainer
            rootProps={props.rootProps}
            activateLoading={props.activateLoading}
            disableLoading={props.disableLoading}
            cancelNotifications={props.cancelNotifications}
            notification={notification}
            key={index}
            notification_index={index}
            fetchNotifications={props.fetchNotifications}
            notificationImage={notificationImage}
          />
        );
      })
    ) : (
      <div
        style={{
          fontFamily: 'Avenir-Roman,Lato',
          width: '100%',
          height: '80px',
          textAlign: 'center',
          padding: '20px',
          fontSize: '1rem'
        }}
      >
        You don't have any notifications yet. Once you do, they will appear
        here.
      </div>
    )}
  </NotificationContainer>
);

export default NotificationList;