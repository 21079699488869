import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import withInputWrapper from 'components/reusable/input/hocs/withInputWrapper';

const SelectComponent = styled.select`
  display: inline-block;
  font-family: Gotham Pro;
  color: ${({ value }) => (value ? colors.gray[700] : colors.gray[500])};
  height: 48px;
  border-radius: 24px;
  padding: 0 40px 0 16px;
  font-size: 1rem;
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.24);
  border: none;
  outline: none;
  width: 100%;
  letter-spacing: 0.5px;
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-color: ${colors.light}; /* For IOS */
  text-align-last: ${({ textAlign }) => textAlign};
  text-align: ${({ textAlign }) => textAlign};

  @media (max-width: 768px) {
    display: inline-block;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Select = ({
  textAlign = 'center',
  value,
  onChange,
  placeholder = 'Select a value',
  error,
  children,
  label,
  options,
  showError = true,
  ...props
}) => {
  const handleChange = useCallback(
    e => {
      const v = e.target.value;
      if (onChange && v !== '') onChange(v);
    },
    [onChange]
  );

  useLayoutEffect(() => {
    if (!options) console.error('Please provide options array');
  }, [options]);

  return (
    <InputWrapper className={props.className}>
      <SelectComponent
        textAlign={textAlign}
        placeholder={placeholder}
        onChange={handleChange}
        value={value || ''}
        {...props}
      >
        <option value="" disabled>
          {placeholder}
        </option>

        {options &&
          options.map(({ value, label }) => {
            return (
              <option key={label} value={value}>
                {label}
              </option>
            );
          })}
      </SelectComponent>
      <ArrowWrapper>{svgIcons.arrowBottom(colors.gray[300])}</ArrowWrapper>
    </InputWrapper>
  );
};

export default withInputWrapper(Select);
