import Pusher from 'pusher-js';
import { isMobile } from 'react-device-detect';

import AuthHelper from 'helpers/auth_helper.js';
import UserHelpers from 'helpers/user_helpers.js';
import Settings from 'helpers/settings';

const notificationActions = {
  moveToConversation: 'moveToConversation'
};

const WebNotificationHelper = {
  goToConversation(conversationId) {
    if (!conversationId) return;
    const redirectUrl = `/chat/${conversationId}`;
    if (window.location.pathname === redirectUrl) return;
    return (window.location.href = redirectUrl);
  },

  async initWebNotifications() {
    if (!('Notification' in window)) return console.error('Notifications not supported');
    try {
      const permission = await Notification.requestPermission();
      if (Settings.LOGGING) console.log(`Notifications ${permission} ${Notification.permission}`);

      UserHelpers.loadUser(AuthHelper.logged_in_profileID, user => {
        // TODO rework to use SCPusher singleton
        const pusher = new Pusher(Settings.SWIPECAST_PUSHER_APP_KEY, {
          cluster: Settings.SWIPECAST_PUSHER_APP_CLUSTER
        });

        const channel = pusher.subscribe(user.secret_alias_id);
        channel.bind('message', data => {
          const isRecipient = AuthHelper.logged_in_profileID !== data.swipecast_user_id;

          if (isRecipient) {
            this.triggerNotification(
              `New message from ${data.first_name}`,
              data.message,
              {
                action: notificationActions.moveToConversation,
                conversationId: data.conversation_id
              },
              () => this.goToConversation(data.conversation_id)
            );
          }
        });
      });
    } catch (error) {
      // Safari doesn't return a promise for requestPermissions and it
      // throws a TypeError. It takes a callback as the first argument
      // instead.
      if (error instanceof TypeError) {
        Notification.requestPermission(() => {});
      } else {
        throw error;
      }
    }
  },

  triggerNotification: async function(title, body, data, callback) {
    if (typeof Notification === 'undefined') return;

    if (Notification.permission === 'granted') {
      this.handleNotification(title, body, data, callback);
    } else {
      await Notification.requestPermission();
      this.handleNotification(title, body, data, callback);
    }
  },

  handleNotification: async function(title, body, data, callback) {
    if (isMobile) {
      this.handleSwNotification(title, body, data);
    } else {
      this.handleDesktopNotification(title, body, callback);
    }
  },

  handleSwNotification: async function(title, body, data = {}) {
    const registration = await navigator.serviceWorker.ready;
    registration.showNotification(title, {
      icon: '/images/icons/sc-logo.png',
      data,
      body
    });
  },

  handleDesktopNotification: function(title, body, callback) {
    const notification = new Notification(title, {
      icon: '/images/icons/sc-logo.png',
      body
    });
    notification.onclick = function() {
      window.focus();
      this.close();
      if (callback) callback();
    };
    // setTimeout(notification.close.bind(notification), 5000);
  }
};

export default WebNotificationHelper;
