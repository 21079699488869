import { useMemo } from 'react';

import { valueToProgress } from '../helpers/values_helper';

const useBreakpoints = ({ breakpoints, max, min, midPoint }) => {
  return useMemo(() => {
    return breakpoints
      .filter(bp => bp.value >= min && bp.value <= max)
      .sort((a, b) => a.value - b.value)
      .map(bp => ({
        label: `$${bp.value}`,
        value: valueToProgress({ midPoint, min, max, value: bp.value })
      }));
  }, [breakpoints]);
};

export default useBreakpoints;
