import React from 'react';
import { connect } from 'react-redux';
import NotificationList from 'components/views/logged_in/notifications/notification_list';
import styled from 'styled-components';
import { fetchNotifications } from 'store/actions';
import { getNotifications } from 'store/reducers';

const DropdownContainer = styled.div`
  z-index: 300;
  width: 600px;
  position: fixed;
  right: 0px;
  background: white;
  top: 80px;
  border-bottom: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  border-top: 1px solid #777777;
  max-height: 400px;
  overflow: scroll;
`;

const NotificationDropdownStatic = props => (
  <div>
    {props.activated ? (
      <div>
        <div className="popup_backdrop" onClick={props.cancelNotifications} />
        <DropdownContainer>
          <NotificationList
            notifications={props.notifications.data}
            fetchNotifications={props.fetchNotifications}
            rootProps={props.rootProps}
          />
        </DropdownContainer>
      </div>
    ) : (
      <div />
    )}
  </div>
);

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

const NotificationDropdown = connect(mapStateToProps, {
  fetchNotifications
})(NotificationDropdownStatic);

export default NotificationDropdown;