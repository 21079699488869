import React, { useMemo } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import DateHelpers from 'helpers/date_helpers';
import svgIcons from 'helpers/svgIcons';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import SquareImage from 'components/complex/square_image';
import ApplicationHelper from 'helpers/application_helper';
import SvgAppleEmailSolid from 'components/reusable/svg/share_buttons/svg_apple_email_solid';
import SvgAppleSmsSolid from 'components/reusable/svg/share_buttons/svg_apple_sms_solid';
import SvgWhatsAppSolid from 'components/reusable/svg/share_buttons/svg_whats_app_solid';

const Wrapper = styled(Flex)`
  max-width: 760px;
  width: 100%;
  padding-bottom: 40px;
  position: relative;
  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
`;

const LeftWrapper = styled(Flex)`
  @media (min-width: 769px) {
    border-right: 1px solid ${colors.gray[100]};
  }
`;

const TitleWrapper = styled(Flex)`
  padding: 56px 40px 24px;
  border-bottom: 1px solid ${colors.gray[100]};
  @media (max-width: 768px) {
    border: none;
    padding: 36px 20px 16px;
    justify-content: space-between;
    width: 100%;
  }
`;

const ProjectInfoWrapper = styled(Flex)`
  padding: 24px 40px 0;
  height: 100%;
  @media (max-width: 768px) {
    padding: 16px 20px;
  }
`;

const ImageWrap = styled.div`
  min-width: 112px;
  min-height: 112px;
`;

const RightWrapper = styled(Flex)`
  padding: 56px 40px 0;
  @media (max-width: 768px) {
    width: 100%;
    padding: 16px 20px 0;
  }
`;

const ActionWrap = styled(Flex)`
  cursor: pointer;
  padding-bottom: 16px;
  ${({ last }) => !last && `border-bottom: 1px solid ${colors.gray[100]}`};
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ButtonWrap = styled.div`
  width: 160px;
  @media (max-width: 768px) {
    width: 100%;
    padding: 0 20px;
  }
`;

const ShareProjectModal = ({ onClose, modalProps }) => {
  const shareLink = `${window.location.origin}/project/${modalProps.project.swipecast_project_id}?pid=${modalProps.project.secret_alias_id}`;

  const actions = [
    {
      text: 'Text Message',
      icon: <SvgAppleSmsSolid size={24} />,
      onClick: () => window.open(`sms:?&body=${shareLink}`, '_blank')
    },
    {
      text: 'WhatsApp',
      icon: <SvgWhatsAppSolid size={24} />,
      onClick: () => window.open(`https://wa.me/?text=${shareLink}`, '_blank')
    },
    {
      text: 'E-Mail',
      icon: <SvgAppleEmailSolid size={24} />,
      onClick: () =>
        window.open(`mailto:?subject=Sharing&body=${shareLink}`, '_blank')
    },
    {
      text: 'Copy Link',
      icon: svgIcons.copyLinkV2({ width: 24, height: 24 }),
      onClick: () => {
        navigator.clipboard.writeText(shareLink);
        onClose();
      }
    }
  ];

  const renderAction = (el, index) => (
    <ActionWrap
      key={el.text}
      width={200}
      jc="space-between"
      wrap="nowrap"
      onClick={el.onClick}
      last={index === actions.length - 1}
    >
      <Text size={[{ maxWidth: 1200, size: 14 }, { size: 16 }]}>{el.text}</Text>
      {el.icon}
    </ActionWrap>
  );

  const projectLocation = `${modalProps?.project?.jobs[0]?.city}, ${modalProps?.project?.jobs[0]?.country}`;
  const dateLabel = useMemo(
    () =>
      modalProps?.project?.jobs[0]?.dates &&
      DateHelpers.timeStampLabelV2(modalProps?.project?.jobs[0]?.dates),
    [modalProps?.project]
  );

  return (
    <Wrapper dir="column">
      <Flex
        width="100%"
        wrap="nowrap"
        dir={ApplicationHelper.isMobile ? 'column' : 'row'}
        ai="flex-start"
      >
        <LeftWrapper dir="column">
          <TitleWrapper jc="flex-start" width="100%">
            <Text
              size={[
                { maxWidth: 768, size: 18 },
                { maxWidth: 1200, size: 20 },
                { size: 24 }
              ]}
              weight={500}
              textTransform="uppercase"
            >
              Share job
            </Text>
          </TitleWrapper>
          <ProjectInfoWrapper
            gap={24}
            wrap="nowrap"
            width={420}
            jc="flex-start"
            ai="flex-start"
          >
            <ImageWrap>
              <SquareImage
                fetched={!!modalProps.project}
                src={modalProps.project?.images[0]}
              />
            </ImageWrap>

            <Flex dir="column" ai="flex-start" gap={8} wrap="nowrap">
              <Text
                size={ApplicationHelper.isMobile ? 16 : 18}
                weight={500}
                lines={2}
              >
                {modalProps?.project?.name}
              </Text>

              <Flex dir="column" gap={4} ai="flex-start">
                <Text size={12} textTransform="uppercase" textAlign="left">
                  {modalProps?.project?.project_type}
                </Text>
                <Text size={12} textTransform="uppercase">
                  {projectLocation}
                </Text>
                <Text size={12} textTransform="uppercase">
                  {dateLabel}
                </Text>
              </Flex>
            </Flex>
          </ProjectInfoWrapper>
        </LeftWrapper>

        <RightWrapper dir="column" gap={16}>
          {actions.map((action, i) => renderAction(action, i))}
        </RightWrapper>
      </Flex>
      <Indent bottom={ApplicationHelper.isMobile ? 20 : 40} />

      <ButtonWrap>
        <Button
          color={colors.gray[600]}
          borderColor="transparent"
          height={40}
          width="100%"
          onClick={onClose}
        >
          Cancel
        </Button>
      </ButtonWrap>
    </Wrapper>
  );
};

export default ShareProjectModal;
