import React from 'react';

import { colors } from 'helpers/colors';

function SvgArrowLeftSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 10 16" fill="none">
      <path
        d="M9 15L2 8L9 1"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgArrowLeftSolid;
