import React, { useCallback, useContext, useState } from 'react';
import { reduxForm } from 'redux-form';

import ResetPasswordForm from './components/reset_password_form';
import { validateEmail } from 'helpers/validation_helper';
import AuthHelper from 'helpers/auth_helper';
import { RootContext } from 'contexts/contexts';

function ResetPasswordModal({ onClose, handleSubmit, ...props }) {
  const isSubmitDisabled = props.invalid || props.submitting || props.pristine;
  const rootContext = useContext(RootContext);
  const [error, setError] = useState('');

  const onResetPassword = useCallback(data => {
    rootContext.setLoading(true);
    AuthHelper.passwordResetEmail(
      data.email,
      () => {
        alert(
          'We have sent you an email with instructions to update your password!'
        );
        onClose();
        rootContext.setLoading(false);
      },
      err => {
        console.log(err);
        setError(err.message);
        rootContext.setLoading(false);
      }
    );
  }, []);

  const componentProps = {
    onClose,
    error,
    isSubmitDisabled,
    handleSubmit: handleSubmit(onResetPassword)
  };

  return <ResetPasswordForm {...componentProps} />;
}

const validate = data => {
  const errors = {};
  if (!validateEmail(data.email)) {
    errors.email = 'Please provide a valid email';
  }
  return errors;
};

export default reduxForm({
  form: 'resetPassword',
  validate
})(ResetPasswordModal);
