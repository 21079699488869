import capitalize from 'lodash.capitalize';

// Documentation https://docs.google.com/spreadsheets/d/1E_blZ2rKfP3TW5P5voLSpSfuLc-bJMeWXecIHYsJXso/edit#gid=0
export const modelsPartsInfluencersBreakpoints = talentLabel => [
  {
    min: 3500,
    label: `Top Campaign ${talentLabel}`,
    message: 'Reaches the top talent on the platform.'
  },
  {
    min: 2000,
    label: `Runway / Campaign ${talentLabel}`,
    message: 'Great rate for elevating your brands.'
  },
  {
    min: 1500,
    label: `Runway ${capitalize(talentLabel)} / Editorial ${capitalize(
      talentLabel
    )}`,
    message: 'Appeals to higher-end talent.',
    hideOnScale: true
  },
  {
    min: 1250,
    label: `New face / Runway ${talentLabel}`,
    message: 'Great for reaching broad range of talent.'
  },
  {
    min: 1000,
    label: `New face / Runway ${talentLabel}`,
    message: 'Great for reaching broad range of talent.'
  },
  {
    min: 800,
    label: 'New face talent',
    message: 'Good for basic lookbooks. Raise rate to attract better talent.'
  },
  {
    min: 650,
    label: 'New face talent',
    message: 'At this rate, your job may not be approved. Try raising the rate.'
  },
  {
    min: 0,
    label: 'New face talent',
    message: 'At this rate, your job may not be approved. Try raising the rate.'
  }
];

export const makeupHairBreakpoints = () => [
  {
    min: 2000,
    label: 'Campaign-level Artist / Advertising / Campaigns',
    message: 'Reaches highest rated talent.'
  },
  {
    min: 1500,
    label: 'Campaign-level Artist / Advertising / Campaigns',
    message: 'Reaches top talent.'
  },
  {
    min: 1000,
    label: 'Experienced Artist / Advertising / Campaigns',
    message: 'Reaches top talent.',
    hideOnScale: true
  },
  {
    min: 800,
    label: 'Experienced artist / Campaigns',
    message: 'Experienced talent.'
  },
  {
    min: 700,
    label: 'Mid-level artist / Lookbooks / Ecomm',
    message: 'Perfect for lookbooks and e-comm.'
  },
  {
    min: 650,
    label: 'Mid-level artist / Lookbooks / Ecomm',
    message: 'Great for simple lookbooks and e-comm.'
  },
  {
    min: 600,
    label: 'Emerging talent',
    message: 'At this rate, your job may no be approved. Try raising the rate.'
  },
  {
    min: 0,
    label: 'Emerging talent',
    message:
      'This is the minimum rate for lookbooks or ecomm. Raise rate to attract higher level talent.'
  }
];

export const photoVideoBreakpoints = talentLabel => {
  const shortLabel = talentLabel.toLowerCase().includes('photo')
    ? 'photo'
    : 'video';
  return [
    {
      min: 7500,
      label: `Campaign ${capitalize(
        talentLabel
      )} / Advanced Lighting Options / Digital Technician / ${capitalize(
        shortLabel
      )} Studio`,
      message: `Campaign level ${shortLabel} team.`
    },
    {
      min: 5000,
      label: `${capitalize(
        talentLabel
      )}  / Advanced Lighting Options / Digital Technician / ${capitalize(
        shortLabel
      )} Studio`,
      message: `Premium ${shortLabel}graphy package.`,
      hideOnScale: true
    },
    {
      min: 3500,
      label: `Advertising / Advanced Lighting Options / ${capitalize(
        shortLabel
      )} Assistant`,
      message: `Attracts more experienced ${talentLabel}s. Ideal for elevating your brand.`
    },
    {
      min: 2000,
      label: `Advertising / Good Lighting Options / ${capitalize(
        shortLabel
      )} Assistant`,
      message:
        'Good rate for shooting multiple models. Advanced lighting options.'
    },
    {
      min: 1500,
      label: `Ecomm and Lookbooks / Good Lighting Options / ${capitalize(
        shortLabel
      )} Assistant`,
      message: 'Includes basic lighting package.'
    },
    {
      min: 1250,
      label: `Social Media / Basic Lighting Options / No ${capitalize(
        shortLabel
      )} Assistant`,
      message: 'Good rate for a lookbook or ecomm.',
      hideOnScale: true
    },
    {
      min: 900,
      label: `Social Media / Basic Lighting Options / No ${capitalize(
        shortLabel
      )} Assistant`,
      message: `Emerging ${talentLabel}. Raise rate to attract higher-level talent.`
    },
    {
      min: 0,
      label: `Social Media / Basic Lighting Options / No ${capitalize(
        shortLabel
      )} Assistant`,
      message: `Entry level’ rate for an emerging ${talentLabel}. Raise rate to attract higher-level talent.`
    }
  ];
};

export const stylistPropBreakpoints = () => [
  {
    min: 2500,
    label: 'Top stylist (prestige clients)',
    message: 'Reaches highest rated talent.'
  },
  {
    min: 2000,
    label: 'Top stylist (prestige clients)',
    message: 'Reaches top talent.'
  },
  {
    min: 1500,
    label: 'Top stylist (prestige clients)',
    message: 'Reaches top talent.'
  },
  {
    min: 1250,
    label: 'Top stylist (prestige clients)',
    message: 'Experienced talent.',
    hideOnScale: true
  },
  {
    min: 1000,
    label: 'Top stylist',
    message: 'Perfect for lookbooks and e-comm.'
  },
  {
    min: 850,
    label: 'Experienced stylist',
    message: 'Great for simple lookbooks and e-comm.'
  },
  {
    min: 700,
    label: 'Junior stylist',
    message: 'At this rate, your job may not be approved. Try raising the rate.'
  },
  {
    min: 0,
    label: 'Junior stylist',
    message: 'At this rate, your job may not be approved. Try raising the rate.'
  }
];

export const manicBreakpoints = () => [
  {
    min: 1000,
    label: 'Campaign-level Artist / Advertising / Campaigns',
    message: 'Reaches highest rated talent.'
  },
  {
    min: 850,
    label: 'Campaign-level Artist / Advertising / Campaigns',
    message: 'Reaches highest rated talent.'
  },
  {
    min: 750,
    label: 'Campaign-level Artist / Advertising / Campaigns',
    message: 'Reaches top talent.'
  },
  {
    min: 600,
    label: 'Experienced Artist / Advertising / Campaigns',
    message: 'Reaches top talent.'
  },
  {
    min: 575,
    label: 'Experienced artist / Campaigns',
    message: 'Experienced talent.'
  },
  {
    min: 550,
    label: 'Mid-level artist / Lookbooks / Ecomm',
    message: 'Perfect for lookbooks and e-comm.'
  },
  {
    min: 525,
    label: 'Mid-level artist / Lookbooks / Ecomm',
    message: 'Great for simple lookbooks and e-comm.'
  },
  {
    min: 500,
    label: 'Emerging talent',
    message: 'At this rate, your job may no be approved. Try raising the rate.'
  },
  {
    min: 0,
    label: 'Emerging talent',
    message: 'Starting rate for 1 model for e-comm and lookbook shoots.'
  }
];

export const rentalBreakpoints = () => [
  {
    min: 2000,
    label: 'Top Studio / Lighting Options',
    message: 'Top studio with strong lighting and equipment options.'
  },
  {
    min: 1500,
    label: 'Top Studio / Lighting Options',
    message: 'Top studio with strong lighting and equipment options.'
  },
  {
    min: 1250,
    label: 'Top Studio / Lighting Options',
    message: 'Top studio with strong lighting and equipment options.',
    hideOnScale: true
  },
  {
    min: 1000,
    label: 'E-Comm Studio / Good Lighting',
    message: 'Great for basic e-comm and lookbook shoots.'
  },
  {
    min: 850,
    label: 'E-Comm Studio / Good Lighting',
    message: 'Great for basic e-comm and lookbook shoots.'
  },
  {
    min: 750,
    label: 'Entry level studio'
  },
  {
    min: 650,
    label: 'Entry level studio'
  },
  {
    min: 0,
    label: 'Entry level studio',
    message: 'At this rate, your job may not be approved. Try raising the rate.'
  }
];

export const castingProducersRetBreakpoints = talentLabel => {
  const castingLabels = [
    'Casting Director (Junior)',
    'Casting Director (Junior)',
    'Casting Director (Junior)',
    'Casting Director (Midlevel)',
    'Casting Director (Midlevel)',
    'Casting Director (Top Level)',
    'Casting Director (Top Level)',
    'Casting Director (Top Level)'
  ];
  const producerLabels = [
    'Junior Producer',
    'Junior Producer',
    'Junior Producer',
    'Midlevel Producer',
    'Senior Producer',
    'Senior Producer',
    'Senior Production Team',
    'Senior Production Team'
  ];
  const retoucherLabels = [
    'Junior Retoucher',
    'Junior Retoucher',
    'Junior Retoucher',
    'Midlevel Retoucher',
    'Midlevel Retoucher',
    'Retoucher for Campaigns',
    'Retoucher for Campaigns',
    'Retoucher for Campaigns'
  ];
  const talentLabels = talentLabel.includes('asting')
    ? castingLabels
    : talentLabel.includes('roducer')
    ? producerLabels
    : retoucherLabels;

  return [
    {
      min: 2500,
      label: talentLabels[7],
      message: `Attracts the very best ${talentLabel}s on the platform.`
    },
    {
      min: 2000,
      label: talentLabels[6],
      message: `Attracts the top ${talentLabel}s, on the platform.`
    },
    {
      min: 1500,
      label: talentLabels[5],
      message: `Attracts the top ${talentLabel}s, on the platform.`,
      hideOnScale: true
    },
    {
      min: 1250,
      label: talentLabels[4],
      message: `Appeals to a broad range of ${talentLabel}s on the platform.`
    },
    {
      min: 800,
      label: talentLabels[3],
      message: `At this rate, you might miss out on some of the top ${talentLabel}s on the platform.`
    },
    {
      min: 750,
      label: talentLabels[2],
      message: `At this rate, you might miss out on some of the top ${talentLabel}s on the platform.`,
      hideOnScale: true
    },
    {
      min: 650,
      label: talentLabels[1],
      message:
        'At this rate, your job may not be approved. Try raising the rate.'
    },
    {
      min: 0,
      label: talentLabels[0],
      message: `At this rate, your job may not be approved. Try raising the rate.`
    }
  ];
};

export const creativeBreakpoints = () => [
  {
    min: 3500,
    label: 'Top Creative Director'
  },
  {
    min: 2500,
    label: 'Top Creative Director',
    hideOnScale: true
  },
  {
    min: 2000,
    label: 'Top Creative Director'
  },
  {
    min: 1500,
    label: 'Top Creative Director'
  },
  {
    min: 1250,
    label: 'Creative Director'
  },
  {
    min: 1000,
    label: 'Creative Director'
  },
  {
    min: 850,
    label: 'Junior Creative Director',
    hideOnScale: true
  },
  {
    min: 0,
    label: 'Junior Art Director',
    message: 'Great rate for junior Art Director with 2-3 years experience.'
  }
];
