import React from 'react';
import styled from 'styled-components';

import Tip from 'components/complex/tip';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import Settings from 'helpers/settings';

const TriggerText = styled(Text)`
  width: fit-content;
`;
const TipListItem = styled.li`
  font-size: 12px;
`;
const TipList = styled.ul`
  padding-left: 20px;
`;

export const FeeTip = () => {
  return (
    <Tip
      trigger={
        <TriggerText color={colors.primary[500]}>
          Why do we charge this?
        </TriggerText>
      }
    >
      <TipList>
        <TipListItem>
          The {Settings.clientFee} fee covers the costs of running the platform
          (agencies charge 20%).
        </TipListItem>
        <Indent bottom={12} />
        <TipListItem>
          The fee also helps pay for our client managers and customer support.
        </TipListItem>
        <Indent bottom={12} />
        <TipListItem>
          When you pay by credit card, we're able to protect you by holding your
          money in escrow until 24 hours after the shoot.
        </TipListItem>
      </TipList>
    </Tip>
  );
};

export const LearnMoreTip = () => {
  return (
    <Tip
      trigger={
        <TriggerText color={colors.primary[500]}>Learn more</TriggerText>
      }
    >
      <Text size={12}>
        Only bookings completed through our site will be covered by our fraud
        safeguards and cancelation protection.
      </Text>
      <Indent bottom={12} />
      <TipList>
        <TipListItem>
          Talent Logistics: We can assist with any talent issues including
          travel and accommodation.
        </TipListItem>
        <Indent bottom={12} />
        <TipListItem>
          24/7 Customer Support: Our client managers can be reached any time,
          including weekends and holidays.
        </TipListItem>
        <Indent bottom={12} />
        <TipListItem>
          Swipecast Guarantee: If there are ever any issues with your booking,
          we will work to resolve them, including providing other talent options
          if needed.
        </TipListItem>
      </TipList>
    </Tip>
  );
};
