import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import Link from 'components/reusable/link';
import Text from 'components/reusable/text';
import Flex from 'components/reusable/flex';

export const StyledText = styled(Text)`
  @media (max-width: 768px) {
    flex: 1;
  }
`;

export const TextAndIconsWrapper = styled.div`
  display: flex;
  margin-bottom: 12px;
  @media (max-width: 768px) {
    display: flex;
    margin-bottom: 8px;
  }
`;

export const Banner = styled(Flex)`
  z-index: 4;
  max-width: 1360px;
  width: calc(100% - 80px);
  background-color: ${colors.light};
  box-shadow: 0px 0px 40px rgba(31, 30, 30, 0.1);
  height: 72px;
  min-height: 72px;
  border-radius: 200px;
  position: fixed;
  inset: auto 40px 40px 50%;
  transform: translateX(-50%);

  @media (max-width: 768px) {
    max-width: 500px;
    justify-content: flex-start;
    padding: 8px 12px;
    width: calc(100% - 32px);
    height: auto;
    max-height: 65px;
    border-radius: 12px;
    inset: auto 16px
      ${({ bottomOffset = '0px' }) => `calc(${bottomOffset} + 16px)`} 50%;
  }
`;

export const IconsWrapper = styled.span`
  display: flex;
  @media (max-width: 768px) {
    display: inline;
    vertical-align: middle;
    > :first-child {
      margin-right: 4px;
    }
    > :nth-child(2) {
      margin-right: 8px;
    }
  }
`;

export const StyledLink = styled(Link)`
  color: ${colors.gray[700]};
  margin-left: 12px;
  @media (max-width: 768px) {
    margin-left: 0;
    font-size: 12px;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 44px;
  @media (max-width: 768px) {
    right: 18px;
    color: ${colors.gray[700]};
  }
`;
