import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import { getDimention } from 'helpers/style';
import useNodeDimensions from 'hooks/use_node_dimensions';

const getBorderWidth = ({ width }) => {
  if (width < 40) return '1px';
  if (width < 160) return '2px';
  if (width < 320) return '3px';
  return '4px';
};

const FallbackWrapper = styled.div`
  display: inline-flex;
  background-color: ${colors.gray[700]};
  align-items: center;
  justify-content: center;
  color: ${colors.light};

  ${({ width }) =>
    css`
      width: ${getDimention(width)};
      min-width: ${getDimention(width)};
    `};
  ${({ height }) =>
    css`
      height: ${getDimention(height)};
    `};
  ${({ br }) =>
    css`
      border-radius: ${getDimention(br)};
    `};
`;

const CircleWrapper = styled.div`
  font-weight: normal;
  z-index: inherit;
  border-radius: 100%;
  border: ${getBorderWidth} solid ${colors.gray[300]};
  font-size: ${({ width }) => getDimention(width / 2.6) || '1rem'};
  position: relative;
  width: ${({ width }) => width * 0.66 || '66%'};
  height: ${({ width }) => width * 0.66 || '66%'};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Symbol = styled.span`
  line-height: 1em;
  height: 1.25em;
  transform: translateY(12%);
  color: ${colors.gray[300]};
`;

function ImageFallback(props) {
  const { dimensions, targetRef } = useNodeDimensions();

  return (
    <FallbackWrapper
      ref={targetRef}
      className="image__fallback-wrapper"
      {...props}
    >
      <CircleWrapper className="image__fallback" width={dimensions.width}>
        <Symbol>S</Symbol>
      </CircleWrapper>
    </FallbackWrapper>
  );
}

export default ImageFallback;
