import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileMovOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1V5C12 5.26522 12.1054 5.51957 12.2929 5.70711C12.4805 5.89464 12.7348 6 13 6H17M12 1H5.00002C4.46959 1 3.96088 1.21071 3.58581 1.58579C3.21074 1.96086 3.00002 2.46957 3.00002 3V10M12 1L17 6M17 6V10M15 13L17 19L19 13M1.40002 19V13L3.90002 16L6.40002 13V19M10.9 13C11.2978 13 11.6794 13.158 11.9607 13.4393C12.242 13.7206 12.4 14.1022 12.4 14.5V17.5C12.4 17.8978 12.242 18.2794 11.9607 18.5607C11.6794 18.842 11.2978 19 10.9 19C10.5022 19 10.1207 18.842 9.83936 18.5607C9.55806 18.2794 9.40002 17.8978 9.40002 17.5V14.5C9.40002 14.1022 9.55806 13.7206 9.83936 13.4393C10.1207 13.158 10.5022 13 10.9 13Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileMovOutline;
