import React from 'react';
import styled from 'styled-components';
import QRCode from 'react-qr-code';

import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';
import RouterHelper, { routes } from 'helpers/router_helper';
import AuthHelper from 'helpers/auth_helper';
import Link from 'components/reusable/link';

function HamburgerMenuQr() {
  const user = AuthHelper.currentUser();
  const href = RouterHelper.getLIBReferralLink(user);

  return (
    <StyledLink to={routes.profileQrShare.redirectUrl()}>
      <QrWrapper>
        <QRCode size={80} value={href} viewBox={`0 0 256 256`} />
      </QrWrapper>

      <Indent top={12} />
      <Text textAlign="center" color={colors.light} size={10}>
        SHARE PROFILE
      </Text>
    </StyledLink>
  );
}

const StyledLink = styled(Link)`
  display: block;
  margin: 24px auto;
  width: fit-content;
  background-image: url('/images/backgrounds/tripple-gradient-sm.png');
  background-size: cover;
  padding: 8px 8px 12px;
  border-radius: 12px;

  @media (min-width: 769px) {
    display: none;
  }
`;

const QrWrapper = styled.div`
  padding: 12px;
  border-radius: inherit;
  background-color: ${colors.light};
`;

export default HamburgerMenuQr;
