import React, { useCallback } from 'react';
import styled from 'styled-components';

import { CloseButton } from 'components/modals/v2/job_rate_edit_modal/components/job_rate_edit_content/styled';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';

export const Wrapper = styled.div`
  width: 480px;
  max-width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

export const SwitchRateModal = ({ modalProps, onClose }) => {
  const { switchToHourlyRate } = modalProps;
  const handleSwitch = useCallback(() => {
    switchToHourlyRate();
    onClose();
  }, [switchToHourlyRate, onClose]);

  return (
    <Wrapper>
      <CloseButton onClick={onClose} type="semantic-wrapper">
        {svgIcons.close(colors.gray[700], { width: 24, height: 24 })}
      </CloseButton>

      <Text textAlign="center" size={18} weight={500}>
        Switch to hourly rate?
      </Text>

      <Indent top={16} />
      <Text size={14} color={colors.gray[400]} textAlign="center">
        Use hourly for presentations and small projects not requiring usage. For
        eComm and lookbook select Day Rate.
      </Text>
      <Indent top={40} />
      <Button width="100%" height={40} type="primary" onClick={onClose}>
        Continue with Day Rate
      </Button>
      <Indent top={16} />
      <Button
        width="100%"
        height={40}
        type="gray-shadow"
        onClick={handleSwitch}
      >
        Proceed to Hourly Rate
      </Button>
    </Wrapper>
  );
};

export default SwitchRateModal;
