import {
  modelsPartsInfluencersBreakpoints,
  makeupHairBreakpoints,
  photoVideoBreakpoints,
  stylistPropBreakpoints,
  manicBreakpoints,
  rentalBreakpoints,
  castingProducersRetBreakpoints,
  creativeBreakpoints
} from './breakpoints';

const templates = {
  modelInfParts: {
    min: 750,
    max: 5000,
    minRecommended: 1000,
    maxRecommended: 2000,
    default: 1500,
    midPoint: 1600,
    buildBreakpoints: modelsPartsInfluencersBreakpoints
  },
  hairMakeUp: {
    min: 650,
    max: 2000,
    minRecommended: 700,
    maxRecommended: 1100,
    default: 750,
    midPoint: 800,
    buildBreakpoints: makeupHairBreakpoints
  },
  photoVideo: {
    min: 1000,
    max: 10000,
    minRecommended: 1500,
    maxRecommended: 4000,
    default: 2000,
    midPoint: 2600,
    buildBreakpoints: photoVideoBreakpoints
  },
  producerCastingRet: {
    min: 650,
    max: 3000,
    minRecommended: 800,
    maxRecommended: 1600,
    default: 800,
    midPoint: 1050,
    buildBreakpoints: castingProducersRetBreakpoints
  },
  stylistProp: {
    min: 700,
    max: 3000,
    minRecommended: 900,
    maxRecommended: 1500,
    default: 1000,
    midPoint: 1150,
    buildBreakpoints: stylistPropBreakpoints
  },
  creative: {
    min: 850,
    max: 5000,
    minRecommended: 1050,
    maxRecommended: 2000,
    default: 1250,
    midPoint: 1600,
    buildBreakpoints: creativeBreakpoints
  },
  manic: {
    min: 500,
    max: 1000,
    minRecommended: 550,
    maxRecommended: 750,
    default: 550,
    midPoint: 600,
    buildBreakpoints: manicBreakpoints
  },
  studio: {
    min: 650,
    max: 3000,
    minRecommended: 750,
    maxRecommended: 1400,
    default: 1000,
    midPoint: 1100,
    buildBreakpoints: rentalBreakpoints
  }
};

export default templates;
