import React from 'react';

import { colors } from 'helpers/colors';

function SvgHeartSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M13.4085 19.3802L13.4077 19.3807C12.9724 19.6654 12.6091 19.8852 12.3538 20.0356C12.2379 20.1037 12.1198 20.1708 12.0006 20.2371C11.8816 20.1717 11.7635 20.1045 11.6462 20.0357C10.1406 19.1484 8.71781 18.1148 7.39667 16.9484C5.07324 14.8808 2.75 11.995 2.75 8.71095C2.75 5.80674 5.01663 3.75 7.21455 3.75C8.98583 3.75 10.4408 4.72825 11.3484 6.32044C11.4818 6.55446 11.7305 6.69897 11.9999 6.69902C12.2692 6.69908 12.5179 6.55466 12.6514 6.32069C13.5601 4.7281 15.0143 3.75 16.7855 3.75C18.9825 3.75 21.25 5.80674 21.25 8.71189C21.25 11.9959 18.9258 14.8827 16.6036 16.9473C15.5954 17.8373 14.5276 18.6504 13.4085 19.3802Z"
        fill={color}
        stroke="none"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgHeartSolid;
