import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import loadImage from 'blueimp-load-image';
import Croppie from 'croppie';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import {
  Wrapper,
  Footer,
  Body,
  Row,
  FileInput,
  OrWrapper,
  UnderPictureWrapper
} from './styled';
import ImagePreview from './components/image_preview';
import SubmitBtnIcon from './components/submit_btn_icon';
import svgIcons from 'helpers/svgIcons';
import ApplicationHelper from 'helpers/application_helper';

const ImageInput = ({ onChange, value, css, showImageName = true }) => {
  const wrapperRef = useRef();
  const croppieRef = useRef();
  const imagePreviewRef = useRef();
  const [isCroppieEnabled, setCroppieEnabled] = useState(false);
  const [croppie, setCroppie] = useState();
  const [croppedImg, setCroppedImg] = useState(value && value.croppedImg);
  const [original64, setOriginal64] = useState();
  const [imageName, setImageName] = useState(value && value.name);

  useEffect(() => {
    if (css) console.log('css detected - get rid of it', css);
    if (value?.croppedImg) setCroppedImg(value.croppedImg);
  }, [value]);

  useEffect(() => {
    if (imagePreviewRef.current) setCroppie(false);
  }, [imagePreviewRef.current]);

  useEffect(() => {
    if (ApplicationHelper.isMobile) {
      const $body = document.querySelector('body');

      if (isCroppieEnabled) $body.style.overflowX = 'hidden';
      if (!isCroppieEnabled) $body.removeAttribute('style');
    }
  }, [isCroppieEnabled]);

  const btnLabel = useMemo(() => {
    if (isCroppieEnabled) return 'Crop';
    if (value) return 'Change photo';
    return 'Upload from device';
  }, [isCroppieEnabled, value]);

  const onFooterClick = () => {
    if (isCroppieEnabled) onCrop();
  };

  const onCroppieEnable = useCallback(
    newFile => {
      if (!newFile || !croppieRef.current) return;
      const manipulatorHeight = 45;
      const width = wrapperRef.current && wrapperRef.current.offsetWidth;
      const height =
        wrapperRef.current &&
        wrapperRef.current.offsetHeight - manipulatorHeight;
      const croppieInstance = new Croppie(croppieRef.current, {
        enforceBoundary: true,
        viewport: {
          width: imagePreviewRef.current
            ? imagePreviewRef.current.clientWidth
            : 407,
          height: imagePreviewRef.current
            ? imagePreviewRef.current.clientWidth
            : 407
        },
        boundary: {
          width,
          height
        }
      });
      croppieInstance.bind({ url: newFile });
      setCroppieEnabled(true);
      setCroppie(croppieInstance);
      setOriginal64(newFile);
    },
    [croppieRef, wrapperRef, setCroppieEnabled, setCroppie]
  );

  const onChangeImage = useCallback(
    event => {
      const { files } = event.target;
      const currentFile = files[0];
      if (!currentFile || !currentFile.type.match(/image.*/)) return;
      const reader = new FileReader();
      const image = new Image();

      reader.onload = e => {
        image.onload = () => {
          const options = { canvas: true, maxHeight: 900 };
          return loadImage.parseMetaData(currentFile, data => {
            if (!data.exif) return onCroppieEnable(e.target.result);

            options.orientation = data.exif.get('Orientation');
            return loadImage(
              currentFile,
              canvas => onCroppieEnable(canvas.toDataURL()),
              options
            );
          });
        };

        setOriginal64(reader.result);
        image.src = reader.result;
        return image;
      };

      reader.readAsDataURL(currentFile);
      if (showImageName) setImageName(currentFile.name);
    },
    [onCroppieEnable, showImageName]
  );

  const onCrop = useCallback(async () => {
    if (!croppie) return;
    const image = await croppie.result();
    const crop_info = croppie.get();
    const topLeftX = parseInt(crop_info.points[0]);
    const topLeftY = parseInt(crop_info.points[1]);
    const bottomRightX = parseInt(crop_info.points[2]);
    const bottomRightY = parseInt(crop_info.points[3]);
    const width = document.getElementsByClassName('cr-image').width;
    const height = document.getElementsByClassName('cr-image').height;
    const box_width = bottomRightX - topLeftX;
    const box_height = bottomRightY - topLeftY;
    const db_box_width = (box_width * 100) / width;
    const db_box_height = (box_height * 100) / height;
    const db_offset_top = (topLeftY * 100) / box_height;
    const db_offset_left = (topLeftX * 100) / box_width;
    const crop = {
      topLeftX,
      topLeftY,
      bottomRightX,
      bottomRightY,
      width,
      height,
      box_width,
      box_height,
      db_box_width,
      db_box_height,
      db_offset_top,
      db_offset_left
    };
    setCroppieEnabled(false);
    croppie.destroy();
    onChange({
      croppedBase64: image, // to match the same value schema as v2
      originalBase64: original64, // to match the same value schema as v2
      croppedImg: image, // deprecated
      image: original64, // deprecated
      crop,
      name: imageName
    });
    setCroppedImg(image);
  }, [croppie, setCroppieEnabled, onChange]);

  const clearImage = useCallback(() => {
    setCroppedImg(null);
    if (showImageName) setImageName(null);
    onChange(null);
  }, [showImageName]);

  return (
    <Wrapper css={isCroppieEnabled ? `height: 580px; ${css}` : css}>
      <Body ref={wrapperRef}>
        <div ref={croppieRef}></div>
        {!isCroppieEnabled && (
          <>
            <ImagePreview image={croppedImg} previewRef={imagePreviewRef} />
            {imageName && (
              <UnderPictureWrapper>
                <Text lines={1} size={14}>
                  {imageName}
                </Text>
                <div style={{ cursor: 'pointer' }} onClick={clearImage}>
                  {svgIcons.close(colors.gray[700])}
                </div>
              </UnderPictureWrapper>
            )}
          </>
        )}
      </Body>

      {!ApplicationHelper.isMobile && (
        <OrWrapper>
          <Text color={colors.gray[400]} size={12}>
            or
          </Text>
        </OrWrapper>
      )}

      <Footer onClick={onFooterClick}>
        {!isCroppieEnabled && (
          <FileInput
            accept="image/png, image/gif, image/jpeg"
            type="file"
            onChange={onChangeImage}
          />
        )}

        <Row>
          <SubmitBtnIcon isCroppieEnabled={isCroppieEnabled} value={value} />
          <Indent right={10} />
          <Text
            color={colors.primary[500]}
            weight={500}
            size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}
          >
            {btnLabel}
          </Text>
        </Row>
      </Footer>
    </Wrapper>
  );
};

export default ImageInput;
