import React from 'react';

import { colors } from 'helpers/colors';

function SvgShareOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.42597 7.39116C1.35824 7.45918 1.31646 7.5557 1.31646 7.66102V18.339C1.31646 18.4443 1.35824 18.5408 1.42597 18.6088C1.49321 18.6764 1.5815 18.7119 1.67089 18.7119H14.3291C14.4185 18.7119 14.5068 18.6764 14.574 18.6088C14.6418 18.5408 14.6835 18.4443 14.6835 18.339V7.66102C14.6835 7.5557 14.6418 7.45918 14.574 7.39116C14.5068 7.32363 14.4185 7.28814 14.3291 7.28814H11.7975C11.6183 7.28814 11.449 7.21649 11.3263 7.09331C11.2041 6.9706 11.1392 6.80846 11.1392 6.64407C11.1392 6.47967 11.2041 6.31753 11.3263 6.19483C11.449 6.07164 11.6183 6 11.7975 6H14.3291C14.7769 6 15.2037 6.17879 15.5163 6.49269C15.8284 6.8061 16 7.22691 16 7.66102V18.339C16 18.7731 15.8284 19.1939 15.5163 19.5073C15.2037 19.8212 14.7769 20 14.3291 20H1.67089C0.733378 20 0 19.2393 0 18.339V7.66102C0 7.22691 0.171594 6.8061 0.483689 6.49269C0.796266 6.17879 1.22313 6 1.67089 6H4.20253C4.38172 6 4.55101 6.07164 4.67367 6.19483C4.79586 6.31753 4.86076 6.47967 4.86076 6.64407C4.86076 6.80846 4.79586 6.9706 4.67367 7.09331C4.55101 7.21649 4.38172 7.28814 4.20253 7.28814H1.67089C1.5815 7.28814 1.49321 7.32363 1.42597 7.39116Z"
        fill={color}
      />
      <path
        d="M8.45192 0.192859L11.7852 3.49007L11.7878 3.49256C11.8533 3.55293 11.9058 3.62573 11.9423 3.70663C11.9787 3.78752 11.9983 3.87484 11.9999 3.96339C12.0015 4.05194 11.985 4.13989 11.9515 4.222C11.9179 4.30412 11.868 4.37871 11.8047 4.44133C11.7414 4.50395 11.666 4.55332 11.583 4.58648C11.5 4.61965 11.4111 4.63594 11.3216 4.63438C11.2321 4.63281 11.1438 4.61343 11.062 4.57739C10.9802 4.54135 10.9066 4.48938 10.8456 4.42459L10.8431 4.42202L8.64748 2.25018V13.3405C8.64748 13.5154 8.57724 13.6832 8.45222 13.8068C8.32719 13.9305 8.15762 14 7.98081 14C7.804 14 7.63444 13.9305 7.50941 13.8068C7.38439 13.6832 7.31415 13.5154 7.31415 13.3405V2.25018L5.11987 4.4207C4.99364 4.53644 4.82704 4.59942 4.65491 4.59642C4.4822 4.5934 4.31741 4.52419 4.19526 4.40337C4.07312 4.28255 4.00315 4.11954 4.0001 3.9487C3.99707 3.77843 4.06074 3.61362 4.17774 3.48876L7.5097 0.192859C7.6347 0.0693925 7.80417 0 7.98081 0C8.15746 0 8.32693 0.0693926 8.45192 0.192859Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgShareOutline;
