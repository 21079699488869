import { useCallback, useContext } from 'react';
import get from 'lodash.get';

import { RootContext } from 'contexts/contexts';
import UserHelpers from 'helpers/user_helpers';

const $ = window.jQuery;
const Stripe = window.Stripe;

const useStripeCustomer = ({
  cardDetails,
  onSubmit,
  applicantFirstName,
  applicantUserId
}) => {
  const { setLoading } = useContext(RootContext);

  const handleSubmit = useCallback(() => {
    const dateParts = cardDetails.expDate.split('/');
    const month = parseInt(dateParts[0], 10);
    const year = parseInt(dateParts[1], 10);
    const fullYear = '20' + year;

    const dataToSend = {
      name: cardDetails.cardholderName,
      number: cardDetails.cardNumber.toString(),
      cvc: cardDetails.cardCVC.toString(),
      exp_month: month.toString(),
      exp_year: fullYear.toString(),
      address_line1: cardDetails.addressLine1,
      address_line2: cardDetails.addressLine2,
      address_city: cardDetails.addressCity,
      address_state: cardDetails.addressState,
      address_zip: cardDetails.addressPostal.toString(),
      address_country: cardDetails.addressCountry
    };

    let payment_successful = $.Deferred();
    setLoading(true);

    Stripe.card.createToken(dataToSend, (status, response) => {
      if (response.error) {
        alert(response.error.message);
        setLoading(false);
      } else {
        return payment_successful.resolve(response.id);
      }
    });
    return payment_successful.then(token => {
      return UserHelpers.addCard(token, response => {
        const error = get(response, 'responseJSON.message');

        if (error) {
          alert(error);
          setLoading(false);
        } else {
          setLoading(false);
          onSubmit(response, applicantFirstName, applicantUserId);
        }
      });
    });
  }, [cardDetails, setLoading, onSubmit, applicantFirstName, applicantUserId]);

  return handleSubmit;
};

export default useStripeCustomer;
