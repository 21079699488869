import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import ApplicationHelper from 'helpers/application_helper';
import { colors } from 'helpers/colors';
import React, { useCallback } from 'react';
import styled from 'styled-components';

const Wrapper = styled(Flex)`
  width: 520px;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
    width: 100%;
  }
`;

const ConfirmBookingModal = ({ onClose, modalProps }) => {
  const text = `
    ${modalProps.applicantFirstName} will be notified of your selection; you will be charged
    when they confirm the job. Payment will be released to the
    talent after the successful completion of the job.
  `;
  const handleConfirmPress = useCallback(() => {
    const stripeCustomer = modalProps?.stripeCustomer || null;
    modalProps.confirmPayment(
      'first',
      modalProps.applicantUserId,
      stripeCustomer,
      modalProps.closeFromApplicants
    );
    onClose();
  }, [modalProps, onClose]);

  return (
    <Wrapper dir="column">
      <Text size={ApplicationHelper.isMobile ? 18 : 24} weight={500}>
        Please confirm your booking
      </Text>
      <Indent bottom={40} />

      <Text size={ApplicationHelper.isMobile ? 14 : 16} textAlign="center">
        {text}
      </Text>

      <Indent bottom={40} />
      <Button
        type="primary"
        height={40}
        width="100%"
        onClick={handleConfirmPress}
        isDisabled={modalProps.isLoading}
      >
        Submit Booking
      </Button>
      <Indent bottom={16} />

      <Button
        height={40}
        width="100%"
        color={colors.gray[600]}
        borderColor="transparent"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Wrapper>
  );
};

export default ConfirmBookingModal;
