import React from 'react';

import SvgFilePdfOutline from 'components/reusable/svg/file_types/svg_file_pdf_outline';
import SvgFileJpgOutline from 'components/reusable/svg/file_types/svg_file_jpg_outline';
import SvgFilePngOutline from 'components/reusable/svg/file_types/svg_file_png_outline';
import SvgFileGifOutline from 'components/reusable/svg/file_types/svg_file_gif_outline';
import SvgFileWebpOutline from 'components/reusable/svg/file_types/svg_file_webp_outline';
import SvgFileMp4Outline from 'components/reusable/svg/file_types/svg_file_mp4_outline';
import SvgFileMovOutline from 'components/reusable/svg/file_types/svg_file_mov_outline';
import SvgFileDocxOutline from 'components/reusable/svg/file_types/svg_file_docx_outline';
import SvgFileXlsOutline from 'components/reusable/svg/file_types/svg_file_xls_outline';
import SvgFileZipOutline from 'components/reusable/svg/file_types/svg_file_zip_outline';
import SvgFileUnknownOutline from 'components/reusable/svg/file_types/svg_file_unknown';
import SvgFileLoadingOutline from 'components/reusable/svg/file_types/svg_file_loading_outline';
import { colors } from 'helpers/colors';

function FileIconPicker({ loading = false, url, size, color }) {
  const componentProps = { size, color };
  const URL = url?.toLowerCase();

  if (loading)
    return (
      <SvgFileLoadingOutline {...componentProps} color={colors.primary[500]} secondaryColor={colors.primary[200]} />
    );

  if (!URL) return null;

  const isDocx = URL.endsWith('.docx');
  const isZip = URL.endsWith('.zip');
  const isXls = URL.endsWith('.xls');
  const isMov = URL.endsWith('.mov');
  const isPdf = URL.endsWith('.pdf');
  const isMp4 = URL.endsWith('.mp4');
  const isWebp = URL.endsWith('.webp');
  const isPng = URL.endsWith('.png');
  const isGif = URL.endsWith('.gif');
  const isJpg = URL.endsWith('.jpg') || URL.endsWith('.jpeg');

  if (isDocx) return <SvgFileDocxOutline {...componentProps} />;
  if (isZip) return <SvgFileZipOutline {...componentProps} />;
  if (isXls) return <SvgFileXlsOutline {...componentProps} />;
  if (isMov) return <SvgFileMovOutline {...componentProps} />;
  if (isPdf) return <SvgFilePdfOutline {...componentProps} />;
  if (isMp4) return <SvgFileMp4Outline {...componentProps} />;
  if (isWebp) return <SvgFileWebpOutline {...componentProps} />;
  if (isPng) return <SvgFilePngOutline {...componentProps} />;
  if (isGif) return <SvgFileGifOutline {...componentProps} />;
  if (isJpg) return <SvgFileJpgOutline {...componentProps} />;

  return <SvgFileUnknownOutline {...componentProps} />;
}

export default FileIconPicker;
