import React from 'react';
import styled from 'styled-components';

import UserHelpers from 'helpers/user_helpers';
import AuthHelper from 'helpers/auth_helper';
const $ = window.jQuery;

const Wrapper = styled.div`
  max-width: 802px;
  width: 90%;
  padding: 20px;
  margin: 0 auto;
  border: 1px solid white;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const StyledInput = styled.input`
  outline: none;
  text-align: center;
  max-width: 525px;
  width: 100%;
  height: 50px;
  font-size: 1rem;
  font-family: Avenir-Roman;
  border-right: none;
  border-style: solid;
  letter-spacing: 2px;
`;

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

class InviteFriendPopup extends React.Component {
  state = {
    wishlist_name: '',
    email_text: '',
    error_text: '',
    label: ' ',
    referral_code: ''
  };

  componentDidMount() {
    UserHelpers.loadUser(AuthHelper.logged_in_profileID, user => {
      this.setState({ referral_code: user.referral_code });
    });
  }

  handleChange = e => {
    this.setState({ email_text: e.target.value });
  };

  inviteFriend = () => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (re.test(this.state.email_text)) {
      //this.props.showLoading()
      $.ajax({
        url: '/invite_friend',
        method: 'POST',
        data: {
          email: this.state.email_text
        },
        dataType: 'JSON',
        error: error => {
          console.log(error);
        },
        success: (data, textStatus, jqXHR) =>
          this.setState({
            label: 'E-mail sent to ' + this.state.email_text,
            email_text: ''
          })
        //this.props.hideLoading()
      });
    } else {
      this.setState({ label: 'Please enter a valid email address' });
    }
  };

  getStyles = () => {
    return {
      title: {
        fontSize: '1.875rem',
        fontFamily: 'Avenir-Heavy',
        letterSpacing: '2px'
      },

      close: {
        cursor: 'pointer',
        height: '17px',
        width: '17px',
        opacity: '0.8',
        alignSelf: 'flex-end',
        margin: '11px'
      },

      subtitle: {
        color: '#111',
        fontSize: '0.9375rem',
        fontFamily: 'Avenir-Roman',
        letterSpacing: '1px',
        padding: '40px',
        paddingTop: '20px',
        paddingBottom: '20px',
        whiteSpace: 'pre-wrap',
        textAlign: 'center'
      },

      sendButton: {
        cursor: 'pointer',
        textAlign: 'center',
        fontFamily: 'Avenir-Heavy',
        width: '80px',
        backgroundColor: 'black',
        color: 'white',
        height: '50px',
        lineHeight: '50px'
      },

      labelStyle: {
        height: '40px',
        fontFamily: 'Avenir-Light',
        color: '#fa4726',
        letterSpacing: '1px'
      },

      referralCodeContainer: {
        fontFamily: 'Avenir-Light',
        color: 'black',
        fontSize: '0.9375rem',
        letterSpacing: '1px'
      },

      referralCode: {
        color: '#fa4726',
        display: 'inline'
      },

      disclaimer_text: {
        fontFamily: 'Avenir-Oblique',
        whiteSpace: 'pre-wrap',
        padding: '20',
        textAlign: 'center',
        color: '#777'
      }
    };
  };

  render() {
    const styles = this.getStyles();
    return (
      <div className="modal_background_overlay">
        <Wrapper>
          <img
            style={styles.close}
            src="/images/icons/icon-x-black.png"
            onClick={this.props.hidePopup}
          />
          <div style={styles.title}>INVITE A FRIEND</div>
          <div style={styles.subtitle}>
            {
              'Invite your friend to join SWIPECAST! \n\n Additionally, receive $25 for every member you refer who has a models.com profile.'
            }
          </div>

          <InputWrapper>
            <StyledInput
              type="text"
              placeholder="Enter e-mail address"
              value={this.state.email_text}
              onChange={this.handleChange}
            />
            <div style={styles.sendButton} onClick={this.inviteFriend}>
              SEND
            </div>
          </InputWrapper>

          {/*<div style={styles.referralCodeContainer}>*/}
          {/*  Or, give your friend this referral code for signup:{' '}*/}
          {/*  <div style={styles.referralCode}>{this.state.referral_code}</div>*/}
          {/*</div>*/}
          <div style={styles.labelStyle}>{this.state.label}</div>
        </Wrapper>
      </div>
    );
  }
}

export default InviteFriendPopup;
