const google = window.google;

const locations = {
  ny: {
    address_line: '',
    city: 'New York',
    country: 'USA',
    input_address: 'New York, NY, USA',
    lat: 40.7127753,
    lng: -74.0059728,
    placeID: 'ChIJOwg_06VPwokRYv534QaPC8g',
    state: 'NY'
  }
};

const LocationHelpers = {
  defaultRadius: 50,
  locations,

  getLocationLabel: jobOrUserOrPrediction => {
    if (!jobOrUserOrPrediction) return '';
    const usLabels = ['UNITED STATED', 'USA'];
    const isUS = usLabels.includes(
      (jobOrUserOrPrediction.country || '').toUpperCase()
    );
    let locationArr = [
      jobOrUserOrPrediction.city,
      jobOrUserOrPrediction.country
    ];
    if (isUS)
      locationArr = [jobOrUserOrPrediction.city, jobOrUserOrPrediction.state];
    const value = locationArr.filter(v => !!v).join(', ');
    return value || '';

    // let returned_location = '';
    // if (user.city) {
    //   returned_location += user.city;
    //   if (user.state) {
    //     returned_location += ', ' + user.state;
    //     if (
    //       user.country &&
    //       user.country.toUpperCase() !== 'USA' &&
    //       user.country.toUpperCase() !== 'UNITED STATES'
    //     ) {
    //       returned_location += ', ' + user.country;
    //     }
    //   }
    // }
    // return returned_location;
  },
  getLocationLabelFull: obj => {
    let first_line = obj.street;
    let second_line = '';
    if (obj.city) {
      second_line += obj.city;
      if (obj.state) {
        second_line += ', ' + obj.state;
        if (
          obj.country &&
          obj.country.toUpperCase() !== 'USA' &&
          obj.country.toUpperCase() !== 'UNITED STATES'
        ) {
          second_line += ', ' + obj.country;
        }
        if (obj.postal) {
          second_line += ' ' + obj.postal;
        }
      }
    }
    return first_line + '\n' + second_line;
  },
  getBasicPredictionsGivenLocationInput(input = '', types, callback) {
    if (!input) return;
    /** given params:
      input: a string to search for
      types: can be ['geocode'] for addresses or ['(cities)'] for cities
      returns a set of options with basic info ( country, state, city, address_line)
    **/
    let my_service = new google.maps.places.AutocompleteService();
    my_service.getPlacePredictions(
      { input: input, types: types },
      (predictions, status) => {
        let returned_array = [];
        if (status !== 'ZERO_RESULTS') {
          for (let i = 0; i < predictions.length; i++) {
            let data = predictions[i];
            if (data.hasOwnProperty('description')) {
              let line_item = {
                country: '',
                state: '',
                city: '',
                address_line: ''
              };
              let address_params = data.description.split(',');
              let address_count = address_params.length;
              if (address_count === 1) {
                address_params = data.description.split('-');
                address_count = address_params.length;
              }
              //full_address as opposed to city
              if (types.indexOf('geocode') !== -1) {
                line_item['address_line'] = address_params[0].trim();
                if (data.description.includes('United States')) {
                  line_item['country'] = 'United States';
                  line_item['state'] = address_params[address_count - 2].trim();
                  line_item['city'] = address_params[address_count - 3].trim();
                } else {
                  line_item['state'] = '';
                  line_item['country'] = address_params[
                    address_count - 1
                  ].trim();
                  line_item['city'] = address_params[address_count - 2].trim();
                }
              } else {
                //returning cities, not full address
                line_item['city'] = address_params[0].trim();
                if (address_params.length > 2) {
                  line_item['state'] = address_params[1].trim();
                  line_item['country'] = address_params[2].trim();
                } else {
                  line_item['state'] = '';
                  line_item['country'] = address_params[1]
                    ? address_params[1].trim()
                    : '';
                }
              }
              line_item['input_address'] = data.description;
              line_item['placeID'] = data.place_id;
              returned_array.push(line_item);
            }
          }
        }

        callback(returned_array);
      }
    );
  },
  combinePlaceServiceDetailsAndPrediction(details, prediction) {
    let lng = details.geometry.location.lng();
    let lat = details.geometry.location.lat();
    return Object.assign(prediction, { lng: lng, lat: lat });
  }
};

export default LocationHelpers;
