import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import sharedLinksV2Actions from 'src/store/resources/sharedLinksV2/actions';
import sharedFilesV2Actions from 'src/store/resources/sharedFilesV2/actions';
import sharedVideosV2Actions from 'src/store/resources/sharedVideosV2/actions';
import sharedPhotosV2Actions from 'src/store/resources/sharedPhotosV2/actions';

const useDeleteSharedMessage = () => {
  const dispatch = useDispatch();

  // TODO remove a message in chat as well
  return useCallback((messageID, messageType) => {
    if (!window.confirm(`Are you sure you want to delete this ${messageType}?`)) return false;

    let action;
    const URL = `/v2/messages/${messageID}`;

    if (messageType === 'link') action = sharedLinksV2Actions.resourceDeleteRequest(URL);
    if (messageType === 'video') action = sharedVideosV2Actions.resourceDeleteRequest(URL);
    if (messageType === 'image') action = sharedPhotosV2Actions.resourceDeleteRequest(URL);
    if (messageType === 'file') action = sharedFilesV2Actions.resourceDeleteRequest(URL);

    if (action) dispatch(action);
  }, []);
};

export default useDeleteSharedMessage;
