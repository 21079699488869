import React from 'react';
import { Field } from 'redux-form';

import { Wrapper } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';
import ReduxField from 'components/reusable/input/components/ReduxField';
import Error from 'components/reusable/error';
import Flex from 'components/reusable/flex';
import Button from 'components/reusable/button';
import styled from 'styled-components';

const StyledForm = styled.form`
  width: 100%;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

const StyledButton = styled(Button)`
  margin-top: auto;
`;

const SignUpLogoContent = ({
  isSubmitDisabled,
  onSubmit,
  error,
  accept,
  label
}) => {
  return (
    <Wrapper>
      <Text size={20} weight={500}>
        {label}
      </Text>
      <Indent top={24} />

      <StyledForm onSubmit={onSubmit}>
        <Field
          name={signUpStepsData.image.field}
          accept={accept}
          component={ReduxField}
          showImageName={false}
          type="image"
        />

        <Indent top={4} />
        <Error>{error}</Error>
        <Indent top={4} />

        <Flex jc="flex-start">
          <Field
            name={signUpStepsData.imagePostpone.field}
            component={ReduxField}
            version={2}
            type="checkbox"
            width={30}
          />

          <Indent right={12} />
          <Text size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}>
            I’ll add pic later
          </Text>
        </Flex>

        <Indent top={20} />

        <StyledButton
          isDisabled={isSubmitDisabled}
          semanticType="submit"
          width="100%"
          height={40}
          type="primary"
        >
          Submit
        </StyledButton>
      </StyledForm>
    </Wrapper>
  );
};

export default SignUpLogoContent;
