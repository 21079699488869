import React from 'react';

import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';

const SubmitBtnIcon = ({ isCroppieEnabled, value }) => {
  if (isCroppieEnabled) return svgIcons.tick(colors.primary[500]);

  if (value)
    return (
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.95079 10.0927C3.35713 5.73207 6.70381 2.90918 10.8235 2.90918C13.6235 2.90918 15.922 4.0377 17.2766 5.8566L15.7878 5.4844C15.252 5.35045 14.7091 5.67621 14.5751 6.212C14.4412 6.7478 14.7669 7.29073 15.3027 7.42468L18.9248 8.3302C19.0206 8.35575 19.1208 8.36721 19.2222 8.36305C19.3457 8.35808 19.4656 8.33012 19.5763 8.28244C19.7092 8.2255 19.8242 8.14237 19.9172 8.04126C20.0102 7.94049 20.0835 7.81935 20.1294 7.68252C20.1396 7.65219 20.1484 7.62133 20.1557 7.59006L21.0608 3.96986C21.1947 3.43406 20.8689 2.89113 20.3331 2.75718C19.7974 2.62323 19.2544 2.94899 19.1205 3.48479L18.8406 4.60432C17.0727 2.26842 14.1518 0.90918 10.8235 0.90918C5.71103 0.90918 1.46523 4.47903 0.959418 9.90711C0.908176 10.457 1.31242 10.9443 1.86232 10.9956C2.41223 11.0468 2.89955 10.6426 2.95079 10.0927ZM21.0404 12.0927C21.0916 11.5428 20.6874 11.0555 20.1375 11.0042C19.5876 10.953 19.1002 11.3572 19.049 11.9071C18.6427 16.2677 15.296 19.0906 11.1763 19.0906C8.37698 19.0906 6.07808 17.9621 4.72332 16.1432L6.212 16.5154C6.74779 16.6494 7.29073 16.3236 7.42467 15.7878C7.55862 15.252 7.23286 14.7091 6.69707 14.5751L3.07506 13.6696C2.97921 13.6441 2.87902 13.6326 2.77761 13.6368C2.65407 13.6417 2.53421 13.6697 2.42345 13.7174C2.29065 13.7743 2.17576 13.8573 2.08282 13.9583C1.96561 14.0857 1.88333 14.2416 1.84408 14.4097L0.93903 18.0299C0.805081 18.5657 1.13084 19.1087 1.66664 19.2426C2.20243 19.3766 2.74537 19.0508 2.87931 18.515L3.15923 17.3954C4.92731 19.7314 7.84867 21.0906 11.1763 21.0906C16.2888 21.0906 20.5346 17.5208 21.0404 12.0927Z"
          fill={colors.primary[500]}
        />
      </svg>
    );

  return svgIcons.uploadIcon();
};

export default SubmitBtnIcon;
