import React from 'react';
import { Helmet } from 'react-helmet';

import AuthHelper from 'helpers/auth_helper';

const withSitemana = Component => props => {
  if (process.env.NODE_ENV === 'development') return <Component {...props} />;

  return (
    <>
      <Helmet>
        {AuthHelper.isLoggedIn() ? (
          <script
            type="text/javascript"
            async
            src="https://jsx.s3.us-west-2.amazonaws.com/mana-suppression.js"
          ></script>
        ) : (
          <script type="text/javascript" async src="https://jsx.s3.us-west-2.amazonaws.com/mana.js"></script>
        )}
      </Helmet>

      <Component {...props} />
    </>
  );
};

export default withSitemana;
