export const countriesPN = [
  {
    code: 'AU',
    name: 'Australia',
    prefix: '+61',
    flag: '/images/flags/au.svg'
  },
  {
    code: 'AT',
    name: 'Austria',
    prefix: '+43',
    flag: '/images/flags/at.svg'
  },
  {
    code: 'BE',
    name: 'Belgium',
    prefix: '+32',
    flag: '/images/flags/be.svg'
  },
  { code: 'BR', name: 'Brazil', prefix: '+55', flag: '/images/flags/br.svg' },
  {
    code: 'BG',
    name: 'Bulgaria',
    prefix: '+359',
    flag: '/images/flags/bg.svg'
  },
  {
    code: 'HR',
    name: 'Croatia',
    prefix: '+385',
    flag: '/images/flags/hr.svg'
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    prefix: '+420',
    flag: '/images/flags/cz.svg'
  },
  {
    code: 'DK',
    name: 'Denmark',
    prefix: '+45',
    flag: '/images/flags/dk.svg'
  },
  {
    code: 'EE',
    name: 'Estonia',
    prefix: '+372',
    flag: '/images/flags/ee.svg'
  },
  {
    code: 'FI',
    name: 'Finland',
    prefix: '+358',
    flag: '/images/flags/fi.svg'
  },
  { code: 'FR', name: 'France', prefix: '+33', flag: '/images/flags/fr.svg' },
  {
    code: 'DE',
    name: 'Germany',
    prefix: '+49',
    flag: '/images/flags/de.svg'
  },
  { code: 'GR', name: 'Greece', prefix: '+30', flag: '/images/flags/gr.svg' },
  {
    code: 'HK',
    name: 'Hong Kong',
    prefix: '+852',
    flag: '/images/flags/hk.svg'
  },
  {
    code: 'HU',
    name: 'Hungary',
    prefix: '+36',
    flag: '/images/flags/hu.svg'
  },
  { code: 'IN', name: 'India', prefix: '+91', flag: '/images/flags/in.svg' },
  {
    code: 'IE',
    name: 'Ireland',
    prefix: '+353',
    flag: '/images/flags/ie.svg'
  },
  { code: 'IT', name: 'Italy', prefix: '+39', flag: '/images/flags/it.svg' },
  { code: 'JP', name: 'Japan', prefix: '+81', flag: '/images/flags/jp.svg' },
  {
    code: 'ID',
    name: 'Indonesia',
    prefix: '+62',
    flag: '/images/flags/id.svg'
  },
  {
    code: 'PH',
    name: 'Philippines',
    prefix: '+63',
    flag: '/images/flags/ph.svg'
  },
  {
    code: 'LV',
    name: 'Latvia',
    prefix: '+371',
    flag: '/images/flags/lv.svg'
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    prefix: '+423',
    flag: '/images/flags/li.svg'
  },
  {
    code: 'LT',
    name: 'Lithuania',
    prefix: '+370',
    flag: '/images/flags/lt.svg'
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    prefix: '+325',
    flag: '/images/flags/lu.svg'
  },
  { code: 'MT', name: 'Malta', prefix: '+356', flag: '/images/flags/mt.svg' },
  { code: 'MX', name: 'Mexico', prefix: '+52', flag: '/images/flags/mx.svg' },
  {
    code: 'NL',
    name: 'Netherlands',
    prefix: '+31',
    flag: '/images/flags/nl.svg'
  },
  {
    code: 'NZ',
    name: 'New Zeland',
    prefix: '+64',
    flag: '/images/flags/nz.svg'
  },
  { code: 'NO', name: 'Norway', prefix: '+47', flag: '/images/flags/no.svg' },
  { code: 'PL', name: 'Poland', prefix: '+48', flag: '/images/flags/pl.svg' },
  {
    code: 'PT',
    name: 'Portugal',
    prefix: '+351',
    flag: '/images/flags/pt.svg'
  },
  {
    code: 'RO',
    name: 'Romania',
    prefix: '+40',
    flag: '/images/flags/ro.svg'
  },
  {
    code: 'SL',
    name: 'Slovenia',
    prefix: '+386',
    flag: '/images/flags/si.svg'
  },
  { code: 'ES', name: 'Spain', prefix: '+34', flag: '/images/flags/es.svg' },
  { code: 'SE', name: 'Sweden', prefix: '+46', flag: '/images/flags/se.svg' },
  {
    code: 'CH',
    name: 'Switzerland',
    prefix: '+41',
    flag: '/images/flags/ch.svg'
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    prefix: '+971',
    flag: '/images/flags/ae.svg'
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    prefix: '+44',
    flag: '/images/flags/gb.svg'
  },
  {
    code: 'US',
    name: 'United States',
    prefix: '+1',
    flag: '/images/flags/us.svg'
  },
  {
    code: 'TH',
    name: 'Thailand',
    prefix: '+66',
    flag: '/images/flags/th.svg'
  }
];
