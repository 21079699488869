const boards = (state = [], action) => {
  if (action.type === 'BOARDS_FETCH_SUCCEEDED') {
    return [...action.response.packages];
  }
  if (action.response && action.response.packages) {
    return [...state, ...action.response.packages];
  }
  return state;
};

export default boards;
