import ApplicationHelper from '../helpers/application_helper';
import AuthHelper from '../helpers/auth_helper.js';
import ReactPixel from 'react-facebook-pixel';
import SCLogger from './sc_logger';
import sc_ga4 from "classes/sc_ga4";

const SCTrack = {
  init() {
    if (ApplicationHelper.env === 'production') {
      ReactPixel.init('544342619406480')
      ReactPixel.pageView();
    }
  },

  processLoggedInData(userData: any) {
    if (!AuthHelper.logged_in) return;
    let profileType = null;
    if (AuthHelper.logged_in_profile_types) {
      profileType = AuthHelper.logged_in_profile_types[0];
      if (profileType === 'Model') {
        profileType = `${AuthHelper.logged_in_gender.toLowerCase()}_model`;
      }
    }
    SCTrack.identifyWithAppcues(userData, profileType);
  },

  identifyWithAppcues(userData, profileType = null) {
    if (
      ApplicationHelper.env === 'production' ||
      userData.email !== 'petercloutier@swipecast.com'
    )
      return;
    (<any>window).Appcues.identify(userData.swipecast_user_id, {
      name: `${userData.first_name} ${userData.last_name}`,
      email: userData.email,
      profileType: profileType || '(Unknown)'
    });
  },

  appCues(eventName, properties = {}) {
    if (
      ApplicationHelper.env === 'production' ||
      AuthHelper.logged_in_email !== 'petercloutier@swipecast.com'
    )
      return;
    SCLogger.log(`Appcues Event ${eventName}`);
    (<any>window).Appcues.track(eventName, properties);
  },

  appCuesPage() {
    if (
      ApplicationHelper.env === 'production' ||
      AuthHelper.logged_in_email !== 'petercloutier@swipecast.com'
    )
      return;
    (<any>window).Appcues.page();
  },

  all(eventName, properties = {}, callback = () => {}) {
    SCTrack.appCues(eventName, properties);
    let label = '';

    if(properties['RequestName']) {
      label += properties['RequestName'] + '-';
    }
    if(properties['View']) {
      label += properties['View']+ '-';
    }
    if(properties['Button']) {
      label += properties['Button']+ '-';
    }

    if (ApplicationHelper.env === 'production') {
      ReactPixel.trackCustom(eventName, properties);
      console.log('SCTrack.all');
      sc_ga4.sendMetric(eventName, properties)
    }

    callback()
  },

  // Specific events
  displayView(View: String) {
    SCTrack.all('Display View',{
      View
    });
  },

  pressButton(ButtonName: String, View: String) {
    SCTrack.all('Press Button',{
      ButtonName,
      View
    });
  },

  receiveServerResponse(RequestName: String, Response: String, View: String) {
    SCTrack.all('Receive Server Response',{
      RequestName,
      Response,
      View
    });
  }
};

export default SCTrack;
