import { useCallback, useMemo } from 'react';

import { progressToValue, valueToProgress } from '../helpers/values_helper';

const useNonLinearScaling = ({ midPoint, min, max, value, onChange }) => {
  const sliderValue = useMemo(() => {
    return valueToProgress({ midPoint, min, max, value });
  }, [midPoint, min, max, value]);

  const handleChange = useCallback(
    progress => {
      const value = progressToValue({ midPoint, min, max, progress });
      onChange(value);
    },
    [midPoint, min, max]
  );

  return { sliderValue, handleChange };
};

export default useNonLinearScaling;
