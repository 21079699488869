import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthHelper from 'helpers/auth_helper';
import GeneralRoute from './general_route.js';

const PrivateRoute = props => {
  if (!AuthHelper.logged_in) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: props.location }
        }}
      />
    );
  }

  return <GeneralRoute {...props} />;
};

export default PrivateRoute;
