export const ProjectActionTypes = {
  FETCH: '@@project/FETCH',
  FETCH_FAILURE: '@@project/FETCH_FAILURE',
  FETCH_SUCCESS: '@@project/FETCH_SUCCESS',

  FETCH_BY_SECRET_ALIAS_ID: '@@project/FETCH_BY_SECRET_ALIAS_ID',
  FETCH_BY_SECRET_ALIAS_ID_FAILURE:
    '@@project/FETCH_BY_SECRET_ALIAS_ID_FAILURE',
  FETCH_BY_SECRET_ALIAS_ID_SUCCESS:
    '@@project/FETCH_BY_SECRET_ALIAS_ID_SUCCESS',

  UPDATE: '@@project/UPDATE',
  UPDATE_FAILURE: '@@project/UPDATE_FAILURE',
  UPDATE_SUCCESS: '@@project/UPDATE_SUCCESS'
};
