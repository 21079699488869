import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import Image from 'components/reusable/image';

const StyledImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ImageWrapper = styled.div`
  border-radius: 16px;
  width: 100%;
  position: relative;
  overflow: hidden;

  > * {
    border-radius: 16px;
    position: absolute;
    inset: 0;
  }

  &:after {
    padding-bottom: 100%;
    display: block;
    content: '';
  }
`;

// used in grid components to display image in square aspect ratio when width of the grid column is unknown
function SquareImage({ fetched, src }) {
  return (
    <ImageWrapper>
      {fetched ? (
        <StyledImage src={src} />
      ) : (
        <Skeleton borderRadius={16} width="100%" height="100%" />
      )}
    </ImageWrapper>
  );
}

export default SquareImage;
