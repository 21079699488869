export const readFiles = fileList =>
  new Promise(resolve => {
    const base64Files = [];
    const files = Object.values(fileList);

    files.forEach(f => {
      const reader = new FileReader();
      reader.onloadend = e => {
        base64Files.push(reader.result);
        if (base64Files.length === files.length) resolve(base64Files);
      };

      if (f) reader.readAsDataURL(f);
    });
  });
