import { NavLink as BaseNavLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { styleDimensions } from 'helpers/style';

export const OpacityWrap = styled.div`
  ${({ active }) =>
    active &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(255, 255, 255, 0.75);
      z-index: 4;
      transition: all 0.3s ease-in-out;
    `}
`;

export const Container = styled.div`
  -webkit-appearance: none;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10; // 8 rounded images overlap
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 12px 40px;
  box-shadow: 0px 2px 8px rgba(31, 30, 30, 0.08);
  background-color: ${colors.light};
  height: ${styleDimensions.desktopHeaderHeight};
  transition: all 0.3s;
  border-bottom: 1px solid ${colors.light};
  ${({ burgerOpen }) =>
    burgerOpen &&
    css`
      box-shadow: none;
      border-bottom: 1px solid ${colors.gray[50]};
    `}
  @media (max-width: 985px) {
    padding: 8px 20px;
  }
  @media (max-width: 768px) {
    height: ${styleDimensions.mobileHeaderHeight};
    width: 100%;
    position: fixed;
    padding: 8px 16px;
  }
`;

export const Links = styled(Flex)`
  gap: 40px;
  @media (max-width: 1380px) {
    gap: 20px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const LinkText = styled(Text)`
  cursor: pointer;
  transition: all 0.3s;
  ${({ active }) => active && `color: ${colors.primary[500]};`}
  &:hover {
    color: ${colors.primary[500]};
  }
`;

export const NavLink = styled(BaseNavLink)`
  display: flex;
  align-items: center;
  transition: all 0.3s;
  &:hover,
  &.active {
    p {
      color: ${colors.primary[500]};
    }
  }
  svg {
    display: none;
  }
  @media (max-width: 1380px) {
    svg {
      display: block;
      path {
        ${({ useCssColors }) =>
          useCssColors ? `fill: ${colors.gray[600]} !important` : ''};
      }
    }
    &:hover svg path {
      ${({ useCssColors }) =>
        useCssColors ? `fill: ${colors.primary[500]} !important` : ''};
    }
    &.active svg path {
      ${({ useCssColors }) =>
        useCssColors ? `fill: ${colors.primary[500]} !important` : ''};
    }
    p {
      display: ${({ hamburger }) => (hamburger ? 'block' : 'none')};
    }
  }
`;

export const Buttons = styled(Flex)`
  gap: 16px;
`;
