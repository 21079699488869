import React from 'react';
import { connect } from 'react-redux';
import NotificationList from 'components/views/logged_in/notifications/notification_list';
import styled from 'styled-components';
import { fetchNotifications } from 'store/actions';
import { getNotifications } from 'store/reducers';

const NotificationContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  @media only screen and (min-width: 769px) {
    margin-left: 100px;
    width: 600px;
  }
`;

const NotificationTitle = styled.div`
  width: 100%;
  height: 80px;
  padding: 20px;
  font-size: 18px;
  font-family: Avenir-Black;
  color: black;
  display: none;
  @media only screen and (min-width: 769px) {
    display: block;
  }
`;

class NotificationViewStatic extends React.Component {
  componentDidMount() {
    this.props.fetchNotifications();
  }

  render() {
    return (
      <NotificationContainer className="white_scrollbar">
        <NotificationTitle>YOUR NOTIFICATIONS</NotificationTitle>
        <NotificationList
          activateLoading={() => {}}
          disableLoading={() => {}}
          notifications={this.props.notifications.data}
          fetchNotifications={this.props.fetchNotifications}
          rootProps={this.props.rootProps}
        />
      </NotificationContainer>
    );
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

const NotificationView = connect(mapStateToProps, {
  fetchNotifications
})(NotificationViewStatic);

export default NotificationView;