import React from 'react';
import styled from 'styled-components';

import { hideScrollingIndicator } from 'helpers/style';
import ChatSharedGridGroup from 'components/modals/v2/chat_shared_modal/components/chat_shared_grid_group';
import useSharedFileGroups from 'components/modals/v2/chat_shared_modal/hooks/use_shared_file_groups';
import ChatSharedRowGroup from 'components/modals/v2/chat_shared_modal/components/chat_shared_row_group';
import ChatSharedEmpty from 'components/modals/v2/chat_shared_modal/components/chat_shared_empty';
import ApplicationHelper from 'helpers/application_helper';

/**
 * @param isSkeleton boolean
 * @pagination { handleAppendFiles, hasMoreFiles }
 * @param messages
 * @param messageType image | video | link | file
 * @returns {JSX.Element}
 * @constructor
 */
function ChatSharedList({
  handleDeleteSharedMsg,
  handleOpenAlbum,
  pagination,
  isSkeleton = false,
  messages,
  messageType
}) {
  const messageGroups = useSharedFileGroups(messages, messageType);
  const groupEntries = Object.entries(messageGroups);
  const noMessages = !isSkeleton && !groupEntries.length;

  return (
    <Wrapper className="chat__shared-list">
      {noMessages && <ChatSharedEmpty messageType={messageType} />}

      {isSkeleton ? (
        <>
          {['image', 'video'].includes(messageType) && (
            <ChatSharedGridGroup isSkeleton={isSkeleton} messageType={messageType} />
          )}
          {['file', 'link'].includes(messageType) && (
            <ChatSharedRowGroup isSkeleton={isSkeleton} messageType={messageType} />
          )}
        </>
      ) : (
        <>
          {['image', 'video'].includes(messageType) &&
            groupEntries.map(([dateLabel, messages]) => (
              <ChatSharedGridGroup
                handleOpenAlbum={handleOpenAlbum}
                pagination={pagination}
                key={dateLabel}
                messages={messages}
                label={dateLabel}
                messageType={messageType}
              />
            ))}

          {['file', 'link'].includes(messageType) &&
            groupEntries.map(([dateLabel, messages], index) => (
              <ChatSharedRowGroup
                overlayDirection={ApplicationHelper.isMobile || index > 0 ? 'top-left' : 'bottom-left'}
                handleDeleteSharedMsg={handleDeleteSharedMsg}
                pagination={pagination}
                key={dateLabel}
                messages={messages}
                label={dateLabel}
                messageType={messageType}
              />
            ))}
        </>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 24px 6px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  flex: 1;
  overflow-y: auto;
  ${hideScrollingIndicator};
  @media (max-width: 768px) {
    overflow-y: visible;
  }
`;

export default ChatSharedList;
