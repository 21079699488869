import { useCallback } from 'react';

import AuthHelper from 'helpers/auth_helper';

const useGoogleOauth = ({ rootContext, setError, onClose }) => {
  const _onCreateSessionSuccess = useCallback(() => {
    onClose();
    AuthHelper.performLoginRedirect();
  }, [onClose]);

  const _onCreateSessionFailure = useCallback(
    response => {
      try {
        setError(response.responseJSON.message);
      } catch {
        setError('We are having problems processing your request, please try again later.');
      } finally {
        rootContext.setLoading(false);
      }
    },
    [setError, rootContext.setLoading]
  );

  const onOauthSuccess = useCallback(
    oauth => {
      const { google_token_id, instagram_auth_code, redirect_uri } = oauth;
      rootContext.setLoading(true);
      setError('');
      const params = {
        google_token_id,
        instagram_auth_code,
        redirect_uri,
        client: 'desktop'
      };
      AuthHelper.performLogin(params, _onCreateSessionSuccess, _onCreateSessionFailure);
    },
    [rootContext.setLoading, setError]
  );

  return {
    onOauthSuccess
  };
};

export default useGoogleOauth;
