import React from 'react';

import AuthHelper from 'helpers/auth_helper';
import BroadcastChannel from 'broadcast-channel';
import Pusher from 'pusher-js';
import Settings from 'helpers/settings';

const bc = new BroadcastChannel('swipecast_tabs');

class FaviconHighlighter extends React.Component {
  componentDidMount() {
    if (AuthHelper.logged_in) {
      // TODO rework to use SCPusher singleton
      this.pusher_socket = new Pusher(Settings.SWIPECAST_PUSHER_APP_KEY, {
        cluster: Settings.SWIPECAST_PUSHER_APP_CLUSTER,
        encrypted: true
      });
      const userChannel = this.pusher_socket.subscribe(AuthHelper.currentUser().secret_alias_id);
      userChannel.bind('message', this.activate);
      userChannel.bind('notification', this.activate);
      bc.onmessage = ev => {
        if (ev.data === 'disable-favicon') {
          this.deactivate();
        }
      };
      document.addEventListener(
        'visibilitychange',
        () => {
          if (document.hidden === false) {
            this.deactivate();
            bc.postMessage('disable-favicon');
          }
        },
        false
      );
    }
  }

  adjustIcon = iconPath => {
    const link = document.createElement('link');
    const oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-favicon';
    link.rel = 'shortcut icon';
    link.href = iconPath;
    if (oldLink) {
      document.head.removeChild(oldLink);
    }
    document.head.appendChild(link);
  };

  activate = () => {
    if (document.hidden === false) {
      bc.postMessage('disable-favicon');
    } else {
      this.adjustIcon('favicon_highlighted.ico');
    }
  };

  deactivate = () => {
    this.adjustIcon('favicon.ico');
  };

  render() {
    return <div />;
  }
}

export default FaviconHighlighter;
