import React from 'react';

import Text from 'components/reusable/text';
import styled, { css } from 'styled-components';
import Flex from 'components/reusable/flex';
import { styleDimensions } from 'helpers/style';
import { colors } from 'helpers/colors';
import Link from 'components/reusable/link';
import Button from 'components/reusable/button';
import SvgArrowLeftSolid from 'components/reusable/svg/svg_arrow_left_solid';
import SvgArrowRightSolid from 'components/reusable/svg/svg_arrow_right_solid';
import ApplicationHelper from 'helpers/application_helper';

function ProfileAlbumLayout({
  leftContent,
  onCursorClick,
  children,
  actions,
  cursor,
  onNext,
  onPrev,
  label,
  index,
  size
}) {
  const iconSize = ApplicationHelper.isMobile ? 16 : 28;
  return (
    <Wrapper className="ProfileAlbumLayout" $cursor={cursor}>
      <HeaderWrapper>
        {leftContent}

        <Flex gap={24}>
          <Text size={16} weight={500} textTransform="uppercase">
            {label}
          </Text>

          {!size || (size === 1 && typeof index === 'number') ? null : (
            <Text>
              {index + 1} of {size}
            </Text>
          )}
        </Flex>

        <Flex gap={8}>
          {actions
            .filter(a => a.capable)
            .map((action, i) => (
              <StyledIconLink onClick={action.onClick} key={i}>
                <action.Icon size={18} color={colors.gray[300]} />
              </StyledIconLink>
            ))}
        </Flex>
      </HeaderWrapper>

      <InnerWrapper>
        {children}

        {cursor ? (
          <CursorWrapper
            onClick={onCursorClick}
            cursor={cursor.type}
            style={{
              // USE style because styled-components if much heavier for such frequent operations
              transform: `translate(${cursor.x}px, ${cursor.y}px)`
            }}
          />
        ) : (
          <>
            {onPrev ? (
              <PrevBtn type="semantic-wrapper" onClick={onPrev}>
                <SvgArrowLeftSolid size={iconSize} color={colors.gray[600]} />
              </PrevBtn>
            ) : null}

            {onNext ? (
              <NextBtn type="semantic-wrapper" onClick={onNext}>
                <SvgArrowRightSolid size={iconSize} color={colors.gray[600]} />
              </NextBtn>
            ) : null}
          </>
        )}
      </InnerWrapper>
    </Wrapper>
  );
}

const NextBtn = styled(Button)`
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  right: 40px;

  @media (max-width: 768px) {
    width: 32px;
    height: 32px;
    display: flex;
    align-content: center;
    right: 12px;
    background-color: ${colors.light};
    opacity: 0.5;
  }
`;

const PrevBtn = styled(NextBtn)`
  left: 40px;
  @media (max-width: 768px) {
    left: 12px;
  }
`;

const StyledIconLink = styled(Link)`
  min-width: 24px;
  min-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const InnerWrapper = styled(Flex)`
  position: relative;
  height: calc(100vh - ${styleDimensions.desktopAlbumPreviewHeaderHeight});
  padding: 40px 100px;
  @media (max-width: 768px) {
    height: calc(100dvh - ${styleDimensions.mobileAlbumPreviewHeaderHeight});
    padding: 24px 8px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;
  cursor: ${({ $cursor }) => ($cursor ? 'none' : 'close')};
  @media (max-width: 768px) {
    border-radius: 0;
    background-color: ${colors.light};
    height: 100dvh;
  }
`;

const HeaderWrapper = styled(Flex)`
  justify-content: space-between;
  height: ${styleDimensions.desktopAlbumPreviewHeaderHeight};
  min-height: ${styleDimensions.desktopAlbumPreviewHeaderHeight};
  width: 100%;
  min-width: 100%;
  padding: 0 40px;
  box-shadow: 0px 2px 8px rgba(31, 30, 30, 0.08);
  @media (max-width: 768px) {
    padding: 0 16px;
    height: ${styleDimensions.mobileAlbumPreviewHeaderHeight};
    min-height: ${styleDimensions.mobileAlbumPreviewHeaderHeight};
  }
`;

const cursorStyles = ({ cursor }) => {
  const arrowStyles = css`
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 50%;
      height: 2px;
      top: 0;
      left: 0;
      background-color: #000;
    }
  `;

  if (cursor === 'prev') {
    return css`
      ${arrowStyles}
      &::before {
        transform-origin: -2px 20px;
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform-origin: 15px -10px;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    `;
  } else if (cursor === 'next') {
    return css`
      ${arrowStyles}
      &::before {
        transform-origin: 14px 12px;
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after {
        transform-origin: 15px -10px;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    `;
  } else {
    return css`
      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 2px;
        top: 0;
        left: 0;
        background-color: #000;
      }
      &::before {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(45deg);
      }
    `;
  }
};

const CursorWrapper = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  ${cursorStyles}
`;

export default ProfileAlbumLayout;
