import AuthHelper from 'helpers/auth_helper.js';
import FormatHelpers from 'helpers/format_helpers.js';
import Globals from 'helpers/globals.js';
import StatHelpers from 'helpers/stat_helpers.js';
import SCTrack from 'classes/sc_track';
import isEqual from 'lodash.isequal';
import { colors } from 'helpers/colors';

const $ = window.jQuery;

const ProjectHelpers = {
  getJobsAmount(jobs) {
    try {
      return jobs.reduce((amount, j) => {
        const isDayRate = j.frequency === 'DAYRATE';
        const isHourlyRate = j.frequency === 'HOURLY';
        if (isDayRate) return amount + j.rate * j.dates.length;
        if (isHourlyRate)
          return amount + j.rate * j.dates.length * parseInt(j.hours);
        return amount;
      }, 0);
    } catch {
      return null;
    }
  },

  userIsOwner(project, user = AuthHelper.currentUser()) {
    return (
      project.user && project.user.swipecast_user_id === user.swipecast_user_id
    );
  },

  jobStatusColor(job) {
    const statuses = {
      // final statuses
      canceledJob: colors.gray[400],
      completedJob: colors.gray[400],
      expiredJob: colors.gray[400],

      // active
      confirmedJob: colors.secondary[500],
      waitingForTalentToAccept: colors.secondary[500],
      waitingForTalentToApply: colors.secondary[500],

      // issues
      cardChargeFailed: colors.primary[500],
      jobWaitingForThirdPartyPayAndTalentAccept: colors.primary[500],
      jobWaitingForThirdPartyPay: colors.primary[500]
    };

    return `${statuses[job.status]}`;
  },

  jobStatusText(job) {
    if (job.hidden === true && job.status === 'waitingForTalentToApply') {
      return 'Pending Approval';
    }
    // Canceled job
    const statuses = {
      canceledJob: 'Canceled',
      confirmedJob: 'Confirmed',
      completedJob:
        job.job_type === 'Role' && !job.booking
          ? 'Applications Closed'
          : 'Completed',
      cardChargeFailed: 'Payment Issues',
      waitingForTalentToAccept: 'Awaiting Response',
      jobWaitingForThirdPartyPayAndTalentAccept: 'Pending Payment',
      jobWaitingForThirdPartyPay: 'Awaiting Payment',
      waitingForTalentToApply: 'Open to Applicants',
      expiredJob: 'Expired'
    };

    return `${statuses[job.status]}`;
  },

  STATUS_WHERE_TALENT_CAN_APPLY_TO_JOB() {
    return [
      'waitingForTalentToApply',
      'waitingForTalentToAccept',
      'jobWaitingForThirdPartyPayAndTalentAccept'
    ];
  },

  STATUS_WHERE_TALENT_CANNOT_APPLY_TO_JOB() {
    return ['jobWaitingForThirdPartyPay', 'cardChargeFailed', 'confirmedJob'];
  },

  STATUS_NO_3PP_LINK() {
    return [...ProjectHelpers.FINAL_STATUS(), 'confirmedJob'];
  },

  FINAL_STATUS() {
    return ['completedJob', 'expiredJob', 'canceledJob'];
  },

  jobTotalRate(job) {
    if (job.days) {
      return job.rate * job.days;
    }
    return job.rate * job.hours;
  },

  undisclosed(job) {
    return !job.rate;
  },

  rateLabel(job) {
    let rate = 'Unpaid';
    if (job.rate > 0) {
      rate = FormatHelpers.numberToCurrencyLabel(
        ProjectHelpers.jobTotalRate(job)
      );
    }
    return rate;
  },

  getDuplicateRoleIndices(role, roles) {
    const indices = [];
    roles.forEach((currentRole, index) => {
      // For now, basically want to compare over every parameter, so this works
      if (isEqual(currentRole, role)) {
        indices.push(index);
      }
    });
    indices.shift();
    return indices;
  },

  getDuplicatedRoles(roles) {
    // cycles through array of roles; returns an array with the
    // first instance of each role that has at least one duplicate
    const ignoredIndices = [];
    const rolesBeingCopied = [];
    roles.forEach((role, index) => {
      const duplicateIndices = this.getDuplicateRoleIndices(role, roles);
      if (ignoredIndices.indexOf(index) === -1 && duplicateIndices.length > 0) {
        rolesBeingCopied.push(role);
        ignoredIndices.push(...duplicateIndices);
      }
    });
    return rolesBeingCopied;
  },

  matchesLookingFor(job, user) {
    let profile_type;
    if (user === 'self') {
      if (!AuthHelper.logged_in) {
        return false;
      }
      profile_type = AuthHelper.logged_in_profile_types[0];
    } else {
      profile_type = user.profiles[0].profile_type;
    }
    const looking_for = job.looking_for;
    if (looking_for === profile_type) {
      return true;
    }
    if (looking_for === 'Influencer') {
      return true;
    }
    if (
      looking_for === 'Art Director' &&
      profile_type === 'Creative Director'
    ) {
      return true;
    }
    // TODO PORT - check if lower-upper case is good and add model stuff
    if (
      (looking_for === 'Make-Up Artist' || looking_for === 'Hair Stylist') &&
      profile_type === 'Hair & Make-Up Artist'
    ) {
      return true;
    }
    return false;
  },
  rangeFilters() {
    return [
      'instagram_followers',
      'height',
      'bust',
      'waist',
      'hip',
      'shoe',
      'dress',
      'chest'
    ];
  },
  listFilters() {
    return ['eye_color', 'hair_color', 'ethnicity', 'tags'];
  },
  valueFilters() {
    return ['gender'];
  },

  openToApplications(job) {
    if (!job) return false;
    if (job.open_to_applications === undefined) return true;
    return job.open_to_applications;
  },

  canApplyToJob(job) {
    if (!job) return false;
    return (
      ProjectHelpers.openToApplications(job) &&
      job.can_apply_to &&
      !this.userIsOwner(job.project)
    );
  },

  loadMyJobsTalentCanApplyTo(invitee_swipecast_user_id, showing, callback) {
    return $.ajax({
      method: 'GET',
      url: `/my_jobs_for/${invitee_swipecast_user_id}`,
      dataType: 'JSON',
      data: {
        showing,
        soft: true
      },
      success: data => {
        callback(data.jobs);
      },
      error: error => {
        console.log(error);
        if (error.status === 404) {
          callback([]);
        }
      }
    });
  },

  loadAllJobs(
    callback,
    swipecast_user_id,
    offset,
    limit,
    filters = {},
    count = false
  ) {
    // const now = new Date();
    // var month = now.getUTCMonth() + 1; //months from 1-12
    // var day = now.getUTCDate();
    // var year = now.getUTCFullYear();
    const MIN_DATE = new Date(
      new Date().setDate(new Date().getDate() - 1)
    ).toISOString(); // a day ago

    const query_data = {
      filters: {
        begin_date: {
          // min: '2010-01-01'
          // min: `${year}-${month}-${day}`
          min: MIN_DATE
        },
        ...filters
      },
      count,
      offset,
      limit,
      type: 'project_job',
      // "group_by": ["looking_for", "gender"],
      order_by: [{ manual_ordering: 'desc' }, { rate: 'desc' }],
      swipecast_user_id
      // "custom_order": [{ "looking_for": ["Model"] }, { "gender": ["Male"] }]
    };
    if (AuthHelper.logged_in_profile_types.length > 0) {
      const custom_order = [
        { looking_for: [AuthHelper.logged_in_profile_types[0], 'Influencer'] }
      ];

      if (AuthHelper.logged_in_gender) {
        custom_order.push({ gender: [AuthHelper.logged_in_gender] });
      }

      query_data.custom_order = custom_order;
      // modelProfile = UserHelpers.getProfile(@props.logged_in_user, 'Model')
      // if modelProfile?.data?.gender?
      // custom_order.push({"gender": [modelProfile.data.gender]})
    }
    $.ajax({
      method: 'POST',
      url: '/query',
      dataType: 'JSON',
      contentType: 'application/json',
      data: JSON.stringify({
        query: query_data
      }),
      success: data => {
        callback(data.result);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  inviteToJob(swipecast_job_id, invitee_swipecast_user_id, callback, onError) {
    $.ajax({
      method: 'POST',
      url: `/job/${swipecast_job_id}/invite/${invitee_swipecast_user_id}`,
      dataType: 'JSON',
      success: data => {
        callback(data);
      },
      error: error => {
        if (onError) onError(error);
        console.log(error);
      }
    });
  },

  selectApplicant(
    swipecast_job_id,
    selected_swipecast_user_id,
    payment_info,
    callback,
    onError = () => {}
  ) {
    $.ajax({
      method: 'POST',
      url: `/job/${swipecast_job_id}/select/${selected_swipecast_user_id}`,
      data: payment_info,
      dataType: 'JSON',
      success: data => {
        callback(data);
      },
      error: error => {
        onError(error);
      }
    });
  },

  selectApplicantV2(
    swipecast_job_id,
    selected_swipecast_user_id,
    payment_info
  ) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: `/job/${swipecast_job_id}/select/${selected_swipecast_user_id}`,
        data: payment_info,
        dataType: 'JSON',
        success: data => {
          resolve(data);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  deSelectApplicant(
    swipecastJobId,
    swipecastUserId,
    callback,
    onError = () => {}
  ) {
    $.ajax({
      method: 'POST',
      url: `/job/${swipecastJobId}/deselect/${swipecastUserId}`,
      dataType: 'JSON',
      data: {},
      success: data => {
        callback(data);
      },
      error: error => {
        onError(error);
      }
    });
  },

  deSelectApplicantV2(swipecastJobId, swipecastUserId) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: `/job/${swipecastJobId}/deselect/${swipecastUserId}`,
        dataType: 'JSON',
        data: {},
        success: data => {
          resolve(data);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  updateApplication(swipecast_job_id, swipecast_user_id, status, callback) {
    $.ajax({
      method: 'POST',
      url: `/job/${swipecast_job_id}/update_application/${swipecast_user_id}`,
      data: {
        status
      },
      dataType: 'JSON',
      success: data => {
        callback(data);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  updateApplicationV2(swipecast_job_id, swipecast_user_id, status) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: `/job/${swipecast_job_id}/update_application/${swipecast_user_id}`,
        data: {
          status
        },
        dataType: 'JSON',
        success: data => {
          resolve(data);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  loadMyProjectsAsClient(
    callback,
    include_applicants = true,
    {
      page,
      per_page,
      only_active,
      only_past,
      only_confirmed,
      with_active,
      without_active
    } = {}
  ) {
    let params = {
      only_active,
      only_past,
      only_confirmed,
      with_active,
      without_active
    };
    for (let key in params) {
      if (params[key] === undefined) delete params[key];
    }

    if (per_page && page) {
      params.per_page = per_page;
      params.page = page;
    }

    if (include_applicants) {
      params['include_applicants'] = true;
    }
    return $.ajax({
      method: 'GET',
      url: '/projects_posted',
      dataType: 'JSON',
      data: params,
      success: data => {
        callback(data);
      },
      error: error => {
        if (error.status === 404) {
          callback({ projects: [] });
        }
      }
    });
  },

  loadMyJobsAsTalentV2(callback, filters = {}) {
    $.ajax({
      method: 'GET',
      url: '/v2/applied_jobs',
      dataType: 'JSON',
      contentType: 'application/json',
      data: {
        job_application_statuses: ['selected'],
        ...filters
      },
      success: data => {
        callback(data);
      },
      error: error => {
        console.log(error);
        return null;
      }
    });
  },

  loadMyJobsAsTalent(callback) {
    $.ajax({
      method: 'GET',
      url: '/projects_applied_to',
      dataType: 'JSON',
      contentType: 'application/json',
      data: {
        job_application_statuses: ['selected']
      },
      success: data => {
        callback(data.projects);
      },
      error: error => {
        if (error.status === 404) {
          callback([]);
        }
        console.log(error);
      }
    });
  },

  lookingForLabel(job, singular = false) {
    const ending = singular ? '' : 's';
    if (job.looking_for === 'Model') {
      if (job.looking_for_data) {
        if (
          job.looking_for_data &&
          job.looking_for_data.gender &&
          job.looking_for_data.gender.toLowerCase() === 'female'
        ) {
          return `Female Model${ending}`;
        }
        return `Male Model${ending}`;
      }
      return 'Models';
    }
    return job.looking_for + ending;
  },

  jobDescription(job) {
    const result = this.lookingForLabel(job);

    const filter_description = [];

    const default_filters = Object.assign(
      {},
      Globals.statDetails.General,
      Globals.statDetails.Female,
      Globals.statDetails.Male
    );

    const rangeFilters = this.rangeFilters();
    const list_filers = this.listFilters();
    const valueFilters = this.valueFilters();

    rangeFilters.forEach(filter => {
      if (job.looking_for_data && job.looking_for_data[filter]) {
        if (
          job.looking_for_data[filter].min &&
          job.looking_for_data[filter].max
        ) {
          filter_description.push(
            `${filter
              .replace('_', ' ')
              .toUpperCase()} BETWEEN ${StatHelpers.renderStat(
              job.looking_for_data[filter].min,
              default_filters[filter].displayType
            )} AND ${StatHelpers.renderStat(
              job.looking_for_data[filter].max,
              default_filters[filter].displayType
            )}`
          );
        } else if (job.looking_for_data[filter].min) {
          filter_description.push(
            `${filter
              .replace('_', ' ')
              .toUpperCase()} GREATER THAN ${StatHelpers.renderStat(
              job.looking_for_data[filter].min,
              default_filters[filter].displayType
            )}`
          );
        } else {
          filter_description.push(
            `${filter
              .replace('_', ' ')
              .toUpperCase()} LESS THAN ${StatHelpers.renderStat(
              job.looking_for_data[filter].max,
              default_filters[filter].displayType
            )}`
          );
        }
      }
    });

    list_filers.forEach(filter => {
      if (job.looking_for_data && job.looking_for_data[filter]) {
        filter_description.push(
          `${filter.replace('_', ' ').toUpperCase()}: ${job.looking_for_data[
            filter
          ].join(', ')}`
        );
      }
    });

    valueFilters.forEach(filter => {
      if (
        filter !== 'gender' &&
        job.looking_for_data &&
        job.looking_for_data[filter]
      ) {
        filter_description.push(
          `${filter.replace('_', ' ').toUpperCase()}: ${
            job.looking_for_data[filter]
          }`
        );
      }
    });

    if (filter_description.length > 0) {
      return `${result}\n${filter_description.join('\n')}`;
    }
    return result;
  },

  loadJob(swipecast_job_id, callback) {
    return $.ajax({
      method: 'GET',
      url: `/job/${swipecast_job_id}`,
      dataType: 'JSON',
      data: {},
      success: data => {
        callback(data.job);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  loadJobV2(swipecast_job_id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: `/job/${swipecast_job_id}`,
        dataType: 'JSON',
        data: {},
        success: data => {
          resolve(data.job);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  loadJobBySecretAliasId(secret_alias_id, callback, onError = () => {}) {
    $.ajax({
      method: 'GET',
      url: `/job_by_secret_alias_id/${secret_alias_id}`,
      dataType: 'JSON',
      contentType: 'application/json',
      data: {},
      success: data => {
        callback(data.job);
      },
      error: error => {
        onError(error);
      }
    });
  },

  loadJobBySecretAliasIdV2(secret_alias_id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: `/job_by_secret_alias_id/${secret_alias_id}`,
        dataType: 'JSON',
        contentType: 'application/json',
        data: {},
        success: data => {
          resolve(data.job);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  loadProject(swipecast_project_id, callback) {
    $.ajax({
      method: 'GET',
      url: `/project/${swipecast_project_id}`,
      dataType: 'JSON',
      data: {},
      success: data => {
        callback(data.project);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  loadProjectV2(swipecast_project_id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: `/project/${swipecast_project_id}`,
        dataType: 'JSON',
        data: {},
        success: data => {
          resolve(data.project);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  loadProjectBySecretAliasId(secret_alias_id, callback) {
    $.ajax({
      method: 'GET',
      url: `/project_by_secret_alias_id/${secret_alias_id}`,
      dataType: 'JSON',
      contentType: 'application/json',
      data: {},
      success: data => {
        console.log(data);
        callback(data.project);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  loadProjectBySecretAliasIdV2(secret_alias_id) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'GET',
        url: `/project_by_secret_alias_id/${secret_alias_id}`,
        dataType: 'JSON',
        contentType: 'application/json',
        data: {},
        success: data => {
          resolve(data.project);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },

  convertDatesForJob(dates, time) {
    return dates.map(dateField => {
      let hours = Number(time.match(/^(\d+)/)[1]);
      const minutes = Number(time.match(/:(\d+)/)[1]);
      const AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM === 'PM' && hours < 12) hours += 12;
      if (AMPM === 'AM' && hours === 12) hours -= 12;
      let sHours = hours.toString();
      let sMinutes = minutes.toString();
      if (hours < 10) sHours = `0${sHours}`;
      if (minutes < 10) sMinutes = `0${sMinutes}`;
      const timestamp = (parseInt(dateField, 10) + 1) * 86400000;
      const date = new Date(
        new Date(timestamp).setHours(
          parseInt(sHours, 10),
          parseInt(sMinutes, 10)
        )
      );
      const month = date.getUTCMonth() + 1;
      return `${date.getUTCFullYear()}-${month}-${date.getUTCDate()} ${date.getUTCHours()}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
    });
  },

  createJob(details, callback) {
    $.ajax({
      method: 'POST',
      url: '/job',
      dataType: 'JSON',
      contentType: 'application/json',
      data: JSON.stringify(details),
      success: data => {
        console.log(data);
        callback(data);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  createProject(details) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: '/project',
        dataType: 'JSON',
        contentType: 'application/json',
        data: JSON.stringify(details),
        success: resolve,
        error: reject
      });
    });
  },
  applyToJob(job, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/apply`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        callback(data);
      }
    });
  },
  unapplyFromJob(job, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/unapply`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        callback(data);
      }
    });
  },
  acceptJob(job, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/accept`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        SCTrack.all('Talent Accept Project', {
          'Direct Booking': job.booking
        });
        callback(data);
      }
    });
  },
  declineJob(job, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/decline`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        SCTrack.all('Talent Decline Project', {
          'Direct Booking': job.booking
        });
        callback(data);
      }
    });
  },
  withdrawFromJob(job, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/withdraw`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        console.log(data);
        callback(data);
      }
    });
  },
  retryPayment(job, stripe_source_id, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/retry_payment`,
      data: JSON.stringify({
        stripe_source_id
      }),
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        console.log(data);
        callback(data);
      }
    });
  },
  retryPaymentV2(swipecast_job_id, stripe_source_id) {
    return new Promise((resolve, reject) => {
      return $.ajax({
        method: 'POST',
        url: `/job/${swipecast_job_id}/retry_payment`,
        data: JSON.stringify({
          stripe_source_id
        }),
        dataType: 'JSON',
        contentType: 'application/json',
        success: data => {
          console.log(data);
          resolve(data);
        },
        error: err => {
          reject(err);
        }
      });
    });
  },
  cancelJob(job, callback) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/cancel`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        SCTrack.all('Client Cancel Job', { 'Direct Booking': job.booking });
        callback(data);
      },
      error: error => {
        console.log(error);
      }
    });
  },
  cancelJobV2(job) {
    return new Promise((resolve, reject) => {
      return $.ajax({
        method: 'POST',
        url: `/job/${job.swipecast_job_id}/cancel`,
        dataType: 'JSON',
        contentType: 'application/json',
        success: data => {
          SCTrack.all('Client Cancel Job', { 'Direct Booking': job.booking });
          resolve(data);
        },
        error: error => {
          console.log(error);
          reject(error);
        }
      });
    });
  },
  updateJob(jobParams, swipecast_job_id, callback) {
    return $.ajax({
      method: 'PUT',
      url: `/job/${swipecast_job_id}`,
      data: JSON.stringify({
        job: jobParams
      }),
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        callback(data);
      }
    });
  },
  updateJobV2(jobParams, swipecast_job_id) {
    return new Promise((resolve, reject) => {
      return $.ajax({
        method: 'PUT',
        url: `/job/${swipecast_job_id}`,
        data: JSON.stringify({
          job: jobParams
        }),
        dataType: 'JSON',
        contentType: 'application/json',
        success: data => {
          resolve(data.job);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },
  toggleFinishTalentSelection(swipecastJobId, callback) {
    return $.ajax({
      method: 'PUT',
      url: `/job/${swipecastJobId}/talent-selection`,
      dataType: 'JSON',
      contentType: 'application/json',
      success: data => {
        callback(data);
      }
    });
  },
  toggleFinishTalentSelectionV2(swipecastJobId) {
    return new Promise((resolve, reject) => {
      return $.ajax({
        method: 'PUT',
        url: `/job/${swipecastJobId}/talent-selection`,
        dataType: 'JSON',
        contentType: 'application/json',
        success: data => {
          resolve(data.job);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },
  updateProject(params, swipecast_project_id) {
    return $.ajax({
      method: 'PUT',
      url: `/project/${swipecast_project_id}`,
      data: JSON.stringify({
        project: params
      }),
      dataType: 'JSON',
      contentType: 'application/json'
    });
  },
  updateProjectV2(swipecast_project_id, params) {
    return new Promise((resolve, reject) => {
      return $.ajax({
        method: 'PUT',
        url: `/project/${swipecast_project_id}`,
        data: JSON.stringify({
          project: params
        }),
        dataType: 'JSON',
        contentType: 'application/json',
        success: data => {
          resolve(data.project);
        },
        error: error => {
          reject(error);
        }
      });
    });
  },
  shareJob(job, emails) {
    return $.ajax({
      method: 'POST',
      url: `/job/${job.swipecast_job_id}/cancel`,
      dataType: 'JSON',
      contentType: 'application/json'
    });
  },
  loadJobRecommendations(swipecast_job_id, secret_alias_id, limit, offset) {
    return $.ajax({
      method: 'GET',
      url: `/job/${swipecast_job_id}/recommendations`,
      data: {
        secret_alias_id,
        limit,
        offset
      },
      dataType: 'JSON'
    });
  },
  jobCTAText(isBooker, status) {
    if (!isBooker) {
      switch (status) {
        case 'waitingForTalentToApply':
          return 'This job is accepting applications.';
        case 'expiredJob':
          return 'This job has expired.';
        case 'confirmedJob':
          return 'You are confirmed for this job.';
        case 'canceledJob':
          return 'The job has been canceled.';
        case 'cardChargeFailed':
        case 'jobWaitingForThirdPartyPay':
          return 'We are waiting for the client to complete payment.';
        case 'completedJob':
          return 'This job has been completed!';
        case 'jobWaitingForThirdPartyPayAndTalentAccept':
          return 'You have a booking request! Please let the client know whether you can make it.';
        case 'waitingForTalentToAccept':
          return 'You have been selected for this job! Please accept or decline.';
        default:
          return '';
      }
    } else {
      switch (status) {
        case 'waitingForTalentToApply':
          return 'This job is accepting applications.';
        case 'expiredJob':
          return 'This job has expired.';
        case 'confirmedJob':
          return 'This job is now confirmed.';
        case 'canceledJob':
          return 'The job has been canceled.';
        case 'cardChargeFailed':
          return 'We had a problem processing your credit card, please try again.';
        case 'jobWaitingForThirdPartyPay':
          return 'We are waiting to receive client payment.';
        case 'jobWaitingForThirdPartyPayAndTalentAccept':
          return 'We are waiting for the talent to accept the job and to receive client payment.';
        case 'waitingForTalentToAccept':
          return 'We are waiting for the talent to accept this job.';
        case 'completedJob':
          return 'This job has been completed!';
        default:
          return '';
      }
    }
  }
};

export default ProjectHelpers;
