import { useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AuthHelper from 'helpers/auth_helper';
import { ModalContext } from 'contexts/contexts';
import { routes } from 'helpers/router_helper';

const useBack = ({ setStep, step, index }) => {
  const modalContext = useContext(ModalContext);
  const history = useHistory();

  const onBack = useCallback(() => {
    if (index === 0) {
      if (
        window.confirm(
          'Warning: this action will log out of your account. \nAre you sure you want to proceed?'
        )
      ) {
        AuthHelper.logout();
        modalContext.onCloseModal();
        history.replace(routes.logged_out.landing.redirectUrl());
      }
    } else {
      setStep(step - 1);
    }
  }, [index, setStep, step]);

  return onBack;
};

export default useBack;
