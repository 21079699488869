import { useMemo } from 'react';

import useChangeSliderRate from 'components/views/logged_in/jobs/create_job/create_job_v2/public_posting/components/steps/create_project_rate/components/create_project_rate_item_v2/hooks/use_change_slider_rate';

const duplicatedUseChangeSliderRate = ({ setRate, ratesContext }) => {
  const breakpointsArray = useMemo(
    () => ratesContext.talentRates.breakpoints.map(({ min }) => min),
    [ratesContext.talentRates.breakpoints]
  );

  const changeSliderRate = useChangeSliderRate({
    breakpointsArray,
    onChangeRate: setRate,
    max: ratesContext.talentRates.max
  });

  return changeSliderRate;
};

export default duplicatedUseChangeSliderRate;
