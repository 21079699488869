import React from 'react';

import { colors } from 'helpers/colors';

function SvgEnvelopeSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.8 4H19.2C19.6774 4 20.1352 4.18964 20.4728 4.52721C20.8104 4.86477 21 5.32261 21 5.8V16.6C21 17.0774 20.8104 17.5352 20.4728 17.8728C20.1352 18.2104 19.6774 18.4 19.2 18.4H6.6L3 22V5.8C3 5.32261 3.18964 4.86477 3.52721 4.52721C3.86477 4.18964 4.32261 4 4.8 4ZM7.6 7.8C7.26863 7.8 7 8.06863 7 8.4C7 8.73137 7.26863 9 7.6 9H16.4C16.7314 9 17 8.73137 17 8.4C17 8.06863 16.7314 7.8 16.4 7.8H7.6ZM7 11.4C7 11.0686 7.26863 10.8 7.6 10.8H16.4C16.7314 10.8 17 11.0686 17 11.4C17 11.7314 16.7314 12 16.4 12H7.6C7.26863 12 7 11.7314 7 11.4ZM7.6 13.8C7.26863 13.8 7 14.0686 7 14.4C7 14.7314 7.26863 15 7.6 15H11.4C11.7314 15 12 14.7314 12 14.4C12 14.0686 11.7314 13.8 11.4 13.8H7.6Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgEnvelopeSolid;
