import React, { useCallback, useMemo, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import DefaultAvatar from './default_avatar';
import ImageHelpers from 'helpers/image_helpers';
import { StyledImage, Wrapper } from './styled';

function Avatar({ width, user, url, className }) {
  const [fetched, setFetched] = useState(false);
  const onLoad = useCallback(() => setFetched(true), []);

  const imageUrl = useMemo(() => {
    let imageURL = url;

    // if null use placeholder
    if (imageURL === undefined && user.profile_image && width) {
      // 'https://swipecast.net/imagen/image/upload/e53f3dfa-2232-4ba0-9114-2bcd097e1de9.jpg'
      imageURL = user.profile_image;
    }

    if (imageURL && width)
      return ImageHelpers.imageSizeUrl(imageURL, width * 2);
    return null;
  }, [user.profile_image, width, url]);

  // const imageSrc = AuthHelper.logged_in_profile_img
  //   ? ImageHelpers.imageSizeUrl(AuthHelper.logged_in_profile_img, 600)
  //   : 'blank';

  if (!width) return null;

  if (imageUrl)
    return (
      <Wrapper width={width} className={className}>
        {!fetched && <Skeleton width="100%" height="100%" borderRadius="50%" />}
        <StyledImage onLoad={onLoad} src={imageUrl} />
      </Wrapper>
    );

  return (
    <DefaultAvatar
      className={className}
      width={width}
      firstName={user.first_name}
      lastName={user.last_name}
    />
  );
}

export default Avatar;
