import { call, put, takeLatest } from 'redux-saga/effects'

import client from 'api/rest_client'
import { MetaTypes, PayloadTypes, ActionsTypes } from 'store/configure/actions'

const buildResourceSagas = (actions: ActionsTypes) => {
  function* appendResource({
    meta,
    payload: { method = 'get', ...payload } = {} }: { meta: MetaTypes; type: string, payload: PayloadTypes }) {
    try {
      const res = yield call([client, client[method]], meta.endpoint, payload)
      yield put(actions.resourceAppendSuccess(res, meta))
    } catch (e) {
      yield put(actions.resourceAppendFailure(e, meta))
    }
  }

  function* fetchResource({
    meta,
    payload: { method = 'get', ...payload } = {} }: { meta: MetaTypes; type: string, payload: PayloadTypes }) {
    try {
      const res = yield call([client, client[method]], meta.endpoint, payload)
      yield put(actions.resourceFetchSuccess(res, meta))
    } catch (e) {
      yield put(actions.resourceFetchFailure(e, meta))
    }
  }

  function* createResource({ payload, meta }: { payload: PayloadTypes; meta: MetaTypes; type: string }) {
    try {
      const res = yield call([client, client.post], meta.endpoint, payload)
      yield put(actions.resourceCreateSuccess(res, meta))
    } catch (e) {
      yield put(actions.resourceCreateFailure(e, meta))
    }
  }

  function* updateResource({ payload, meta }: { payload: PayloadTypes; meta: MetaTypes; type: string }) {
    try {
      const res = yield call([client, client.patch], meta.endpoint, payload)
      yield put(actions.resourceUpdateSuccess(res, meta))
    } catch (e) {
      yield put(actions.resourceUpdateFailure(e, meta))
    }
  }

  function* getResource({ meta }: { meta: MetaTypes; type: string }) {
    try {
      const res = yield call([client, client.get], meta.endpoint)
      yield put(actions.resourceGetSuccess(res, meta))
    } catch (e) {
      yield put(actions.resourceGetFailure(e, meta))
    }
  }

  function* deleteResource({ meta }: { meta: MetaTypes; type: string }) {
    try {
      const res = yield call([client, client.delete], meta.endpoint)
      yield put(actions.resourceDeleteSuccess(res, meta))
    } catch (e) {
      yield put(actions.resourceDeleteFailure(e, meta))
    }
  }

  return [
    takeLatest(actions.RESOURCE_APPEND_REQUEST, appendResource),
    takeLatest(actions.RESOURCE_FETCH_REQUEST, fetchResource),
    takeLatest(actions.RESOURCE_CREATE_REQUEST, createResource),
    takeLatest(actions.RESOURCE_UPDATE_REQUEST, updateResource),
    takeLatest(actions.RESOURCE_GET_REQUEST, getResource),
    takeLatest(actions.RESOURCE_DELETE_REQUEST, deleteResource),
  ]
}

export default buildResourceSagas