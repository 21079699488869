import { useCallback, useState } from 'react';
import Settings from 'helpers/settings';
import { UaBannerContext } from 'contexts/contexts';
import { localStorageEnum } from 'helpers/local_storage_helpers';

const getBannerInitial = () => {
  if (localStorage.getItem(localStorageEnum.uaBannerEnabled) === 'false')
    return false;
  return Settings.uaBannerEnabled;
};

const withUaBannerProvider = Component => props => {
  const [showUaBaner, setShowBanner] = useState(getBannerInitial());

  const onCloseBanner = useCallback(() => {
    setShowBanner(false);
    localStorage.setItem(localStorageEnum.uaBannerEnabled, 'false');
  }, [setShowBanner]);

  return (
    <UaBannerContext.Provider value={{ onCloseBanner }}>
      <Component {...props} showUaBaner={showUaBaner} />
    </UaBannerContext.Provider>
  );
};

export default withUaBannerProvider;
