import { useCallback } from 'react';

const useDisabled = ({ month, year, withoutToday }) => {
  const getUseDisabled = useCallback(
    day => {
      const today_y_m_d_combo =
        new Date().getDate() +
        30 * (new Date().getMonth() + 12 * new Date().getFullYear());
      const displayed_y_m_d_combo = day + 30 * (month + 12 * year);
      const clickable =
        today_y_m_d_combo < displayed_y_m_d_combo + (withoutToday ? 0 : 1);
      return !clickable;
    },
    [month, year, month, year]
  );
  return { getUseDisabled };
};

export default useDisabled;
