import React from 'react';

import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';

function SharePortfolioLabels({ title, showCopyHint }) {
  return (
    <>
      <Text size={24} weight={500} textAlign="left">
        {title}
      </Text>

      {showCopyHint && (
        <Indent top={16}>
          <Text size={12} color={colors.gray[400]}>
            <span style={{ fontWeight: 500, color: colors.gray[400] }}>
              Hint:&nbsp;
            </span>
            Copy this link and paste it into your IG, TikTok or Youtube bio and
            attract more bookings.
          </Text>
        </Indent>
      )}
    </>
  );
}

export default SharePortfolioLabels;
