import React from 'react';

import { colors } from 'helpers/colors';

function SvgPeopleSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M10.625 20.5C10.625 20.5 9.25 20.5 9.25 19.0833C9.25 17.6667 10.625 13.4167 16.125 13.4167C21.625 13.4167 23 17.6667 23 19.0833C23 20.5 21.625 20.5 21.625 20.5H10.625ZM16.125 12C17.219 12 18.2682 11.5522 19.0418 10.7552C19.8154 9.95817 20.25 8.87717 20.25 7.75C20.25 6.62283 19.8154 5.54183 19.0418 4.7448C18.2682 3.94777 17.219 3.5 16.125 3.5C15.031 3.5 13.9818 3.94777 13.2082 4.7448C12.4346 5.54183 12 6.62283 12 7.75C12 8.87717 12.4346 9.95817 13.2082 10.7552C13.9818 11.5522 15.031 12 16.125 12Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.172 20.5C7.96816 20.0578 7.86651 19.5729 7.875 19.0833C7.875 17.1638 8.81 15.1875 10.537 13.8133C9.675 13.5397 8.77693 13.4059 7.875 13.4167C2.375 13.4167 1 17.6667 1 19.0833C1 20.5 2.375 20.5 2.375 20.5H8.172Z"
        fill={color}
      />
      <path
        d="M7.18726 12C8.09894 12 8.97328 11.6269 9.61794 10.9627C10.2626 10.2985 10.6248 9.39766 10.6248 8.45835C10.6248 7.51905 10.2626 6.61821 9.61794 5.95402C8.97328 5.28983 8.09894 4.91669 7.18726 4.91669C6.27557 4.91669 5.40123 5.28983 4.75658 5.95402C4.11192 6.61821 3.74976 7.51905 3.74976 8.45835C3.74976 9.39766 4.11192 10.2985 4.75658 10.9627C5.40123 11.6269 6.27557 12 7.18726 12Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgPeopleSolid;
