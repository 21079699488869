import React from 'react';

import { colors } from 'helpers/colors';

function SvgCaseSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 6V6.6H4.8C4.32261 6.6 3.86477 6.78964 3.52721 7.12721C3.18964 7.46477 3 7.92261 3 8.4L3 19.2C3 19.6774 3.18964 20.1352 3.52721 20.4728C3.86477 20.8104 4.32261 21 4.8 21H19.2C19.6774 21 20.1352 20.8104 20.4728 20.4728C20.8104 20.1352 21 19.6774 21 19.2V8.4C21 7.92261 20.8104 7.46477 20.4728 7.12721C20.1352 6.78964 19.6774 6.6 19.2 6.6H15V6C15 5.20435 14.6839 4.44129 14.1213 3.87868C13.5587 3.31607 12.7956 3 12 3C11.2044 3 10.4413 3.31607 9.87868 3.87868C9.31607 4.44129 9 5.20435 9 6ZM12 4.2C11.5226 4.2 11.0648 4.38964 10.7272 4.72721C10.3896 5.06477 10.2 5.52261 10.2 6V6.6H13.8V6C13.8 5.52261 13.6104 5.06477 13.2728 4.72721C12.9352 4.38964 12.4774 4.2 12 4.2ZM3.792 11.8404C6.02719 13.9042 8.95777 15.0502 12 15.0502C15.0422 15.0502 17.9728 13.9042 20.208 11.8404L19.392 10.9596C17.3787 12.8176 14.7396 13.8492 12 13.8492C9.26041 13.8492 6.62129 12.8176 4.608 10.9596L3.792 11.8404Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgCaseSolid;
