export const progressToValue = ({ midPoint, min, max, progress }) => {
  const requiredAttrs = [midPoint, min, max, progress];
  if (requiredAttrs.some(attr => !attr && attr !== 0)) return null;

  let value;
  if (progress <= 50) {
    const firstHalfProgress = progress * 2;
    value = (firstHalfProgress / 100) * (midPoint - min) + min;
  } else {
    const secondHalfProgress = (progress - 50) * 2;
    value = (secondHalfProgress / 100) * (max - midPoint) + midPoint;
  }

  return value;
};

export const valueToProgress = ({ midPoint, min, max, value }) => {
  const requiredAttrs = [midPoint, min, max, value];
  if (requiredAttrs.some(attr => !attr && attr !== 0)) return null;
  let totalProgress;

  if (value <= midPoint) {
    const firstHalfProgress = ((value - min) / (midPoint - min)) * 100;
    totalProgress = firstHalfProgress / 2;
  } else {
    const secondHalfProgress = ((value - midPoint) / (max - midPoint)) * 100;
    totalProgress = 50 + secondHalfProgress / 2;
  }

  return totalProgress;
};
