import { useCallback } from 'react';
import { destroy } from 'redux-form';
import { useDispatch } from 'react-redux';

import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';
import AuthHelper from 'helpers/auth_helper';

const useDestroyOngoingStepsData = () => {
  const dispatch = useDispatch();

  const destroyOngoingStepsData = useCallback(() => {
    [
      // delete initial data if user already have profile
      ...(AuthHelper.currentUser()?.profiles?.length
        ? signUpStepsData.profile.form
        : []),
      // can be initial step if profile provided
      // signUpStepsData.location.form,

      signUpStepsData.oauth.form,
      signUpStepsData.intention.form,
      signUpStepsData.phone.form,
      signUpStepsData.company.form,
      signUpStepsData.website.form,
      signUpStepsData.ig.form,
      signUpStepsData.referral.form
    ].forEach(f => {
      dispatch(destroy(f));
    });
  }, []);

  return destroyOngoingStepsData;
};

export default useDestroyOngoingStepsData;
