import GeneralHelpers from 'helpers/general_helpers.js';
import ImageUploadHelpers from 'helpers/image_upload_helpers.js';

const $ = window.jQuery;

const getImgType = src => {
  const ext = src.split('.').slice(-1)[0];
  if (ext === 'png') return 'image/png';
  if (ext === 'webp') return 'image/webp';
  if (ext === 'jpeg') return 'image/jpeg';
  if (ext === 'jpg') return 'image/jpeg';
  return null;
};

// TODO change for smaller dimensions to get image ratio
// this preloads the actual image which could be very heavy operation
function getMeta(url, callback, onError = undefined) {
  var img = new Image();
  img.src = url;
  img.onload = function() {
    callback({ width: this.width, height: this.height });
  };
  if (onError) {
    img.onerror = onError;
  }
}

const urlToBase64 = async url => {
  try {
    const urlWithoutSizeParam = url.replace(/=s\d*$/, '');
    let res = await fetch(urlWithoutSizeParam);
    const blob = await res.blob();
    const isImage = blob.type.includes('image');
    if (!isImage) return null;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onerror = error => {
        reject(new Error(error));
      };
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  } catch (err) {
    console.log(err);
    return null;
  }
};

// let dataURItoBlob = function(dataURI) {
//   'use strict';
//   var byteString, content, i, mimestring;
//   byteString = void 0;
//   mimestring = void 0;
//   if (dataURI.split(',')[0].indexOf('base64') !== -1) {
//     byteString = atob(dataURI.split(',')[1]);
//   } else {
//     byteString = decodeURI(dataURI.split(',')[1]);
//   }
//   mimestring = dataURI
//     .split(',')[0]
//     .split(':')[1]
//     .split(';')[0];
//   content = new Array();
//   i = 0;
//   while (i < byteString.length) {
//     content[i] = byteString.charCodeAt(i);
//     i++;
//   }
//   return new Blob([new Uint8Array(content)], {
//     type: mimestring
//   });
// };

const sizeUrlForMatching = (pattern, originalUrl, size) => {
  if (pattern.matchQuery.test(originalUrl)) {
    let url = originalUrl.split('/');
    const imageId = url.pop();
    let cropInfo = null;
    if (/(c_crop)+/.test(url[url.length - 1])) {
      cropInfo = url.pop();
    }
    url = pattern.serviceRoot;
    if (cropInfo) {
      url += `/${cropInfo}`;
    }
    url += `/c_scale,w_${size}/${imageId}`;
    return url;
  }
  return null;
};

const sizeUrl = (originalUrl, size) => {
  const patterns = [
    {
      serviceRoot: 'https://swipecast.net/imagen/image/upload',
      matchQuery: /(imagen)+/
    },
    {
      serviceRoot: 'https://res.cloudinary.com/dngrsbxvu/image/upload',
      matchQuery: /(cloudinary)+/
    }
  ];
  const patternMatches = patterns.map(pattern =>
    sizeUrlForMatching(pattern, originalUrl, size)
  );
  const firstPatternMatch = patternMatches.filter(v => !!v)[0];
  if (firstPatternMatch) {
    return firstPatternMatch;
  }
  if (/[?]+/.test(originalUrl)) {
    return `${originalUrl}&size=${size}`;
  }
  return `${originalUrl}?size=${size}`;
};

const ImageHelpers = {
  getImgType,
  urlToBase64,
  getMeta,
  imageSizeUrl: (url, size) => sizeUrl(url, size),
  cropUrl: (url, crop_points) => {
    url = url.split('/');
    let image_id = url.pop();
    url = 'https://swipecast.net/imagen/image/upload';
    url +=
      '/' +
      'x_' +
      crop_points.topLeftX +
      ',y_' +
      crop_points.topLeftY +
      ',w_' +
      crop_points.box_width +
      ',h_' +
      crop_points.box_height +
      ',c_crop' +
      `/${image_id}`;
    return url;
  },
  uploadImageSimple: img => {
    return GeneralHelpers.uploadFile(img)
      .then(res => {
        ImageUploadHelpers.fetchImage(res.secure_url);
        return res;
      })
      .catch(err => {
        console.log(err);
        return err;
      });
  },
  updateAlbum(album_id, content, callback) {
    $.ajax({
      method: 'PUT',
      url: '/album/' + album_id,
      // + '?include_stripe_customer=true',
      data: JSON.stringify({
        album: {
          content: content
        }
      }),
      dataType: 'JSON',
      contentType: 'application/json',
      success: requestData => {
        callback(requestData.album);
      }
    });
  }
};

export default ImageHelpers;
