import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';

const Wrapper = styled(Flex)`
  max-width: 480px;
  width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

const ConfirmModal = ({ modalProps = {}, onClose }) => {
  const handleSubmit = useCallback(() => {
    if (typeof modalProps.callback === 'function') modalProps.callback();
    onClose();
  }, [modalProps, onClose]);

  return (
    <Wrapper dir="column" wrap="nowrap">
      <Text size={14} textAlign="center">
        {modalProps.text || 'Sample confirm text'}
      </Text>
      <Indent bottom={40} />

      <Flex gap={16}>
        <Button
          color={colors.gray[600]}
          borderColor="transparent"
          height={40}
          width={160}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button type="primary" height={40} width={160} onClick={handleSubmit}>
          OK
        </Button>
      </Flex>
    </Wrapper>
  );
};

export default ConfirmModal;
