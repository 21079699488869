import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import throttle from 'lodash.throttle';

import Input from 'components/reusable/input';

const InputWrapper = styled.div`
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.8);
  border-radius: 30px;
`;

const Wrapper = styled.div`
  border-radius: 30px 30px 20px 20px;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
`;

const VariantsWrapper = styled.div`
  > * {
    padding: 6px 16px;
  }
  > :first-child {
    padding-top: 12px;
  }
  > :last-child {
    padding-bottom: 12px;
  }
`;

const Autocomplete = ({ placeholder, onGetVariants, variants, error }) => {
  const [inputValue, setInputValue] = useState('');

  const handleGetVariants = useCallback(
    throttle(text => {
      if (onGetVariants) onGetVariants(text);
    }, 800),
    []
  );

  const handleChange = useCallback(v => {
    setInputValue(v || '');
  }, []);

  useEffect(() => {
    handleGetVariants(inputValue);
  }, [inputValue]);

  return (
    <Wrapper>
      <InputWrapper>
        <Input
          error={error}
          placeholder={placeholder}
          onChange={handleChange}
          value={inputValue}
          showError={false}
        />
      </InputWrapper>
      <VariantsWrapper>{variants}</VariantsWrapper>
    </Wrapper>
  );
};

export default Autocomplete;
