import React, { useMemo } from 'react';

import { PhoneBlock, Select, StyledCustomInput } from '../phone_v1/styled';
import Constants from 'helpers/constants';
import { PrefixSelect } from '../phone_v1/phone_v1';
import useOnChange from './hooks/use_on_change';

const countries = Constants.countriesPN;

const Phone = ({ onChange, value, name, error, textAlign }) => {
  const country = useMemo(() => countries.find(c => c.prefix === value.code), [
    value.code
  ]);

  const { handleCodeChange, handleNumberChange } = useOnChange({
    onChange,
    country,
    value
  });

  return (
    <PhoneBlock>
      <Select>
        <PrefixSelect
          countries={countries}
          value={country}
          onChange={handleCodeChange}
          error={error}
        />
      </Select>

      <StyledCustomInput
        type="input"
        name={name}
        placeholder="Enter phone number"
        value={value.phone}
        error={error}
        textAlign={textAlign}
        onChange={handleNumberChange}
        autoComplete="tel-national"
      />
    </PhoneBlock>
  );
};

export default Phone;
