import { useEffect, useRef } from 'react';

const useMounted = () => {
  const isMountedRef = useRef(false);
  const isMounted = isMountedRef.current;

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return isMounted;
};

export default useMounted;
