import { useCallback } from 'react';

const useCrop = ({ croppieOpts, croppieRef }) => {
  const _getPoints = useCallback(() => {
    const crop_info = croppieRef.current.get();
    return crop_info.points.map(p => parseInt(p));
  }, []);

  const _getCropMeta = useCallback((points, boundary) => {
    const { width, height } = boundary;
    const [topLeftX, topLeftY, bottomRightX, bottomRightY] = points;
    const box_width = bottomRightX - topLeftX;
    const box_height = bottomRightY - topLeftY;
    const db_box_width = (box_width * 100) / width;
    const db_box_height = (box_height * 100) / height;
    const db_offset_top = (topLeftY * 100) / box_height;
    const db_offset_left = (topLeftX * 100) / box_width;

    return {
      box_width,
      box_height,
      db_box_width,
      db_box_height,
      db_offset_top,
      db_offset_left
    };
  }, []);

  const handleCrop = useCallback(() => {
    if (!croppieRef.current) return;
    const points = _getPoints();
    const [topLeftX, topLeftY, bottomRightX, bottomRightY] = points;
    const { boundary } = croppieOpts;
    const meta = _getCropMeta(points, boundary);

    const crop = {
      topLeftX,
      topLeftY,
      bottomRightX,
      bottomRightY,
      ...boundary,
      ...meta
    };

    return crop;
  }, [_getCropMeta, _getPoints]);

  return handleCrop;
};

export default useCrop;
