import React, { useMemo } from 'react';
import styled from 'styled-components';

import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import { styleDimensions } from 'helpers/style';
import Button from 'components/reusable/button';
import Link from 'components/reusable/link';

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${styleDimensions.mobileHeaderHeight};
  min-height: ${styleDimensions.mobileHeaderHeight};
  background-color: ${({ bgColor }) => bgColor};
  box-shadow: ${({ shadow }) => shadow};
`;

const SearchWrapper = styled.div`
  position: absolute;
  right: 18px;
  top: 50%;
  transform: translateY(-50%);
`;

const BackWrapper = styled.div`
  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
`;

const StyledButton = styled(Button)`
  padding: 0;
  min-width: 24px;
  min-height: 24px;
`;

const MobileHeader = ({
  leftIcon,
  rightIcon,
  onLeftIconClick,
  leftIconLink,
  onRightIconClick,
  children = 'BROWSE TALENT',
  bgColor = colors.light,
  shadow = '0px 2px 8px rgba(31, 30, 30, 0.08)'
}) => {
  const leftIconComponent = useMemo(() => {
    if (leftIcon && leftIconLink) return <Link to={leftIconLink}>{leftIcon}</Link>;
    else if (leftIcon)
      return (
        <StyledButton type="transparent" onClick={onLeftIconClick}>
          {leftIcon}
        </StyledButton>
      );
  }, [leftIcon, leftIconLink]);

  const middleContent = useMemo(() => {
    if (typeof children === 'string')
      return (
        <Text height="1.2em" size={16} weight={500}>
          {children}
        </Text>
      );

    return children;
  }, [children]);

  const rightIconComponent = useMemo(() => {
    if (rightIcon)
      return (
        <StyledButton type="transparent" onClick={onRightIconClick}>
          {rightIcon}
        </StyledButton>
      );
  }, [rightIcon]);

  return (
    <Wrapper bgColor={bgColor} shadow={shadow}>
      <BackWrapper>{leftIconComponent}</BackWrapper>
      {middleContent}
      <SearchWrapper>{rightIconComponent}</SearchWrapper>
    </Wrapper>
  );
};

export default MobileHeader;
