import { useCallback } from 'react';

import { fileTypesEnum } from 'components/modals/v2/chat_shared_modal/file_types_enum';

const useGetSharedTabType = () => {
  // used by multiple files
  return useCallback(activeTab => {
    switch (activeTab) {
      case fileTypesEnum.photos:
        return 'image';
      case fileTypesEnum.videos:
        return 'video';
      case fileTypesEnum.files:
        return 'file';
      case fileTypesEnum.links:
        return 'link';
      default:
        return null;
    }
  }, []);
};

export default useGetSharedTabType;
