import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import { isMobileWidth } from 'helpers/style';

export const cellWidth = isMobileWidth ? '32px' : '44px';

const color = ({ isSelected, isDisabled }) => {
  if (isDisabled) return colors.gray[200];
  if (isSelected) return colors.light;
  return colors.gray[700];
};

const Day = styled.div`
  font-family: Gotham Pro;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  width: ${({ $cellSize }) => $cellSize};
  height: ${({ $cellSize }) => $cellSize};
  color: ${color};
  line-height: ${({ $cellSize }) => $cellSize};
  font-size: ${isMobileWidth ? '14px' : '16px'};
  background-color: ${({ isSelected }) => isSelected && colors.primary[500]};
  border-radius: ${({ $cellSize }) => `calc(${$cellSize} / 2)`};
`;

const CalendarDay = ({
  cellSize = cellWidth,
  day,
  isDisabled,
  isSelected,
  onClick
}) => {
  const handleClick = isDisabled ? null : onClick;
  const dayProps = { isDisabled, isSelected, onClick: handleClick };
  return (
    <Day $cellSize={cellSize} {...dayProps}>
      {day}
    </Day>
  );
};

export default CalendarDay;
