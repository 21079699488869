import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 34px;
  border-top: ${({ border }) => border && `1px solid ${colors.light}`};
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 16px;
    padding-top: 18px;
  }
`;

export const AdaptiveItem = styled.div`
  grid-area: ${({ row, column }) => row && column && `${row}/${column}`};
  @media (max-width: 768px) {
    display: ${({ mobile }) => mobile && mobile};
  }
`;

export const AdaptiveText = styled(Text)`
  @media (max-width: 1024px) {
    font-size: 14px;
  }
  @media (max-width: 900px) {
    font-size: 12px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
`;

export const SocialLink = styled.a`
  @media (max-width: 500px) {
    svg {
      width: 15px;
      height: 15px;
    }
  }
`;
