import React from 'react';

import Application_helper from 'helpers/application_helper';
import HowItWorksModalMobile from './how_it_works_modal_mobile';
import HowItWorksModalDesktop from './how_it_works_modal_desktop';

const list = [
  {
    label: 'Post a Job',
    description:
      'Tell us the specs for your job, including rate, date, and other details.'
  },
  {
    label: 'Review Applicants',
    description:
      'Message talent, call a casting, and share your favorites with your team.'
  },
  {
    label: 'Hire Talent',
    description:
      "Hire and pay talent directly over our secure platform. It's that easy!"
  }
];

const HowItWorksModal = ({ onClose }) => {
  const componentProps = { onClose, list };
  return Application_helper.isMobile ? (
    <HowItWorksModalMobile {...componentProps} />
  ) : (
    <HowItWorksModalDesktop {...componentProps} />
  );
};

export default HowItWorksModal;
