import React from 'react';

import { colors } from 'helpers/colors';

function SvgDollarCircledOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none">
      <path
        d="M8 15.5C12.1422 15.5 15.5 12.1422 15.5 8C15.5 3.85775 12.1422 0.5 8 0.5C3.85775 0.5 0.5 3.85775 0.5 8C0.5 9.2 0.782 10.334 1.28225 11.3397C1.41575 11.6067 1.46 11.912 1.38275 12.2007L0.916667 14.25C0.865513 14.4419 0.812677 14.9793 0.916667 15.0833C1.02065 15.1873 1.55812 15.1345 1.75 15.0833L3.79925 14.6172C4.08905 14.5443 4.39552 14.5798 4.661 14.717C5.69819 15.2335 6.84133 15.5015 8 15.5Z"
        stroke={color}
      />
      <path
        d="M8.04167 11.1248C9.07761 11.1248 9.91667 10.4255 9.91667 9.56297C9.91667 8.70047 9.07761 8.00015 8.04167 8.00015C7.00573 8.00015 6.16667 7.30078 6.16667 6.43734C6.16667 5.57484 7.00573 4.87547 8.04167 4.87547M8.04167 11.1248C7.00573 11.1248 6.16667 10.4255 6.16667 9.56297M8.04167 11.1248V12.1668M8.04167 4.87547V3.8335M8.04167 4.87547C9.07761 4.87547 9.91667 5.57484 9.91667 6.43734"
        stroke={color}
        strokeLinecap="round"
      />
    </svg>
  );
}

export default SvgDollarCircledOutline;
