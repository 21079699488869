class SCLocalStorage {
  constructor() {
    this.lsKeys = {
      currentUser: 'log_in_data',
      authToken: 'sc-auth-token',
      unreadNotifications: 'sc-u-notifs',
      isSendJobCreateMetrics: 'is_send_job_create_metrics',
      lastSessionReportDate: 'last_session_report_date',
      referralCode: 'referral_code',
      offerId: 'swipecast_offer_id',
      newAbGuestPostingUser: 'new_ab_guest_posting_user',
      jobsListRoleSwitcher: 'jobs_list_role_switcher',
      specialBookingOnboardingRead: 'special-booking-onboarding-read'
    };
  }

  // Fetch a record from Local Storage and optionally return it's parsed JSON
  fetch = (key, jsonParse = true) => {
    const record = localStorage.getItem(key);
    if (!record) return '';

    try {
      if (jsonParse) return JSON.parse(record);
    } catch {
      return record;
    }

    return record;
  };

  set = (key, value, jsonParse = false) => {
    let v = value;
    if (jsonParse) {
      v = JSON.stringify(value);
    }

    localStorage.setItem(key, v);
  };

  remove = key => {
    localStorage.removeItem(key);
  };

  fetchNewGuestPostingUserAB = () =>
    this.fetch(this.lsKeys.newAbGuestPostingUser, false);
  fetchCurrentUser = () => this.fetch(this.lsKeys.currentUser);
  fetchReferralCode = () => this.fetch(this.lsKeys.referralCode);
  fetchOfferID = () => this.fetch(this.lsKeys.offerId);
  fetchOfferOnboardingViewed = () =>
    this.fetch(this.lsKeys.specialBookingOnboardingRead);
  fetchAuthToken = () => this.fetch(this.lsKeys.authToken, false);
  // fetchUnreadNotifications = () => this.fetch(this.lsKeys.unreadNotifications);

  setNewGuestPostingUserAB = () =>
    this.set(this.lsKeys.newAbGuestPostingUser, true);
  setCurrentUser = value => this.set(this.lsKeys.currentUser, value);
  setAuthToken = value => this.set(this.lsKeys.authToken, value);
  setReferralCode = v => this.set(this.lsKeys.referralCode, v);
  setOfferID = v => this.set(this.lsKeys.offerId, v);
  setOfferOnboardingViewed = v =>
    this.set(this.lsKeys.specialBookingOnboardingRead, v);
  // setUnreadNotifications = value =>
  //   this.set(this.lsKeys.unreadNotifications, value);

  // project metrics
  fetchProjectCreateMetricsSend = () => {
    return this.fetch(this.lsKeys.isSendJobCreateMetrics, true);
  };
  getProjectCreateMetricsSend = step => {
    let metricIndex = step;
    if (typeof step === 'number') {
      metricIndex = metricIndex.toString();
    }
    return this.fetchProjectCreateMetricsSend()[metricIndex];
  };

  removeOfferID = () => this.remove(this.lsKeys.offerId);
  removeReferralCode = () => this.remove(this.lsKeys.referralCode);
  removeProjectCreateMetricsSend = () =>
    this.remove(this.lsKeys.isSendJobCreateMetrics); // called on new job creation init (first step mount)
  setProjectCreateMetricsSend = step => {
    // call on each step submition
    const prevMetrics = this.fetchProjectCreateMetricsSend();
    const newMetrics = { ...prevMetrics, [step]: true };
    this.set(this.lsKeys.isSendJobCreateMetrics, newMetrics, true);
  };
}

export const LocalStorage = new SCLocalStorage();
export default SCLocalStorage;
