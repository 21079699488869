import React from 'react';

import ChatSharedMedia from 'components/modals/v2/chat_shared_modal/components/chat_shared_media';

const ChatSharedModal = ({ modalProps = {}, onClose }) => {
  const { conversationId } = modalProps;

  return <ChatSharedMedia onClose={onClose} conversationId={conversationId} />;
};

export default ChatSharedModal;
