import React from 'react';

function SvgAppleEmailSolid({ size = 30 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5186 0.666504C13.1853 0.666504 14.0186 0.666504 14.926 0.944282C15.9075 1.29613 16.6853 2.07391 17.0371 3.05539C17.3334 3.9628 17.3334 4.81465 17.3334 6.48132V11.5184C17.3334 13.185 17.3334 14.0184 17.0556 14.9258C16.7038 15.9072 15.926 16.685 14.9445 17.0369C14.0371 17.3332 13.1853 17.3332 11.5186 17.3332H6.48156C4.8149 17.3332 3.98156 17.3332 3.07416 17.0554C2.09267 16.685 1.3149 15.9258 0.963044 14.9258C0.666748 14.0369 0.666748 13.2035 0.666748 11.5184V6.48132C0.666748 4.81465 0.666748 3.98132 0.944526 3.07391C1.3149 2.09243 2.09267 1.31465 3.07416 0.9628C3.96304 0.666504 4.8149 0.666504 6.48156 0.666504H11.5186Z"
        fill="url(#paint0_linear_18447_14280)"
      />
      <path
        d="M14.352 12.6109C14.3335 12.6109 14.2964 12.6294 14.2779 12.6294H3.72236C3.70384 12.6294 3.6668 12.6294 3.64828 12.6109L6.94458 9.31456L7.64828 10.0368C8.40754 10.8146 9.61125 10.8146 10.3705 10.0368L11.0742 9.31456L14.352 12.6109ZM14.5557 12.259V5.72197C14.5557 5.68493 14.5557 5.6479 14.5372 5.62938C14.5187 5.66642 11.2594 9.03679 11.2594 9.03679L14.5557 12.3331C14.5557 12.296 14.5557 12.2775 14.5557 12.259ZM3.44458 12.2775V5.75901C3.44458 5.72197 3.44458 5.68493 3.4631 5.66642C3.48162 5.70345 6.74088 9.07382 6.74088 9.07382L3.4631 12.3516C3.44458 12.3331 3.44458 12.296 3.44458 12.2775ZM14.3705 5.40716L10.1668 9.70345C9.51865 10.3516 8.48162 10.3516 7.85199 9.70345L3.64828 5.40716C3.62977 5.38864 14.3705 5.40716 14.3705 5.40716Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18447_14280"
          x1="8.99971"
          y1="0.671689"
          x2="8.99971"
          y2="17.3328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1E51EE" />
          <stop offset="1" stopColor="#19E6FF" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppleEmailSolid;
