import React, { useCallback, useContext, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import HeaderButton from '../header_button/header_button_old';
import { routes } from 'helpers/router_helper';
import { ModalContext, RootContext } from 'contexts/contexts';
import { useLocation } from 'react-router-dom';
import sc_ga4, { actions } from 'classes/sc_ga4';
import { sections } from 'components/views/logged_out/landing_page/helpers/sections';
import Link from 'components/reusable/link';
import Settings from 'helpers/settings';
import { modalEnum } from 'components/modals/v2/modal';
import ApplicationHelper from 'helpers/application_helper';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  -webkit-appearance: none;
  position: fixed;
  z-index: 9; // 8 rounded images overlap
  top: 39px;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 24px 16px 24px 16px;
  background-color: ${colors.light};
  box-shadow: 0px 2px 3px rgba(31, 30, 30, 0.08);
  transform: translateY(-100%);
  transition: all 0.5s;
  ${({ active }) => active && `transform: translate(0, 0);`}
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  flex: 1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
  > :nth-child(4) {
    border-bottom: 1px solid ${colors.gray[100]};
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
`;

const AppLink = styled(Link)`
  margin-top: auto;
  width: 100%;
`;

const StyledImage = styled.img`
  margin-top: auto;
  border-radius: 8px;
  width: 100%;
`;

const getColor = active => (active ? colors.primary[500] : colors.gray[600]);

const HeaderHamburger = ({ close, active }) => {
  const location = useLocation();
  const modalContext = useContext(ModalContext);

  const onHireTalent = useCallback(() => {
    sc_ga4.sendMetric(actions.guestPostingFunnel.init, {
      page: 'Landing Page 2023 WA',
      guest_posting_flow: true,
      label: 'Post a Job',
      section: sections.header,
      is_booking: false
    });
    close();
  }, [close]);

  const onProPress = useCallback(() => {
    sc_ga4.sendMetric(actions.clickButton, {
      label: 'Swipecast PRO',
      page: 'Landing Page 2023 WA',
      section: sections.header
    });
    close();
  }, [close]);

  const onSignUp = useCallback(() => {
    sc_ga4.sendMetric(actions.userCreateFunnel.init, {
      signup_divided_steps_flow: false,
      page: 'Landing Page 2023 WA',
      label: 'Sign Up',
      section: sections.header
    });
    modalContext.onOpenModal(modalEnum.signUp);
    close();
  }, [close]);

  const onLogin = useCallback(() => {
    close();
    modalContext.onOpenModal(modalEnum.signIn);
  }, [close]);

  const buttons = [
    {
      label: 'Log In',
      icon: () => svgIcons.headerLogin(getColor(false)),
      action: onLogin
    },
    {
      label: 'How it works',
      icon: () =>
        svgIcons.headerFaqStroke(
          getColor(location.pathname === routes.faq.redirectUrl())
        ),
      path: routes.faq.redirectUrl(),
      action: close
    },
    {
      label: 'Contact Us',
      icon: () =>
        svgIcons.headerContactStroke(
          getColor(location.pathname === routes.contact.redirectUrl())
        ),
      path: routes.contact.redirectUrl(),
      action: close
    },
    {
      label: 'Swipecast PRO',
      icon: () =>
        svgIcons.moreArrow(
          getColor(
            location.pathname === routes.logged_out.swipecastPro.redirectUrl()
          )
        ),
      path: routes.logged_out.swipecastPro.redirectUrl(),
      action: onProPress
    },
    // {
    //   label: 'Investors',
    //   href: 'mailto:investors@swipecast.com',
    //   icon: ''
    // },
    // {
    //   label: 'Privacy Policy',
    //   href: routes.about.policy.redirectUrl(),
    //   icon: ''
    // },
    // {
    //   label: 'Terms of Service',
    //   href: routes.about.terms.redirectUrl(),
    //   icon: ''
    // },

    {
      label: 'Sign Up',
      icon: () => svgIcons.headerSignUp(getColor(false)),
      action: onSignUp
    },
    {
      label: 'Post a Job',
      icon: () =>
        svgIcons.headerJobStroke(
          getColor(location.pathname === routes.guest.redirectUrl())
        ),
      path: routes.guest.redirectUrl(),
      action: onHireTalent
    }
  ];

  if (!ApplicationHelper.isMobile) return null;

  return (
    <Container active={active}>
      <InnerContainer>
        {buttons.map(b => (
          <div key={b.label}>
            <HeaderButton {...b} hamburger="true" useCssColors={false} />
          </div>
        ))}

        <AppLink href={Settings.appURL}>
          <StyledImage src="/images/screens/view-app.jpeg" alt="view-app" />
        </AppLink>
      </InnerContainer>
    </Container>
  );
};

export default HeaderHamburger;
