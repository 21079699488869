import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';
import Input from 'components/reusable/input';
import { validateEmail } from 'helpers/validation_helper';
import ApplicationHelper from 'helpers/application_helper';

const Wrapper = styled(Flex)`
  width: 520px;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
    width: 100%;
  }
`;

const InvoiceModal = ({ onClose, modalProps }) => {
  const [email, setEmail] = useState('');
  const text = `
    A digital invoice and payment instructions will be sent to
    your choice of email address. After the payment information
    is processed and ${modalProps.applicantFirstName} accepts the booking request, your
    project will be confirmed.
  `;

  const handleSubmit = useCallback(() => {
    modalProps.confirmPayment(
      email,
      modalProps.applicantUserId,
      {},
      modalProps.closeFromApplicants
    );
    onClose();
  }, [onClose, modalProps, email]);

  const isSubmitDisabled = !validateEmail(email) || modalProps.isLoading;

  return (
    <Wrapper dir="column" wrap="nowrap">
      <Text size={ApplicationHelper.isMobile ? 18 : 24} weight={500}>
        Invoice
      </Text>
      <Indent bottom={40} />

      <Text size={ApplicationHelper.isMobile ? 14 : 16} textAlign="center">
        {text}
      </Text>
      <Indent bottom={24} />

      <Flex dir="column" width="100%" wrap="nowrap">
        <Input
          name="email"
          placeholder="name@example.com"
          value={email}
          onChange={setEmail}
        />
        <Indent bottom={40} />

        <Button
          type="primary"
          height={40}
          width="100%"
          onClick={handleSubmit}
          isDisabled={isSubmitDisabled}
        >
          Confirm Booking
        </Button>
        <Indent bottom={16} />

        <Button
          height={40}
          width="100%"
          color={colors.gray[600]}
          borderColor="transparent"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Flex>
    </Wrapper>
  );
};

export default InvoiceModal;
