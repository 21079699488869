export const cities = [
  {
    name: 'Los Angeles'
  },
  {
    name: 'London'
  },
  {
    name: 'Madrid'
  },
  {
    name: 'Copenhagen'
  },
  {
    name: 'Amsterdam'
  },
  {
    name: 'Mumbai'
  },
  {
    name: 'New York'
  },
  {
    name: 'Paris'
  },
  {
    name: 'Hamburg'
  },
  {
    name: 'Toronto'
  },
  {
    name: 'Milan'
  },
  {
    name: 'Sydney'
  },
  {
    name: 'Miami'
  },
  {
    name: 'Berlin'
  },
  {
    name: 'Stockholm'
  },
  {
    name: 'Montreal'
  },
  {
    name: 'Bristol'
  },
  {
    name: 'Rome'
  }
];
