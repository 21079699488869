import moment from 'moment-es6';

export const isToday = date => {
  const today = new Date();
  return (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  );
};

export const isDateValid = date => {
  try {
    const isDateValid = !isNaN(Date.parse(date));
    if (isDateValid) return moment(date).format('YYYY-MM-DD');
    return null;
  } catch {
    return null;
  }
};

const DateHelpers = {
  /**
   * 24hrs x 60mins x 60sec x 1000 ms
   */
  dayInMilliseconds: 86400000,

  getAge: (year, month, date) => moment().diff(moment(`${month}-${date}-${year}`), 'years'),

  hoursMinutesToLabel: (hour_amount, minute_amount) => {
    let amPM, minute_string;
    if (hour_amount === 0) {
      hour_amount = 12;
      amPM = 'AM';
    } else if (hour_amount < 12) {
      amPM = 'AM';
    } else if (hour_amount === 12) {
      amPM = 'PM';
    } else {
      hour_amount -= 12;
      amPM = 'PM';
    }
    if (minute_amount > 9) {
      minute_string = minute_amount;
    } else {
      minute_string = `0${minute_amount.toString()}`;
    }
    return `${hour_amount}:${minute_string}${amPM}`;
  },

  hoursMinutesToLabelV2: (hour_amount, minute_amount) => {
    let amPM, minute_string;
    if (hour_amount === 0) {
      hour_amount = 12;
      amPM = 'AM';
    } else if (hour_amount < 12) {
      amPM = 'AM';
    } else if (hour_amount === 12) {
      amPM = 'PM';
    } else {
      hour_amount -= 12;
      amPM = 'PM';
    }

    minute_string = minute_amount > 9 ? String(minute_amount) : `0${Math.min(59, minute_amount)}`;

    return `${hour_amount}:${minute_string} ${amPM}`;
  },

  fullMonthNames: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ],

  shortMonthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  shortDaysOfWeek: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],

  get monthsMap() {
    return new Map(DateHelpers.fullMonthNames.map((element, index) => [index + 1, element]));
  },

  /**
   * Only allow years after current_year - 80 and before current_year - 18
   * So, only legal aged persons are allowed
   */
  get allowedYears() {
    const currentYear = moment().get('year');
    const minYear = currentYear - 80;
    const maxYear = currentYear - 18;
    return [...Array(maxYear).keys()].filter(value => value > minYear).reverse();
  },

  monthlyDaysArray(month = null) {
    const numberOfDays = month ? DateHelpers.daysOfTheMonth(month) : 31;
    return [...Array(numberOfDays).keys()].map(index => index + 1);
  },

  daysOfTheMonth(month = null) {
    if (month === '2') return 29;
    return moment(month, 'MM').daysInMonth();
  },

  differenceBetweenTimeStampsInS: (time_stamp_1, time_stamp_2) => {
    const date1 = new Date(time_stamp_1);
    const date2 = new Date(time_stamp_2);
    return (date1.getTime() - date2.getTime()) / 1000;
  },

  // includeTime: true,  utc: true  - format October 18, 2023 at 10:30 AM
  // includeTime: false, utc: false - format October 18, 2023
  timeStampToLabelFull: (chosenDate, includeTime = false, utc = false) => {
    const format = `MMMM DD, YYYY${includeTime ? ' [at] hh:mm A' : ''}`;

    return DateHelpers.stringToMoment(chosenDate, utc)
      .utcOffset(new Date().getTimezoneOffset() * -1)
      .format(format);
  },

  // array of iso dates
  // format 11 Mar 2022
  // format 11-12 Mar 2022
  // format 11 Mar - 11 May 2022
  // format 11 Mar 2022 - 11 May 2023
  timeStampLabelV2: dateStrings => {
    const isoDates = dateStrings.map(d => d.replace(/\s/, 'T'));
    const dates = Array.isArray(isoDates) ? isoDates : [isoDates];
    if (dates.length === 0 || !isoDates) return;
    const date = new Date(dates[0]);

    if (dates.length === 1) {
      return `${date.getDate()} ${DateHelpers.shortMonthNames[date.getMonth()]} ${date.getFullYear()}`;
    }
    const dateTimes = dates.map(d => new Date(d));
    const minDate = new Date(Math.min(...dateTimes));
    const maxDate = new Date(Math.max(...dateTimes));
    const isSameMonth = minDate.getMonth() === maxDate.getMonth();
    const isSameYear = minDate.getFullYear() === maxDate.getFullYear();
    if (isSameMonth && isSameYear)
      return `${minDate.getDate()}-${maxDate.getDate()} ${
        DateHelpers.shortMonthNames[date.getMonth()]
      } ${date.getFullYear()}`;
    if (isSameYear)
      return `${minDate.getDate()} ${DateHelpers.shortMonthNames[minDate.getMonth()]} - ${maxDate.getDate()} ${
        DateHelpers.shortMonthNames[maxDate.getMonth()]
      } ${maxDate.getFullYear()}`;
    return `${minDate.getDate()} ${
      DateHelpers.shortMonthNames[minDate.getMonth()]
    } ${minDate.getFullYear()} - ${maxDate.getDate()} ${
      DateHelpers.shortMonthNames[maxDate.getMonth()]
    } ${maxDate.getFullYear()}`;
  },

  // format
  // Mar 11 6:37PM (include_time: true),
  // Mar 21 (include_time: false),
  // Mar 21 (TODAY, include_time: false, skip_today: true, show_year: false)
  // Mar 21, 2017 (TODAY, include_time: false, skip_today: true, show_year: true)
  // 6:37PM (TODAY, include_time: false, skip_today: false)
  timeStampToLabel: (chosen_date, include_time = false, skip_today = false, skip_date = false, show_year = false) => {
    chosen_date = new Date(chosen_date.toString());
    const today = new Date();
    if (
      (chosen_date.getDate() === today.getDate() &&
        chosen_date.getMonth() === today.getMonth() &&
        chosen_date.getFullYear() === today.getFullYear() &&
        !skip_today) ||
      skip_date
    ) {
      return DateHelpers.hoursMinutesToLabel(chosen_date.getHours(), chosen_date.getMinutes());
    }
    if (include_time) {
      return `${
        DateHelpers.shortMonthNames[chosen_date.getMonth()]
      } ${chosen_date.getDate()} ${DateHelpers.hoursMinutesToLabel(chosen_date.getHours(), chosen_date.getMinutes())}`;
    }
    return `${DateHelpers.shortMonthNames[chosen_date.getMonth()]} ${chosen_date.getDate()}${
      show_year ? `, ${chosen_date.getFullYear()}` : ''
    }`;
  },

  /**
   * Returns a date in the format: YYYY-MM-DD HH:MM:SS
   * The chosenDate param is a day code
   */
  formatTimeAndDate: (chosenDate, chosenTime) => {
    const timestamp = DateHelpers.getTimeStampFromDayCode(chosenDate);
    // Creates a moment.js object from the timestamp, then format it to MM/DD/YYYY
    // and concat it with the selected time
    const dateFromTimeStamp = `${moment.unix(timestamp).format('MM/DD/YYYY')} ${chosenTime}`;
    return moment(dateFromTimeStamp).format('YYYY-MM-DD HH:mm:ss');
  },

  // ShortUnix like 19111
  getMomentFromShortUnix: unix => {
    const timestamp = DateHelpers.getTimeStampFromDayCode(unix);
    const dateFromTimeStamp = moment.unix(timestamp);
    return moment(dateFromTimeStamp);
  },

  getDateTimestamp(chosenDate, chosenTime) {
    return moment(this.formatTimeAndDate(chosenDate, chosenTime)).format('x');
  },

  // Mon Jun 17 2024 10:43:02 GMT+0300 (Eastern European Summer Time)
  parseDate(date) {
    return DateHelpers.stringToMoment(date).toDate();
  },

  stringToMoment(string, utc) {
    const format = 'YYYY-MM-DD HH:mm:ss';
    if (utc) {
      return moment.utc(string, format);
    }
    return moment(string, format);
  },

  // format 4:37 PM (UTC)
  getTimeFromDate(date, trailingZeroes = false) {
    const hourFormat = trailingZeroes ? 'hh' : 'h';
    return DateHelpers.stringToMoment(date).format(`${hourFormat}:mm A`);
  },

  getTimeFromDateV2(dateTimeStr) {
    const regex = /\d{2}:\d{2}/;
    const matches = dateTimeStr.match(regex);

    if (matches && matches.length > 0) {
      return matches[0];
    }

    return null;
  },

  updateTimesInDatesArray(datesArray, newTime) {
    return datesArray.map(dateStr => {
      const datePart = dateStr.split(' ')[0];
      return `${datePart} ${newTime}:00`;
    });
  },

  unixToDate(unixDate) {
    return new Date(unixDate * 1000);
  },

  getFromNowUsingTimestamp(timestamp) {
    return moment(timestamp).fromNow();
  },

  /**
   * This takes the code returned by CreateJobCalendarMonth, which comes
   * from this formula: Math.floor(myDate / 8.64e7)
   * Unfortunately, this is not clean code, as it's overcomplicating something
   * that can be otherwise very simple.
   *
   * As I'm not refactoring that component for now, I'm creating this function
   * to remind us how bad that is.
   *
   * @todo: Fix this logic. That would require refactoring CreateJobCalendarMonth
   * @param {number} dayCode: The numeric representation of a date in the
   *  form DATE / 8.64e7
   */
  // format 174787200
  getTimeStampFromDayCode(dayCode) {
    // For a reason I don't know, it always returns one day less, so, I'm
    // forced to add one day here.
    return (parseInt(dayCode, 10) + 1) * (DateHelpers.dayInMilliseconds / 1000);
  },
  // format 19065
  getDayCodeFromDate(date) {
    /**
     * This formula is un-descripherable. I'm pasting it here for legacy reasons
     * but it's gonna go away once we refactor CreateJobCalendarMonth
     */
    return (
      Math.floor(
        (DateHelpers.dayInMilliseconds + DateHelpers.parseDate(date).getTime()) / DateHelpers.dayInMilliseconds
      ) - 1
    );
  }
};

export default DateHelpers;
