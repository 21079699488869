export const sections = {
  slideShow: 'Slide show',
  steps: 'Steps',
  categories: 'Categories',
  whySwipecast: 'Why Swipecast?',
  swipecastPro: 'Swipecast Pro',
  faq: 'FAQ',
  banner: 'Banner',
  downloadApp: 'Download app',
  footer: 'Footer',
  header: 'Header',
  introMessage: 'Message',
  join: 'Join Swipecast'
};
