import { useCallback } from 'react';

const MILLISECONDS_IN_DAY = 8.64e7; // 1000 * 60 * 60 * 24;
export const dateToNumeric = date => {
  const numericDate0AM = Math.floor(date / MILLISECONDS_IN_DAY);
  return numericDate0AM;
};

const useValuesManipulator = ({
  month,
  year,
  changeMonth,
  onChange,
  value
}) => {
  const incrementMonth = useCallback(() => {
    if (month !== 11) return changeMonth({ month: month + 1, year });
    changeMonth({ month: 0, year: year + 1 });
  }, [month, year, changeMonth]);

  const decrementMonth = useCallback(() => {
    if (month !== 0) return changeMonth({ month: month - 1, year });
    changeMonth({ month: 11, year: year - 1 });
  }, [month, year, changeMonth]);

  const onClick = useCallback(
    day => {
      const date = new Date(year, month, day);
      const dateNumeric = dateToNumeric(date);
      if (!value.includes(dateNumeric))
        return onChange([...value, dateNumeric]);
      const withDeselectedDay = value.filter(v => v !== dateNumeric);
      return onChange(withDeselectedDay);
    },
    [year, month, onChange, value]
  );

  return {
    incrementMonth,
    decrementMonth,
    onClick
  };
};

export default useValuesManipulator;
