import React from 'react';
import { Redirect } from 'react-router-dom';
import AuthHelper from 'helpers/auth_helper';
import GeneralRoute from './general_route.js';

/**
 * The user must be logged out to access this route or they will be redirected
 */
const OuterRoute = props => {
  if (AuthHelper.logged_in) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: props.location }
        }}
      />
    );
  }

  return <GeneralRoute {...props} />;
};

export default OuterRoute;
