import { useCallback } from 'react';
import Croppie from 'croppie';

import { previewId } from 'components/reusable/input/components/ImageInput/image_input_v2/image_input_v2';

const croppieOpts = {
  enableExif: true,
  viewport: {
    width: 260,
    height: 260
  },
  boundary: {
    width: 320,
    height: 320
  }
};

const useInitCroppie = croppieRef => {
  const initCroppie = useCallback(() => {
    if (!croppieRef.current) {
      const previewNode = document.getElementById(previewId);
      if (!previewNode) return null;
      croppieRef.current = new Croppie(previewNode, croppieOpts);
    }
    return croppieRef.current;
  }, []);

  const purgeCroppie = useCallback(() => {
    if (croppieRef.current) {
      croppieRef.current.destroy();
      croppieRef.current = null;
    }
  }, []);

  return { initCroppie, purgeCroppie, croppieOpts };
};

export default useInitCroppie;
