import React, { lazy, Suspense } from 'react';
import queryString from 'query-string';

import { withModalContext } from 'contexts/contexts';

const AddBoardModal = lazy(() => import('components/modals/v1/add_board_modal'));
const InviteFriendPopup = lazy(() => import('components/modals/v1/invite_friend_popup'));
const DirectBookingModal = lazy(() =>
  import('components/views/logged_in/jobs/create_job/create_job_v2/direct_booking/direct_booking_modal')
);
const SharePortfolioModal = lazy(() =>
  import('components/views/logged_in/menu/my_profile/share/share_portfolio_modal')
);
const InviteToJobPopup = lazy(() => import('components/modals/v1/invite_to_job_popup'));
const AddCardModalView = lazy(() => import('components/views/logged_in/menu/payment_settings/add_card'));
const ConnectRequestSenderView = lazy(() => import('components/modals/v1/connect_request_sender_view'));
const ConnectRequestRecipientView = lazy(() => import('components/modals/v1/connect_request_recipient_view'));

/**
 * @deprecated use instead components/modals/modal
 **/
const ModalsView = ({ modalContextProps, rootProps }) => {
  const {
    show_connect_request_recipient,
    connect_request_recipient_swipecast_user_id,
    show_connect_request_sender,
    connect_request_sender_swipecast_user_id,
    on_connect_request_sender_success,
    show_add_card_modal,
    add_card_modal_callback,
    showBookingModal,
    booking_user_id,
    showShareModal,
    user_id,
    show_invite_project,
    invite_project_user,
    show_invite_friend,
    show_add_board_modal,
    add_board_swipecast_user_id,
    onCloseConnectRequestParticipiant,
    onCloseConnectRequestSender,
    onCloseAddCard,
    onCloseBooking,
    onCloseShare,
    onCloseInviteToJob,
    onCloseAddBoard,
    onCloseInviteFriend
  } = modalContextProps;
  const q = queryString.parse(window.location.search);
  const utm = {
    utm_medium: q.utm_medium,
    utm_source: q.utm_source,
    utm_term: q.utm_term,
    utm_campaign: q.utm_campaign,
    utm_content: q.utm_content
  };

  return (
    <Suspense fallback={() => null}>
      {show_connect_request_recipient === true && (
        <ConnectRequestRecipientView
          swipecast_user_id={connect_request_recipient_swipecast_user_id}
          hidePopup={onCloseConnectRequestParticipiant}
        />
      )}

      {show_connect_request_sender === true && (
        <ConnectRequestSenderView
          swipecast_user_id={connect_request_sender_swipecast_user_id}
          hidePopup={onCloseConnectRequestSender}
          onSuccess={on_connect_request_sender_success}
        />
      )}

      {show_add_card_modal === true && <AddCardModalView callback={add_card_modal_callback} cancel={onCloseAddCard} />}

      {showBookingModal === true && <DirectBookingModal hidePopup={onCloseBooking} swipecastUserId={booking_user_id} />}

      {showShareModal === true && <SharePortfolioModal hidePopup={onCloseShare} swipecastUserId={user_id} />}

      {show_invite_project === true && <InviteToJobPopup user={invite_project_user} hidePopup={onCloseInviteToJob} />}

      {show_invite_friend === true && <InviteFriendPopup hidePopup={onCloseInviteFriend} />}

      {show_add_board_modal === true && (
        <AddBoardModal
          hideModal={onCloseAddBoard}
          swipecastUserId={add_board_swipecast_user_id}
          rootProps={rootProps}
        />
      )}
    </Suspense>
  );
};

/**
 * @deprecated use instead components/modals/modal
 */
export default withModalContext(ModalsView);
