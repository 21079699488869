import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';

export const validate = data => {
  const errors = {};
  const imgValue = data[signUpStepsData.image.field];
  const imgEmpty = !(imgValue && Object.values(imgValue).length);
  const imgPostponedValue = data[signUpStepsData.imagePostpone.field];
  const invalid = imgEmpty && !imgPostponedValue;

  if (invalid) {
    errors[signUpStepsData.image.field] = 'Please provide some image';
  }

  return errors;
};