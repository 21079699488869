const $ = window.jQuery;

const PaymentHelpers = {
  loadStripeAccounts(callback) {
    $.ajax({
      method: 'GET',
      url: '/stripe_account',
      dataType: 'JSON',
      data: {},
      success: data => {
        callback(data.stripe_accounts);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  updateStripeAccount(stripeAccountId, details) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'PUT',
        url: `/stripe_account/${stripeAccountId}`,
        dataType: 'JSON',
        contentType: 'application/json',
        data: JSON.stringify(details),
        success: resolve,
        error: reject
      });
    });
  },

  createStripeAccount(details) {
    return new Promise((resolve, reject) => {
      $.ajax({
        method: 'POST',
        url: '/stripe_account',
        dataType: 'JSON',
        contentType: 'application/json',
        data: JSON.stringify(details),
        success: resolve,
        error: reject
      });
    });
  },

  loadStripeCustomers(callback) {
    $.ajax({
      method: 'GET',
      url: '/stripe_customer',
      dataType: 'JSON',
      data: {},
      success: data => {
        callback([data.stripe_customer]);
      },
      error: error => {
        // DEALING WITH 'NO STRIPE CUSTOMER FOUND'
        if (error.status === 404) {
          callback([]);
        } else {
          console.log(error.status);
        }
      }
    });
  },

  uploadStripeDocument(formData, callback, onError) {
    $.ajax({
      type: 'POST',
      url: '/stripe_account/upload_image',
      dataType: 'json',
      processData: false,
      contentType: false,
      data: formData,
      success: data => {
        callback(data);
      },
      error: err => {
        onError(err);
      }
    });
  }
};

export default PaymentHelpers;
