import React from 'react';

import HelpCenterMetaTags from 'components/seo/help_center_meta_tags';
import HelpHeader from 'components/views/help/help_v3/help_root/components/help_header';
import Text from 'components/reusable/text';
import styled from 'styled-components';
import Indent from 'components/reusable/indent';
import Flex from 'components/reusable/flex';
import HelpContactUsItem from 'components/views/help/help_v3/help_contact_us/help_contact_us_item';

function HelpContactUsTemplate({ label, items = [] }) {
  return (
    <>
      <HelpCenterMetaTags />
      <HelpHeader />

      <ContentWrapper>
        <Text size={18} weight={500}>
          {label}
        </Text>
        <Indent top={24} />

        <Flex dir="column" gap={8}>
          {items.map((item, i) => (
            <HelpContactUsItem key={i} {...item} />
          ))}
        </Flex>
      </ContentWrapper>
    </>
  );
}

const ContentWrapper = styled.div`
  padding: 24px 16px;
`;

export default HelpContactUsTemplate;
