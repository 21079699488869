import React from 'react';

import HelpContactUsTemplate from 'components/views/help/help_v3/help_contact_us/help_contact_us_template';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import SvgPhoneOutline from 'components/reusable/svg/svg_phone_outline';
import Settings from 'helpers/settings'

function HelpCallUs() {
  const items = [
    {
      label: 'Phone number',
      linkLabel: Settings.support.phoneLabel,
      href: `tel:${Settings.support.phoneNumber}`,
      icon: <SvgPhoneOutline size={20} />
    },
    {
      label: 'WhatsApp',
      linkLabel: Settings.support.whatsappLabel,
      href: Settings.support.whatsappURL,
      icon: svgIcons.whatsApp({ color: colors.whatsapp, width: 20 })
    }
  ];

  return <HelpContactUsTemplate items={items} label="Call Us" />;
}

export default HelpCallUs;
