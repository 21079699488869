import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';

const Wrapper = styled.div`
  /* border: 1px solid ${colors.gray[300]};
  border-radius: 20px; */
  /* padding: 20px; */
  margin-top: -20px;
  padding: 35px 20px 20px 20px;
  background: #ffffff;
  position: relative;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
  border-radius: 0 0 20px 20px;
  border: none;
  font-weight: 400;
  font-size: 1rem;
  z-index: 2;
  > :not(:last-child) {
    margin-bottom: 20px;
  }
  
  ${({ absolutePredictions }) =>
    absolutePredictions &&
    css`
      position: absolute;
      bottom: 20px;
      transform: translateY(100%);
      width: 100%;
    `}
`;

const PredictionWrapper = styled.div`
  cursor: pointer;
  font-family: Gotham Pro;
`;

const Predictions = ({
  onClick,
  predictions,
  isShown,
  absolutePredictions,
  ...props
}) => {
  if (!isShown || !predictions.length) return null;

  const predictiosItems = predictions.map((prediction, index) => {
    const { state, city, country } = prediction;
    const onPredictionClick = () => onClick(prediction);

    return (
      <PredictionWrapper key={index} onClick={onPredictionClick}>
        {`${city}, ${(state ? state : country).toUpperCase()}`}
      </PredictionWrapper>
    );
  });

  return (
    <Wrapper absolutePredictions={absolutePredictions} {...props}>
      {predictiosItems}
    </Wrapper>
  );
};

export default Predictions;
