const RATES = {
  HOURLY: 'HOURLY',
  DAYRATE: 'DAYRATE',
  UNPAID: 'UNPAID'
};

const frequencyDesc = job => {
  const days = job.days || job.dates.length;
  const dayRateDesc = `${job.dates.length} day${days === 1 ? '' : 's'}`;
  const hourlyRateDesc = `${job.hours} hour${job.hours === 1 ? '' : 's'}`;
  const rateDesc =
    job.frequency === RATES.HOURLY && job.hours ? hourlyRateDesc : dayRateDesc;
  return rateDesc.toUpperCase();
};

export const RatesHelper = {
  frequencyDesc
};

export default RATES;
