import React, { useCallback } from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Button from 'components/reusable/button';

const Wrapper = styled(Flex)`
  height: 48px;
  border-radius: 30px;
  padding: 8px 8px 8px 16px;
  box-shadow: 0 0 4px 0 #1f1e1e7a;
`;

const CopyLink = ({ url, onCopy }) => {
  const handleCopy = useCallback(() => {
    onCopy();
    navigator.clipboard.writeText(url).then(() => {
      window.alert('Copied to clipboard');
    });
  }, [url, onCopy]);

  return (
    <Wrapper gap={8} ai="center" wrap="nowrap">
      <Text size={14} lines={1}>
        {url}
      </Text>
      <Button width="72px" type="primary" onClick={handleCopy}>
        Copy
      </Button>
    </Wrapper>
  );
};

export default CopyLink;
