import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { getDimention } from 'helpers/style';
import { colors } from 'helpers/colors';

const Wrapper = styled.div`
  position: relative;
  width: fit-content;
`;

const StyledButton = styled.button`
  outline: none;
  background: none;
  border: none;
  height: fit-content;
  width: fit-content;
  min-height: 20px;
  min-width: 30px;
  cursor: pointer;
`;

const OverlayWrapper = styled.div`
  position: absolute;
  background-color: ${colors.light};
  box-shadow: 0px 2px 8px 0px rgba(31, 30, 30, 0.08);
  border-radius: 12px;
  padding: 8px 12px;
  z-index: 1;

  ${({ overlayDirection }) => {
    if (overlayDirection === 'bottom-left') {
      return css`
        bottom: -16px;
        transform: ${({ buttonWidth }) => `translateX(calc(-100% + ${getDimention(buttonWidth)})) translateY(100%)`};
      `;
    }
    if (overlayDirection === 'top-left') {
      return css`
        top: -16px;
        transform: ${({ buttonWidth }) => `translateX(calc(-100% + ${getDimention(buttonWidth)})) translateY(-100%)`};
      `;
    }
    if (overlayDirection === 'top-right') {
      return css`
        top: -16px;
        transform: ${({ buttonWidth }) => `translateX(calc(0% + ${getDimention(buttonWidth)})) translateY(-100%)`};
      `;
    }
  }}

  >:not(:first-child) {
    ${({ borderColor }) => borderColor && `border-top: 1px solid ${borderColor};`}
  }
  @media (max-width: 768px) {
    padding: 8px 12px;
  }
`;

const Dropdown = ({ children, trigger, overlayDirection = 'bottom-left', borderColor = colors.gray[100] }) => {
  const [isOpen, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const overlayRef = useRef(null);

  const handleClick = useCallback(() => {
    setOpen(isOpen => !isOpen);
  }, []);

  const handleClickOutside = useCallback(
    e => {
      const clickOutside = overlayRef.current && !overlayRef.current.contains(e.target);
      if (isOpen && clickOutside) setOpen(false);
    },
    [isOpen]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  return (
    <Wrapper ref={overlayRef} className="dropdown-overlay">
      <StyledButton onClick={handleClick} ref={buttonRef}>
        {trigger}
      </StyledButton>

      {isOpen && (
        <OverlayWrapper
          buttonWidth={buttonRef.current.offsetWidth}
          overlayDirection={overlayDirection}
          borderColor={borderColor}
        >
          {children}
        </OverlayWrapper>
      )}
    </Wrapper>
  );
};

export default Dropdown;
