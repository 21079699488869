import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';

const StyledError = styled(Text)`
  color: ${colors.primary[500]};
  height: 16px;
`;

const Error = ({ children, ...props }) => {
  return (
    <StyledError size={11} weight={400} {...props}>
      {children}
    </StyledError>
  );
};

export default Error;
