import { all, call, put, takeLatest } from 'redux-saga/effects';

import ProjectHelpers from 'helpers/project_helpers';
import { ProjectActionTypes } from 'helpers/constants/action_types/project';
import { ProjectActions } from 'store/actions/project';

function* watchFetch() {
  yield takeLatest(ProjectActionTypes.FETCH, function*({ payload }) {
    try {
      const response = yield call(ProjectHelpers.loadProjectV2, payload);

      yield put(ProjectActions.FETCH_SUCCESS(response));
    } catch (error) {
      yield put(ProjectActions.FETCH_FAILURE());
    }
  });
}

function* watchLoadBySecretAliasId() {
  yield takeLatest(ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID, function*({
    payload
  }) {
    try {
      const response = yield call(
        ProjectHelpers.loadProjectBySecretAliasIdV2,
        payload
      );

      yield put(ProjectActions.FETCH_BY_SECRET_ALIAS_ID_SUCCESS(response));
    } catch (error) {
      yield put(ProjectActions.FETCH_BY_SECRET_ALIAS_ID_FAILURE());
    }
  });
}

function* watchUpdate() {
  yield takeLatest(ProjectActionTypes.UPDATE, function*({
    id,
    payload,
    onSuccessCallback,
    onErrorCallback = () => {}
  }) {
    try {
      const response = yield call(ProjectHelpers.updateProjectV2, id, payload);

      yield put(ProjectActions.UPDATE_SUCCESS(response));
      onSuccessCallback();
    } catch (error) {
      yield put(ProjectActions.UPDATE_FAILURE());
      onErrorCallback();
    }
  });
}

export default function* projectSaga() {
  yield all([watchFetch(), watchUpdate(), watchLoadBySecretAliasId()]);
}
