import { useEffect, useState } from 'react';

import Constants from 'helpers/constants';

const useCountryCodes = (code, phone) => {
  const [defaultCountry, setDefaultCountry] = useState('');
  const [defaultPhone, setDefaultPhone] = useState('');
  const [isInited, setInited] = useState(false);
  const countries = Constants.countriesPN;

  const selectedCountry = value => {
    return (
      countries.find(country => '+' + value === country.prefix) ||
      countries.find(country => ('+' + value).includes(country.prefix)) ||
      ''
    );
  };

  const isNumberValid = number => number.length >= 5;

  useEffect(() => {
    if (!phone && !code && isInited === false) return;
    if (!code) {
      const country = selectedCountry(phone);
      setDefaultCountry(country || '');

      const formattedPhone = country
        ? phone.replaceAll('+', '').slice(country.prefix.length - 1)
        : phone;

      if (!isInited && country && !isNumberValid(formattedPhone)) {
        setDefaultCountry('');
        setDefaultPhone(phone);
      } else {
        setDefaultPhone(formattedPhone);
      }
      setInited(true);
    } else {
      const country = selectedCountry(code);
      setDefaultCountry(country);
      setDefaultPhone(phone);
      setInited(true);
    }
  }, [code, phone]);

  return { countries, defaultPhone, defaultCountry };
};

export default useCountryCodes;
