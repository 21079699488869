import AuthHelper from 'helpers/auth_helper.js';

const $ = window.jQuery;

const ConnectHelpers = {
  loadConnection: (swipecast_user_id, callback) => {
    $.ajax({
      method: 'GET',
      url:
        '/user/' +
        AuthHelper.logged_in_profileID +
        '/connections/with/' +
        swipecast_user_id,
      data: {
        // requestor_swipecast_user_id: requestor_swipecast_user_id
      },
      dataType: 'JSON',
      success: requestData => {
        callback(requestData.connection);
      },
      error: error => {
        if (error.status === 404) {
          // IS THIS HOW WE WANT TO DO IT?
          callback(null);
        } else {
          console.log(error.status);
        }
      }
    });
  },

  proposeConnection: (requestee_swipecast_user_id, message, callback) => {
    $.ajax({
      method: 'POST',
      url: '/user/' + AuthHelper.logged_in_profileID + '/connections/create',
      data: {
        requestee_swipecast_user_id: requestee_swipecast_user_id,
        message: message,
        reason: 'other'
      },
      dataType: 'JSON',
      success: requestData => {
        callback(requestData);
      },
      error: error => {
        console.log(error);
      }
    });
  },

  acceptConnection: (requestee_swipecast_user_id, connection_id, callback) => {
    $.ajax({
      method: 'POST',
      url:
        '/user/' +
        AuthHelper.logged_in_profileID +
        '/connections/' +
        connection_id +
        '/accept',
      data: {
        // requestor_swipecast_user_id: requestor_swipecast_user_id
      },
      dataType: 'JSON',
      success: requestData => {
        callback(requestData);
      }
    });
  },

  declineConnection: (requestor_swipecast_user_id, connection_id, callback) => {
    $.ajax({
      method: 'POST',
      url:
        '/user/' +
        AuthHelper.logged_in_profileID +
        '/connections/' +
        connection_id +
        '/decline',
      data: {},
      dataType: 'JSON',
      success: requestData => {
        callback(requestData);
      }
    });
  }
};

export default ConnectHelpers;
