import { requestV1, requestV2 } from './request';

/** @deprecated use requestV2 */
const getV1 = url => requestV1(url);

export const loadBoards = userId =>
  getV1(`/package?swipecast_user_id=${userId}`);
export const loadIndividualJob = jodId => getV1(`/job/${jodId}`);
export const loadReviews = userId => getV1(`/user/${userId}/reviews_received`);
export const loadNotifications = (offset = 0, limit = 15) =>
  getV1(`/notification?offset=${offset}&limit=${limit}`);
export const loadUnreadNotificationsCount = () =>
  getV1('/unviewed_notifications_count');
export const loadFeedEntries = () => getV1('/feed_entry');
export const loadFeedEntry = id => getV1(`/feed_entry/${id}`);
export const loadRecentActivity = () => getV1('/activity');

export const postV2 = (url, rest = {}) =>
  requestV2(url, { ...rest, method: 'POST' });
export const patchV2 = (url, rest = {}) =>
  requestV2(url, { ...rest, method: 'PATCH' });
export const getV2 = (url, rest = {}) =>
  requestV2(url, { ...rest, method: 'GET' });

export const loadAlbums = userId => getV2(`/user/${userId}/albums`);
export const loadConversations = async ({
  starting_after = 'initial',
  limit
} = {}) => {
  const params = {
    limit,
    ...(starting_after !== 'initial' && { starting_after })
  };
  return await getV2('/conversation', { params });
};

export const loadJobConnection = async swipecast_user_id => {
  return await getV2(`/v2/job_connections/${swipecast_user_id}`);
};

export const loadConversationWithMessages = async (
  conversation_id,
  { starting_after, limit = 50 }
) => {
  const params = {
    limit,
    ...(starting_after !== 'initial' && { starting_after })
  };
  return await getV2(`/conversation/${conversation_id}`, { params });
};

export const search = async ({
  text,
  offset,
  limit,
  type = 'user',
  filters,
  count,
  order_by
}) => {
  const body = {
    query: { search: text, offset, limit, type, filters, order_by, count }
  };
  return await postV2('/query', { body });
};
