import React, { useCallback } from 'react';

import sc_ga4, { actions } from 'classes/sc_ga4';

const useSendMetric = ({ page, label }) => {
  const sendMetric = useCallback(() => {
    sc_ga4.sendMetric(actions.clickButton, {
      page: page,
      section: 'Footer',
      label: label
    });
  }, [page, label]);

  return sendMetric;
};

export default useSendMetric;
