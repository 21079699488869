import React, { useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Constants from 'helpers/constants';
import useStripeCustomer from './hooks/use_stripe_customer';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import Input from 'components/reusable/input';
import Text from 'components/reusable/text';
import CustomInput from 'components/reusable/input/components/CustomInput';
import ApplicationHelper from 'helpers/application_helper';

const Wrapper = styled(Flex)`
  width: 520px;
  padding: 40px 40px 32px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
    width: 100%;
  }
`;

const AddCardModal = ({ onClose, modalProps }) => {
  const [cardDetails, setCardDetails] = useState({
    cardholderName: '',
    cardNumber: '',
    cardCVC: '',
    expDate: '',
    addressLine1: '',
    addressLine2: '',
    addressCity: '',
    addressState: '',
    addressPostal: '',
    addressCountry: 'US' //by default the dropdown menu will start off with 'US' selected
  });

  const handleChange = (name, value) => {
    setCardDetails(prev => ({ ...prev, [name]: value }));
  };

  const handleCardNameChange = e => {
    const rx_letters = /^[a-zA-Z\s]*$/;
    if (rx_letters.test(e)) handleChange('cardHolderName', e);
  };

  const handleCardNumberChange = e => {
    e = e.replaceAll(' ', '');
    if (e.length <= 16) {
      e = cc_format(e);
      handleChange('cardNumber', e);
    }
  };

  const handleCardDateChange = e => {
    const regex = /^([0-1][0-9]|2[0-1])\/?([0-9]{0,2})$/;

    let inputValue = e;

    inputValue = inputValue.replace(/\D/g, '');

    if (
      inputValue.length < 3 ||
      (regex.test(inputValue) && inputValue.length <= 5)
    ) {
      if (inputValue.length <= 2) {
        handleChange('expDate', inputValue);
      } else if (
        (inputValue.length === 3 || inputValue.length === 4) &&
        inputValue.indexOf('/') === -1
      ) {
        handleChange(
          'expDate',
          inputValue.slice(0, 2) + '/' + inputValue.slice(2)
        );
      }
    }
  };

  const handleCvcChange = e => {
    if (e.length > 3) return;
    e = e.replaceAll(' ', '').replace(/[^0-9]/gi, '');
    handleChange('cardCVC', e);
  };

  const onPaymentParamChange = (key, value) => {
    switch (key) {
      case 'cardNumber':
        handleCardNumberChange(value);
        break;
      case 'expDate':
        handleCardDateChange(value);
        break;
      case 'cardHolderName':
        handleCardNameChange(value);
        break;
      case 'cardCVC':
        handleCvcChange(value);
        break;
      default:
        handleChange(key, value);
    }
  };

  const handleCountryChange = value => {
    handleChange('addressCountry', value);
  };

  const countries = Constants.countries.map(item => ({
    value: item.code,
    label: item.name
  }));

  const handleSubmit = useStripeCustomer({
    cardDetails,
    onSubmit: modalProps.onAddCard,
    applicantFirstName: modalProps.applicantFirstName,
    applicantUserId: modalProps.applicantUserId
  });

  return (
    <Wrapper dir="column" wrap="nowrap">
      <Text size={ApplicationHelper.isMobile ? 18 : 24} weight={500}>
        Add Your Card
      </Text>
      <Indent bottom={24} />

      <Flex dir="column" width="100%" wrap="nowrap" ai="flex-start">
        <Text size={14} textAlign="left" width="100%">
          Card Details
        </Text>
        <Indent bottom={8} />

        <CustomInput
          type="input"
          placeholder="Cardholder Name"
          textAlign="center"
          width="100%"
          name="cardholderName"
          value={cardDetails.cardholderName}
          onChange={e => onPaymentParamChange('cardholderName', e.target.value)}
        />
        <Indent bottom={16} />

        <CustomInput
          type="input"
          placeholder="Card Number"
          textAlign="center"
          width="100%"
          name="cardNumber"
          value={cardDetails.cardNumber}
          onChange={e => onPaymentParamChange('cardNumber', e.target.value)}
        />
        <Indent bottom={16} />

        <Flex jc="space-between" gap={24} wrap="nowrap" width="100%">
          <CustomInput
            type="input"
            placeholder="mm/yy"
            textAlign="center"
            name="expDate"
            value={cardDetails.expDate}
            onChange={e => onPaymentParamChange('expDate', e.target.value)}
          />
          <CustomInput
            type="input"
            placeholder="CVC"
            textAlign="center"
            name="cardCVC"
            value={cardDetails.cardCVC}
            onChange={e => onPaymentParamChange('cardCVC', e.target.value)}
          />
        </Flex>
      </Flex>
      <Indent bottom={24} />

      <Flex dir="column" width="100%" wrap="nowrap" ai="flex-start">
        <Text size={14} textAlign="left" width="100%">
          Billing Address
        </Text>
        <Indent bottom={8} />

        <CustomInput
          type="input"
          placeholder="Street"
          textAlign="center"
          width="100%"
          name="addressLine1"
          value={cardDetails.addressLine1}
          onChange={e => onPaymentParamChange('addressLine1', e.target.value)}
        />
        <Indent bottom={16} />

        <CustomInput
          type="input"
          placeholder="Appt, floor, unit (optional)"
          textAlign="center"
          width="100%"
          name="addressLine2"
          value={cardDetails.addressLine2}
          onChange={e => onPaymentParamChange('addressLine2', e.target.value)}
        />
        <Indent bottom={16} />

        <Flex jc="space-between" gap={24} wrap="nowrap" width="100%">
          <CustomInput
            type="input"
            placeholder="City / town"
            textAlign="center"
            name="addressCity"
            value={cardDetails.addressCity}
            onChange={e => onPaymentParamChange('addressCity', e.target.value)}
          />
          <CustomInput
            type="input"
            placeholder="State / province"
            textAlign="center"
            name="addressState"
            value={cardDetails.addressState}
            onChange={e => onPaymentParamChange('addressState', e.target.value)}
          />
        </Flex>
        <Indent bottom={16} />

        <Flex jc="space-between" gap={24} wrap="nowrap" width="100%">
          <CustomInput
            type="input"
            placeholder="Postal / Zip Code"
            textAlign="center"
            name="addressPostal"
            value={cardDetails.addressPostal}
            onChange={e =>
              onPaymentParamChange('addressPostal', e.target.value)
            }
          />
          <Input
            type="select"
            value={cardDetails.addressCountry}
            onChange={handleCountryChange}
            options={countries}
          />
        </Flex>
      </Flex>
      <Indent bottom={40} />

      <Button
        type="primary"
        height={40}
        width="100%"
        onClick={handleSubmit}
        isDisabled={modalProps.isLoading}
      >
        Update Settings
      </Button>
      <Indent bottom={16} />

      <Button
        height={40}
        width="100%"
        color={colors.gray[600]}
        borderColor="transparent"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Wrapper>
  );
};

export default AddCardModal;

function cc_format(value) {
  var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
  var matches = v.match(/\d{4,16}/g);
  var match = (matches && matches[0]) || '';
  var parts = [];

  for (let i = 0, len = match.length; i < len; i += 4) {
    parts.push(match.substring(i, i + 4));
  }

  if (parts.length) {
    return parts.join(' ');
  } else {
    return v;
  }
}
