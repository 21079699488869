import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';

const usePreventScrollBouncing = ({
  isReverse,
  children,
  getParent,
  prevScrollOffsetRef
}) => {
  const scrollBottomRef = useRef(null);
  const scrollHeightREf = useRef(null);

  const heightChanged = overflow =>
    scrollHeightREf.current !== overflow.scrollHeight;

  const getScrollBottom = useCallback(overflow => {
    const { clientHeight, scrollHeight, scrollTop } = overflow;
    const scrollBottom = scrollHeight - clientHeight - scrollTop;
    return scrollBottom;
  }, []);

  const getScrollTopByBottom = useCallback((overflow, scrollBottom) => {
    const scrollTop =
      overflow.scrollHeight - scrollBottom - overflow.clientHeight;
    return scrollTop;
  }, []);

  const fixReversedScrollPosition = () => {
    if (!isReverse) return false;
    const overflow = getParent();

    if (heightChanged(overflow)) {
      scrollHeightREf.current = overflow.scrollHeight;
      const top = getScrollTopByBottom(overflow, scrollBottomRef.current);
      overflow.scrollTo({ top });
    }
  };

  const onScroll = e => {
    const overflow = e.target;

    if (isReverse) {
      scrollBottomRef.current = getScrollBottom(overflow);
      // console.log(overflow.scrollTop, scrollBottomRef.current, overflow.scrollHeight);
    }
  };

  useEffect(() => {
    const overflow = getParent();
    overflow.addEventListener('scroll', onScroll);
    return () => overflow.removeEventListener('scroll', onScroll);
  }, []);

  useLayoutEffect(() => {
    if (isReverse) fixReversedScrollPosition();
  }, [children]);
};

export default usePreventScrollBouncing;
