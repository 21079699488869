import React from 'react';

import { colors } from 'helpers/colors';

function SvgPlaySolidV2({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 13V3C0 2.33696 0.263392 1.70107 0.732233 1.23223C1.20107 0.763392 1.83696 0.5 2.5 0.5H17.5C18.163 0.5 18.7989 0.763392 19.2678 1.23223C19.7366 1.70107 20 2.33696 20 3V13C20 13.663 19.7366 14.2989 19.2678 14.7678C18.7989 15.2366 18.163 15.5 17.5 15.5H2.5C1.83696 15.5 1.20107 15.2366 0.732233 14.7678C0.263392 14.2989 0 13.663 0 13ZM8.4875 4.36625C8.39404 4.29971 8.28408 4.26018 8.16965 4.25198C8.05522 4.24379 7.94074 4.26724 7.83876 4.31978C7.73678 4.37233 7.65122 4.45192 7.59147 4.54986C7.53172 4.64779 7.50007 4.76028 7.5 4.875V11.125C7.50007 11.2397 7.53172 11.3522 7.59147 11.4501C7.65122 11.5481 7.73678 11.6277 7.83876 11.6802C7.94074 11.7328 8.05522 11.7562 8.16965 11.748C8.28408 11.7398 8.39404 11.7003 8.4875 11.6337L12.8625 8.50875C12.9435 8.45093 13.0096 8.37461 13.0551 8.28612C13.1007 8.19762 13.1245 8.09953 13.1245 8C13.1245 7.90047 13.1007 7.80237 13.0551 7.71388C13.0096 7.62539 12.9435 7.54906 12.8625 7.49125L8.4875 4.36625Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgPlaySolidV2;
