import React, { useCallback, useContext, useState } from 'react';
import queryString from 'query-string';

import { ModalContext, RootContext } from 'contexts/contexts';
import SignInByEmail from './components/sign_in_by_email';
import useSubmit from './hooks/use_submit';
import { useHistory } from 'react-router-dom';
import { modalEnum } from 'components/modals/v2/modal';

const SignInByEmailModal = ({ onClose }) => {
  const rootContext = useContext(RootContext);
  const modalContext = useContext(ModalContext);
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPW] = useState('');
  const [error, setError] = useState('');
  const q = queryString.parse(window.location.search);
  const signUpSection = q.sign_up !== 'false';

  const onForgotPassword = useCallback(() => {
    onClose();
    modalContext.onOpenModal(modalEnum.resetPassword);
  }, [onClose, history.push]);

  const handleSubmit = useSubmit({
    setError,
    email,
    password,
    rootContext,
    onClose
  });

  const componentProps = {
    onClose,
    handleSubmit,
    error,
    password,
    setPW,
    email,
    isLoading: rootContext.isLoading,
    onForgotPassword,
    signUpSection,
    setEmail
  };

  return <SignInByEmail {...componentProps} />;
};

export default SignInByEmailModal;
