import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import svgIcons from 'helpers/svgIcons';
import Indent from 'components/reusable/indent';
import { previewId } from 'components/reusable/input/components/ImageInput/image_input_v2/image_input_v2';
import InputComponent from './input_component';

export const DragWrapper = styled.div`
  width: 100%;
  background-color: ${colors.gray[50]};
  border: 1px solid ${colors.gray[100]};
  min-height: 440px;
  max-height: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  border-radius: 20px;

  // styled components didnt work for 15.2
  #croppie_preview {
    margin: 0 !important;
  }
`;

const StyledButton = styled.div`
  width: fit-content;
  position: relative;
  cursor: pointer;
  > svg {
    z-index: 1;
    cursor: pointer;
  }
`;

const ImageInputPreview = ({ handleChange, accept, resetValue, onSelect }) => {
  return (
    <DragWrapper>
      <Flex>
        <StyledButton>
          <InputComponent onChange={handleChange} accept={accept} />
          {svgIcons.rotate(colors.primary[500])}
        </StyledButton>

        <Indent right={20} />

        <StyledButton type="semantic-wrapper" onClick={resetValue}>
          {svgIcons.trash(colors.primary[500])}
        </StyledButton>

        <Indent right={20} />

        <StyledButton type="semantic-wrapper" onClick={onSelect}>
          {svgIcons.tick(colors.primary[500])}
        </StyledButton>
      </Flex>

      <Indent top={12} />
      <div id={previewId} />
    </DragWrapper>
  );
};

export default ImageInputPreview;
