import React from 'react';
import styled from 'styled-components';

import Image from 'components/reusable/image';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import Indent from 'components/reusable/indent';
import { isMobileWidth } from 'helpers/style';

const wrapperMargin = isMobileWidth ? '16px' : '24px';
const Wrapper = styled.div`
  margin: 16px;
  padding: 16px 88px;
  width: calc(100% - ${wrapperMargin} * 2);
  height: calc(100% - ${wrapperMargin} * 2);
  background-color: ${colors.gray[50]};
  border: 1px solid ${colors.gray[100]};
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 0px;
  }

  ${props => props.css}
`;

const StyledImage = styled(Image)`
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
`;

const ImagePreview = ({ image, previewRef }) => {
  return (
    <Wrapper
      ref={previewRef}
      css={
        previewRef.current &&
        isMobileWidth &&
        `height: ${previewRef.current.offsetWidth}`
      }
    >
      {image ? (
        <StyledImage src={image} alt="preview" />
      ) : (
        <>
          {svgIcons.galery()}
          <Indent top={16} />
          {!isMobileWidth && (
            <Text size={18} weight={400} color={colors.gray[400]}>
              Drag your photo here
            </Text>
          )}
          <Text size={isMobileWidth ? 10 : 12} color={colors.gray[400]}>
            jpg, png, gif
          </Text>
        </>
      )}
    </Wrapper>
  );
};

export default ImagePreview;
