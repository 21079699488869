import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Link from 'components/reusable/link';
import { colors } from 'helpers/colors';
import ApplicationHelper from 'helpers/application_helper';
import Indent from 'components/reusable/indent';

function SharePortfolioOptions({ options }) {
  return (
    <Wrapper dir="column" width="100%" wrap="nowrap">
      {options.map(o => (
        <OptionWrapper href={o.url} target="_blank" onClick={o.onClick} key={o.label}>
          <Text size={[{ maxWidth: 1400, size: 14 }, { size: 16 }]} lineHeight="20px">
            {o.label}
          </Text>

          {o.icon ? <o.icon size={ApplicationHelper.isMobile ? 24 : 20} /> : null}
        </OptionWrapper>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled(Flex)`
  @media (min-width: 769px) {
    > :last-child {
      border-bottom: none;
    }
  }
`;

const OptionWrapper = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.gray[100]};
  padding: 16px 0;
  gap: 16px;
  @media (max-width: 768px) {
    padding: 12px 0;
  }
`;

export default SharePortfolioOptions;
