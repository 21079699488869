import React, { useCallback, useMemo } from 'react';

import { Track, Progress, Wrapper, StyledMax, StyledMin } from './styled';
import Handle from './components/handle';
import Breakpoint from './components/breakpoint';
import BestRange from './components/best_range';
import useWidth from 'components/complex/slider/hooks/use_width';
import usePositionOnSlider from 'components/complex/slider/hooks/use_position_on_slider';

/**
 * @desc very custom slider used for job posting rates
 * @param min
 * @param max
 * @param minLabel
 * @param maxLabel
 * @param onChange
 * @param value
 * @param breakpoints
 * @param bestRange
 * @param className
 * @returns {JSX.Element}
 * @constructor
 */
const Slider = ({ min = 0, max = 100, minLabel, maxLabel, onChange, value, breakpoints, bestRange, className }) => {
  const { startsAt, endsAt } = bestRange;
  const { setNodeWidth, width } = useWidth();
  const getPositionOnSlider = usePositionOnSlider(width);

  const progressValue = useMemo(() => {
    if (value > max) return max;
    if (value < min) return min;
    return value;
  }, [value]);

  const progress = useMemo(() => {
    return ((progressValue - min) / (max - min)) * 100;
  }, [progressValue, max, min]);

  const handleChange = useCallback(e => onChange(parseInt(e.target.value)), [onChange]);

  const sliderBestRange = {
    startsAt: getPositionOnSlider(startsAt),
    endsAt: getPositionOnSlider(endsAt)
  };

  const buildBreakpoints = () => {
    return (
      breakpoints &&
      breakpoints.map(bp => {
        const left = getPositionOnSlider(bp.value);
        const isReached = progress >= bp.value;
        return <Breakpoint label={bp.label} left={left} key={bp.value} isReached={isReached} />;
      })
    );
  };

  return (
    <Wrapper ref={setNodeWidth} className={className}>
      <Progress value={progressValue} min={min} max={max} onChange={handleChange} type="range" />
      <Track progress={getPositionOnSlider(progress)} />
      <Handle progress={progress} />

      {buildBreakpoints()}

      {minLabel && (
        <StyledMin size={10} weight={400}>
          {minLabel}
        </StyledMin>
      )}
      {maxLabel && (
        <StyledMax size={10} weight={400}>
          {maxLabel}
        </StyledMax>
      )}

      <BestRange {...sliderBestRange} />
    </Wrapper>
  );
};

export default Slider;
