import React from 'react';

import Input from 'components/reusable/input/components/TextInput';
import AmPm from './components/am_pm';
import { IconWrapper } from 'components/reusable/input/components/DatePicker/date_picker';
import SvgClockOutline from 'components/reusable/svg/svg_clock_outline';
import { colors } from 'helpers/colors';

export const timeInputIcons = {
  amPm: 'am-pm-interface',
  clock: 'clock-icon'
};

const TimeInput = ({
  iconVersion = timeInputIcons.amPm,
  value,
  onChange,
  type,
  ...props
}) => {
  let TimeIcon = null;
  if (iconVersion === timeInputIcons.amPm)
    TimeIcon = <AmPm onChange={onChange} value={value} />;
  else if (iconVersion === timeInputIcons.clock)
    TimeIcon = (
      <IconWrapper>
        <SvgClockOutline color={colors.light} size={20} />
      </IconWrapper>
    );

  return (
    <Input type={type} onChange={onChange} value={value} {...props}>
      {TimeIcon}
    </Input>
  );
};

export default TimeInput;
