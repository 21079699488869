import { useMemo } from 'react';

const versions = [64, 128, 256, 512, 1024];

const _getVersion = size => versions.find(v => v >= parseInt(size));

const _getTransformedSrc = (src, width) => {
  if (typeof src !== 'string') return null;
  return src.replace('/upload/', `/upload/c_scale,w_${width}/`);
};

const useImageURL = ({ secondTry, src, setError, width }) => {
  const imageURL = useMemo(() => {
    const version = _getVersion(width);
    if (secondTry || !version) return src;

    return _getTransformedSrc(src, version);
  }, [secondTry, src, setError, width]);

  return imageURL;
};

export default useImageURL;
