import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import { colors } from 'helpers/colors';
import { cities } from '../helpers/cities';

const Container = styled(Flex)`
  padding: 64px 0;
  width: 100%;
  @media (max-width: 768px) {
    padding: 40px 0;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-row-gap: 16px;
  grid-column-gap: 48px;
  grid-template-columns: repeat(6, auto);
  @media (min-width: 769px) and (max-width: 1250px) {
    grid-column-gap: 24px;
  }
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 40px;
    grid-template-columns: repeat(3, auto);
    grid-row-gap: 8px;
  }
  @media (max-width: 481px) {
    grid-template-columns: repeat(2, auto);
  }
`;

const AdaptiveText = styled(Text)`
  font-size: 24px;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const CityWrap = styled.div`
  cursor: pointer;
`;

const CitiesBlock = ({ isMobile }) => {
  return (
    <Container
      dir={isMobile ? 'column' : 'row'}
      jc="space-between"
      wrap="nowrap"
    >
      {isMobile ? (
        <AdaptiveText color={colors.light}>
          Thousands of models, photographers, stylists, video directors and more
          from around the world.
        </AdaptiveText>
      ) : (
        <Flex dir="column" ai="flex-start">
          <Indent bottom={16}>
            <AdaptiveText lineHeight="110%" color={colors.light}>
              Thousands of models, photographers,
            </AdaptiveText>
          </Indent>
          <Indent bottom={16}>
            <AdaptiveText lineHeight="110%" color={colors.light}>
              stylists, video directors and more
            </AdaptiveText>
          </Indent>
          <AdaptiveText lineHeight="110%" color={colors.light}>
            from around the world.
          </AdaptiveText>
        </Flex>
      )}

      <Grid>
        {cities.map((city, i) => (
          <CityWrap key={i}>
            <Text size={16} color={colors.light}>
              {city.name}
            </Text>
          </CityWrap>
        ))}
      </Grid>
    </Container>
  );
};

export default CitiesBlock;
