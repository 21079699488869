import { all, call, put, takeLatest } from 'redux-saga/effects';

import { JobActionTypes } from 'helpers/constants/action_types/job';
import { JobActions } from 'store/actions/job';
import ProjectHelpers from 'helpers/project_helpers';
import Settings from 'helpers/settings';

function* watchFetch() {
  yield takeLatest(JobActionTypes.FETCH, function*({ payload }) {
    try {
      const response = yield call(ProjectHelpers.loadJobV2, payload);

      yield put(JobActions.FETCH_SUCCESS(response));
    } catch (error) {
      yield put(JobActions.FETCH_FAILURE());
    }
  });
}

function* watchFetchBySecretAliasId() {
  yield takeLatest(JobActionTypes.FETCH_BY_SECRET_ALIAS_ID, function*({
    payload
  }) {
    try {
      const response = yield call(
        ProjectHelpers.loadJobBySecretAliasIdV2,
        payload
      );

      yield put(JobActions.FETCH_BY_SECRET_ALIAS_ID_SUCCESS(response));
    } catch (error) {
      yield put(JobActions.FETCH_BY_SECRET_ALIAS_ID_FAILURE());
    }
  });
}

function* watchUpdate() {
  yield takeLatest(JobActionTypes.UPDATE, function*({
    id,
    payload,
    onSuccessCallback,
    onErrorCallback = () => {}
  }) {
    try {
      const response = yield call(ProjectHelpers.updateJobV2, payload, id);

      yield put(JobActions.UPDATE_SUCCESS(response));
      onSuccessCallback();
    } catch (error) {
      yield put(JobActions.UPDATE_FAILURE());
      onErrorCallback();
    }
  });
}

function* watchToggleSelection() {
  yield takeLatest(JobActionTypes.TOGGLE_TALENT_SELECTION, function*({ id }) {
    try {
      const response = yield call(
        ProjectHelpers.toggleFinishTalentSelectionV2,
        id
      );

      yield put(JobActions.TOGGLE_TALENT_SELECTION_SUCCESS(response));
    } catch (error) {
      alert(error?.responseJSON?.message || Settings.unknownErrorMessage);
      yield put(JobActions.TOGGLE_TALENT_SELECTION_FAILURE());
    }
  });
}

function* watchCancel() {
  yield takeLatest(JobActionTypes.CANCEL, function*({ job, onErrorCallback }) {
    try {
      yield call(ProjectHelpers.cancelJobV2, job);

      yield put(JobActions.CANCEL_SUCCESS());
    } catch (error) {
      yield put(JobActions.CANCEL_FAILURE());
      onErrorCallback(
        error.statusText || 'Error',
        error.responseJSON.exception || 'Oops, something went wrong'
      );
    }
  });
}

function* watchUpdateApplication() {
  yield takeLatest(JobActionTypes.UPDATE_APPLICATION, function*({
    jobId,
    applicantId,
    status
  }) {
    try {
      yield call(
        ProjectHelpers.updateApplicationV2,
        jobId,
        applicantId,
        status
      );

      yield put(JobActions.UPDATE_APPLICATION_SUCCESS(applicantId, status));
    } catch (error) {
      yield put(JobActions.UPDATE_APPLICATION_FAILURE());
    }
  });
}

function* watchSelectApplicant() {
  yield takeLatest(JobActionTypes.SELECT, function*({
    jobId,
    selectedApplicantId,
    paymentInfo,
    onSuccessCallback
  }) {
    try {
      const response = yield call(
        ProjectHelpers.selectApplicantV2,
        jobId,
        selectedApplicantId,
        paymentInfo
      );

      yield put(JobActions.SELECT_SUCCESS(response));
      onSuccessCallback();
    } catch (error) {
      console.log(error);
      yield put(JobActions.SELECT_FAILURE());
    }
  });
}

function* watchDeSelectApplicant() {
  yield takeLatest(JobActionTypes.DESELECT, function*({ jobId, applicantId }) {
    try {
      yield call(ProjectHelpers.deSelectApplicantV2, jobId, applicantId);

      yield put(JobActions.DESELECT_SUCCESS(applicantId));
    } catch (error) {
      yield put(JobActions.DESELECT_FAILURE());
    }
  });
}

function* watchRetryPayment() {
  yield takeLatest(JobActionTypes.RETRY_PAYMENT, function*({
    jobId,
    sourceId,
    onSuccessCallback,
    onErrorCallback
  }) {
    try {
      const response = yield call(
        ProjectHelpers.retryPaymentV2,
        jobId,
        sourceId
      );

      yield put(JobActions.RETRY_PAYMENT_SUCCESS());
      onSuccessCallback(response.message);
    } catch (error) {
      yield put(JobActions.RETRY_PAYMENT_FAILURE());
      onErrorCallback(error.statusText, error.responseJSON.message);
    }
  });
}

export default function* jobSaga() {
  yield all([
    watchFetch(),
    watchFetchBySecretAliasId(),
    watchUpdate(),
    watchToggleSelection(),
    watchCancel(),
    watchUpdateApplication(),
    watchSelectApplicant(),
    watchDeSelectApplicant(),
    watchRetryPayment()
  ]);
}
