import { colors } from 'helpers/colors';
import React from 'react';
import styled from 'styled-components';
import ApplicationHelper from 'helpers/application_helper';

export const indent = ApplicationHelper.isMobile ? 12 : 16;
export const handleWidth = 40;

const HandleWrapper = styled.div`
  width: calc(100% - ${indent}px - ${handleWidth}px);
  height: 100%;
  position: absolute;
  top: 0;
  left: calc(${indent}px / 2);
  pointer-events: none;
`;

const HandleComponent = styled.div`
  width: ${handleWidth}px;
  background-color: white;
  height: 28px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ progress }) => `${progress}%`};
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.15);
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
  > :not(:first-child) {
    margin-left: 6px;
  }
  @media (max-width: 768px) {
    height: 24px;
  }
`;

const Handle = ({ progress }) => {
  return (
    <HandleWrapper className="fml">
      <HandleComponent progress={progress} className="fml-comp">
        {[1, 2].map(i => (
          <svg
            key={i}
            width="1"
            height="12"
            viewBox="0 0 1 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="1" height="12" rx="0.5" fill={colors.gray[300]} />
          </svg>
        ))}
      </HandleComponent>
    </HandleWrapper>
  );
};

export default Handle;
