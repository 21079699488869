import { useCallback } from 'react';

import { readFiles } from 'helpers/utils/file';

const useCroppieChange = ({ initCroppie, purgeCroppie }) => {
  const onCroppieChange = useCallback(async value => {
    if (!value || !value.length) return purgeCroppie();
    const croppieInstance = initCroppie();
    if (!croppieInstance) return;
    const base64Files = await readFiles(value);
    croppieInstance.bind({ url: base64Files[0] });
  }, []);

  return onCroppieChange;
};

export default useCroppieChange;
