import Settings from "helpers/settings";
import AuthHelper from "helpers/auth_helper";

const API_URL = Settings.SWIPECAST_BACKEND
const parseJson = async (response: any) => {
  try {
    const json = await response.json()
    return json
  } catch {
    return {}
  }
}

export default {
  headers: {},
  setHeader(key: string, value: string | number) {
    this.headers = { ...this.headers, [key]: value }
  },
  async request(method: string, endpoint: string, body) {
    const fullUrl = endpoint[0] === '/'
      ? `${API_URL}${endpoint}`
      : endpoint

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${AuthHelper.currentAuthToken()}`,
      credentials: 'include',
      ...this.headers,
    };

    const options = {
      method,
      ...body && { body: JSON.stringify(body) },
      headers
    }
    const response = await fetch(fullUrl, options);
    const json = await parseJson(response)
    if (response.ok) return json
    return Promise.reject(json)
  },

  async post(endpoint: string, body: any) {
    const result = await this.request('POST', endpoint, body)
    return result
  },
  async put(endpoint: string, body: any) {
    const result = await this.request('PUT', endpoint, body)
    return result
  },
  async patch(endpoint: string, body: any) {
    const result = await this.request('PATCH', endpoint, body)
    return result
  },
  async get(endpoint: string) {
    const result = await this.request('GET', endpoint)
    return result
  },
  async delete(endpoint: string) {
    const result = await this.request('DELETE', endpoint)
    return result
  },
}