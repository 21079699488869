import React from 'react';

import { colors } from 'helpers/colors';

function SvgClockOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M19.25 10C19.25 15.1088 15.1088 19.25 10 19.25C4.89121 19.25 0.75 15.1088 0.75 10C0.75 4.89121 4.89121 0.75 10 0.75C15.1088 0.75 19.25 4.89121 19.25 10Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 5V10.2751L14 14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgClockOutline;
