import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Link from 'components/reusable/link';
import Button from 'components/reusable/button';
import { useModalContext } from 'hooks/use_context';

const ChatParticipantActionsModal = ({ modalProps = {} }) => {
  const modalContext = useModalContext();
  const { contactActions, commonActions } = modalProps.otherParticipant;

  return (
    <ModalContainer>
      <Text size={18} weight={500}>
        {modalProps.otherParticipant.user.full_name.toUpperCase()}
      </Text>
      <Indent top={24} />

      {[...contactActions, ...commonActions].map(({ onClick, label, icon, Icon, capable, target, href, to }) => {
        if (!capable) return null;
        const handleClick = () => {
          modalContext.onCloseModal();
          if (onClick) onClick();
        };

        return (
          <StyledLink onClick={handleClick} href={href} target={target} to={to} key={label}>
            <Text size={14}>{label}</Text>
            {Icon && <Icon size={18} color={colors.gray[700]} />}
            {/*{icon}*/}
          </StyledLink>
        );
      })}

      <Indent top={20} />

      <Button
        onClick={modalContext.onCloseModal}
        width="100%"
        height={40}
        bgColor={colors.gray[100]}
        color={colors.gray[600]}
      >
        Cancel
      </Button>
    </ModalContainer>
  );
};

const ModalContainer = styled.div`
  position: absolute;
  background: ${colors.light};
  box-shadow: 0px 0px 80px rgba(31, 30, 30, 0.08);
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  padding: 24px 16px;
  bottom: 0;
`;

const buttonStyles = `
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
  border-bottom: 1px solid ${colors.gray[100]};
  margin-bottom: 10px;
  border-radius: 0;
`;

const StyledLink = styled(Link)`
  ${buttonStyles}
`;

const StyledButton = styled(Button)`
  ${buttonStyles}
`;

export default ChatParticipantActionsModal;
