import React from 'react';

import { colors } from 'helpers/colors';

function SvgHeartOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.21455 1.42457C3.32455 1.42457 1.36364 3.21004 1.36364 5.71159C1.36364 8.66236 3.47636 11.3719 5.78273 13.4233C7.07291 14.562 8.46265 15.5716 9.93364 16.4386L10 16.4776L10.0664 16.4396C10.3127 16.2943 10.6673 16.0787 11.0936 15.7995C11.9482 15.241 13.0845 14.4309 14.2173 13.4233C16.5236 11.3719 18.6364 8.66236 18.6364 5.71159C18.6364 3.21004 16.6755 1.42457 14.7855 1.42457C12.8709 1.42457 11.33 2.74373 10.6464 4.88439C10.6009 5.02587 10.514 5.14885 10.3981 5.23598C10.2821 5.32311 10.1429 5.36998 10 5.36998C9.85715 5.36998 9.71791 5.32311 9.60193 5.23598C9.48595 5.14885 9.3991 5.02587 9.35364 4.88439C8.67 2.74373 7.12818 1.42457 5.21455 1.42457ZM10 17.2877L10.3118 17.9211C10.2154 17.973 10.1085 18 10 18C9.89154 18 9.78464 17.973 9.68818 17.9211L10 17.2877ZM0 5.71064C0 2.42463 2.57182 0 5.21455 0C7.3 0 8.98273 1.16435 10 2.94887C11.0182 1.16435 12.7 0 14.7855 0C17.4273 0 20 2.42368 20 5.71159C20 9.33761 17.4536 12.4166 15.1009 14.5079C13.6298 15.806 12.0358 16.9439 10.3436 17.9041L10.3209 17.9164L10.3145 17.9192L10.3127 17.9202L10 17.2877L9.68818 17.9211L9.68546 17.9192L9.67909 17.9164L9.65636 17.9041C9.15581 17.6217 8.66405 17.3227 8.18182 17.0075C7.0317 16.2575 5.93464 15.4221 4.89909 14.5079C2.54545 12.4156 0 9.33666 0 5.71064Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgHeartOutline;
