import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import Input from 'components/reusable/input';
import Text from 'components/reusable/text';
import ApplicationHelper from 'helpers/application_helper';

const ModalContainer = styled.div`
  position: absolute;
  width: 640px;
  padding: 40px 56px;
  background: ${colors.light};
  box-shadow: 0px 0px 80px rgba(31, 30, 30, 0.08);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 769px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 768px) {
    position: relative;
    width: 100%;
    padding: 24px 16px;
  }
`;

const TalentTosModal = ({ modalProps, onClose }) => {
  const [state, setState] = useState({
    freeSheldule: false,
    canceling: false,
    protecting: false,
    promise: false
  });

  const content = useRef();

  const handleCheck = field => value =>
    setState(ps => ({ ...ps, [field]: value }));

  const handleSubmit = useCallback(() => {
    modalProps.onSubmit();
    onClose();
  }, [modalProps, onClose]);

  const isSubmitDisabled = !Object.values(state).every(v => v);

  return (
    <ModalContainer onClick={e => e.stopPropagation()} ref={content}>
      <Text
        size={ApplicationHelper.isMobile ? 18 : 24}
        weight={500}
        textTransform="uppercase"
      >
        Swipecast agreement
      </Text>
      <Indent bottom={24} />

      <Text size={14} color={colors.gray[400]} textAlign="center">
        Swipecast was created to bring models exciting job opportunities that
        pay within 2-3 days! Before we submit you, please confirm the following:
      </Text>
      <Indent bottom={40} />

      <Flex gap={8} width="100%" ai="flex-start" wrap="nowrap">
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('freeSheldule')}
            value={state.freeSheldule}
          />
        </Indent>
        <Text>
          Prior to accepting a job, I will always make sure I&apos;m booked out
          and that my schedule is free.
        </Text>
      </Flex>
      <Indent bottom={16} />

      <Flex gap={8} width="100%" ai="flex-start" wrap="nowrap">
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('canceling')}
            value={state.canceling}
          />
        </Indent>
        <Text>
          Once I confirm a job, I understand that it can&apos;t becanceled
          except for an emergency. Canceling a confirmed job may lead to my
          removal from the platform.
        </Text>
      </Flex>
      <Indent bottom={16} />

      <Flex gap={8} width="100%" ai="flex-start" wrap="nowrap">
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('protecting')}
            value={state.protecting}
          />
        </Indent>
        <Text>
          Swipecast is here to protect my images, rights and payments. I will
          therefore only accept jobs where the clients pay me over the platform.
        </Text>
      </Flex>
      <Indent bottom={16} />

      <Flex gap={8} width="100%" ai="flex-start" wrap="nowrap">
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('promise')}
            value={state.promise}
          />
        </Indent>
        <Text>
          Models with great reviews work more! I will always do my best to show
          up on time and be pleasant on set.
        </Text>
      </Flex>
      <Indent bottom={40} />

      <Flex gap={16}>
        <Button
          bgColor={colors.gray[100]}
          color={colors.gray[700]}
          width={160}
          height={40}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          type="primary"
          width={160}
          height={40}
          onClick={handleSubmit}
          isDisabled={isSubmitDisabled}
        >
          Submit
        </Button>
      </Flex>
    </ModalContainer>
  );
};

export default TalentTosModal;
