import { useMemo } from 'react';

const useBreakpoints = ({ breakpoints, rate }) => {
  const currentBreakpoint = useMemo(
    () => breakpoints.find(({ min }) => min <= rate),
    [breakpoints, rate]
  );

  const sliderBreakpoints = useMemo(
    () =>
      breakpoints
        .filter(bp => !bp.hideOnScale)
        .map(bp => ({
          label: `$${bp.min}`,
          value: bp.min
        })),
    [breakpoints]
  );

  return {
    sliderBreakpoints,
    currentBreakpoint
  };
};

export default useBreakpoints;
