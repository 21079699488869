import React from 'react';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import styled from 'styled-components';
import { styleDimensions } from 'helpers/style';

const Wrapper = styled(Flex)`
  width: 100%;
  height: ${styleDimensions.desktopHeaderHeight};
  max-height: ${styleDimensions.desktopHeaderHeight};
  min-height: ${styleDimensions.desktopHeaderHeight};

  @media (max-width: 768px) {
    display: none;
  }
`;

function GuestOnboardingHeaderDesktop({ sequence }) {
  const buildStep = (s, i) => (
    <Flex ai="flex-start" key={s.label}>
      <Text
        size={16}
        color={s.active ? colors.primary[500] : colors.primary[200]}
        weight={500}
      >
        0{i + 1}.&nbsp;
      </Text>
      <Text
        size={16}
        color={s.active ? colors.gray[700] : colors.gray[300]}
        weight={500}
      >
        {s.label}
      </Text>
    </Flex>
  );

  return (
    <Wrapper flex={1} jc="space-around">
      {sequence.map(buildStep)}
    </Wrapper>
  );
}

export default GuestOnboardingHeaderDesktop;
