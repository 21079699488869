import ApplicationHelper from 'helpers/application_helper';
import React from 'react';
import styled from 'styled-components';
import HamburgerMenu from './hamburger/hamburger_menu';

const MainContainer = styled.div`
  z-index: 9000;
  width: 350px;
  position: fixed;
  top: 80px;
  right: ${props => (props.isMobile ? '0px' : '30px')};
  height: ${props => (props.isMobile ? '100vh' : 'auto')};
  border-radius: ${props => (props.isMobile ? '0' : '30px')};
  background-color: white;
  border-left: 1px solid #ccc;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  webkit-animation: fade 0.15s;
  animation: fade 0.15s;
`;

const PopupBackdrop = styled.div`
  z-index: 300;
  position: fixed;
  top: 0;
  bottom: 0px;
  left: 0px;
  right: 0px;
  overflow-y: auto;
  overflow-x: hidden;
`;

class HamburgerMenuDropdown extends React.Component {
  render() {
    const isMobile = ApplicationHelper.isMobile;
    if (this.props.activated === false) {
      return <div />;
    }
    return (
      <div>
        <PopupBackdrop onClick={this.props.cancelHamburger} />
        <MainContainer isMobile={isMobile} className="hamburger_menu">
          <HamburgerMenu onClose={this.props.cancelHamburger} />
        </MainContainer>
      </div>
    );
  }
}

export default HamburgerMenuDropdown;
