import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileJpgOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H15M10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V10M10 1L15 6M15 6V10M7 16H8.5C8.89782 16 9.27936 15.842 9.56066 15.5607C9.84196 15.2794 10 14.8978 10 14.5C10 14.1022 9.84196 13.7206 9.56066 13.4393C9.27936 13.158 8.89782 13 8.5 13H7V19M16 13H15C14.4696 13 13.9609 13.2107 13.5858 13.5858C13.2107 13.9609 13 14.4696 13 15V17C13 17.5304 13.2107 18.0391 13.5858 18.4142C13.9609 18.7893 14.4696 19 15 19H16V16M1 13H4V17.5C4 17.8978 3.84196 18.2794 3.56066 18.5607C3.27936 18.842 2.89782 19 2.5 19C2.10218 19 1.72064 18.842 1.43934 18.5607C1.15804 18.2794 1 17.8978 1 17.5"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileJpgOutline;
