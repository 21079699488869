import styled from 'styled-components';

import { colors } from 'helpers/colors';

const Divider = styled.div`
  height: 0;
  border-bottom: 1px solid ${colors.gray[100]};
`;

export default Divider;
