import React, { useCallback, useRef } from 'react';

import svgIcons from 'helpers/svgIcons';
import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import styled from 'styled-components';
import Button from 'components/reusable/button';
import { buttonStyles } from 'components/modals/v2/auth/sign_up_modal/sign_up_modal_v3/styled';
import { GoogleLogin } from '@react-oauth/google';
import useErrorHandler from 'components/complex/google_sso/hooks/use_error_handler';

export const StyledButton = styled(Button)`
  ${buttonStyles};
  width: 100%;
  background-color: ${colors.google};
`;

const GoogleSso = ({
  onFailure,
  onSuccess,
  label,
  disabled = false,
  ...props
}) => {
  const btnContainerRef = useRef(null);
  const onError = useErrorHandler(onFailure);

  const handleSuccess = async oauth => {
    onSuccess({ google_token_id: oauth.credential });
  };

  // @react-oauth/google with hooks implementation does not provide token_id but access_token instead
  // our backend implementation as of now accepts only token_id
  const handleOauth = useCallback(() => {
    try {
      const btn =
        btnContainerRef.current?.querySelectorAll("[tabindex='0']")[0] ||
        btnContainerRef.current.firstChild.firstChild.children[1].children[1];

      if (!btn) return onFailure('Service is unavailable');
      return btn.click();
    } catch {
      onFailure('Service is unavailable');
    }
  }, []);

  return (
    <>
      <div style={{ display: 'none' }} ref={btnContainerRef}>
        <GoogleLogin onSuccess={handleSuccess} onError={onError} />
      </div>

      <StyledButton onClick={handleOauth} disabled={disabled} {...props}>
        {svgIcons.googleIcon()}
        <Indent right={8} />
        <Text
          color={colors.light}
          size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}
          weight={500}
        >
          {label}
        </Text>
      </StyledButton>
    </>
  );
};

export default GoogleSso;
