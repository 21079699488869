import userV2Actions from 'store/resources/usersV2/actions';

// represents API v2
class UserAPI {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  searchUsers = async ({ scope, conditions }) => {
    try {
      const result = await this.dispatch(
        userV2Actions.resourceFetchRequest('/v2/search/users', {
          method: 'post',
          scope,
          conditions
        })
      );
      return { error: false, result };
    } catch (e) {
      return { error: false, message: e.message };
    }
  };

  checkIfInstagramTaken = async instagram => {
    try {
      const igHandle = instagram.trim().replace('@', '');
      const result = await this.dispatch(
        userV2Actions.resourceFetchRequest('/v2/search/users', {
          method: 'post',
          scope: { '~ilike~instagram': igHandle }
        })
      );
      if (result.meta.count > 0) return true;
      return false;
    } catch (e) {}
  };
}

export default UserAPI;
