import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
`;

const InputComponent = ({ onChange, accept }) => {
  return <StyledInput onChange={onChange} type="file" accept={accept} />;
};

export default InputComponent;
