import SvgAppleSmsSolid from 'components/reusable/svg/share_buttons/svg_apple_sms_solid';
import SvgAppleEmailSolid from 'components/reusable/svg/share_buttons/svg_apple_email_solid';
import SvgWhatsAppColored from 'components/reusable/svg/share_buttons/svg_whats_app_solid';
import SvgWhatsAppOutline from 'components/reusable/svg/share_buttons/svg_whats_app_outline';

const useShareProfileOptions = ({ url, sendMetric }) => {
  return [
    {
      label: 'Share via Text Message',
      url: `sms:?&body=${url}`,
      onClick: () => sendMetric('sms'),
      icon: SvgAppleSmsSolid
    },
    {
      label: 'Share via E-mail',
      url: `mailto:?body=${url}`,
      onClick: () => sendMetric('mail'),
      icon: SvgAppleEmailSolid
    },
    {
      label: 'Share via Whatsapp',
      url: `https://wa.me/?text=${url}`,
      onClick: () => sendMetric('whatsapp'),
      icon: SvgWhatsAppOutline
    }
  ];
};

export default useShareProfileOptions;
