import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import Text from 'components/reusable/text';
import ChatSharedRowItem from 'components/modals/v2/chat_shared_modal/components/chat_shared_row_item';
import InfiniteScroll from 'components/complex/infinite_scroll/infinite_scroll';
import ApplicationHelper from 'helpers/application_helper';

/**
 * @param messageType file | link
 * @param _messages
 * @param label string
 * @param isSkeleton boolean
 * @returns {JSX.Element|null}
 * @constructor
 */
function ChatSharedRowGroup({
  handleDeleteSharedMsg,
  pagination: _pagination,
  messageType,
  messages: _messages,
  label,
  isSkeleton = false
}) {
  const pagination = isSkeleton ? {} : _pagination;
  const messages = isSkeleton ? new Array(4).fill({}) : _messages;

  if (!isSkeleton && !messages.length) return null;

  return (
    <GroupWrapper
      key={label}
      className="chat__shared-row-group"
      loadMore={pagination.handleAppendFiles}
      hasMore={pagination.hasMoreFiles}
      // Desktop has a separate overlay while MR use the root overlay
      ref={ApplicationHelper.isMobile ? { current: document.getElementById('modal__overlay') } : undefined}
    >
      {isSkeleton ? (
        <Skeleton width={100} height={16} />
      ) : (
        <Text size={[{ size: 12, maxWidth: 768 }, { size: 16 }]}>{label}</Text>
      )}

      <RowWrapper className="chat__shared-row">
        {messages.map((message, i) => {
          if (!['file', 'link'].includes(messageType)) return null;
          if (isSkeleton) return <ChatSharedRowItem key={i} isSkeleton={isSkeleton} messageType={messageType} />;
          return (
            <ChatSharedRowItem
              handleDeleteSharedMsg={handleDeleteSharedMsg}
              key={message.id}
              message={message}
              isSkeleton={isSkeleton}
              messageType={messageType}
            />
          );
        })}
      </RowWrapper>
    </GroupWrapper>
  );
}

const GroupWrapper = styled(InfiniteScroll)`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 16px;
  @media (max-width: 768px) {
    padding: 0 4px;
  }
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export default ChatSharedRowGroup;
