import React, { useMemo } from 'react';

import Slider from 'components/complex/slider';
import useNonLinearScaling from './hooks/use_non_linear_scaling';
import useBreakpoints from './hooks/use_breakpoints';
import { valueToProgress } from './helpers/values_helper';

const NonLinearSlider = ({
  min,
  max,
  minLabel,
  maxLabel,
  midPoint,
  value,
  onChange,
  breakpoints,
  className,
  bestRange
}) => {
  const sliderBestRange = useMemo(() => {
    if (!bestRange || !bestRange.startsAt || !bestRange.endsAt) return null;
    return {
      startsAt: valueToProgress({
        midPoint,
        min,
        max,
        value: bestRange.startsAt
      }),
      endsAt: valueToProgress({ midPoint, min, max, value: bestRange.endsAt })
    };
  }, [bestRange, midPoint, min, max]);

  const sliderBreakpoints = useBreakpoints({ breakpoints, max, min, midPoint });

  const { sliderValue, handleChange } = useNonLinearScaling({
    midPoint,
    min,
    max,
    value,
    onChange
  });

  return (
    <Slider
      className={className}
      minLabel={minLabel}
      maxLabel={maxLabel}
      bestRange={sliderBestRange}
      breakpoints={sliderBreakpoints}
      value={sliderValue}
      onChange={handleChange}
    />
  );
};

export default NonLinearSlider;
