import { useMemo } from 'react';

import RATES from 'helpers/job/rates/rates_helper';
import dayRatesHelper from 'helpers/job/rates/day_rates_helper';
import hourlyRates from 'helpers/job/rates/hourly_rates_helper';
import Settings from 'helpers/settings';

const useInitialValues = (category, value) => {
  const initialFrequency = useMemo(() => value.frequency || RATES.DAYRATE, []);

  const initialRate = useMemo(() => {
    if (value.rate) return value.rate;
    const { lookingFor } = category;
    return initialFrequency === RATES.DAYRATE
      ? dayRatesHelper[lookingFor].default
      : hourlyRates[lookingFor].default;
  }, [initialFrequency]);

  const initialHours = useMemo(
    () =>
      initialFrequency === RATES.HOURLY
        ? value.hours || Settings.minHoursForHourly
        : null,
    [initialFrequency]
  );

  return {
    initialFrequency,
    initialRate,
    initialHours
  };
};

export default useInitialValues;
