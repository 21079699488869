import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Link from 'components/reusable/link';
import { routes } from 'helpers/router_helper';
import Button from 'components/reusable/button';
import AssistanceHelpers from 'helpers/assistance_helpers';

const Wrapper = styled(Flex)`
  max-width: 744px;
  padding-bottom: 40px;
`;

const SectionsWrapper = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  > :first-child {
    border-right: 1px solid ${colors.gray[100]};
  }
`;

const Section = styled(Flex)`
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 56px 40px 16px;
`;

const AssistanceItemsWrapper = styled.div`
  width: 100%;
  > :not(:last-child) {
    border-bottom: 1px solid ${colors.gray[100]};
    padding-bottom: 16px;
  }
  > :not(:first-child) {
    padding-top: 16px;
  }
`;

const AssistanceItemWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AssistanceLink = styled(Link)`
  color: ${colors.gray[700]};
  font-weight: 400;
  font-size: 16px;
`;

const HelpCenterModal = ({ onClose }) => {
  return (
    <Wrapper dir="column">
      <SectionsWrapper>
        <Section width="55%">
          <Text weight={500} size={24}>
            HOW CAN WE HELP?
          </Text>
          <Indent top={24} />
          <Text size={16}>
            Our Team of support specialists is here 24/7 to make sure you have a
            smooth and successful SWIPECAST experience.
          </Text>
          <Indent top={38} />
          <Text>
            For general questions{' '}
            <Link onClick={onClose} to={routes.faq.redirectUrl()}>
              check out our FAQ
            </Link>
          </Text>
        </Section>

        <Section width="45%">
          <Text weight={400} size={12} color={colors.gray[300]}>
            Need immediate assistance?
          </Text>
          <Indent top={24} />

          <AssistanceItemsWrapper>
            {AssistanceHelpers.variants.map(item => (
              <AssistanceItemWrapper key={item.label}>
                <AssistanceLink target="_top" blank href={item.href}>
                  {item.label}
                </AssistanceLink>
                <AssistanceLink target="_top" blank href={item.href}>
                  {item.icon(24)}
                </AssistanceLink>
              </AssistanceItemWrapper>
            ))}
          </AssistanceItemsWrapper>
        </Section>
      </SectionsWrapper>

      <Indent top={40} />
      <Button onClick={onClose} height={40} width={160} type="gray-shadow">
        Cancel
      </Button>
    </Wrapper>
  );
};

export default HelpCenterModal;
