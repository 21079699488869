import React from 'react';

import { colors } from 'helpers/colors';

function SvgBellSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
      <path
        d="M20 17.881V18.7857H4V17.881L5.77778 16.0714V10.6429C5.77778 7.8381 7.58222 5.3681 10.2222 4.5719V4.30952C10.2222 3.82961 10.4095 3.36935 10.7429 3.03C11.0763 2.69065 11.5285 2.5 12 2.5C12.4715 2.5 12.9237 2.69065 13.2571 3.03C13.5905 3.36935 13.7778 3.82961 13.7778 4.30952V4.5719C16.4178 5.3681 18.2222 7.8381 18.2222 10.6429V16.0714L20 17.881ZM13.7778 19.6905C13.7778 20.1704 13.5905 20.6307 13.2571 20.97C12.9237 21.3094 12.4715 21.5 12 21.5C11.5285 21.5 11.0763 21.3094 10.7429 20.97C10.4095 20.6307 10.2222 20.1704 10.2222 19.6905"
        fill={color}
      />
    </svg>
  );
}

export default SvgBellSolid;
