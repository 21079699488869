import { useMemo } from 'react';

import AuthHelper from 'helpers/auth_helper';

const useLabel = () => {
  const label = useMemo(() => {
    const user = AuthHelper.currentUser();
    const clientLabel = 'Upload your logo or a pic';
    const talentLabel = 'Upload a pic from your portfolio';

    if (user) {
      const { intent_role } = user;
      const isTalent = intent_role === 'talent';
      if (isTalent) return talentLabel;
    }

    return clientLabel;
  }, []);

  return label;
};

export default useLabel;
