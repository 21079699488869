import { useState, useCallback, useEffect, useMemo } from 'react';
import { searchParams } from 'helpers/router_helper';
import ApplicationHelper from 'helpers/application_helper';
import { RootContext } from 'contexts/contexts';
import { useRootContext } from 'hooks/use_context';

const useAlbumPreviewContent = (assets, initialIndex) => {
  const [assetIndex, setAssetIndex] = useState(initialIndex);
  const asset = assets[assetIndex];

  const onPrev = useCallback(() => {
    const lastIndex = assets.length - 1;
    const newIndex = assetIndex === 0 ? lastIndex : assetIndex - 1;
    const asset = assets[newIndex];

    if (asset) setAssetIndex(newIndex);
  }, [assetIndex, assets]);

  const onNext = useCallback(() => {
    const lastIndex = assets.length - 1;
    const newIndex = assetIndex === lastIndex ? 0 : assetIndex + 1;
    const asset = assets[newIndex];

    if (asset) setAssetIndex(newIndex);
  }, [assetIndex, assets]);

  return {
    leftContent: ApplicationHelper.isMobile ? null : <div />,
    asset,
    assetIndex,
    onPrev,
    onNext
  };
};

export default useAlbumPreviewContent;
