import React from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';

import RootWrapper from './components/root_wrapper/root_wrapper';
import withSitemana from 'hocs/with_sitemana';
import withAbProvider from 'hocs/with_ab_provider';
import './App.css';
import Settings from 'helpers/settings';
import store from 'store/configureStore';

if (typeof Object.assign !== 'function') {
  // Must be writable: true, enumerable: false, configurable: true
  Object.defineProperty(Object, 'assign', {
    value: function assign(target, varArgs) {
      // .length of function is 2

      if (target === null) {
        // TypeError if undefined or null
        throw new TypeError('Cannot convert undefined or null to object');
      }

      const to = Object(target);

      for (let index = 1; index < arguments.length; index++) {
        const nextSource = arguments[index];

        if (nextSource != null) {
          // Skip over if undefined or null
          for (const nextKey in nextSource) {
            // Avoid bugs when hasOwnProperty is shadowed
            if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
              to[nextKey] = nextSource[nextKey];
            }
          }
        }
      }
      return to;
    },
    writable: true,
    configurable: true
  });
}

const App = () => (
  <GoogleOAuthProvider clientId={Settings.GOOGLE_OAUTH_CLIENT_ID}>
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <Router>
          <RootWrapper />
        </Router>
      </PersistGate>
    </Provider>
  </GoogleOAuthProvider>
);

export default withSitemana(withAbProvider(App));
