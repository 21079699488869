const initialState = {
  data: 0,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UNREAD_NOTIFICATIONS_COUNT_FETCH_REQUESTED':
      return { ...state, loading: true };

    case 'UNREAD_NOTIFICATIONS_COUNT_FETCH_SUCCEEDED':
      return { ...state, loading: false, data: action.response.unread_count };

    case 'UNREAD_NOTIFICATIONS_COUNT_FETCH_FAILED':
      return initialState;

    default:
      return state;
  }
};
