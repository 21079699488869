import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import { colors } from 'helpers/colors';
import SvgCrossMarkSolid from 'components/reusable/svg/svg_cross_mark_solid';
import Button from 'components/reusable/button';
import Link from 'components/reusable/link';

function ChatSharedHeader({ activeTab, items, onClose, onChange, isSkeleton = false }) {
  return (
    <Header>
      {items.map(item => {
        const onClick = () => onChange(item.label);
        const active = activeTab === item.label;

        return (
          <HeaderItem key={item.label} $active={active}>
            <StyledLink
              activeColor={colors.gray[700]}
              weight={active ? 500 : 400}
              color={active ? colors.gray[700] : colors.gray[300]}
              size={[{ maxWidth: 768, size: 16 }, { size: 18 }]}
              onClick={onClick}
            >
              {isSkeleton ? (
                <Skeleton width="90px" height="100%" />
              ) : (
                <>
                  {item.label}
                  {typeof item.count === 'number' ? <Counter $active={active}>{item.count}</Counter> : null}
                </>
              )}
            </StyledLink>
          </HeaderItem>
        );
      })}

      {onClose ? (
        <StyledButton type="semantic-wrapper" onClick={onClose}>
          <SvgCrossMarkSolid size={20} color={colors.gray[700]} />
        </StyledButton>
      ) : null}
    </Header>
  );
}

const StyledButton = styled(Button)`
  margin-left: auto;
`;

const StyledLink = styled(Link)`
  gap: 8px;
  display: flex;
  align-items: center;
`;

const Counter = styled.span`
  display: flex;
  justify-content: center;
  padding: 6px 0 4px;
  border-radius: 200px;
  font-size: 10px;
  background-color: ${({ $active }) => ($active ? colors.gray[600] : colors.gray[100])};
  color: ${({ $active }) => ($active ? colors.light : colors.gray[300])};
  min-width: 32px;
`;

const Header = styled.header`
  height: 70px;
  min-height: 70px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border-bottom: 1px solid ${colors.gray[100]};
  &:last-child {
    align-self: flex-end;
  }
  @media (max-width: 768px) {
    overflow-x: auto;
    white-space: nowrap;
    height: 60px;
    min-height: 60px;
  }
`;

const HeaderItem = styled.div`
  display: inline-flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: ${({ $active }) => ($active ? colors.primary[500] : 'transparent')};
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export default ChatSharedHeader;
