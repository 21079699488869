import { useCallback } from 'react';

import {
  handleWidth,
  indent
} from 'components/complex/slider/components/handle';

const usePositionOnSlider = width => {
  const getPositionOnSlider = useCallback(
    val => {
      if (!width) return 0;
      const handleWidthPercentage = (handleWidth + indent) / width;
      if (val >= 50) return (val - 50) * (1 - handleWidthPercentage) + 50;
      return (val - 50) * (1 - handleWidthPercentage) + 50;
    },
    [width]
  );

  return getPositionOnSlider;
};

export default usePositionOnSlider;
