import React from 'react';

function SvgAppleSmsSolid({ size = 30 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5186 0.666504C13.1853 0.666504 14.0186 0.666504 14.926 0.944282C15.9075 1.29613 16.6853 2.07391 17.0371 3.05539C17.3334 3.9628 17.3334 4.81465 17.3334 6.48132V11.5184C17.3334 13.185 17.3334 14.0184 17.0556 14.9258C16.7038 15.9072 15.926 16.685 14.9445 17.0369C14.0186 17.3332 13.1853 17.3332 11.5186 17.3332H6.48156C4.8149 17.3332 3.98156 17.3332 3.07416 17.0554C2.07416 16.685 1.3149 15.9258 0.944526 14.9258C0.666748 14.0369 0.666748 13.2035 0.666748 11.5184V6.48132C0.666748 4.81465 0.666748 3.98132 0.944526 3.07391C1.3149 2.09243 2.07416 1.31465 3.07416 0.9628C3.96304 0.666504 4.79638 0.666504 6.48156 0.666504H11.5186Z"
        fill="url(#paint0_linear_18447_13129)"
      />
      <path
        d="M7.79583 13.7592C8.18472 13.8148 8.57361 13.8518 8.98102 13.8518C12.3514 13.8518 15.0921 11.574 15.0921 8.77772C15.0921 5.98143 12.3699 3.72217 8.99954 3.72217C5.62917 3.72217 2.88843 5.99995 2.88843 8.79624C2.88843 10.6296 4.05509 12.2222 5.79584 13.1111C5.79584 13.1666 5.79584 13.2222 5.79584 13.2962C5.79584 13.8333 4.90695 14.537 4.9625 14.537C5.85139 14.537 6.48102 13.9814 6.90695 13.8518C7.2588 13.7592 7.4625 13.7407 7.79583 13.7592Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18447_13129"
          x1="8.99749"
          y1="0.671689"
          x2="8.99749"
          y2="17.3328"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#67FF81" />
          <stop offset="1" stopColor="#01B41F" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgAppleSmsSolid;
