import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';

const Container = styled(Flex)`
  cursor: pointer;
  height: 40px;
  border-radius: 20px;
  border: 1px solid;
  padding: 8px 0 8px 0;
  ${({ color }) => color && `border-color: ${color}`};
  ${({ width }) => width && `width: ${width}px`};
  transition: all 0.5s;
`;

const OutlinedButton = ({ onClick, color, children, width }) => {
  return (
    <Container color={color} width={width} onClick={onClick}>
      <Text style={{ transition: 'all .5s' }} color={color} weight={500}>
        {children}
      </Text>
    </Container>
  );
};

export default OutlinedButton;
