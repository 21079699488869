import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';
import SvgArrowRightSolid from 'components/reusable/svg/svg_arrow_right_solid';
import SvgArrowLeftSolid from 'components/reusable/svg/svg_arrow_left_solid';
import { colors } from 'helpers/colors';
import Image from 'components/reusable/image';
import { dimensions } from 'helpers/style';

const steps = [
  {
    label: 'COPY LINK TO YOUR SWIPCAST PROFILE',
    desc: 'Text clients your portfolio and have them book you over Swipecast.',
    fullImgPath: '/images/discover/discover-onboarding-lg-1.jpeg',
    imgPath: '/images/screens/onboarding1.jpg'
  },
  {
    label: 'SHARE YOUR PORTFOLIO ON SOCIAL MEDIA',
    desc: 'Chat with prospective clients and get booked securely with zero fee.',
    fullImgPath: '/images/discover/discover-onboarding-lg-2.jpeg',
    imgPath: '/images/screens/onboarding2.jpg'
  },
  {
    label: 'BOOST YOUR INCOME',
    desc: 'Manage contracts, send invoices, and get paid securely in real-time.',
    fullImgPath: '/images/discover/discover-onboarding-lg-3.jpeg',
    imgPath: '/images/screens/onboarding3.jpg'
  }
];

const ARROW_ICON_SIZE = 14;

const LibOnboardingModal = ({ onClose }) => {
  const [step, setStep] = useState(0);
  const isLastStep = !steps[step + 1];
  const isFirstStep = step === 0;
  const currentStep = steps[step];
  const stepNumber = `${step + 1 > 9 ? '' : '0'}${step + 1}.`;
  const desktopImages = steps.map(step => step.fullImgPath);

  const handleNextStep = useCallback(() => setStep(prev => (steps[prev + 1] ? prev + 1 : prev)), []);
  const handlePrevStep = useCallback(() => setStep(prev => (prev > 0 ? prev - 1 : prev)), []);

  if (!currentStep) return null;

  return (
    <Wrapper dir="column" wrap="nowrap">
      <Text size={[{ maxWidth: 768, size: 18 }, { size: 24 }]} weight={500}>
        How it Works
      </Text>

      <MobileImage src={currentStep.imgPath} alt="link-in-bio onboarding" height={dimensions.height / 2} width="100%" />

      <StyledHeading size={[{ maxWidth: 1200, size: 14 }, { size: 18 }]} weight={500} textAlign="center">
        <StepNumber>{stepNumber}</StepNumber>
        &nbsp;
        {currentStep.label}
      </StyledHeading>

      <Indent bottom={12} />
      <StyledDesc size={[{ maxWidth: 1200, size: 12 }, { size: 16 }]} textAlign="center">
        {currentStep.desc}
      </StyledDesc>

      <DesktopImagesSection>
        {desktopImages.map(img => (
          <DesktopImage $active={img === currentStep.fullImgPath} key={img} src={img} alt="link-in-bio onboarding" />
        ))}
      </DesktopImagesSection>

      <Indent bottom={[{ maxWidth: 768, margin: 24 }, { maxWidth: 1200, margin: 24 }, { margin: 40 }]} />

      <Flex dir="row" gap={8} wrap="no-wrap" width="100%">
        {!isFirstStep && (
          <StyledButton type="gray-shadow" height={40} width={160} onClick={handlePrevStep}>
            <SvgArrowLeftSolid size={ARROW_ICON_SIZE} color={colors.gray[600]} />
            Back
          </StyledButton>
        )}

        <StyledButton type="primary" height={40} width={160} onClick={isLastStep ? onClose : handleNextStep}>
          {isLastStep ? 'Got it' : 'Next'}
          {isLastStep ? '' : <SvgArrowRightSolid size={ARROW_ICON_SIZE} color={colors.light} />}
        </StyledButton>
      </Flex>
    </Wrapper>
  );
};

const DesktopImagesSection = styled.section`
  width: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 16px;
  margin-top: 32px;

  @media (max-width: 1200px) {
    margin-top: 24px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const DesktopImage = styled(Image)`
  height: 384px;
  border-radius: 20px;
  filter: ${({ $active }) => ($active ? 'grayscale(0)' : 'grayscale(1)')};
  @media (max-width: 1200px) {
    height: 300px;
  }
`;

const MobileImage = styled(Image)`
  display: none;
  @media (max-width: 768px) {
    margin-top: 24px;
    display: block;
  }
`;

const Wrapper = styled(Flex)`
  padding: 40px 56px;
  @media (max-width: 1200px) {
    padding: 24px 24px 32px;
  }
  @media (max-width: 768px) {
    padding: 16px 16px 24px;
  }
`;

const StyledHeading = styled(Text)`
  margin-top: 40px;

  @media (max-width: 1200px) {
    margin-top: 24px;
  }
  @media (max-width: 768px) {
    min-height: 38px;
    margin-top: 24px;
  }
`;

const StyledDesc = styled(Text)`
  @media (max-width: 768px) {
    min-height: 34px;
  }
`;

const StepNumber = styled.span`
  color: ${colors.primary[500]};
`;

const StyledButton = styled(Button)`
  gap: 16px;

  @media (max-width: 768px) {
    flex: 1;
  }
`;

export default LibOnboardingModal;
