import React from 'react';
import styled from 'styled-components';

import { getDimention } from 'helpers/style';

const _getIndent = (marginPlacement, size) => {
  const isResponsiveText = Array.isArray(size);
  if (!isResponsiveText) return `${marginPlacement}: ${getDimention(size)};`;

  const medias = size
    .sort((x, y) => {
      if (!x.maxWidth || x.maxWidth < y.maxWidth) return -1;
      if (x.maxWidth > y.maxWidth) return 1;
      return 0;
    })
    .map(s => {
      if (!s.maxWidth) return `${marginPlacement}: ${getDimention(s.margin)};`;
      return `@media (max-width: ${getDimention(
        s.maxWidth
      )}) { ${marginPlacement}: ${getDimention(s.margin)} };`;
    });

  return medias;
};

const _getIndents = ({ bottom, top, left, right }) => {
  const medias = [
    { placement: 'margin-bottom', margin: bottom },
    { placement: 'margin-top', margin: top },
    { placement: 'margin-left', margin: left },
    { placement: 'margin-right', margin: right }
  ]
    .map(({ placement, margin }) => _getIndent(placement, margin))
    .flat();

  return medias.join('');
};

const IndentComponent = styled.div`
  display: ${({ display }) => display};
  ${_getIndents}
`;

const Indent = ({
  horizontal,
  vertical,
  bottom,
  right,
  children,
  left,
  top,
  ...props
}) => {
  const handledLeft = left || horizontal || 0;
  const handledRight = right || horizontal || 0;
  const handledTop = top || vertical || 0;
  const handledBottom = bottom || vertical || 0;
  return (
    <IndentComponent
      left={handledLeft}
      right={handledRight}
      top={handledTop}
      bottom={handledBottom}
      children={children}
      {...props}
    />
  );
};

export default Indent;
