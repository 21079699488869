import React, { useCallback } from 'react';

const useOnChange = ({ onChange, value, country }) => {
  const handleCodeChange = useCallback(
    code => {
      onChange({ code, phone: value.phone });
    },
    [onChange, value]
  );

  const handleNumberChange = useCallback(
    e => {
      let valid = true;
      const phone = e.target.value.replace(/\D/g, '');

      if (country?.code === 'US' && phone.length > 10) {
        valid = false;
      }

      if (valid) onChange({ code: value.code, phone });
    },
    [onChange, value, country]
  );

  return {
    handleCodeChange,
    handleNumberChange
  };
};

export default useOnChange;
