import React, { useEffect, useCallback, useMemo, useRef } from 'react';
import debounce from 'lodash.debounce';

const useScrollHandler = ({ isReverse, hasMore, getParent, setLoading, loading, prevScrollOffsetRef, loadMore }) => {
  const loadingRef = useRef(loading);

  const _getThreshold = useCallback(overflow => {
    if (!overflow) return 0;
    return overflow.clientHeight * 1.5;
  }, []);

  const _getReversePreloadRequired = useCallback(
    overflow => {
      const threshold = _getThreshold(overflow);

      return threshold >= overflow.scrollTop;
    },
    [isReverse]
  );

  const _getCommonPreloadRequired = useCallback(
    overflow => {
      const threshold = _getThreshold(overflow);
      const endReachedScrollTop = overflow.scrollHeight - overflow.clientHeight;

      return overflow.scrollTop + threshold >= endReachedScrollTop;
    },
    [isReverse]
  );

  const _getPreloadRequired = useCallback(() => {
    const overflow = getParent();
    if (!hasMore || !overflow) return false;
    if (isReverse) return _getReversePreloadRequired(overflow);
    return _getCommonPreloadRequired(overflow);
  }, [hasMore, getParent, isReverse, _getReversePreloadRequired]);

  const _handleLoadMore = debounce(
    useCallback(async () => {
      await loadMore();
      setLoading(false);
      loadingRef.current = false;
    }, [loadMore]),
    500
  );

  const onScroll = useCallback(() => {
    if (loadingRef.current || !_getPreloadRequired()) return false;
    loadingRef.current = true;
    setLoading(true);
    const overflow = getParent();
    prevScrollOffsetRef.current = overflow.scrollHeight - overflow.scrollTop;
    _handleLoadMore();
  }, [hasMore, loadMore, _handleLoadMore, _getPreloadRequired]);

  useEffect(() => {
    if (isReverse) {
      const overflow = getParent();
      overflow.scrollTo({ top: overflow.scrollHeight });
    }
  }, []);

  useEffect(() => {
    const overflow = getParent();
    overflow.addEventListener('scroll', onScroll);
    return () => overflow.removeEventListener('scroll', onScroll);
  }, [onScroll]);
};

export default useScrollHandler;
