import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import svgIcons from 'helpers/svgIcons';
import React from 'react';
import styled from 'styled-components';

const MobileWrapper = styled(Flex)`
  width: 100%;
  padding: 24px 16px 16px;
`;

const ButtonWrap = styled(Flex)`
  padding-bottom: 4px;
  border-bottom: 1px solid ${colors.gray[100]};
`;

const JobOptionsModal = ({ modalProps, onClose }) => {
  const buttons = [
    {
      label: 'Edit Job Role',
      icon: svgIcons.edit(colors.gray[700]),
      onClick: () => {
        modalProps.handleEditJob();
        onClose();
      },
      condition: true
    },
    // {
    //   label: 'Share Applicants',
    //   icon: svgIcons.share(colors.gray[700]),
    //   onClick: modalProps.handleEditJob,
    //   condition: true
    // },
    {
      label: 'Cancel Job',
      icon: svgIcons.close(colors.gray[700], { width: 20, height: 20 }),
      onClick: modalProps.cancelJob,
      condition: modalProps.cancelButtonCondition
    },
    {
      label: 'Finish Search',
      icon: svgIcons.success(colors.gray[700], { width: 20, height: 20 }),
      onClick: () => {
        modalProps.toggleFinishTalentSelection();
        onClose();
      },
      condition: modalProps.finishSearchCondition
    },
    {
      label: 'Resume Search',
      icon: svgIcons.reset(colors.gray[700]),
      onClick: () => {
        modalProps.toggleFinishTalentSelection();
        onClose();
      },
      condition: modalProps.resumeSearchCondition
    }
  ];

  return (
    <MobileWrapper dir="column" ai="flex-start" wrap="nowrap" gap={24}>
      <Text size={18} weight={500} textTransform="uppercase">
        Job options
      </Text>
      <Flex dir="column" gap={16} width="100%" wrap="nowrap">
        {buttons.map(
          button =>
            button.condition && (
              <ButtonWrap
                key={button.label}
                onClick={button.onClick}
                jc="space-between"
                width="100%"
                wrap="nowrap"
              >
                <Text size={14}>{button.label}</Text>
                {button.icon}
              </ButtonWrap>
            )
        )}
      </Flex>
      <Button
        onClick={onClose}
        color={colors.gray[700]}
        width="100%"
        height={40}
      >
        Cancel
      </Button>
    </MobileWrapper>
  );
};

export default JobOptionsModal;
