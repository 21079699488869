import React from 'react';

import { DragWrapper } from 'components/reusable/input/components/ImageInput/image_input_v2/components/cropper_preview';
import Image from 'components/reusable/image';
import InputComponent from 'components/reusable/input/components/ImageInput/image_input_v2/components/input_component';

const SelectedImagePreview = ({ value, handleChange, accept }) => {
  const { croppedBase64 } = value;
  return (
    <DragWrapper>
      <Image src={croppedBase64} alt="logo" />
      <InputComponent onChange={handleChange} type="file" accept={accept} />
    </DragWrapper>
  );
};

export default SelectedImagePreview;
