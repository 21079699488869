import React, { useCallback } from 'react';

import SvgAppleEmailSolid from 'components/reusable/svg/share_buttons/svg_apple_email_solid';
import SvgAppleSmsSolid from 'components/reusable/svg/share_buttons/svg_apple_sms_solid';
import SvgWhatsAppSolid from 'components/reusable/svg/share_buttons/svg_whats_app_solid';
import ShareOfferMobile from './components/share_offer_mobile';
import useAdaptiveDesign from 'hooks/use_adaptive_design';
import ShareOfferDesktop from 'components/modals/v2/share_offer_modal/components/share_offer_desktop';

/**
 * @description missing 768+ screen resolution implementation
 * @param modalProps
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const ShareOfferModal = ({ modalProps = {}, onClose }) => {
  const Component = useAdaptiveDesign(ShareOfferDesktop, ShareOfferMobile);

  const handleClose = useCallback(() => {
    if (typeof modalProps.callback === 'function') modalProps.callback();
    onClose();
  }, [modalProps, onClose]);

  const links = [
    {
      label: 'Text Message',
      icon: <SvgAppleSmsSolid size={22} />,
      href: `sms:?&body=${modalProps.body}`
    },
    {
      label: 'WhatsApp',
      icon: <SvgWhatsAppSolid size={22} />,
      href: `mailto:?&body=${modalProps.body}`
    },
    {
      label: 'E-Mail',
      icon: <SvgAppleEmailSolid size={22} />,
      href: `mailto:?subject=${modalProps.subject}&body=${modalProps.body}`
    }
  ];

  return (
    <Component
      offer={modalProps.offer}
      title={modalProps.title}
      links={links}
      onClose={handleClose}
    />
  );
};

export default ShareOfferModal;
