const FormatHelpers = {
  numberToCurrencyLabel(number, includeCents = true) {
    if (Number.isInteger(number)) return `$${FormatHelpers.numberWithCommas(number)}`;
    return `$${FormatHelpers.numberWithCommas(number.toFixed(includeCents ? 2 : 0))}`;
    // return `$${number
    //   .toFixed(includeCents ? 2 : 0)
    //   .toString()
    //   .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
  },
  numberWithCommas(x) {
    return x.toLocaleString('en-US');
    // return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  round(x, number = 50) {
    return Math.round(x / number) * number;
  },
  toFixedLabel(number, numbersAfterComa, defaultValue = '') {
    if (typeof number !== 'number' || number === 0) return defaultValue;
    return number.toFixed(numbersAfterComa);
  },
  phoneNumber(phone_country_code, phone_number, delimiter = '') {
    if (phone_country_code && phone_number) return `+${phone_country_code}${delimiter}${phone_number}`;
    return phone_number;
  }
};

export default FormatHelpers;
// can we have a space between code and number?
