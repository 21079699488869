import Settings from 'helpers/settings';
import SvgAppleEmailSolid from 'components/reusable/svg/share_buttons/svg_apple_email_solid';
import SvgWhatsAppSolid from 'components/reusable/svg/share_buttons/svg_whats_app_solid';
import SvgApplePhoneSolid from 'components/reusable/svg/share_buttons/svg_apple_phone_solid';

const variants = [
  {
    label: Settings.support.phoneLabel,
    href: `tel:+${Settings.support.phoneNumber}`,
    icon: width => <SvgApplePhoneSolid size={width} />
  },
  {
    label: 'WhatsApp',
    href: Settings.support.whatsappURL,
    icon: width => <SvgWhatsAppSolid size={width} />
  },
  {
    label: 'Email Us',
    href: `mailto:${Settings.support.email}`,
    icon: width => <SvgAppleEmailSolid size={width} />
  }
];

const AssistanceHelpers = { variants };

export default AssistanceHelpers;
