import {
  modelInfPartBreakpoints,
  stylistsMakeUpManicRetBreakpoints
} from './breakpoints';
import templates from './templates';
import Globals from 'helpers/globals';

export const hourlyRates = {
  // category 1
  [Globals.profileTypes.hair]: {
    ...templates.default,
    buildBreakpoints: () =>
      stylistsMakeUpManicRetBreakpoints({ talentsType: 'hairstylists' })
  },
  [Globals.profileTypes.makeUp]: {
    ...templates.default,
    buildBreakpoints: () =>
      stylistsMakeUpManicRetBreakpoints({ talentsType: 'makeup artists' })
  },
  [Globals.profileTypes.manicurist]: {
    ...templates.default,
    buildBreakpoints: () =>
      stylistsMakeUpManicRetBreakpoints({ talentsType: 'manicurists' })
  },
  [Globals.profileTypes.retoucher]: {
    ...templates.default,
    buildBreakpoints: () =>
      stylistsMakeUpManicRetBreakpoints({ talentsType: 'retouchers' })
  },

  // category 2
  [Globals.profileTypes.model]: {
    ...templates.default,
    buildBreakpoints: modelInfPartBreakpoints
  },
  [Globals.profileTypes.influencer]: {
    ...templates.default,
    buildBreakpoints: modelInfPartBreakpoints
  },
  [Globals.profileTypes.parts]: {
    ...templates.default,
    buildBreakpoints: modelInfPartBreakpoints
  }
};

export default hourlyRates;
