import { useCallback } from 'react';

const useSelect = ({
  croppieRef,
  handleCrop,
  onChange,
  setShowCroppie,
  purgeCroppie
}) => {
  const onSelect = useCallback(async () => {
    if (!croppieRef.current) return;

    const crop = handleCrop();
    const originalBase64 = croppieRef.current.data.url;
    const croppedBase64 = await croppieRef.current.result();

    onChange({ crop, croppedBase64, originalBase64 });
    setShowCroppie(false);
    purgeCroppie();
  }, [handleCrop, onChange, setShowCroppie, purgeCroppie]);

  return onSelect;
};

export default useSelect;
