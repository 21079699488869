const templates = {
  default: {
    min: 125,
    max: 300,
    minRecommended: 135,
    maxRecommended: 175,
    default: 150,
    midPoint: 170
  }
};

export default templates;
