import { useRef, useState, useLayoutEffect } from 'react';

const useNodeDimensions = () => {
  const targetRef = useRef();
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useLayoutEffect(() => {
    if (targetRef.current) {
      setDimensions({
        width: targetRef.current.offsetWidth,
        height: targetRef.current.offsetHeight
      });
    }
  }, [setDimensions]);

  return {
    targetRef,
    dimensions
  };
};

export default useNodeDimensions;
