/* eslint-disable max-len */
import React from 'react';

function SvgApplePhoneSolid({ size = 30 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0222 0C15.0222 0 16.0222 0 17.1111 0.333333C18.2889 0.755556 19.2222 1.68889 19.6444 2.86667C20 3.97778 20 4.97778 20 6.97778V13.0222C20 15.0222 20 16.0222 19.6667 17.1111C19.2444 18.2889 18.3111 19.2222 17.1333 19.6444C16.0444 20 15.0444 20 13.0222 20H6.97778C4.97778 20 3.97778 20 2.88889 19.6667C1.71111 19.2222 0.777778 18.3111 0.355556 17.1111C-2.6491e-08 16.0444 0 15.0444 0 13.0222V6.97778C0 4.97778 0 3.97778 0.333333 2.88889C0.777778 1.71111 1.71111 0.777778 2.88889 0.355556C3.97778 -2.6491e-08 4.97778 0 6.97778 0H13.0222Z"
        fill="url(#paint0_linear_11577_68688)"
      />
      <path
        d="M4.59998 8.73335C3.86665 7.40001 3.57776 6.31112 3.57776 5.57779C3.57776 4.84446 3.75554 4.64446 3.93331 4.46668C4.11109 4.2889 4.93331 3.80001 5.08887 3.6889C5.2222 3.60001 5.75554 3.40001 6.08887 3.8889C6.4222 4.37779 7.04443 5.33335 7.48887 5.97779C8.17776 6.8889 7.6222 7.2889 7.44443 7.53335C7.11109 7.97779 6.93331 8.0889 6.93331 8.62223C6.93331 9.15557 8.44443 10.7111 8.79998 11.0889C9.15554 11.4667 10.6666 12.7778 11.1333 12.8667C11.6 12.9333 12.2444 12.4445 12.3778 12.3111C13.0666 11.7778 13.4444 12.1778 13.7555 12.3556C14.0666 12.5333 15.4889 13.4222 15.9333 13.7111C16.3555 14 16.3111 14.4667 16.3111 14.4667C16.3111 14.4667 16.0222 15.3333 15.3555 15.9778C15.2 16.1333 14.9111 16.3333 14.2222 16.3333C13.5333 16.3333 12.7778 16.2 11 15.2222C9.55554 14.4222 8.17776 13.1778 7.44443 12.4445C6.71109 11.7333 5.44443 10.2667 4.59998 8.73335Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_11577_68688"
          x1="10.0049"
          y1="0.00888889"
          x2="10.0049"
          y2="19.9996"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#67FF81" />
          <stop offset="1" stopColor="#01B41F" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgApplePhoneSolid;
