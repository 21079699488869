import React, { createContext } from 'react';

export const ModalContext = createContext({});
export const RootContext = createContext({});
export const AbContext = createContext({});
export const ChatContext = createContext(null);
export const SearchContext = createContext(null);
export const UaBannerContext = createContext(null);
export const JobsContext = createContext({ tab: undefined, tabs: [] });

export const withModalContext = Component => props => {
  return (
    <ModalContext.Consumer>
      {value => <Component {...props} modalContextProps={value} />}
    </ModalContext.Consumer>
  );
};
