import React from 'react';

import ApplicationHelper from 'helpers/application_helper';
import UaBannerDesktop from './ua_banner_desktop';
import UaBannerMobile from './us_banner_mobile';

function UaBanner() {
  return ApplicationHelper.isMobile ? <UaBannerMobile /> : <UaBannerDesktop />;
}

export default UaBanner;
