import React from 'react';

import { colors } from 'helpers/colors';

function SvgCalendarOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 20 18" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12318 0.725459C4.12318 0.324664 4.44694 0 4.84602 0C5.24511 0 5.56887 0.324664 5.56887 0.725459V1.03432H14.4311V0.725459C14.4311 0.324664 14.7549 0 15.154 0C15.5531 0 15.8768 0.324664 15.8768 0.725459V1.03432H16.1848C18.2917 1.03432 20 2.74822 20 4.86273V14.1716C20 16.2861 18.2917 18 16.1848 18H3.81523C1.70827 18 0 16.2861 0 14.1716V4.86273C0 2.74822 1.70827 1.03432 3.81523 1.03432H4.12318V0.725459ZM3.81523 2.48524C2.50643 2.48524 1.44569 3.54981 1.44569 4.86273V6.20591H18.5543V4.86273C18.5543 3.54981 17.4936 2.48524 16.1848 2.48524H15.8768V2.79409C15.8768 3.19489 15.5531 3.51955 15.154 3.51955C14.7549 3.51955 14.4311 3.19489 14.4311 2.79409V2.48524H5.56887V2.79409C5.56887 3.19489 5.24511 3.51955 4.84602 3.51955C4.44694 3.51955 4.12318 3.19489 4.12318 2.79409V2.48524H3.81523ZM1.44569 7.65682V14.1716C1.44569 15.4845 2.50643 16.5491 3.81523 16.5491H16.1848C17.4936 16.5491 18.5543 15.4845 18.5543 14.1716V7.65682H1.44569Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgCalendarOutline;
