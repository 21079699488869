import React from 'react';

import Text from 'components/reusable/text';
import TextInput from 'components/reusable/input/components/TextInput';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import styled from 'styled-components';
import Button from 'components/reusable/button';

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ReferFriendsWrapper = styled.div`
  width: 416px;
  padding: 56px 40px 40px 40px;
  border-right: 1px solid ${colors.gray[100]};
  border-bottom: 1px solid ${colors.gray[100]};
`;

const ReferInputWrapper = styled.div`
  padding: 36px 40px;
  border-right: 1px solid ${colors.gray[100]};
  position: relative;
`;

const CopyButton = styled(Button)`
  width: 32px;
  height: 32px;
  background-color: ${colors.gray[500]};
  position: absolute;
  top: 50%;
  right: 48px;
  transform: translateY(-50%);
`;

const StyledTextInput = styled(TextInput)`
  text-align: center;
  font-weight: 500;
`;

function LeftPartition({ onCopyReferralCode, referralCode }) {
  return (
    <Wrapper>
      <ReferFriendsWrapper>
        <Text size={24} weight={500}>
          REFER FRIENDS
        </Text>
      </ReferFriendsWrapper>

      <ReferInputWrapper>
        <StyledTextInput disabled value={referralCode} />
        <CopyButton onClick={onCopyReferralCode} type="semantic-wrapper">
          {svgIcons.document(colors.light, { width: 14, height: 18 })}
        </CopyButton>
      </ReferInputWrapper>
    </Wrapper>
  );
}

export default LeftPartition;
