import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import UserHelpers from 'helpers/user_helpers';
import { routes } from 'helpers/router_helper';
import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Image from 'components/reusable/image';
import Indent from 'components/reusable/indent';
import Text from 'components/reusable/text';

const Container = styled.div`
  -webkit-appearance: none;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 25px 0 15px 0;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
  border-radius: 0 0 20px 20px;
  background-color: ${colors.light};
  z-index: 1;
`;

const Item = styled(Flex)`
  -webkit-appearance: none;
  cursor: pointer;
  padding: 10px 0;
  margin: 0 8px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0px 2px 1px rgba(31, 30, 30, 0.08);
  }
`;

const ProfileImage = styled(Image)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

const HidingText = styled(Text)`
  width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SearchResults = ({ results, cancelSearch }) => {
  const history = useHistory();
  const userTitle = user => UserHelpers.getUserTitle(user);
  const onResultClick = userId => {
    cancelSearch();
    history.push(routes.userProfile.redirectUrl(userId));
  };

  return results.length > 0 ? (
    <Container>
      {results.map((result, i) => (
        <Item jc="flex-start" wrap="nowrap" key={i} onClick={() => onResultClick(result.public_alias_id)}>
          <>
            {result.profile_image ? (
              <ProfileImage width={40} src={result.profile_image} />
            ) : (
              <ProfileImage src="/images/icons/circle_profile.png" />
            )}
          </>
          <Indent right={6} />
          <Flex dir="column" ai="flex-start">
            <HidingText>{result.full_name}</HidingText>
            <HidingText size={12} color={colors.gray[300]}>
              {userTitle(result)}
            </HidingText>
          </Flex>
        </Item>
      ))}
    </Container>
  ) : null;
};

export default SearchResults;
