import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import photosActions from 'store/resources/sharedPhotosV2/actions';
import videosActions from 'store/resources/sharedVideosV2/actions';
import filesActions from 'store/resources/sharedFilesV2/actions';
import linksActions from 'store/resources/sharedLinksV2/actions';

const useResetSharedFiles = () => {
  const dispatch = useDispatch();

  const handleDestroySharedFiles = useCallback(async () => {
    await dispatch(photosActions.resourceResetSuccess());
    await dispatch(videosActions.resourceResetSuccess());
    await dispatch(filesActions.resourceResetSuccess());
    await dispatch(linksActions.resourceResetSuccess());
  }, []);

  return handleDestroySharedFiles;
};

export default useResetSharedFiles;
