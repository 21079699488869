import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';
import Indent from 'components/reusable/indent';

const Wrapper = styled(Flex)`
  width: 100%;
  display: none;

  @media (max-width: 768px) {
    height: 48px;
    max-height: 48px;
    display: flex;
    justify-content: flex-start;
    padding: 0 16px;
  }
`;

const GuestOnboradingHeaderMobile = ({ currentStep }) => {
  if (!currentStep) return null;
  const { index, label } = currentStep;

  return (
    <Wrapper>
      <Text size={14} weight={500} color={colors.primary[500]}>
        0{index + 1}.&nbsp;
      </Text>

      <Indent right={6} />

      <Text size={14} weight={500} color={colors.gray[700]}>
        {label}
      </Text>
    </Wrapper>
  );
};

export default GuestOnboradingHeaderMobile;
