import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import useTimePicker from './hooks/use_time_picker';

const WrapperAMPM = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  border-radius: 20px;
  padding: 4px;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid ${colors.gray[100]};
`;

const AMPM = styled.div`
  width: 46px;
  padding: 7px;
  color: ${({ isSelected }) => (isSelected ? colors.light : colors.gray[700])};
  background-color: ${({ isSelected }) =>
    isSelected ? colors.gray[600] : 'transparent'};
  border-radius: 14px;
  display: flex;
  align-items: center;
  line-height: 14px;
  font-family: Gotham Pro;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
`;

const AmPm = ({ onChange, value }) => {
  const invalidValue =
    !value || typeof value !== 'string' || !value.includes(':');
  if (invalidValue) return null;

  const { isPmSelected, buildOnChangeAmPm } = useTimePicker({
    value,
    onChange
  });

  return (
    <WrapperAMPM>
      <AMPM onClick={buildOnChangeAmPm(true)} isSelected={!isPmSelected}>
        AM
      </AMPM>
      <AMPM onClick={buildOnChangeAmPm(false)} isSelected={isPmSelected}>
        PM
      </AMPM>
    </WrapperAMPM>
  );
};

export default AmPm;
