import styled from 'styled-components';

import Button from 'components/reusable/button';

export const Wrapper = styled.div`
  position: relative;
`;

export const CloseBtn = styled(Button)`
  position: absolute;
  right: 0;
  top: 24px;
  z-index: 1;
  transform: translate(-20px, -50%);
`;
