import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';

import { colors } from 'helpers/colors';

const Wrapper = styled.div`
  position: relative;
  width: ${({ $width }) => $width};
`;

const InputComponent = styled.textarea`
  resize: none;
  height: 23vh;
  font-family: Gotham Pro;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 12px 16px;
  border-radius: 20px;
  -webkit-appearance: none;
  box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.48);
  font-size: 1rem;
  color: ${colors.gray[700]};
  border: none;
  ::placeholder {
    font-family: Gotham Pro;
    color: ${colors.gray[300]};
  }

  ${props =>
    props.directBooking &&
    css`
      padding: 12px 16px;
      border-radius: 20px;
      border: 1px solid #b4b1b1;
      ::placeholder {
        color: #d0cece;
        font-size: 1rem;
      }
      &:focus {
        border: 1px solid #d9d9d9;
        box-shadow: 0px 0px 4px rgba(31, 30, 30, 0.4);
      }
      &:focus-visible {
        outline: none;
      }
    `}
`;

const TextArea = ({
  value,
  onChange,
  width,
  placeholder,
  error,
  type,
  children,
  ...props
}) => {
  const handleChange = useCallback(e => {
    let v = e.target.value;
    onChange(v);
  }, []);

  return (
    <Wrapper $width={width} className="text-area__container">
      <InputComponent
        className="text-area__input"
        type={type}
        placeholder={placeholder}
        onChange={handleChange}
        value={value}
        {...props}
      />
      {children}
    </Wrapper>
  );
};

export default TextArea;
