import { colors } from 'helpers/colors';
import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 550px;
  height: 550px;
  max-width: 65vh;
  max-height: 65vh;
  box-shadow: 0px 0px 8px rgba(31, 30, 30, 0.08);
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;

  @media (max-width: 768px) {
    width: 100%;
    max-width: unset;
    height: auto;
  }

  ${props => props.css}
`;

export const Footer = styled.div`
  margin-top: -30px;
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-top: 0;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 24px;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${colors.gray[100]};

  .croppie-container {
    margin: 0;
  }
  .cr-slider-wrap {
    margin: 20px auto !important;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const FileInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
`;

export const OrWrapper = styled.div`
  width: 40px;
  height: 40px;
  border: 1px solid ${colors.gray[100]};
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(-50%);
  z-index: 1;
  background-color: ${colors.light};
`;

export const UnderPictureWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px 8px;
  width: 100%;
`;
