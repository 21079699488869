import React from 'react';

import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import CustomSelect from '../../CustomSelect';
import useCountryCodes from './hooks/use_country_codes';
import { colors } from 'helpers/colors';
import {
  PhoneBlock,
  Select,
  StyledCustomInput,
  Flag,
  ValueWrapper,
  Container,
  Fade
} from '../phone_v1/styled';
import useMobileDetect from 'components/reusable/input/components/Phone/phone_v1/hooks/use_mobile_detect';

const Phone = ({ onChange, value, name, error, textAlign }) => {
  const { code, phone } = value;
  const { countries, defaultPhone, defaultCountry } = useCountryCodes(
    code,
    phone
  );

  const handleTargetChange = e => {
    let value = { phone, code };
    if (e.includes('+')) {
      value.code = e.replaceAll('+', '');
      value.phone = defaultPhone;
    } else {
      value.phone = e;
      value.code = defaultCountry
        ? defaultCountry.prefix.replaceAll('+', '')
        : '';
    }

    onChange({
      target: {
        name: 'phone_country_code',
        value: value.code
      }
    });
    onChange({
      target: {
        name: 'phone_number',
        value: value.phone
      }
    });
  };

  return (
    <PhoneBlock>
      <Select>
        <PrefixSelect
          value={defaultCountry || { prefix: '' }}
          onChange={handleTargetChange}
          countries={countries}
          error={error}
        />
      </Select>

      <StyledCustomInput
        type="input"
        name={name}
        placeholder="Enter phone number"
        value={defaultPhone}
        error={error}
        textAlign={textAlign}
        onChange={e => handleTargetChange(e.target.value)}
        autoComplete="tel-national"
      />
    </PhoneBlock>
  );
};

export default Phone;

export const PrefixSelect = ({ value, onChange, countries, error }) => {
  const isMobile = useMobileDetect();
  return (
    <CustomSelect
      options={countries}
      getOptionLabel={el => (
        <Container>
          <Flag src={el.flag} width="24" height="18" alt={el.code} />
          <Indent right={10} />
          <Text size="1rem">{el.prefix}</Text>
          {isMobile && <Text size="1rem">&nbsp; {'(' + el.name + ')'}</Text>}
        </Container>
      )}
      value={
        value && value.prefix !== '' ? (
          <Container>
            <Flag src={value.flag} width="24" height="18" alt={value.code} />
            <Indent right={10} />
            <ValueWrapper>
              <Text size="1rem">{value.prefix}</Text>
              {isMobile && (
                <Text size="1rem">&nbsp; {'(' + value.name + ')'}</Text>
              )}
            </ValueWrapper>

            <Fade />
          </Container>
        ) : (
          <Container>
            <Text size="1rem" color={colors.gray[300]}>
              Code
            </Text>
          </Container>
        )
      }
      onChange={onChange}
      error={error}
    />
  );
};
