import styled from 'styled-components';

import CustomInput from 'components/reusable/input/components/CustomInput';
import Image from 'components/reusable/image';
import Flex from 'components/reusable/flex';

export const PhoneBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;

export const ValueWrapper = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Select = styled.div`
  width: 35%;
  @media screen and (max-width: 500px) {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const StyledCustomInput = styled(CustomInput)`
  width: 62%;
  @media screen and (max-width: 500px) {
    width: 100%;
  }
`;

export const Flag = styled(Image)`
  border-radius: 15%;
  object-fit: unset;
`;

export const Container = styled(Flex)`
  position: relative;
  width: 100%;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: clip;
`;

export const Fade = styled.div`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 59%,
    rgba(255, 255, 255, 1) 83%
  );
  position: absolute;
  @media (max-width: 500px) {
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 75%,
      rgba(255, 255, 255, 1) 83%
    );
  }
`;
