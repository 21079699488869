import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import { NavLink, LinkText } from '../../styled';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Link from 'components/reusable/link';

const Container = styled.div`
  position: relative;
`;

const NotificationContainer = styled(Flex)`
  position: absolute;
  top: -5px;
  left: 100%;
  @media (max-width: 1380px) {
    top: -3px;
    left: 60%;
  }
`;

const NotificationDot = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${colors.primary[500]};
  @media (max-width: 1380px) {
    width: 10px;
    height: 10px;
    border: 2px solid ${colors.light};
  }
`;

const ButtonLink = styled(Flex)`
  cursor: pointer;
  transition: all 0.3s;
  svg {
    display: none;
  }
  @media (max-width: 1380px) {
    svg {
      display: block;
      ${({ useCssColors }) =>
        useCssColors
          ? `
          fill: ${({ active }) => {
            return active ? colors.primary[500] : colors.gray[600];
          }} !important`
          : ''}
    }
    ${({ useCssColors }) => {
      return useCssColors
        ? `
          &:hover svg path {
            fill: ${colors.primary[500]} !important;
          }
        `
        : '';
    }}
    p {
      display: ${({ hamburger }) => (hamburger ? 'block' : 'none')};
      margin-top: ${({ hamburger }) => hamburger && '3px'};
    }
  }
`;

/**
 * @deprecated use header_button instead
 * @param action
 * @param label
 * @param path
 * @param href
 * @param notifications
 * @param active
 * @param icon
 * @param hamburger
 * @param useCssColors
 * @returns {JSX.Element}
 * @constructor
 */
const HeaderButton = ({
  action,
  label,
  path,
  href,
  notifications,
  active,
  icon,
  hamburger,
  useCssColors = true
}) => {
  const hasNotifications = !!notifications && notifications > 0;

  const renderLink = () => (
    <NavLink to={path} hamburger={hamburger}>
      {icon()}
      {hamburger && <Indent right={8} />}
      <Text weight={500} size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}>
        {label}
      </Text>
    </NavLink>
  );

  const renderExtLink = () => (
    <Link href={href}>
      {icon()}
      {hamburger && <Indent right={8} />}
      <Text weight={500} size={[{ maxWidth: 768, size: 14 }, { size: 16 }]}>
        {label}
      </Text>
    </Link>
  );

  const renderText = () => (
    <ButtonLink
      jc="flex-start"
      active={active}
      hamburger={hamburger}
      useCssColors={useCssColors}
    >
      {icon()}
      {hamburger && <Indent right={8} />}
      <LinkText weight={500} size={[{ maxWidth: 768, size: 14 }, { size: 16 }]} active={active}>
        {label}
      </LinkText>
    </ButtonLink>
  );

  return (
    <Container onClick={action}>
      {hasNotifications && (
        <NotificationContainer wrap="nowrap">
          <NotificationDot />
          <Indent right={4} />
          <Text size={12} color={colors.primary[500]}>
            {notifications}
          </Text>
        </NotificationContainer>
      )}
      {path ? renderLink() : href ? renderExtLink() : renderText()}
    </Container>
  );
};

export default HeaderButton;
