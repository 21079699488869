import Settings from 'helpers/settings';

const SCLogger = {
  log(message) {
    if (Settings.LOGGING) {
      console.log(message);
    }
  }
};

export default SCLogger;
