import React from 'react';
import debounce from 'lodash.debounce';

const $ = window.jQuery;

class ImageWrapper extends React.Component {
  static defaultProps = {
    load_s: true
  };

  constructor(props) {
    super(props);
    this.imageWrapper = null;
  }

  state = {
    useFallback: false
  };

  componentDidMount() {
    this.resizeContainer();
    $(window).resize(debounce(this.resizeContainer, 500));
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      useFallback: false
    });
  }

  componentDidUpdate() {
    this.resizeContainer();
  }

  resizeWidthGivenHeightAndRatio = () => {
    if (!this.imageWrapper) return;

    const wrapper = this.imageWrapper;
    const ch = $(wrapper).height();
    $(wrapper).css({
      width: `${ch / this.props.ratio}px`
    });
  };

  resizeContainer = () => {
    if (!this.imageWrapper) return;

    const wrapper = this.imageWrapper;
    if (this.props.width) {
      $(wrapper).css({
        width: this.props.width
      });
    }
    if (this.props.height) {
      $(wrapper).css({
        height: this.props.height
      });
    }
    if (this.props.ratio) {
      if (this.props.height) {
        $(wrapper).css({
          height: this.props.height
        });
        setTimeout(() => {
          this.resizeWidthGivenHeightAndRatio();
        }, 1);
      } else {
        const cw = $(wrapper).width();
        $(wrapper).css({
          height: `${cw * this.props.ratio}px`
        });
      }
    }
  };

  getImageURL = () => {
    if (!this.props.src) {
      this.updateWithFallback();
      return;
    }
    if (this.props.width) {
      return this.props.src.replace(
        '/upload/',
        `/upload/c_scale,w_${this.props.width}/`
      );
    }

    return this.props.src;
  };

  updateWithFallback = () => {
    this.setState({
      useFallback: true
    });
  };

  render() {
    const onClick = this.props.onClick ? this.props.onClick : () => {};

    return (
      <div
        role="button"
        tabIndex={0}
        ref={wrapper => {
          this.imageWrapper = wrapper;
        }}
        onClick={onClick}
        className={`image_wrapper ${this.props.className || ''}`}
        style={
          this.state.useFallback
            ? this.props.style
            : Object.assign(
                {
                  backgroundPosition: 'center',
                  backgroundSize: 'cover',
                  backgroundImage: `url(${this.getImageURL()})`,
                  backgroundPositionY: '0px'
                },
                this.props.style
              )
        }
      >
        {this.state.useFallback ? (
          <div className="fallback_container">
            {this.props.load_s ? (
              <div className="fallback_circle">
                <p className="fallback_s">S</p>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          // Really hacky, good chance the image is being loaded twice.
          // Just need something to trigger the fallback event
          <img
            alt=""
            style={{
              width: '0px',
              height: '0px'
            }}
            src={this.getImageURL()}
            onError={this.updateWithFallback}
          />
        )}
      </div>
    );
  }
}

export default ImageWrapper;
