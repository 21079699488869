import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileMp4Outline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 1V5C12 5.26522 12.1054 5.51957 12.2929 5.70711C12.4804 5.89464 12.7348 6 13 6H17M12 1H5C4.46957 1 3.96086 1.21071 3.58579 1.58579C3.21071 1.96086 3 2.46957 3 3V10M12 1L17 6M17 6V10M9.5 16H11C11.3978 16 11.7794 15.842 12.0607 15.5607C12.342 15.2794 12.5 14.8978 12.5 14.5C12.5 14.1022 12.342 13.7206 12.0607 13.4393C11.7794 13.158 11.3978 13 11 13H9.5V19M1.5 19V13L4 16L6.5 13V19M15 13V15.25C15 15.4489 15.079 15.6397 15.2197 15.7803C15.3603 15.921 15.5511 16 15.75 16H18M18 13V19"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileMp4Outline;
