import get from 'lodash.get';
import { validateEmail, validatePassword } from 'helpers/validation_helper';

export const validate = values => {
  const errors = {};
  const validations = [
    {
      field: 'first_name',
      condition: get(values, 'first_name', '').trim() === '',
      message: 'Please provide a valid first name'
    },
    {
      field: 'last_name',
      condition: get(values, 'last_name', '').trim() === '',
      message: 'Please provide a valid last name'
    },
    {
      field: 'email',
      condition: !validateEmail(values.email),
      message: 'Please provide a valid email'
    },
    {
      field: 'password',
      condition: !validatePassword(values.password),
      message: 'Password must include 8 symbols, at least 1 letter and 1 number'
    }
  ];

  validations.forEach(v => {
    if (v.condition) errors[v.field] = v.message;
  });

  return errors;
};
