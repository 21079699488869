import React, { useMemo } from 'react';

import { colors } from 'helpers/colors';
import {
  DefaultAvatarWrapper,
  DefaultAvatarInnerWrapper,
  DefaultAvtarText
} from 'components/complex/user/avatar/styled';

function DefaultAvatar({ width, firstName, lastName, className }) {
  const name = useMemo(() => {
    if (firstName[0] || lastName[0])
      return `${firstName[0]}${lastName[0]}`.trim().toUpperCase();
    return 'SC';
  }, [firstName, lastName]);

  if (!width) return null;

  return (
    <DefaultAvatarWrapper width={width} className={className}>
      <DefaultAvatarInnerWrapper width={width}>
        <DefaultAvtarText size={width / 3.25} color={colors.light}>
          {name}
        </DefaultAvtarText>
      </DefaultAvatarInnerWrapper>
    </DefaultAvatarWrapper>
  );
}

export default DefaultAvatar;
