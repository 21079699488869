import React, { useCallback } from 'react';
import Button from 'components/reusable/button';
import { colors } from 'helpers/colors';

// Dont move svg to svgIcons since those are not going to be reusable
const UncheckedComponent = ({ width }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 0.65C13.6098 0.65 17.35 4.39023 17.35 9C17.35 13.6098 13.6098 17.35 9 17.35C4.39023 17.35 0.65 13.6098 0.65 9C0.65 4.39023 4.39023 0.65 9 0.65Z"
      stroke={colors.gray[300]}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
  </svg>
);

// Dont move svg to svgIcons since those are not going to be reusable
const CheckedComponent = ({ width }) => (
  <svg
    width={width}
    height={width}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(3,3)">
      <path
        d="M6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12Z"
        fill={colors.primary[500]}
      />
    </g>
    <path
      d="M9 0.65C13.6098 0.65 17.35 4.39023 17.35 9C17.35 13.6098 13.6098 17.35 9 17.35C4.39023 17.35 0.65 13.6098 0.65 9C0.65 4.39023 4.39023 0.65 9 0.65Z"
      stroke={colors.gray[300]}
      strokeWidth="1.3"
      strokeMiterlimit="10"
    />
  </svg>
);

const Radio = ({ value, onChange, checked: isChecked, width = 18 }) => {
  const componentParams = { width: width.toString() };
  const Component = isChecked ? CheckedComponent : UncheckedComponent;

  const handleChange = useCallback(() => {
    if (!onChange || isChecked) return;
    onChange(value);
  }, [onChange, value, isChecked]);

  return (
    <Button onClick={handleChange} type="semantic-wrapper">
      <Component {...componentParams} />
    </Button>
  );
};

export default Radio;
