import React from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import Text from 'components/reusable/text';
import ChatSharedGridItem from 'components/modals/v2/chat_shared_modal/components/chat_shared_grid_item';
import InfiniteScroll from 'components/complex/infinite_scroll/infinite_scroll';
import ApplicationHelper from 'helpers/application_helper';

const ITEMS_IN_GRID_L = 4;
const ITEMS_IN_GRID_M = 2;
const ITEMS_IN_GRID = ApplicationHelper.isMobile ? ITEMS_IN_GRID_M : ITEMS_IN_GRID_L;

function ChatSharedGridGroup({
  handleOpenAlbum,
  pagination: _pagination,
  messageType,
  messages: _messages,
  label,
  isSkeleton = false
}) {
  const pagination = isSkeleton ? {} : _pagination;
  const messages = isSkeleton ? new Array(ITEMS_IN_GRID * 2).fill({}) : _messages;

  if (!isSkeleton && !messages.length) return null;

  return (
    <GroupWrapper
      key={label}
      // Desktop has a separate overlay while MR use the root overlay
      ref={ApplicationHelper.isMobile ? { current: document.getElementById('modal__overlay') } : undefined}
      className="chat__shared-grid-group"
      loadMore={pagination.handleAppendFiles}
      hasMore={pagination.hasMoreFiles}
    >
      {isSkeleton ? (
        <Skeleton width={100} height={16} />
      ) : (
        <Text size={[{ size: 12, maxWidth: 768 }, { size: 16 }]}>{label}</Text>
      )}

      <GridWrapper className="chat__shared-grid">
        {messages.map((message, i) => {
          if (!['video', 'image'].includes(messageType)) return null;

          if (isSkeleton) return <ChatSharedGridItem key={i} isSkeleton={isSkeleton} messageType={messageType} />;
          return (
            <ChatSharedGridItem
              onOpenAlbum={handleOpenAlbum}
              key={message.id}
              message={message}
              isSkeleton={isSkeleton}
              messageType={messageType}
            />
          );
        })}
      </GridWrapper>
    </GroupWrapper>
  );
}

const GroupWrapper = styled(InfiniteScroll)`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  > :first-child {
    margin-left: 16px;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${ITEMS_IN_GRID}, 1fr);
  gap: 6px;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export default ChatSharedGridGroup;
