import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Input from 'components/reusable/input';
import Button from 'components/reusable/button';
import { RootContext } from 'contexts/contexts';
import ApplicationHelper from 'helpers/application_helper';

const Wrapper = styled(Flex)`
  max-width: 440px;
  width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

const ButtonWrap = styled(Flex)`
  cursor: pointer;
`;

const RetryPaymentModal = ({ onClose, modalProps }) => {
  const { isLoading } = useContext(RootContext);
  const [selectedSource, setSelectedSource] = useState(
    modalProps?.stripeSources[0]
  );

  const handleSourceChange = source => () => {
    setSelectedSource(source);
  };

  const handleSubmit = useCallback(() => {
    modalProps?.retryPayment(modalProps?.jobId, selectedSource.id);
  }, [selectedSource, modalProps]);

  return (
    <Wrapper dir="column">
      <Text
        size={ApplicationHelper.isMobile ? 18 : 24}
        weight={500}
        textTransform="uppercase"
        textAlign="center"
      >
        Select a Credit Card
      </Text>
      <Indent bottom={40} />

      <Flex dir="column" gap={8} width="100%">
        {modalProps.stripeSources.map(stripeSource => (
          <ButtonWrap
            key={stripeSource.id.slice(10)}
            gap={8}
            jc="flex-start"
            width="100%"
            onClick={handleSourceChange(stripeSource)}
          >
            <Input
              type="radio"
              checked={stripeSource.id === selectedSource.id}
            />
            <Text size={ApplicationHelper.isMobile ? 14 : 16}>
              {stripeSource.brand} ending in {stripeSource.last4}
            </Text>
          </ButtonWrap>
        ))}
      </Flex>
      <Indent bottom={40} />

      <Button
        type="primary"
        height={40}
        width="100%"
        onClick={handleSubmit}
        isDisabled={isLoading}
      >
        Retry Payment
      </Button>
      <Indent bottom={16} />

      <Button
        color={colors.gray[600]}
        borderColor="transparent"
        height={40}
        width="100%"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Wrapper>
  );
};

export default RetryPaymentModal;
