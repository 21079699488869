import React from 'react';

function SvgWhatsAppSolid({ size = 30 }) {
  return (
    <svg
      width={size}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.9034 0.666504C4.81008 0.666504 4.62345 0.666504 4.45547 0.666504C4.0822 0.685168 3.59695 0.703831 3.39165 0.759822C3.0557 0.834477 2.73842 0.927795 2.47712 1.05844C2.17851 1.20775 1.89855 1.41305 1.65592 1.65568C1.4133 1.89831 1.22666 2.1596 1.05869 2.47688C0.92804 2.73817 0.816057 3.05545 0.760066 3.37274C0.722739 3.5967 0.685412 4.08196 0.666748 4.45523C0.666748 4.60454 0.666748 4.80984 0.666748 4.90316V13.0965C0.666748 13.1898 0.666748 13.3765 0.666748 13.5444C0.685412 13.9177 0.704075 14.403 0.760066 14.6083C0.834721 14.9442 0.92804 15.2615 1.05869 15.5228C1.20799 15.8214 1.4133 16.1014 1.65592 16.344C1.89855 16.5866 2.15984 16.7733 2.47712 16.9412C2.73842 17.0719 3.0557 17.1839 3.37298 17.2399C3.59695 17.2772 4.0822 17.3145 4.45547 17.3332C4.60478 17.3332 4.81008 17.3332 4.9034 17.3332H13.0968C13.1901 17.3332 13.3767 17.3332 13.5447 17.3332C13.918 17.3145 14.4032 17.2958 14.6085 17.2399C14.9445 17.1652 15.2617 17.0719 15.523 16.9412C15.8217 16.7919 16.1016 16.5866 16.3442 16.344C16.5869 16.1014 16.7735 15.8401 16.9415 15.5228C17.0721 15.2615 17.1841 14.9442 17.2401 14.6269C17.2774 14.403 17.3148 13.9177 17.3334 13.5444C17.3334 13.3951 17.3334 13.1898 17.3334 13.0965V4.90316C17.3334 4.80984 17.3334 4.6232 17.3334 4.45523C17.3148 4.08196 17.2961 3.5967 17.2401 3.3914C17.1654 3.05545 17.0721 2.73817 16.9415 2.47688C16.7922 2.17826 16.5869 1.89831 16.3442 1.65568C16.1016 1.41305 15.8403 1.22641 15.523 1.05844C15.2617 0.927795 14.9445 0.815813 14.6272 0.759822C14.4032 0.722495 13.918 0.685168 13.5447 0.666504C13.3954 0.666504 13.1901 0.666504 13.0968 0.666504H4.9034Z"
        fill="url(#paint0_linear_18447_10080)"
      />
      <path
        d="M12.0329 9.88657C11.8836 9.81192 11.137 9.45731 11.0064 9.40132C10.8757 9.34533 10.7637 9.32666 10.6704 9.47597C10.5771 9.62528 10.2785 9.96123 10.2038 10.0545C10.1105 10.1479 10.0358 10.1665 9.88654 10.0919C9.73723 10.0172 9.25197 9.86791 8.6734 9.36399C8.22547 8.97205 7.92685 8.4868 7.83353 8.33749C7.74022 8.18818 7.83353 8.11353 7.90819 8.03887C7.98284 7.96422 8.0575 7.8709 8.13215 7.77758C8.20681 7.68426 8.22547 7.62827 8.28146 7.53495C8.33745 7.44163 8.30013 7.34831 8.2628 7.27366C8.22547 7.19901 7.92685 6.47112 7.79621 6.1725C7.66556 5.89255 7.55358 5.92987 7.46026 5.91121C7.36694 5.91121 7.27362 5.91121 7.18031 5.91121C7.08699 5.91121 6.91901 5.94854 6.78837 6.09785C6.65772 6.24716 6.26579 6.60177 6.26579 7.32965C6.26579 8.05753 6.80703 8.74809 6.88169 8.86007C6.95634 8.95339 7.94552 10.4465 9.43861 11.0811C9.79322 11.2304 10.0732 11.3237 10.2971 11.3983C10.6518 11.5103 10.9877 11.4917 11.249 11.4543C11.5289 11.417 12.1448 11.0997 12.2568 10.7638C12.3875 10.4278 12.3875 10.1292 12.3501 10.0732C12.2755 9.99856 12.1822 9.96123 12.0329 9.88657ZM9.17732 13.7313C8.28146 13.7313 7.42293 13.4887 6.65772 13.0407L6.47109 12.9288L4.60472 13.414L5.10864 11.6036L4.99666 11.417C4.5114 10.6331 4.25011 9.73727 4.25011 8.80408C4.25011 6.09785 6.47109 3.89553 9.19598 3.89553C10.4838 3.89553 11.7342 4.39945 12.6674 5.33264C13.6006 6.26582 14.1045 7.49762 14.1045 8.80408C14.1045 11.5103 11.8836 13.7313 9.17732 13.7313ZM13.3766 4.62342C12.2568 3.5036 10.7637 2.8877 9.17732 2.8877C5.91118 2.8877 3.24227 5.53794 3.24227 8.80408C3.24227 9.84925 3.52223 10.8571 4.02614 11.7529L3.18628 14.8138L6.32178 13.9926C7.18031 14.4592 8.16948 14.7205 9.15866 14.7205C12.4248 14.7205 15.0937 12.0702 15.0937 8.80408C15.1124 7.23633 14.4965 5.74324 13.3766 4.62342Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_18447_10080"
          x1="9.0068"
          y1="0.672476"
          x2="9.0068"
          y2="17.3197"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#62FA7F" />
          <stop offset="0.6865" stopColor="#22CC40" />
          <stop offset="1" stopColor="#05B723" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default SvgWhatsAppSolid;
