import React from 'react';

import { colors } from 'helpers/colors';

function SvgExitOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60501 3.62717C4.34567 3.62717 4.09695 3.73019 3.91357 3.91357C3.73019 4.09695 3.62717 4.34567 3.62717 4.60501V15.395C3.62717 15.6543 3.73019 15.903 3.91357 16.0864C4.09695 16.2698 4.34567 16.3728 4.60501 16.3728H7.68786C7.99912 16.3728 8.25145 16.6252 8.25145 16.9364C8.25145 17.2477 7.99912 17.5 7.68786 17.5H4.60501C4.04673 17.5 3.51131 17.2782 3.11654 16.8835C2.72178 16.4887 2.5 15.9533 2.5 15.395V4.60501C2.5 4.04673 2.72178 3.51131 3.11654 3.11654C3.51131 2.72178 4.04673 2.5 4.60501 2.5H7.68786C7.99912 2.5 8.25145 2.75233 8.25145 3.06358C8.25145 3.37484 7.99912 3.62717 7.68786 3.62717H4.60501ZM12.6843 5.74792C12.9044 5.52783 13.2613 5.52783 13.4814 5.74792L17.3349 9.60149C17.555 9.82158 17.555 10.1784 17.3349 10.3985L13.4814 14.2521C13.2613 14.4722 12.9044 14.4722 12.6843 14.2521C12.4642 14.032 12.4642 13.6751 12.6843 13.4551L15.5758 10.5636H7.68786C7.3766 10.5636 7.12428 10.3113 7.12428 10C7.12428 9.68874 7.3766 9.43642 7.68786 9.43642H15.5758L12.6843 6.54495C12.4642 6.32486 12.4642 5.96801 12.6843 5.74792Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgExitOutline;
