import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { routes } from 'helpers/router_helper';

const useGuestPostingSequence = () => {
  const history = useHistory();
  const { pathname } = history.location;

  const guestSequence = useMemo(() => {
    const accountActive = [
      routes.createProject.redirectUrl(),
      routes.createProjectSuccess.redirectUrl(),
      routes.guestSignUp.redirectUrl()
    ].includes(pathname);

    const isPosting = [
      routes.createProject.redirectUrl(),
      routes.createProjectSuccess.redirectUrl()
    ].includes(pathname);

    const isPostingSuceeded = [
      routes.createProjectSuccess.redirectUrl()
    ].includes(pathname);

    return [
      { label: 'SET UP AN ACCOUNT', active: accountActive },
      { label: 'POST YOUR JOB', active: isPosting },
      { label: 'INVITE FRIENDS', active: isPostingSuceeded }
    ];
  }, [pathname]);

  return guestSequence;
};

export default useGuestPostingSequence;
