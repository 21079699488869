import React from 'react';
import styled from 'styled-components';

import Image from 'components/reusable/image';

function ProfileAlbumImage({ src }) {
  return <StyledImage src={src} />;
}

const StyledImage = styled(Image)`
  display: flex;
  max-width: 100%;
  max-height: 100%;
`;

export default ProfileAlbumImage;
