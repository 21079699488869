import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { getDimention } from 'helpers/style';
import useImageURL from 'components/reusable/image/hooks/use_image_url';
import useImageAlt from 'components/reusable/image/hooks/use_image_alt';
import ImageFallback from 'components/reusable/image/components/image_fallback';

const ImageComponent = styled(({ isIcon, ...props }) => (
  <LazyLoadImage {...props} />
))`
  object-fit: cover;
  object-position: top;
  ${({ ratio }) => ratio && `aspect-ratio: ${ratio};`}
  ${({ isIcon }) =>
    isIcon
      ? css`
          cursor: pointer;
        `
      : undefined}
  ${({ width }) =>
    css`
      width: ${getDimention(width)};
      min-width: ${getDimention(width)};
    `};
  ${({ height }) =>
    css`
      height: ${getDimention(height)};
    `};
  ${({ br }) =>
    css`
      border-radius: ${getDimention(br)};
    `};
`;

const Image = ({
  initialAlt,
  src,
  width, // CDN config
  height,
  br,
  onClick,
  ratio,
  ...props
}) => {
  const [secondTry, setSecondTry] = useState(!src);
  const [isError, setError] = useState(!src);
  const alt = useImageAlt({ initialAlt, src });
  const imageURL = useImageURL({ secondTry, src, setError, width });
  const isIcon = useMemo(() => src && src.includes('.svg'), [src]);

  const handledHeight = useMemo(() => {
    if (ratio && width) return width / ratio;
    if (height) return height;
  }, [ratio, height, width]);

  useLayoutEffect(() => {
    const isError = !src;
    setError(isError);
  }, [src]);

  const commonProps = {
    isIcon,
    onClick,
    br,
    height: handledHeight,
    width,
    ratio
  };

  if (isError && secondTry)
    return <ImageFallback {...props} {...commonProps} />;

  return (
    <ImageComponent
      alt={alt}
      src={imageURL}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        if (secondTry) {
          setError(true);
        } else {
          if (!width) setError(true);
          setSecondTry(true);
        }
      }}
      {...props}
      {...commonProps}
    />
  );
};

export default Image;
