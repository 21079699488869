import React from 'react';
import { NavLink as BaseNavLink } from 'react-router-dom';

import Indent from 'components/reusable/indent';
import HeaderSearch from 'components/reusable/header/components/default_header/components/header_search/header_search';
import styled from 'styled-components';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import { colors } from 'helpers/colors';

const LeftSide = styled(Flex)`
  transition: all 0.3s;
`;

const Logo = styled(Text)`
  cursor: pointer;
  letter-spacing: 0.5em;
  transition: all 0.5s;
  font-size: 16px;
  @media (hover: hover) {
    &:hover {
      color: ${colors.primary[500]};
    }
  }
  @media (max-width: 768px) {
    font-size: 14px;
    letter-spacing: 0.5em;
  }
`;

function HeaderLeftItems(props) {
  const {
    closeHamburger,
    isSearchVisible,
    handleSearchChange,
    searchResult,
    cancelSearch,
    searchActive,
    isModalVisible,
    setModalVisible
  } = props;

  return (
    <LeftSide dir="row" wrap="nowrap">
      <BaseNavLink to="/" onClick={closeHamburger}>
        <Logo weight={500} textTransform="uppercase">
          Swipecast
        </Logo>
      </BaseNavLink>
      <Indent right={isSearchVisible ? 20 : 0} />

      {isSearchVisible && (
        <HeaderSearch
          onChange={handleSearchChange}
          searchResult={searchResult}
          cancelSearch={cancelSearch}
          active={searchActive}
          isModalsVisible={isModalVisible}
          setNModalVisible={setModalVisible}
        />
      )}
    </LeftSide>
  );
}

export default HeaderLeftItems;