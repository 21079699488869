import React, { forwardRef } from 'react';

import TextInput from './components/TextInput';
import TimeInput from './components/TimeInput';
import MonthCalendarInput from './components/MonthCalendarInput';
import Select from './components/Select';
import LocationInput from './components/LocationInput';
import ImageInput from './components/ImageInput';
import TextAreaInput from './components/TextArea';
import Checkbox from './components/Checkbox';
import Autocomplete from './components/Autocomplete';
import Radio from 'components/reusable/input/components/Radio';
import Phone from 'components/reusable/input/components/Phone/phone_v2';
import DatePicker from 'components/reusable/input/components/DatePicker';
import MoneyInput from 'components/reusable/input/components/MoneyInput';

const inputs = {
  time: TimeInput,
  date: DatePicker,
  month_calendar: MonthCalendarInput,
  select: Select,
  location: LocationInput,
  image: ImageInput,
  textarea: TextAreaInput,
  checkbox: Checkbox,
  autocomplete: Autocomplete,
  phone: Phone,
  radio: Radio,
  money: MoneyInput
};

const Input = forwardRef(({ type, ...props }, forwardedRef) => {
  if (!type || ['text', 'password'].includes(type))
    return <TextInput type={type} {...props} />;
  const Component = inputs[type];

  if (!Component) {
    console.error('Component is missing for this type');
    return null;
  }

  return <Component ref={forwardedRef} type={type} {...props} />;
});

export default Input;
