import React from 'react';
import styled from 'styled-components';

import ApplicationHelper from 'helpers/application_helper';
import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import DefaultBlock from './components/default_block';
import useContentMapping from './hooks/use_content';

const Container = styled(Flex)`
  z-index: 1;
  padding: 0 40px 34px 40px;
  background-color: ${colors.gray[700]};
  @media (max-width: 768px) {
    padding: 0 16px 18px 16px;
  }
`;

const Footer = () => {
  const footerContentMapping = useContentMapping();
  const noFooterObj = { hasMainBlock: false };
  const getFooterContent = () =>
    footerContentMapping.find(f => f.condition) || noFooterObj;

  const content = getFooterContent();
  const ExtraBlock = content ? content.extraBlock : null;
  const hasExtraBlock = !!ExtraBlock;

  if (!content) return null;
  if (!content.hasMainBlock) return null;

  return (
    <Container dir="col">
      {hasExtraBlock ? (
        <ExtraBlock
          isMobile={ApplicationHelper.isMobile}
          page={content.pageLabel}
        />
      ) : null}
      <DefaultBlock hasExtraBlock={hasExtraBlock} page={content.pageLabel} />
    </Container>
  );
};

export default Footer;
