const StatHelpers = {
  renderStat(input, display_type) {
    var output = '';
    if (display_type === 'ftIn') {
      var output_feet = (input - (input % 12)) / 12;
      var output_inches = input % 12;
      output = output_feet + "' " + output_inches + '"';
    } else if (display_type === 'USEU') {
      var us_value = input % 1 === 0 ? parseInt(input).toString() : input;

      output = us_value + '    ' + (parseFloat(input) + 30.5) + 'EU';
    } else if (display_type === 'inCm') {
      output = input % 1 === 0 ? parseInt(input).toString() + '"' : input + '"';
    } else if (display_type === 'asIs') {
      output = input;
    } else if (display_type === 'abbr') {
      output = StatHelpers.abbrNumber(input, 0);
    }
    return output;
  },
  inchesToFeet(inches) {
    if (!inches) return null;
    var feet;
    inches = parseInt(inches);
    feet = Math.floor(inches / 12);
    inches = inches % 12;
    return feet + "'" + inches + '"';
  },
  abbrNumber(number, decPlaces) {
    var abbrev, i, size;
    decPlaces = Math.pow(10, decPlaces);
    abbrev = ['K', 'M', 'B', 'T'];
    i = abbrev.length - 1;
    while (i >= 0) {
      size = Math.pow(10, (i + 1) * 3);
      if (size <= number) {
        number = Math.round((number * decPlaces) / size) / decPlaces;
        if (number === 1000 && i < abbrev.length - 1) {
          number = 1;
          i++;
        }
        number += abbrev[i];
        break;
      }
      i--;
    }
    return number;
  }
};

export default StatHelpers;
