import { JobActionTypes } from 'helpers/constants/action_types/job';

const initialState = {
  data: null,
  isLoading: false,
  lastAction: ''
};

export const jobReducer = (state = initialState, action) => {
  const newState = {
    ...state,
    lastAction: action.type
  };

  switch (action.type) {
    case JobActionTypes.FETCH:
      return { ...newState, isLoading: true };

    case JobActionTypes.FETCH_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case JobActionTypes.FETCH_FAILURE:
      return { ...newState, data: initialState.data, isLoading: false };

    case JobActionTypes.FETCH_BY_SECRET_ALIAS_ID:
      return { ...newState, isLoading: true };

    case JobActionTypes.FETCH_BY_SECRET_ALIAS_ID_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case JobActionTypes.FETCH_BY_SECRET_ALIAS_ID_FAILURE:
      return { ...newState, data: initialState.data, isLoading: false };

    case JobActionTypes.UPDATE:
      return { ...newState, isLoading: true };

    case JobActionTypes.UPDATE_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case JobActionTypes.UPDATE_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.TOGGLE_TALENT_SELECTION:
      return { ...newState, isLoading: true };

    case JobActionTypes.TOGGLE_TALENT_SELECTION_SUCCESS:
      return { ...newState, data: action.payload, isLoading: false };

    case JobActionTypes.TOGGLE_TALENT_SELECTION_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.CANCEL:
      return { ...newState, isLoading: true };

    case JobActionTypes.CANCEL_SUCCESS:
      return {
        ...newState,
        data: {
          ...newState.data,
          status: 'canceledJob'
        },
        isLoading: false
      };

    case JobActionTypes.CANCEL_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.UPDATE_APPLICATION:
      return { ...newState, isLoading: true };

    case JobActionTypes.UPDATE_APPLICATION_SUCCESS: {
      const updatedApplicants = newState.data.applicants.map(applicant => {
        if (applicant.user.swipecast_user_id === action.applicantId) {
          return { ...applicant, status: action.status };
        }
        return applicant;
      });

      return {
        ...newState,
        data: {
          ...newState.data,
          applicants: updatedApplicants
        },
        isLoading: false
      };
    }

    case JobActionTypes.UPDATE_APPLICATION_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.SELECT:
      return { ...newState, isLoading: true };

    case JobActionTypes.SELECT_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.SELECT_SUCCESS:
      return { ...newState, data: action.payload.job, isLoading: false };

    case JobActionTypes.DESELECT:
      return { ...newState, isLoading: true };

    case JobActionTypes.DESELECT_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.DESELECT_SUCCESS: {
      const updatedApplicants = newState.data.applicants.map(applicant => {
        if (applicant.user.swipecast_user_id === action.payload) {
          return { ...applicant, status: 'considering' };
        }
        return applicant;
      });
      return {
        ...newState,
        data: {
          ...newState.data,
          applicants: updatedApplicants
        },
        isLoading: false
      };
    }

    case JobActionTypes.RETRY_PAYMENT:
      return { ...newState, isLoading: true };

    case JobActionTypes.RETRY_PAYMENT_FAILURE:
      return { ...newState, isLoading: false };

    case JobActionTypes.RETRY_PAYMENT_SUCCESS:
      return { ...newState, isLoading: false };

    default:
      return state;
  }
};
