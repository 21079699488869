import { useCallback, useMemo } from 'react';
import GeneralHelpers from 'helpers/general_helpers';
import hourlyRates from 'helpers/job/rates/hourly_rates_helper';
import dayRates from 'helpers/job/rates/day_rates_helper';
import FormatHelpers from 'helpers/format_helpers';
import useChangeSliderRate from 'components/views/logged_in/jobs/create_job/create_job_v2/public_posting/components/steps/create_project_rate/components/create_project_rate_item_v2/hooks/use_change_slider_rate';

const useRatesChange = ({ onChangeTalent, pureLookingFor, isHourlyRate }) => {
  const rates = isHourlyRate ? hourlyRates : dayRates;
  const onChangeRate = useCallback(rate => onChangeTalent({ rate }), [
    onChangeTalent
  ]);

  const talentRates = useMemo(() => {
    const template = rates[pureLookingFor];
    return {
      ...template,
      ...(isHourlyRate && GeneralHelpers.isAdmin() && { min: 100 }),
      breakpoints: template.buildBreakpoints(pureLookingFor)
    };
  }, [pureLookingFor, rates, isHourlyRate]);

  const breakpointsArray = useMemo(
    () => talentRates.breakpoints.map(({ min }) => min),
    [talentRates.breakpoints]
  );

  const onChangeInputRate = useCallback(
    v => {
      let value = parseInt(v.replace(/\D/g, '')) || 0;
      if (value > talentRates.max) value = talentRates.max;
      onChangeRate(value);
    },
    [onChangeRate, talentRates]
  );

  const onChangeSliderRate = useChangeSliderRate({
    onChangeRate,
    max: talentRates.max,
    breakpointsArray
  });

  return {
    onChangeSliderRate,
    onChangeInputRate,
    talentRates
  };
};

export default useRatesChange;
