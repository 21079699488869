import AuthHelper from 'helpers/auth_helper';
import {
  bothRolesProfiles,
  clientProfiles,
  talentProfiles
} from 'helpers/globals';

function getProfileByCategory(category, profiles) {
  if (!category || !profiles) return null;
  const profile = profiles.find(p => {
    const { type, gender } = category.filters || {};
    if (!type) return false;
    const genderMatch = gender ? gender.some(g => g === p.data.gender) : true;
    const typeMatch = type.includes(p.profile_type);
    return typeMatch && genderMatch;
  });

  return profile;
}

function getProfileLabel(profile) {
  if (!profile) return null;
  return [profile.data.gender, profile.profile_type].filter(v => !!v).join(' ');
}

function hasClientProfile(user = AuthHelper.currentUser()) {
  if (!user || user.profiles.length === 0) return false;

  const targetProfiles = [...clientProfiles, ...bothRolesProfiles];
  const hasClientProfile = user.profiles.some(p =>
    targetProfiles.includes(p.profile_type)
  );
  return hasClientProfile;
}

function hasTalentProfile(user = AuthHelper.currentUser()) {
  if (!user || user.profiles.length === 0) return false;

  const targetProfiles = [...talentProfiles, ...bothRolesProfiles];
  const hasTalentProfile = user.profiles.some(p =>
    targetProfiles.includes(p.profile_type)
  );
  return hasTalentProfile;
}

const ProfileHelpers = {
  getProfileLabel,
  getProfileByCategory,
  hasClientProfile,
  hasTalentProfile
};

export default ProfileHelpers;
