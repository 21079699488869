import { useCallback, useEffect, useState } from 'react';

const useAlbumPreviewCursor = ({ cursorBehaviour, onPrev, onClose, onNext, size }) => {
  const [cursor, setCursor] = useState(null);

  const handleMouseMove = useCallback(
    e => {
      if (size === 1) return false;
      const screenWidth = window.innerWidth;
      let type;
      if (e.clientX < screenWidth / 4) type = 'prev';
      else if (e.clientX >= screenWidth * 0.25 && e.clientX <= screenWidth * 0.75) type = 'close';
      else type = 'next';
      setCursor({ type, x: e.clientX, y: e.clientY });
    },
    [cursor, size]
  );

  const onCursorClick = useCallback(() => {
    if (!cursorBehaviour) return false;

    if (cursor.type === 'close') onClose();
    else if (cursor.type === 'prev') onPrev();
    else onNext();
  }, [cursor, onPrev, onClose, onNext, cursorBehaviour]);

  useEffect(() => {
    if (cursorBehaviour) {
      document.addEventListener('mousemove', handleMouseMove);
      return () => document.removeEventListener('mousemove', handleMouseMove);
    }
  }, []);

  return [cursor, onCursorClick];
};

export default useAlbumPreviewCursor;
