import React from 'react';
import ReactPlayer from 'react-player';

/**
 * https://www.npmjs.com/package/react-player
 * @param url
 * @param thumbnail
 * @param playing
 * @param controls
 * @param stopOnUnmount
 * @returns {JSX.Element}
 * @constructor
 */
function VideoPlayer({
  url,
  height = '360',
  width = '640',
  thumbnail,
  loop = false,
  playing = true,
  controls = true,
  stopOnUnmount = true
}) {
  return (
    <ReactPlayer
      loop={loop}
      width={width}
      height={height}
      light={thumbnail}
      stopOnUnmount={stopOnUnmount}
      controls={controls}
      playing={playing}
      url={url}
    />
  );
}

export default VideoPlayer;
