import React, { useCallback } from 'react';
import styled from 'styled-components';
import capitalize from 'lodash.capitalize';
import { useHistory } from 'react-router-dom';

import { colors } from 'helpers/colors';
import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Link from 'components/reusable/link';
import { routes } from 'helpers/router_helper';
import Button from 'components/reusable/button';
import AuthHelper from 'helpers/auth_helper';
import Avatar from 'components/complex/user/avatar';
import useDiscoverWaitlistTip from 'components/views/logged_in/discover/discover_view/discover_view_desktop/hooks/use_discover_waitlist_tip';
import sc_ga4, { actions } from 'classes/sc_ga4';

const Wrapper = styled(Flex)`
  max-width: 744px;
  border-radius: 0;
  background-color: ${colors.light};
  height: 100vh;
  width: 100vw;
  padding: 24px 16px;
`;

const SignUpSuccessModal = ({ onClose }) => {
  const user = AuthHelper.currentUser();
  const history = useHistory();
  const waitlistTip = useDiscoverWaitlistTip();
  const primaryLabel = AuthHelper.hasTalentProfile
    ? 'Edit Profile'
    : 'Post a Job';
  const secondaryLabel = 'Go to Home';

  const onPrimaryClick = useCallback(() => {
    if (AuthHelper.hasTalentProfile) {
      history.push(routes.user.me.albums.redirectUrl());
    } else {
      history.push(routes.createProject.redirectUrl());
      sc_ga4.sendMetric(actions.projectCreateFunnel.init, {
        is_booking: false,
        page: 'Sign Up Success Modal',
        section: 'Sign Up Success'
      });
    }

    onClose();
  }, []);

  const onSecondaryClick = useCallback(() => {
    history.push(routes.discover.base.redirectUrl());
    onClose();
  }, []);

  return (
    <Wrapper dir="column">
      <Link onClick={onClose} to={routes.user.me.albums.redirectUrl()}>
        <Avatar user={user} width={window.screen.width / 3} />
      </Link>

      <Indent top={24} />
      <Text textAlign="center" size={20} weight={500}>
        Congratulations, {capitalize(AuthHelper.logged_in_firstName)}!
      </Text>
      <Indent top={16} />
      <Text textAlign="center" size={12}>
        You've been added to the waitlist. We'll get to you soon.
      </Text>
      <Text textAlign="center" size={12}>
        {waitlistTip}
      </Text>
      <Indent top={40} />
      <Button width="100%" onClick={onPrimaryClick} height={40} type="primary">
        {primaryLabel}
      </Button>
      <Indent top={12} />
      <Button
        width="100%"
        onClick={onSecondaryClick}
        height={40}
        type="gray-shadow"
      >
        {secondaryLabel}
      </Button>
      <Indent top={12} />
      <Text size={12}>
        <Link onClick={onClose} size={12} to={routes.help.redirectUrl()}>
          Click here
        </Link>{' '}
        if you need assistance
      </Text>
    </Wrapper>
  );
};

export default SignUpSuccessModal;
