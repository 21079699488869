import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';
import ApplicationHelper from 'helpers/application_helper';
import capitalize from 'lodash.capitalize';

const Wrapper = styled(Flex)`
  max-width: 480px;
  width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

const StyledText = styled(Text)`
  width: 100%;
`;

const ContactUnavailableModal = ({ modalProps = {}, onClose }) => {
  const isMobile = ApplicationHelper.isMobile;

  return (
    <Wrapper dir="column" wrap="nowrap">
      <StyledText size={24} weight={500} textAlign={isMobile ? 'left' : 'center'}>
        CONTACT {modalProps.name?.toUpperCase()}
      </StyledText>
      <Indent bottom={24} />

      <Text size={14} textAlign={isMobile ? 'left' : 'center'}>
        Contacts will be available to you as soon as {capitalize(modalProps.name)} confirms your job.
      </Text>
      <Indent bottom={isMobile ? 24 : 40} />

      <Button type="primary" height={40} width={isMobile ? '100%' : 160} onClick={onClose}>
        Got it
      </Button>
    </Wrapper>
  );
};

export default ContactUnavailableModal;
