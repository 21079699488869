import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';
import Input from 'components/reusable/input';

/**
 * @description missing 768+ screen resolution implementation
 * @param modalProps
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const DatePickerModal = ({ modalProps = {}, onClose }) => {
  const [dates, setDates] = useState(modalProps.value || []);

  const handleClose = useCallback(() => {
    if (typeof modalProps.callback === 'function') modalProps.callback();
    onClose();
  }, [modalProps, onClose]);

  const handleSubmit = useCallback(() => {
    if (modalProps.onChange) modalProps.onChange(dates);
    onClose();
  }, [dates, onClose, modalProps.onChange]);

  return (
    <Wrapper dir="column" wrap="nowrap">
      <HeadingWrapper>
        <Text size={18} weight={500}>
          {modalProps.title || 'Select a Date'}
        </Text>
        <Indent bottom={16} />
      </HeadingWrapper>

      <StyledInput
        fullWidth
        type="month_calendar"
        value={dates}
        onChange={setDates}
        withoutToday
      />

      <Indent bottom={24} />

      <Flex dir="row" gap={8} width="100%">
        <StyledButton type="gray-shadow" height={40} onClick={handleClose}>
          Cancel
        </StyledButton>

        <StyledButton type="primary" height={40} onClick={handleSubmit}>
          Submit
        </StyledButton>
      </Flex>
    </Wrapper>
  );
};

const Wrapper = styled(Flex)`
  max-width: 480px;
  width: 100%;
  padding: 16px 40px 24px;
  position: relative;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
  }
`;

const StyledButton = styled(Button)`
  flex: 1;
`;

const HeadingWrapper = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

const StyledInput = styled(Input)`
  @media (min-width: 769px) {
    box-shadow: none;
    padding: 0;
    margin: 0;
  }
`;

export default DatePickerModal;
