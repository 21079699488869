import React from 'react';
import styled from 'styled-components';

import Image from 'components/reusable/image';
import useTimePicker from '../TimeInput/components/am_pm/hooks/use_time_picker';

const WrapperAMPM = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

const AmPm = ({ onChange, value }) => {
  const invalidValue =
    !value || typeof value !== 'string' || !value.includes(':');

  const { isPmSelected, buildOnChangeAmPm } = useTimePicker({
    value,
    onChange
  });

  if (invalidValue) return null;

  return (
    <WrapperAMPM>
      {isPmSelected ? (
        <div>
          <Image
            src="/images/icons/svg/time-icon.svg"
            onClick={buildOnChangeAmPm(true)}
            isSelected={!isPmSelected}
          />
        </div>
      ) : (
        <div>
          <Image
            src="/images/icons/svg/time-icon.svg"
            onClick={buildOnChangeAmPm(false)}
            isSelected={isPmSelected}
          />
        </div>
      )}
    </WrapperAMPM>
  );
};

export default AmPm;
