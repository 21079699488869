import { useMemo } from 'react';

const useCurrentStep = sequence => {
  const currentStep = useMemo(() => {
    const step = [...sequence].reverse().find(s => s.active);
    if (!step) return null;

    const index = sequence.indexOf(step);
    return { index, ...step };
  }, [sequence]);

  return currentStep;
};

export default useCurrentStep;
