import { useCallback } from 'react';

const useHandleChange = ({ setFile, setShowCroppie, onChange }) => {
  const handleChange = useCallback(
    async e => {
      const { files } = e.target;
      if (files.length) setShowCroppie(true);
      setFile(files);
    },
    [setFile, setShowCroppie]
  );

  const resetValue = useCallback(async () => {
    setFile();
    onChange();
    setShowCroppie(false);
  }, [setFile, onChange, setShowCroppie]);

  return { resetValue, handleChange };
};

export default useHandleChange;
