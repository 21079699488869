import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import Text from 'components/reusable/text';
import Input from 'components/reusable/input';

export const Wrapper = styled.div`
  width: 880px;
  max-width: 100%;
  padding: 56px 56px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 768px) {
    padding: 24px 16px 16px;
    min-height: 100%;
  }
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 32px;
  right: 32px;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SliderWrapper = styled.div`
  width: 560px;
  max-width: 100%;
  height: 116px;
  margin: 32px 0;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin: 0 0 24px;
  }
`;

export const RateDesc = styled(Text)`
  height: 2.3em;
  min-height: 2.3em;
  max-width: 560px;
  margin-left: 12px;
  @media (max-width: 768px) {
    margin-top: 8px;
    margin-left: 0;
  }
`;

export const RowWrapper = styled.div`
  margin-top: 16px;
  position: relative;
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    margin-top: 12px;
    width: 100%;
  }
`;

export const HourlyOptLabel = styled(Text)`
  border-bottom: 1px solid ${colors.gray[600]};
`;

export const StyledError = styled(Text)`
  min-height: 1.2em;
  margin-top: 4px;
  height: 1.2em;
`;

export const FrequencyButton = styled(Button)`
  margin-top: 12px;
  @media (max-width: 768px) {
    margin: 8px 0 40px;
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 32px;
  width: 160px;
  height: 40px;
  @media (max-width: 768px) {
    width: auto;
    flex: 1;
    margin-top: 0;
  }
`;

export const Label = styled(Text)`
  text-align: center;
  max-width: 90%;
  @media (max-width: 768px) {
    height: 2.7em;
    margin-top: 12px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
`;

export const Title = styled(Text)`
  font-size: 18px;
  @media (max-width: 768px) {
    font-size: 16px;
    align-self: flex-start;
  }
`;

export const StyledInput = styled(Input)`
  text-align: center;
  font-size: 18px;
  width: 143px;
  height: 42px;
  font-weight: 500;
  @media (max-width: 768px) {
    height: 48px;
    width: 100%;
  }
`;

export const CancelButton = styled(SubmitButton)`
  margin-right: 12px;
  @media (min-width: 769px) {
    display: none;
  }
`;

export const ButtonsToolbar = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    margin-top: auto;
  }
`;
