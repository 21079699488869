const initialState = {
  user_type: 'Female Models',
  location: '',
  link: 'female_models'
};

const topSlide = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_TOP_SLIDE_DATA':
      return {
        ...state,
        ...action.payload
      };
    default: {
      return {
        ...state
      };
    }
  }
};

export default topSlide;
