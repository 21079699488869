import React, { useContext } from 'react';

import {
  Banner,
  StyledButton,
  StyledLink
} from 'components/static/ua_banner/styled';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import { UaBannerContext } from 'contexts/contexts';

function UaBannerDesktop() {
  const uaBannerContext = useContext(UaBannerContext);

  return (
    <Banner>
      <Text size={16} weight={400}>
        Take Action Now: Support Ukraine's Independence and Peace.
      </Text>
      <Indent right={12} />

      {svgIcons.uaFlag()}
      <Indent right={8} />
      {svgIcons.doveOfPeace()}
      <StyledLink blank href="https://savelife.in.ua/donate">
        Click to Help.
      </StyledLink>

      <StyledButton
        onClick={uaBannerContext.onCloseBanner}
        type="semantic-wrapper"
      >
        {svgIcons.close(colors.gray[700], { width: 20, height: 20 })}
      </StyledButton>
    </Banner>
  );
}

export default UaBannerDesktop;
