import { useCallback } from 'react';
import queryString from 'query-string';

import { signUp, submitReferralCode } from 'components/views/logged_out/SignUp/Helpers';
import SC_GA4, { actions } from 'classes/sc_ga4';
import { useHistory, useParams } from 'react-router-dom';
import { modalEnum } from 'components/modals/v2/modal';
import { LocalStorage } from 'classes/sc_localstorage';
import AuthHelper from 'helpers/auth_helper';
import QueryHelpers from 'helpers/query_helpers';
import { routes } from 'helpers/router_helper';

const useSubmit = ({ rootContext, setError, modalContext }) => {
  const history = useHistory();

  const _sendEvent = useCallback(oauth => {
    SC_GA4.sendMetric(actions.userCreateFunnel.generalInfo, {
      signup_divided_steps_flow: true,
      ...(oauth && { oauth })
    });
  }, []);

  const onSubmit = useCallback(
    async formData => {
      rootContext.setLoading(true);
      const referralCode = LocalStorage.fetchReferralCode();
      const referralProvided = !!referralCode && referralCode.length > 0;
      const data = {
        ...formData,
        ...(referralProvided && { referral_code: referralCode })
      };

      const res = await signUp(data);
      rootContext.setLoading(false);
      if (res !== 'success') return setError(res);
      _sendEvent();
      LocalStorage.setNewGuestPostingUserAB();
      handleAuthRedirect(history);
      modalContext.onOpenModal(modalEnum.signUpAdditionalDetails);
    },
    [setError, rootContext.setLoading]
  );

  return onSubmit;
};

// TODO check Link-in-Bio if we can use instead AuthHelper.performLoginRedirect()
export const handleAuthRedirect = history => {
  const redirectURL = QueryHelpers.getParam(QueryHelpers.keys.redirectUrl);
  history.replace(redirectURL || routes.discover.base.redirectUrl());

  if (redirectURL) {
    // it should reload page according to new user state, since we keep user in LocalStorage
    const redirectUriWithoutQuery = redirectURL.split('?')[0];
    if (window.location.pathname === redirectUriWithoutQuery) window.location.reload();
  }
};

export default useSubmit;
