import { useCallback, useContext } from 'react';
import get from 'lodash.get';

import { signUpStepsData } from 'components/modals/v2/auth/sign_up_additional_details_modal/helpers/signup_steps_helper';
import { initializeAlbums } from 'components/views/logged_out/SignUp/Helpers';
import AuthHelper from 'helpers/auth_helper';
import SC_GA4, { actions } from 'classes/sc_ga4';
import ApplicationHelper from 'helpers/application_helper';
import { ModalContext } from 'contexts/contexts';
import { modalEnum } from 'components/modals/v2/modal';
import QueryHelpers from 'helpers/query_helpers';

export const buildGa4ImageStepAttrs = (
  user,
  user_logo_filled,
  signup_divided_steps_flow
) => {
  const profile = user.profiles[0];
  const profile_type = [
    get(profile, 'data.gender'),
    get(profile, 'profile_type')
  ]
    .filter(v => !!v)
    .join(' ');

  const { intent_role } = user;
  let oauth;
  if (user.google_id) oauth = 'google';

  return {
    intent_role,
    profile_type,
    user_logo_filled,
    signup_divided_steps_flow,
    oauth
  };
};

const useSubmit = ({ onClose, setError }) => {
  const modalContext = useContext(ModalContext);

  const _sendEvent = useCallback(user_logo_filled => {
    const user = AuthHelper.currentUser();
    if (!user) return;

    SC_GA4.sendMetric(
      actions.userCreateFunnel.image,
      buildGa4ImageStepAttrs(user, user_logo_filled, true)
    );
  }, []);

  const onSubmit = useCallback(
    async values => {
      const skipped = values[signUpStepsData.imagePostpone.field];

      if (!skipped) {
        const data = values.image;
        if (!data) return console.log('data key is empty');
        const images = [{ image: data.originalBase64, crop: data.crop }];
        const result = await initializeAlbums(images);
        if (result !== 'success') return setError(result);
        await AuthHelper.loadFromBackend();
      }

      _sendEvent(!skipped);
      onClose();

      const successScreenDisabled =
        QueryHelpers.getParam(QueryHelpers.keys.successScreen) === 'false';

      if (ApplicationHelper.isMobile && !successScreenDisabled) {
        modalContext.onOpenModal(modalEnum.signUpSuccess);
      }
    },
    [onClose, setError]
  );

  return onSubmit;
};

export default useSubmit;
