import { JobActionTypes } from 'helpers/constants/action_types/job';

export const JobActions = {
  FETCH: payload => ({
    payload,
    type: JobActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: JobActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.FETCH_SUCCESS
  }),

  FETCH_BY_SECRET_ALIAS_ID: payload => ({
    payload,
    type: JobActionTypes.FETCH_BY_SECRET_ALIAS_ID
  }),

  FETCH_BY_SECRET_ALIAS_ID_FAILURE: () => ({
    type: JobActionTypes.FETCH_BY_SECRET_ALIAS_ID_FAILURE
  }),

  FETCH_BY_SECRET_ALIAS_ID_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.FETCH_BY_SECRET_ALIAS_ID_SUCCESS
  }),

  UPDATE: (id, payload, onSuccessCallback, onErrorCallback) => ({
    payload,
    id,
    type: JobActionTypes.UPDATE,
    onSuccessCallback,
    onErrorCallback
  }),

  UPDATE_FAILURE: () => ({
    type: JobActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.UPDATE_SUCCESS
  }),

  TOGGLE_TALENT_SELECTION: id => ({
    id,
    type: JobActionTypes.TOGGLE_TALENT_SELECTION
  }),

  TOGGLE_TALENT_SELECTION_FAILURE: () => ({
    type: JobActionTypes.TOGGLE_TALENT_SELECTION_FAILURE
  }),

  TOGGLE_TALENT_SELECTION_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.TOGGLE_TALENT_SELECTION_SUCCESS
  }),

  CANCEL: (job, onErrorCallback) => ({
    job,
    onErrorCallback,
    type: JobActionTypes.CANCEL
  }),

  CANCEL_FAILURE: () => ({
    type: JobActionTypes.CANCEL_FAILURE
  }),

  CANCEL_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.CANCEL_SUCCESS
  }),

  UPDATE_APPLICATION: (jobId, applicantId, status) => ({
    jobId,
    applicantId,
    status,
    type: JobActionTypes.UPDATE_APPLICATION
  }),

  UPDATE_APPLICATION_FAILURE: () => ({
    type: JobActionTypes.UPDATE_APPLICATION_FAILURE
  }),

  UPDATE_APPLICATION_SUCCESS: (applicantId, status) => ({
    applicantId,
    status,
    type: JobActionTypes.UPDATE_APPLICATION_SUCCESS
  }),

  SELECT: (jobId, selectedApplicantId, paymentInfo, onSuccessCallback) => ({
    jobId,
    selectedApplicantId,
    paymentInfo,
    onSuccessCallback,
    type: JobActionTypes.SELECT
  }),

  SELECT_FAILURE: () => ({
    type: JobActionTypes.SELECT_FAILURE
  }),

  SELECT_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.SELECT_SUCCESS
  }),

  DESELECT: (jobId, applicantId) => ({
    jobId,
    applicantId,
    type: JobActionTypes.DESELECT
  }),

  DESELECT_FAILURE: () => ({
    type: JobActionTypes.DESELECT_FAILURE
  }),

  DESELECT_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.DESELECT_SUCCESS
  }),

  RETRY_PAYMENT: (jobId, sourceId, onSuccessCallback, onErrorCallback) => ({
    jobId,
    sourceId,
    onSuccessCallback,
    onErrorCallback,
    type: JobActionTypes.RETRY_PAYMENT
  }),

  RETRY_PAYMENT_FAILURE: () => ({
    type: JobActionTypes.RETRY_PAYMENT_FAILURE
  }),

  RETRY_PAYMENT_SUCCESS: payload => ({
    payload,
    type: JobActionTypes.RETRY_PAYMENT_SUCCESS
  })
};
