import templates from './templates';
import Globals from 'helpers/globals';

const pureDayRatesHelper = {
  // category 1
  [Globals.profileTypes.model]: templates.modelInfParts,
  [Globals.profileTypes.influencer]: templates.modelInfParts,
  [Globals.profileTypes.parts]: templates.modelInfParts,
  // category 2
  [Globals.profileTypes.makeUp]: templates.hairMakeUp,
  [Globals.profileTypes.hair]: templates.hairMakeUp,
  // category 3
  [Globals.profileTypes.photo]: templates.photoVideo,
  [Globals.profileTypes.video]: templates.photoVideo,
  // category 4
  [Globals.profileTypes.casting]: templates.producerCastingRet,
  [Globals.profileTypes.producer]: templates.producerCastingRet,
  [Globals.profileTypes.retoucher]: templates.producerCastingRet,
  // category 5
  [Globals.profileTypes.stylist]: templates.stylistProp,
  [Globals.profileTypes.prop]: templates.stylistProp,
  // other
  [Globals.profileTypes.creative]: templates.creative,
  [Globals.profileTypes.manicurist]: templates.manic,
  [Globals.profileTypes.studio]: templates.studio
};

const defaultHandler = {
  get: function(target, name) {
    return target.hasOwnProperty(name)
      ? target[name]
      : pureDayRatesHelper[Globals.profileTypes.casting];
  }
};

const dayRatesHelper = new Proxy(pureDayRatesHelper, defaultHandler);
export default dayRatesHelper;
