import React, { useContext, useRef, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Input from 'components/reusable/input';
import { isMobileWidth, styleDimensions } from 'helpers/style';
import ButtonsToolbar from './components/buttons_toolbar';
import { FeeTip, LearnMoreTip } from './components/tips';
import { ModalContext, RootContext } from 'contexts/contexts';
import Settings from 'helpers/settings';
import Link from 'components/reusable/link'

const ModalContainer = styled.div`
  position: absolute;
  width: 640px;
  padding: 40px 56px;
  background: ${colors.light};
  box-shadow: 0px 0px 80px rgba(31, 30, 30, 0.08);
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 769px) {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 16px;
    bottom: ${({ isMobileFooterEnabled }) =>
      isMobileFooterEnabled ? styleDimensions.mobileBarHeight : 0};
  }
`;

const TextBlock = styled.div`
  display: flex;
  width: 100%;
`;

const StyledText = styled(Text)`
  display: inline;
`;

const ProjectTosModal = () => {
  const { onSubmit, isBooking } = useContext(ModalContext).modalProps;
  const [state, setState] = useState({
    fee: false,
    comms: false,
    contacts: false,
    supp: false
  });

  const content = useRef();
  const rootContext = useContext(RootContext);

  const handleCheck = field => value =>
    setState(ps => ({ ...ps, [field]: value }));

  const isSubmitDisabled = isBooking
    ? false
    : !Object.values(state).every(v => v);

  return (
    <ModalContainer
      isMobileFooterEnabled={rootContext.isMobileFooterEnabled}
      onClick={e => e.stopPropagation()}
      ref={content}
    >
      <Text size={isMobileWidth ? 18 : 24}>ONE LAST THING</Text>
      <Indent bottom={isMobileWidth ? 16 : 24} />
      <Text size={14} color={colors.gray[400]}>
        Before we submit your job, please agree to the following:
      </Text>
      <Indent bottom={isMobileWidth ? 24 : 40} />

      <TextBlock>
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('fee')}
            value={state.fee}
          />
        </Indent>

        <Indent left={12}>
          <div>
            <StyledText>
              I agree to Swipecast's {Settings.clientFee} platform fee.
            </StyledText>
            <FeeTip />
          </div>
        </Indent>
      </TextBlock>

      <Indent bottom={12} />

      <TextBlock>
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('comms')}
            value={state.comms}
          />
        </Indent>

        <Indent left={12} />
        <div>
          <StyledText>
            I agree to keep all of my communications and payments over the
            platform.&nbsp;
          </StyledText>
          <LearnMoreTip />
        </div>
      </TextBlock>

      <Indent bottom={12} />
      <TextBlock>
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('contacts')}
            value={state.contacts}
          />
        </Indent>
        <Indent left={12} />
        <Text>
          I understand that I will be provided with the phone number and email
          of the talent once my booking is confirmed.
        </Text>
      </TextBlock>
      <Indent bottom={12} />
      <TextBlock>
        <Indent horizontal={3} vertical={3}>
          <Input
            type="checkbox"
            onChange={handleCheck('supp')}
            value={state.supp}
          />
        </Indent>
        <Indent left={12} />
        <Text>
          If I have any issues with my booking, I will contact{' '}
          <Link weight={400} href={`mailto:${Settings.support.email}`}>{Settings.support.email}</Link>.
        </Text>
      </TextBlock>
      <Indent bottom={40} />

      <ButtonsToolbar
        isSubmitDisabled={isSubmitDisabled}
        handleSubmit={onSubmit}
      />
    </ModalContainer>
  );
};

export default ProjectTosModal;
