import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import useSharedFiles from 'components/modals/v2/chat_shared_modal/hooks/use_shared_files';
import useSharedFilesSkeleton from 'components/modals/v2/chat_shared_modal/hooks/use_shared_files_skeleton';
import useGetSharedFilesCount from 'components/modals/v2/chat_shared_modal/hooks/use_get_shared_files_count';
import useGetSharedFiles from 'components/modals/v2/chat_shared_modal/hooks/use_get_shared_files';
import useResetSharedFiles from 'components/modals/v2/chat_shared_modal/hooks/use_reset_shared_files';
import useGetSharedTabType from 'components/modals/v2/chat_shared_modal/hooks/use_get_shared_tab_type';
import useSharedFilesPagination from 'components/modals/v2/chat_shared_modal/hooks/use_shared_files_pagination';
import useDeleteSharedMessage from 'components/modals/v2/chat_shared_modal/hooks/use_delete_shared_message';
import useSharedFileOpen from 'components/modals/v2/chat_shared_modal/hooks/use_shared_file_open';
import useSharedFilesHeader from 'components/modals/v2/chat_shared_modal/hooks/use_shared_files_header';
import ChatSharedHeader from 'components/modals/v2/chat_shared_modal/components/chat_shared_header';
import ChatSharedList from 'components/modals/v2/chat_shared_modal/components/chat_shared_list';
import { fileTypesEnum } from 'components/modals/v2/chat_shared_modal/file_types_enum';

const fileTypes = Object.values(fileTypesEnum);

// NOTE: this is used outside of modal for MR
function ChatSharedMedia({ conversationId, onClose }) {
  const [activeTab, setActiveTab] = useState(fileTypes[0]);
  const [messages, meta] = useSharedFiles(conversationId, activeTab);
  const [isMainSkeleton, isHeaderSkeleton] = useSharedFilesSkeleton(messages);
  const handleFetchCount = useGetSharedFilesCount(conversationId);
  const handleFetchMessages = useGetSharedFiles(conversationId, meta);
  const handleResetSharedFiles = useResetSharedFiles();
  const handleGetSharedTabType = useGetSharedTabType();
  const pagination = useSharedFilesPagination({ activeTab, handleFetchMessages, meta });
  const handleDeleteSharedMsg = useDeleteSharedMessage();
  const handleOpenAlbum = useSharedFileOpen(messages, handleDeleteSharedMsg, onClose);
  const headerItems = useSharedFilesHeader();

  useEffect(() => {
    if (!messages.length || messages.length === 1) handleFetchMessages(activeTab, false);
  }, [activeTab]);

  useEffect(() => {
    handleFetchCount(activeTab);
    return () => handleResetSharedFiles();
  }, []);

  return (
    <Wrapper dir="column" wrap="nowrap">
      <ChatSharedHeader
        isSkeleton={isHeaderSkeleton}
        activeTab={activeTab}
        items={headerItems}
        onClose={onClose}
        onChange={setActiveTab}
      />

      <ChatSharedList
        handleDeleteSharedMsg={handleDeleteSharedMsg}
        handleOpenAlbum={handleOpenAlbum}
        messageType={handleGetSharedTabType(activeTab)}
        isSkeleton={isMainSkeleton}
        messages={messages}
        pagination={pagination}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
  min-height: 486px;
  max-height: 486px;
  position: relative;
  padding-bottom: 16px;
  overflow: hidden;
  @media (max-width: 768px) {
    padding-bottom: 0;
    width: 100%;
    min-height: unset;
    max-height: unset;
  }
`;

export default ChatSharedMedia;
