import React from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import SharePortfolioOptions from 'components/modals/v2/share_portfolio/components/share_portfolio_options';
import CopyLink from 'components/complex/copy_link';
import Divider from 'components/reusable/divider';
import Button from 'components/reusable/button';
import SharePortfolioLabels from 'components/modals/v2/share_portfolio/components/share_portfolio_labels';

function SharePortfolioModalDesktop({ libURL, showCopyHint, onClose, options, onCopyText, title }) {
  return (
    <Wrapper>
      <ColumnsWrapper>
        <LeftColumn>
          <LabelAndDescWrapper>
            <SharePortfolioLabels title={title} showCopyHint={showCopyHint} />
          </LabelAndDescWrapper>

          <Divider />

          <CopyLinkWrapper>
            <CopyLink url={libURL} onCopy={onCopyText} />
          </CopyLinkWrapper>
        </LeftColumn>

        <RightColumn>
          <SharePortfolioOptions options={options} />
        </RightColumn>
      </ColumnsWrapper>

      <BtnToolbar>
        <Button type="gray-shadow" width={160} height={40} onClick={onClose}>
          Cancel
        </Button>
      </BtnToolbar>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const LabelAndDescWrapper = styled.div`
  padding: 56px 40px 24px;
`;

const ColumnsWrapper = styled.div`
  display: flex;
  > :last-child {
    border-left: 1px solid ${colors.gray[100]};
  }
`;

const CopyLinkWrapper = styled.div`
  padding: 24px 40px 0;
`;

const LeftColumn = styled.div`
  max-width: 416px;
  width: 416px;
`;

const BtnToolbar = styled.div`
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const RightColumn = styled.div`
  max-width: 310px;
  min-width: 288px;
  padding: 56px 40px 40px;
`;

export default SharePortfolioModalDesktop;
