/* eslint-disable max-len */
import React from 'react';

import { colors } from 'helpers/colors';

function SvgThreeDotsSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="8" cy="2" r="1.5" fill={color} />
      <circle cx="2" cy="2" r="1.5" fill={color} />
      <circle cx="14" cy="2" r="1.5" fill={color} />
    </svg>
  );
}

export default SvgThreeDotsSolid;
