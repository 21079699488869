import React from 'react';

import useShareProfileOptions from './hooks/use_share_profile_options';
import sc_ga4, { actions } from 'classes/sc_ga4';
import RouterHelper from 'helpers/router_helper';
import useAdaptiveDesign from 'hooks/use_adaptive_design';
import SharePortfolioModalDesktop from 'components/modals/v2/share_portfolio/share_portfolio_modal_desktop';
import SharePortfolioModalMobile from 'components/modals/v2/share_portfolio/share_portfolio_modal_mobile';

const SharePortfolioModal = ({ modalProps = {}, onClose }) => {
  const { user, isCurrentUser } = modalProps;
  const libURL = RouterHelper.getLIBReferralLink(user);

  const sendShareMetric = method =>
    sc_ga4.sendMetric(actions.share, {
      method,
      item_id: libURL,
      content_type: 'user'
    });

  const options = useShareProfileOptions({
    url: libURL,
    sendMetric: sendShareMetric
  });
  const title = `SHARE ${
    isCurrentUser ? 'MY' : `${user.first_name.toUpperCase()}'s\}`
  } PORTFOLIO`;
  const onCopyText = () => sendShareMetric('clipboard');

  const Component = useAdaptiveDesign(
    SharePortfolioModalDesktop,
    SharePortfolioModalMobile
  );
  const componentProps = {
    showCopyHint: isCurrentUser,
    libURL,
    onClose,
    options,
    onCopyText,
    title
  };

  return <Component {...componentProps} />;
};

export default SharePortfolioModal;
