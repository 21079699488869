import React, { useCallback, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';

import { colors } from 'helpers/colors';
import PaymentHelpers from 'helpers/payment_helpers';
import { ModalContext } from 'contexts/contexts';
import Button from 'components/reusable/button';
import Flex from 'components/reusable/flex';
import Indent from 'components/reusable/indent';
import Input from 'components/reusable/input';
import Text from 'components/reusable/text';
import { modalEnum } from '../modal';
import ApplicationHelper from 'helpers/application_helper';

const Wrapper = styled(Flex)`
  width: 520px;
  padding: 56px 56px 40px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
    padding: 24px 16px 16px;
  }
`;

const ButtonWrap = styled(Flex)`
  cursor: pointer;
`;

const PaymentSelectionModal = ({ onClose, modalProps }) => {
  const { onOpenModal } = useContext(ModalContext);
  const [paymentsMethod, setPaymentMethod] = useState('first');
  const [checkboxValue, setCheckboxValue] = useState(true);

  const handleCheckbox = () => {
    setCheckboxValue(!checkboxValue);
  };

  const onChange = useCallback(
    val => () => {
      setPaymentMethod(val);
    },
    [setPaymentMethod]
  );

  const getStripeCustomer = () => {
    return new Promise(resolve => {
      PaymentHelpers.loadStripeCustomers(customers => resolve(customers[0]));
    });
  };

  const handleSubmit = useCallback(async () => {
    const stripeCustomer = await getStripeCustomer();

    if (paymentsMethod === 'first') {
      if (!!stripeCustomer?.data?.default_source) {
        onOpenModal(modalEnum.confirmBooking, {
          applicantFirstName: modalProps.applicantFirstName,
          applicantUserId: modalProps.applicantUserId,
          confirmPayment: modalProps.confirmPayment,
          closeFromApplicants: checkboxValue,
          isLoading: modalProps.isLoading
        });
      } else {
        onOpenModal(modalEnum.addCard, {
          onAddCard: modalProps.onAddCard,
          applicantFirstName: modalProps.applicantFirstName,
          applicantUserId: modalProps.applicantUserId,
          closeFromApplicants: checkboxValue,
          isLoading: modalProps.isLoading
        });
      }
    } else {
      onOpenModal(modalEnum.invoice, {
        applicantFirstName: modalProps.applicantFirstName,
        applicantUserId: modalProps.applicantUserId,
        confirmPayment: modalProps.confirmPayment,
        closeFromApplicants: checkboxValue,
        isLoading: modalProps.isLoading
      });
    }
  }, [onOpenModal, paymentsMethod, modalProps, checkboxValue]);

  const inputName = 'Payment method';

  return (
    <Wrapper dir="column">
      <Text size={ApplicationHelper.isMobile ? 18 : 24} weight={500}>
        Payment Method
      </Text>
      <Indent bottom={40} />

      <Flex dir="column" ai="flex-start" gap={8} width="100%">
        <ButtonWrap gap={8} onClick={onChange('first')}>
          <Input
            name={inputName}
            checked={paymentsMethod === 'first'}
            value="first"
            type="radio"
            width={24}
          />
          <Text size={ApplicationHelper.isMobile ? 14 : 16} lineHeight="170%">
            Credit card (recommended)
          </Text>
        </ButtonWrap>

        <ButtonWrap gap={8} onClick={onChange('third')}>
          <Input
            name={inputName}
            checked={paymentsMethod === 'third'}
            value="third"
            type="radio"
            width={24}
          />
          <Text size={ApplicationHelper.isMobile ? 14 : 16} lineHeight="170%">
            Invoice
          </Text>
        </ButtonWrap>
      </Flex>
      <Indent bottom={24} />

      <Flex width="100%" wrap="nowrap" jc="flex-start" gap={8}>
        <Input
          type="checkbox"
          value={checkboxValue}
          onChange={handleCheckbox}
        />
        <Text size={ApplicationHelper.isMobile ? 14 : 16}>
          Close job to new applicants
        </Text>
      </Flex>
      <Indent bottom={40} />

      <Button type="primary" height={40} width="100%" onClick={handleSubmit}>
        Continue
      </Button>
      <Indent bottom={16} />

      <Button
        height={40}
        width="100%"
        color={colors.gray[600]}
        borderColor="transparent"
        onClick={onClose}
      >
        Cancel
      </Button>
    </Wrapper>
  );
};

export default PaymentSelectionModal;
