import { searchParams } from 'helpers/router_helper';

const ApplicationHelper = {
  MOBILE_WIDTH: 768,
  M_WIDTH: 1400,

  get isMobile() {
    return ApplicationHelper.detectResolution();
  },
  get isWidthM() {
    return ApplicationHelper.detectResolution(ApplicationHelper.M_WIDTH);
  },
  env: process.env.NODE_ENV,

  /**
   * Helper functions
   */

  detectResize() {
    if (!ApplicationHelper.currentPathIsWhiteListed() && ApplicationHelper.detectResolution()) {
      window.location.href = '/mobile';
    }
  },
  currentPathIsWhiteListed() {
    return !(
      window.location.pathname.indexOf('contacts') !== -1 ||
      window.location.pathname.indexOf('requests') !== -1 ||
      window.location.pathname.indexOf('FAQ') !== -1
    );
  },
  detectResolution(width = ApplicationHelper.MOBILE_WIDTH) {
    return window.matchMedia(`only screen and (max-width: ${width}px)`).matches;
  },
  isGuestOnboarding(rootContextMode) {
    return rootContextMode === searchParams.modes.guestOnboarding;
  }
};

export default ApplicationHelper;
