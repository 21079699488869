import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileZipOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H15M10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V10M10 1L15 6M15 6V10M12 16H13.5C13.8978 16 14.2794 15.842 14.5607 15.5607C14.842 15.2794 15 14.8978 15 14.5C15 14.1022 14.842 13.7206 14.5607 13.4393C14.2794 13.158 13.8978 13 13.5 13H12V19M8 13V19M1 13H4L1 19H4"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileZipOutline;
