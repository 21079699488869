import React, { useRef, useEffect, useState } from 'react';

import EmptyPreview from 'components/reusable/input/components/ImageInput/image_input_v2/components/empty_preview';
import CropperPreview from 'components/reusable/input/components/ImageInput/image_input_v2/components/cropper_preview';
import useHandleChange from 'components/reusable/input/components/ImageInput/image_input_v2/hooks/use_handle_change';
import useInitCroppie from 'components/reusable/input/components/ImageInput/image_input_v2/hooks/use_init_croppie';
import useCroppieChange from 'components/reusable/input/components/ImageInput/image_input_v2/hooks/use_croppie_change';
import useCrop from './hooks/use_crop';
import useSelect from 'components/reusable/input/components/ImageInput/image_input_v2/hooks/use_select';
import SelectedImagePreview from './components/selected_image_preview';

export const previewId = 'croppie_preview';

const ImageInputV2 = ({ value, onChange, accept }) => {
  const [file, setFile] = useState(null);
  const [showCroppie, setShowCroppie] = useState(false);
  const croppieRef = useRef(null);
  const { initCroppie, purgeCroppie, croppieOpts } = useInitCroppie(croppieRef);
  const handleCrop = useCrop({ croppieRef, croppieOpts });

  const { handleChange, resetValue } = useHandleChange({
    setFile,
    setShowCroppie,
    onChange
  });

  const onSelect = useSelect({
    croppieRef,
    purgeCroppie,
    handleCrop,
    onChange,
    setShowCroppie
  });

  const onCroppieChange = useCroppieChange({
    initCroppie,
    purgeCroppie
  });

  useEffect(() => {
    onCroppieChange(file);
  }, [file]);

  if (showCroppie)
    return (
      <CropperPreview {...{ handleChange, accept, resetValue, onSelect }} />
    );

  if (value)
    return <SelectedImagePreview {...{ value, handleChange, accept }} />;

  return <EmptyPreview {...{ handleChange, accept }} />;
};

export default ImageInputV2;
