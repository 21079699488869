import { useCallback, useState } from 'react';

import LocationHelpers from 'helpers/location_helpers';

const useSearch = ({
  changePredictionsShown,
  changePredictions,
  changeSearchInput
}) => {
  const onSearch = useCallback(
    value => {
      if (value === '') {
        changeSearchInput(value);
        changePredictions([]);
        changePredictionsShown(false);
      } else {
        changeSearchInput(value);
        LocationHelpers.getBasicPredictionsGivenLocationInput(
          value,
          ['(cities)'],
          predictions => {
            changePredictions(predictions);
            changePredictionsShown(true);
          }
        );
      }
    },
    [changeSearchInput, changePredictionsShown, changePredictions]
  );

  return {
    onSearch
  };
};

export default useSearch;
