import styled from 'styled-components';

import { colors } from 'helpers/colors';
import {
  handleWidth,
  indent
} from 'components/complex/slider/components/handle';
import Text from 'components/reusable/text';

export const Wrapper = styled.div`
  width: 100%;
  position: relative;
  height: 40px;
  border-radius: 20px;
  background-color: ${colors.secondary[100]};
  @media (max-width: 768px) {
    height: 32px;
  }
`;

export const Progress = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const Track = styled.div`
  background-color: ${colors.secondary[500]};
  position: absolute;
  top: 0;
  width: ${({ progress }) =>
    `calc(${progress}% + ${handleWidth + indent}px / 2)`};
  height: 100%;
  border-radius: 20px;
  pointer-events: none;
`;

export const StyledMax = styled(Text)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: max-content;
  left: calc(100% + 8px);
  font-size: 14px;
`;

export const StyledMin = styled(Text)`
  position: absolute;
  right: calc(100% + 8px);
  top: 50%;
  transform: translateY(-50%);
  width: max-content;
  font-size: 14px;
`;
