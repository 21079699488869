import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import Skeleton from 'react-loading-skeleton';

import SvgPlaySolid from 'components/reusable/svg/svg_play_solid_v2';
import { colors } from 'helpers/colors';
import Button from 'components/reusable/button';
import { heightSameAsWidth } from 'helpers/style';
import Image from 'components/reusable/image';

function ChatSharedGridItem({ onOpenAlbum, messageType, isSkeleton = false, message }) {
  if (!isSkeleton && !message) return null;
  const isVideo = message?.message_type === 'video';
  const handleOpenAlbum = useCallback(() => onOpenAlbum(message, messageType), [message, messageType]);

  const imgSrc = useMemo(() => {
    if (isSkeleton) return null;
    if (message.message_type === 'video') return message.video_preview;
    return message.message;
  }, [isSkeleton, message]);

  return (
    <GridItem type="semantic-wrapper" className="chat__shared-grid-item">
      {isSkeleton ? (
        <MobileImageWrap>
          <Skeleton width="100%" height="100%" />
        </MobileImageWrap>
      ) : (
        <>
          <MobileImageWrap onClick={handleOpenAlbum}>
            <StyledImage $isVideo={isVideo} src={imgSrc} width={256} />
          </MobileImageWrap>

          {isVideo && (
            <PlayWrapper>
              <SvgPlaySolid color={colors.light} size={20} />
            </PlayWrapper>
          )}
        </>
      )}
    </GridItem>
  );
}

const GridItem = styled(Button)`
  position: relative;
  ${heightSameAsWidth};
`;

const StyledImage = styled(Image)`
  height: 100%;
  object-fit: cover;
  object-position: center;
  filter: brightness(${({ $isVideo }) => ($isVideo ? '60%' : '100%')});
  // override transformation styles
  min-width: 100%;
  width: 100%;
  max-width: 100%;
`;

const PlayWrapper = styled.div`
  position: absolute;
  bottom: 8px;
  right: 8px;
`;

const MobileImageWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export default ChatSharedGridItem;
