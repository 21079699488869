import React from 'react';

import {
  CloseButton,
  HourlyOptLabel,
  RateDesc,
  RowWrapper,
  SliderWrapper,
  Wrapper,
  StyledInput,
  StyledError,
  FrequencyButton,
  SubmitButton,
  Label,
  Title,
  CancelButton,
  ButtonsToolbar
} from './styled';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import Text from 'components/reusable/text';
import NonLinearSlider from 'components/complex/non_linear_slider';
import FormatHelpers from 'helpers/format_helpers';
import Indent from 'components/reusable/indent';
import NumberManipulator from 'components/complex/number_manipulator';
import ApplicationHelper from 'helpers/application_helper';

function JobRateEditContent({
  breakpointsContext,
  isHourlyAvailable,
  changeSliderRate,
  frequencyLabel,
  rateTransition,
  ratesContext,
  isHourlyRate,
  setHours,
  onSubmit,
  category,
  setRate,
  onClose,
  error,
  hours,
  rate
}) {
  const slider = (
    <SliderWrapper>
      <NonLinearSlider
        minLabel={
          ApplicationHelper.isMobile
            ? null
            : `$${FormatHelpers.numberWithCommas(
                ratesContext.talentRates.min
              )} MIN`
        }
        maxLabel={
          ApplicationHelper.isMobile
            ? null
            : `$${FormatHelpers.numberWithCommas(
                ratesContext.talentRates.max
              )} MAX`
        }
        bestRange={{
          startsAt: ratesContext.talentRates.minRecommended,
          endsAt: ratesContext.talentRates.maxRecommended
        }}
        min={ratesContext.talentRates.min}
        midPoint={ratesContext.talentRates.midPoint}
        max={ratesContext.talentRates.max}
        value={rate}
        onChange={changeSliderRate}
        breakpoints={breakpointsContext.sliderBreakpoints}
      />
    </SliderWrapper>
  );

  return (
    <Wrapper>
      <CloseButton onClick={onClose} type="semantic-wrapper">
        {svgIcons.close(colors.gray[700], { width: 24, height: 24 })}
      </CloseButton>

      <Title weight={500}>
        {category?.singularTitle} {frequencyLabel}
      </Title>

      {ApplicationHelper.isMobile ? null : slider}

      {breakpointsContext.currentBreakpoint ? (
        <>
          <Label lines={2} size={14} weight={500} color={colors.secondary[500]}>
            {breakpointsContext.currentBreakpoint.label}
          </Label>
          <RateDesc size={12} lines={2} textAlign="center">
            {breakpointsContext.currentBreakpoint.message}
          </RateDesc>
        </>
      ) : null}

      <RowWrapper>
        <Text size={12} weight={500}>
          RATE
        </Text>
        <Indent right={[{ maxWidth: 768, margin: 16 }, { margin: 12 }]} />
        <StyledInput type="money" onChange={setRate} value={rate} />
      </RowWrapper>

      {isHourlyRate ? (
        <RowWrapper>
          <Text size={12} weight={500}>
            HOURS
          </Text>
          <Indent right={24} />
          <NumberManipulator onChange={setHours} value={hours} />
        </RowWrapper>
      ) : null}

      <StyledError size={12} color={colors.primary[500]}>
        {error}
      </StyledError>

      {ApplicationHelper.isMobile ? slider : null}

      {isHourlyAvailable ? (
        <FrequencyButton
          type="semantic-wrapper"
          onClick={rateTransition.onChangeRatesTransition(true)}
        >
          <HourlyOptLabel size={12} color={colors.gray[600]}>
            {ApplicationHelper.isMobile ? 'Tap' : 'Click'} here for{' '}
            {isHourlyRate ? 'day rate' : 'hourly'} option
          </HourlyOptLabel>
        </FrequencyButton>
      ) : null}

      <ButtonsToolbar>
        <CancelButton type="gray-shadow" onClick={onClose}>
          Cancel
        </CancelButton>

        <SubmitButton type="primary" onClick={onSubmit} disabled={!!error}>
          Confirm
        </SubmitButton>
      </ButtonsToolbar>
    </Wrapper>
  );
}

export default JobRateEditContent;
