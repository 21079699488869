import React, { useCallback, useMemo } from 'react';

import ProfileAlbumImage from 'components/modals/v2/album_preview_modal/components/profile_album_image';
import useAlbumPreviewContent from 'components/modals/v2/album_preview_modal/hooks/use_album_preview_content';
import useAlbumPreviewCursor from 'components/modals/v2/album_preview_modal/hooks/use_album_preview_cursor';
import useButtonListener, { btnListenerEnum } from 'hooks/use_button_listener';
import ProfileAlbumLayout from 'components/modals/v2/album_preview_modal/components/profile_album_layout';
import ProfileAlbumVideo from 'components/modals/v2/album_preview_modal/components/profile_album_video';
import SvgCrossMarkSolid from 'components/reusable/svg/svg_cross_mark_solid';
import SvgSaveOutline from 'components/reusable/svg/svg_save_outline';
import FileHelpers from 'helpers/file_helpers';
import SvgTrashOutline from 'components/reusable/svg/svg_trash_outline';

/**
 * @param assets { src: '' } || { video_url: '', video_preview: '' }
 * @param initialIndex number
 * @param label string
 * @param onDestroy func
 * @param cursorBehaviour boolean
 * @param onClose
 * @returns {JSX.Element}
 * @constructor
 */
const AlbumPreviewModal = ({
  modalProps: { initialIndex, assets, label, onDestroy, cursorBehaviour: _cursorBehaviour = true },
  onClose
}) => {
  const { asset, assetIndex, onPrev, onNext } = useAlbumPreviewContent(assets, initialIndex);
  const isVideo = !!asset.video_url;
  const size = assets.length;
  const source = isVideo ? asset.video_url : asset.src;

  const cursorBehaviour = useMemo(() => {
    if (!isVideo) return false;
    return _cursorBehaviour;
  }, [_cursorBehaviour, isVideo]);

  const [cursor, onCursorClick] = useAlbumPreviewCursor({
    cursorBehaviour,
    onClose,
    onPrev,
    onNext,
    size
  });

  const handleDestroy = useCallback(() => {
    if (onDestroy) onDestroy();
    onClose();
  }, [onDestroy]);

  const handleDownloadFile = useCallback(() => FileHelpers.triggerDownload(source), []);

  // cursor conflicts with actions by rewriting onclick events
  const actions = cursor
    ? []
    : [
        { capable: true, Icon: SvgSaveOutline, onClick: handleDownloadFile },
        { capable: !!onDestroy, Icon: SvgTrashOutline, onClick: handleDestroy },
        { capable: true, Icon: SvgCrossMarkSolid, onClick: onClose }
      ];

  useButtonListener(btnListenerEnum.esc, onClose);
  useButtonListener(btnListenerEnum.arrL, onPrev);
  useButtonListener(btnListenerEnum.arrR, onNext);

  return (
    <ProfileAlbumLayout
      onPrev={size > 1 ? onPrev : undefined}
      onNext={size > 1 ? onNext : undefined}
      cursor={cursor}
      actions={actions}
      onClose={onClose}
      label={label}
      index={assetIndex}
      size={size}
      onCursorClick={onCursorClick}
    >
      {isVideo ? <ProfileAlbumVideo {...asset} /> : <ProfileAlbumImage {...asset} />}
    </ProfileAlbumLayout>
  );
};

export default AlbumPreviewModal;
