import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import {
  initializeAlbums,
  signUpWithOauth
} from 'components/views/logged_out/SignUp/Helpers';
import SC_GA4, { actions } from 'classes/sc_ga4';
import AuthHelper from 'helpers/auth_helper';
import errors from 'helpers/errors_helper';
import { modalEnum } from 'components/modals/v2/modal';
import { LocalStorage } from 'classes/sc_localstorage';
import ImageUploadHelpers from 'helpers/image_upload_helpers';
import { handleAuthRedirect } from 'components/modals/v2/auth/sign_up_by_email_modal/hooks/use_submit';

const useOauthSignup = ({ dispatch, modalContext, rootContext, setError }) => {
  const history = useHistory();

  const _sendEvent = useCallback(oauth => {
    SC_GA4.sendMetric(actions.userCreateFunnel.generalInfo, {
      signup_divided_steps_flow: true,
      ...(oauth && { oauth })
    });
  }, []);

  const _handleLogin = useCallback(
    oauth => {
      AuthHelper.performLogin({ ...oauth, client: 'desktop' }, () => {
        rootContext.setLoading(false);
        modalContext.onCloseModal();
        handleAuthRedirect(history);
      });
    },
    [modalContext.onCloseModal, rootContext.setLoading, history.replace]
  );

  const _handleSignUp = useCallback(async oauth => {
    const { google_token_id, instagram_auth_code } = oauth;

    const referralCode = LocalStorage.fetchReferralCode();
    const referralProvided = !!referralCode && referralCode.length > 0;
    const signUp = await signUpWithOauth(
      {
        ...(referralProvided && { referral_code: referralCode })
      },
      { google_token_id, instagram_auth_code }
    );
    return signUp;
  }, []);

  const onOauthSignUp = useCallback(
    async oauth => {
      rootContext.setLoading(true);
      const signUp = await _handleSignUp(oauth);

      const emailTaken = signUp.message === errors.user.emailTaken;
      if (emailTaken) return _handleLogin(oauth);
      if (signUp.error) return setError(signUp.message);

      await ImageUploadHelpers.uploadImageFromGoogle(oauth.google_token_id);
      _sendEvent('google');
      LocalStorage.setNewGuestPostingUserAB();
      handleAuthRedirect(history);
      rootContext.setLoading(false);
      modalContext.onOpenModal(modalEnum.signUpAdditionalDetails);
    },
    [dispatch, modalContext.onOpenModal, _sendEvent, setError, _handleLogin]
  );

  return onOauthSignUp;
};

export default useOauthSignup;
