import React from 'react';

import { colors } from 'helpers/colors';

function SvgFilePdfOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.3334 1V7.66667C16.3334 8.10869 16.509 8.53262 16.8215 8.84518C17.1341 9.15774 17.558 9.33333 18 9.33333H24.6667M16.3334 1H4.66671C3.78265 1 2.93481 1.35119 2.30968 1.97631C1.68456 2.60143 1.33337 3.44928 1.33337 4.33333V16M16.3334 1L24.6667 9.33333M24.6667 9.33333V16M1.33337 26H3.83337C4.49642 26 5.1323 25.7366 5.60114 25.2678C6.06998 24.7989 6.33337 24.163 6.33337 23.5C6.33337 22.837 6.06998 22.2011 5.60114 21.7322C5.1323 21.2634 4.49642 21 3.83337 21H1.33337V31M21.3334 26H24.6667M26.3334 21H21.3334V31M11.3334 21V31H13C13.8841 31 14.7319 30.6488 15.3571 30.0237C15.9822 29.3986 16.3334 28.5507 16.3334 27.6667V24.3333C16.3334 23.4493 15.9822 22.6014 15.3571 21.9763C14.7319 21.3512 13.8841 21 13 21H11.3334Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFilePdfOutline;
