import React from 'react';
import styled from 'styled-components';

import Flex from 'components/reusable/flex';
import Image from 'components/reusable/image';
import AuthHelper from 'helpers/auth_helper';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';

const Container = styled(Flex)`
  cursor: pointer;
  padding: 4px 6px;
  gap: 8px;
  border: 1px solid #b4b1b1;
  border-radius: 20px;
`;

const ImageWrap = styled(Image)`
  width: 30px;
  height: 30px;
  border-radius: 50%;
`;

const UserBlock = ({ openHamburger }) => {
  return (
    <Container wrap="nowrap" onClick={openHamburger}>
      {svgIcons.burgerMenu(colors.gray[300])}

      {AuthHelper.logged_in_profile_img ? (
        <ImageWrap width={30} src={AuthHelper.logged_in_profile_img} />
      ) : (
        <ImageWrap src="/images/icons/circle_profile.png" />
      )}
    </Container>
  );
};

export default UserBlock;
