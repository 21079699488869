import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileGifOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 1V5C10 5.26522 10.1054 5.51957 10.2929 5.70711C10.4804 5.89464 10.7348 6 11 6H15M10 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V10M10 1L15 6M15 6V10M4 13H2.5C2.10218 13 1.72064 13.158 1.43934 13.4393C1.15804 13.7206 1 14.1022 1 14.5V17.5C1 17.8978 1.15804 18.2794 1.43934 18.5607C1.72064 18.842 2.10218 19 2.5 19H4V16H3.25M8 13V19M12 16H14.25M15 13H12V19"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileGifOutline;
