import { ProjectActionTypes } from 'helpers/constants/action_types/project';

export const ProjectActions = {
  FETCH: payload => ({
    payload,
    type: ProjectActionTypes.FETCH
  }),

  FETCH_FAILURE: () => ({
    type: ProjectActionTypes.FETCH_FAILURE
  }),

  FETCH_SUCCESS: payload => ({
    payload,
    type: ProjectActionTypes.FETCH_SUCCESS
  }),

  FETCH_BY_SECRET_ALIAS_ID: payload => ({
    payload,
    type: ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID
  }),

  FETCH_BY_SECRET_ALIAS_ID_FAILURE: () => ({
    type: ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID_FAILURE
  }),

  FETCH_BY_SECRET_ALIAS_ID_SUCCESS: payload => ({
    payload,
    type: ProjectActionTypes.FETCH_BY_SECRET_ALIAS_ID_SUCCESS
  }),

  UPDATE: (id, payload, onSuccessCallback, onErrorCallback) => ({
    id,
    payload,
    type: ProjectActionTypes.UPDATE,
    onSuccessCallback,
    onErrorCallback
  }),

  UPDATE_FAILURE: () => ({
    type: ProjectActionTypes.UPDATE_FAILURE
  }),

  UPDATE_SUCCESS: payload => ({
    payload,
    type: ProjectActionTypes.UPDATE_SUCCESS
  })
};
