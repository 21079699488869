export type PayloadTypes = any

/**
@description PLEASE do not add additional actions.
 You should only use basic CRUD operations on the backend
 Check SOLID for interface segregation
 If you think about it, that means that you are doing something wrong
 also don't ever add dependencies (other entities) to a serializer
*/

export type ErrorTypes =
| {
  [key: string]: any
}
| string

export type MetaTypes = {
  endpoint: string;
  thunk: true;
  method?: string;
}

export type ActionTypes = {
  payload?: PayloadTypes;
  error?: true;
  meta: MetaTypes;
  type: string;
}

export interface ActionsTypes {
  RESOURCE_APPEND_REQUEST: string;
  RESOURCE_APPEND_SUCCESS: string;
  RESOURCE_APPEND_FAILURE: string;

  RESOURCE_FETCH_REQUEST: string;
  RESOURCE_FETCH_SUCCESS: string;
  RESOURCE_FETCH_FAILURE: string;

  RESOURCE_GET_REQUEST: string;
  RESOURCE_GET_SUCCESS: string;
  RESOURCE_GET_FAILURE: string;

  RESOURCE_CREATE_REQUEST: string;
  RESOURCE_CREATE_FAILURE: string;
  RESOURCE_CREATE_SUCCESS: string;

  RESOURCE_DELETE_REQUEST: string;
  RESOURCE_DELETE_SUCCESS: string;
  RESOURCE_DELETE_FAILURE: string;

  RESOURCE_UPDATE_REQUEST: string;
  RESOURCE_UPDATE_SUCCESS: string;
  RESOURCE_UPDATE_FAILURE: string;

  RESOURCE_RESET_SUCCESS: string;

  resourceAppendRequest: (endpoint: string, payload?: PayloadTypes) => ActionTypes;
  resourceAppendSuccess: (payload: PayloadTypes, meta?: MetaTypes) => ActionTypes;
  resourceAppendFailure: (error: ErrorTypes, meta: MetaTypes) => ActionTypes;

  resourceFetchRequest: (endpoint: string, payload?: PayloadTypes) => ActionTypes;
  resourceFetchSuccess: (payload: PayloadTypes, meta: MetaTypes) => ActionTypes;
  resourceFetchFailure: (error: ErrorTypes, meta: MetaTypes) => ActionTypes;

  resourceGetRequest: (endpoint: string) => ActionTypes;
  resourceGetSuccess: (payload: PayloadTypes, meta: MetaTypes) => ActionTypes;
  resourceGetFailure: (error: ErrorTypes, meta: MetaTypes) => ActionTypes;

  resourceCreateRequest: (endpoint: string, payload: PayloadTypes) => ActionTypes;
  resourceCreateSuccess: (payload: PayloadTypes, meta: MetaTypes) => ActionTypes;
  resourceCreateFailure: (error: ErrorTypes, meta: MetaTypes) => ActionTypes;

  resourceDeleteRequest: (endpoint: string) => ActionTypes;
  resourceDeleteSuccess: (payload: PayloadTypes, meta: MetaTypes) => ActionTypes;
  resourceDeleteFailure: (error: ErrorTypes, meta: MetaTypes) => ActionTypes;

  resourceUpdateRequest: (endpoint: string, payload: PayloadTypes) => ActionTypes;
  resourceUpdateSuccess: (payload: PayloadTypes, meta: MetaTypes) => ActionTypes;
  resourceUpdateFailure: (error: ErrorTypes, meta: MetaTypes) => ActionTypes;

  resourceResetSuccess: () => Omit<ActionTypes, "meta">;
}

function buildResourceActions(pureResource: string): ActionsTypes {
  const resource = pureResource.toUpperCase()

  const RESOURCE_APPEND_REQUEST = `${resource}_APPEND_REQUEST`
  const RESOURCE_APPEND_SUCCESS = `${resource}_APPEND_SUCCESS`
  const RESOURCE_APPEND_FAILURE = `${resource}_APPEND_FAILURE`

  const RESOURCE_FETCH_REQUEST = `${resource}_FETCH_REQUEST`
  const RESOURCE_FETCH_SUCCESS = `${resource}_FETCH_SUCCESS`
  const RESOURCE_FETCH_FAILURE = `${resource}_FETCH_FAILURE`

  const RESOURCE_GET_REQUEST = `${resource}_GET_REQUEST`
  const RESOURCE_GET_SUCCESS = `${resource}_GET_SUCCESS`
  const RESOURCE_GET_FAILURE = `${resource}_GET_FAILURE`

  const RESOURCE_CREATE_REQUEST = `${resource}_CREATE_REQUEST`
  const RESOURCE_CREATE_SUCCESS = `${resource}_CREATE_SUCCESS`
  const RESOURCE_CREATE_FAILURE = `${resource}_CREATE_FAILURE`

  const RESOURCE_DELETE_REQUEST = `${resource}_DELETE_REQUEST`
  const RESOURCE_DELETE_SUCCESS = `${resource}_DELETE_SUCCESS`
  const RESOURCE_DELETE_FAILURE = `${resource}_DELETE_FAILURE`

  const RESOURCE_UPDATE_REQUEST = `${resource}_UPDATE_REQUEST`
  const RESOURCE_UPDATE_SUCCESS = `${resource}_UPDATE_SUCCESS`
  const RESOURCE_UPDATE_FAILURE = `${resource}_UPDATE_FAILURE`

  const RESOURCE_RESET_SUCCESS = `${resource}_SUCCESS_SUCCESS`

  return {
    RESOURCE_APPEND_REQUEST,
    RESOURCE_APPEND_SUCCESS,
    RESOURCE_APPEND_FAILURE,

    resourceAppendRequest: (endpoint: string, payload: PayloadTypes) => ({
      type: RESOURCE_APPEND_REQUEST,
      payload,
      meta: {
        endpoint,
        thunk: true,
      },
    }),

    resourceAppendSuccess: (payload: PayloadTypes, meta: MetaTypes) => ({
      type: RESOURCE_APPEND_SUCCESS,
      payload,
      meta,
    }),

    resourceAppendFailure: (error: ErrorTypes, meta: MetaTypes) => ({
      type: RESOURCE_APPEND_FAILURE,
      payload: error,
      error: true,
      meta,
    }),

    RESOURCE_FETCH_REQUEST,
    RESOURCE_FETCH_SUCCESS,
    RESOURCE_FETCH_FAILURE,

    resourceFetchRequest: (endpoint: string, payload: PayloadTypes) => ({
      type: RESOURCE_FETCH_REQUEST,
      payload,
      meta: {
        endpoint,
        thunk: true,
      },
    }),

    resourceFetchSuccess: (payload: PayloadTypes, meta: MetaTypes) => ({
      type: RESOURCE_FETCH_SUCCESS,
      payload,
      meta,
    }),

    resourceFetchFailure: (error: ErrorTypes, meta: MetaTypes) => ({
      type: RESOURCE_FETCH_FAILURE,
      payload: error,
      error: true,
      meta,
    }),

    RESOURCE_GET_REQUEST,
    RESOURCE_GET_SUCCESS,
    RESOURCE_GET_FAILURE,

    resourceGetRequest: (endpoint: string) => ({
      type: RESOURCE_GET_REQUEST,
      meta: {
        endpoint,
        thunk: true,
      },
    }),

    resourceGetSuccess: (payload: PayloadTypes, meta: MetaTypes) => ({
      type: RESOURCE_GET_SUCCESS,
      payload,
      meta,
    }),

    resourceGetFailure: (error: ErrorTypes, meta: MetaTypes) => ({
      type: RESOURCE_GET_FAILURE,
      payload: error,
      error: true,
      meta,
    }),

    RESOURCE_CREATE_REQUEST,
    RESOURCE_CREATE_SUCCESS,
    RESOURCE_CREATE_FAILURE,

    resourceCreateRequest: (endpoint: string, payload: PayloadTypes) => ({
      type: RESOURCE_CREATE_REQUEST,
      payload,
      meta: {
        endpoint,
        thunk: true,
      },
    }),

    resourceCreateSuccess: (payload: PayloadTypes, meta: MetaTypes) => ({
      type: RESOURCE_CREATE_SUCCESS,
      payload,
      meta,
    }),

    resourceCreateFailure: (error: ErrorTypes, meta: MetaTypes) => ({
      type: RESOURCE_CREATE_FAILURE,
      payload: error,
      error: true,
      meta,
    }),

    RESOURCE_DELETE_REQUEST,
    RESOURCE_DELETE_SUCCESS,
    RESOURCE_DELETE_FAILURE,

    resourceDeleteRequest: (endpoint: string) => ({
      type: RESOURCE_DELETE_REQUEST,
      meta: {
        endpoint,
        thunk: true,
      },
    }),

    resourceDeleteSuccess: (payload: PayloadTypes, meta: MetaTypes) => ({
      type: RESOURCE_DELETE_SUCCESS,
      payload,
      meta,
    }),

    resourceDeleteFailure: (error: ErrorTypes, meta: MetaTypes) => ({
      type: RESOURCE_DELETE_FAILURE,
      payload: error,
      error: true,
      meta,
    }),

    RESOURCE_UPDATE_REQUEST,
    RESOURCE_UPDATE_SUCCESS,
    RESOURCE_UPDATE_FAILURE,

    resourceUpdateRequest: (endpoint: string, payload: PayloadTypes) => ({
      type: RESOURCE_UPDATE_REQUEST,
      payload,
      meta: {
        endpoint,
        thunk: true,
      },
    }),

    resourceUpdateSuccess: (payload: PayloadTypes, meta: MetaTypes) => ({
      type: RESOURCE_UPDATE_SUCCESS,
      payload,
      meta,
    }),

    resourceUpdateFailure: (error: ErrorTypes, meta: MetaTypes) => ({
      type: RESOURCE_UPDATE_FAILURE,
      payload: error,
      error: true,
      meta,
    }),

    RESOURCE_RESET_SUCCESS,

    resourceResetSuccess: () => ({ type: RESOURCE_RESET_SUCCESS }),
  }
}

export default buildResourceActions