import React, { useContext } from 'react';

import {
  Banner,
  StyledButton,
  StyledLink,
  IconsWrapper,
  StyledText,
  TextAndIconsWrapper
} from 'components/static/ua_banner/styled';
import Indent from 'components/reusable/indent';
import svgIcons from 'helpers/svgIcons';
import { colors } from 'helpers/colors';
import { RootContext, UaBannerContext } from 'contexts/contexts';
import ApplicationHelper from 'helpers/application_helper';
import { styleDimensions } from 'helpers/style';

function UsBannerMobile() {
  const uaBannerContext = useContext(UaBannerContext);
  const rootContext = useContext(RootContext);
  const bottomOffset =
    ApplicationHelper.isMobile && rootContext.isMobileFooterEnabled
      ? styleDimensions.mobileBarHeight
      : '0px';

  return (
    <Banner bottomOffset={bottomOffset}>
      <TextAndIconsWrapper>
        <IconsWrapper>
          {svgIcons.uaFlag({ width: 22, height: 22 })}
          {svgIcons.doveOfPeace({ width: 22, height: 22 })}
        </IconsWrapper>

        <StyledText size={12} weight={400}>
          Take Action Now: Support Ukraine's Independence and Peace.
        </StyledText>
        <Indent right={12} />
      </TextAndIconsWrapper>

      <StyledLink blank colo href="https://savelife.in.ua/donate">
        Click to Help.
      </StyledLink>

      <StyledButton
        onClick={uaBannerContext.onCloseBanner}
        type="semantic-wrapper"
      >
        {svgIcons.close(colors.gray[700], { width: 16, height: 16 })}
      </StyledButton>
    </Banner>
  );
}

export default UsBannerMobile;
