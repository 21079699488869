import React from 'react';

import { colors } from 'helpers/colors';

function SvgCourtHouseSolid({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
      <path
        d="M4.9478 14.9979H3.6363C3.52389 14.9979 3.46143 14.9103 3.46143 14.8228V8.19222C3.46143 8.07962 3.54887 8.01709 3.6363 8.01709H4.9478C5.0602 8.01709 5.12267 8.10464 5.12267 8.19222V14.7977C5.13516 14.9103 5.04771 14.9979 4.9478 14.9979Z"
        fill={color}
      />
      <path
        d="M8.81959 14.9979H7.5081C7.39569 14.9979 7.33337 14.9103 7.33337 14.8228V8.19222C7.33337 8.07962 7.42067 8.01709 7.5081 8.01709H8.81959C8.932 8.01709 8.99447 8.10464 8.99447 8.19222V14.7977C8.99447 14.9103 8.90702 14.9979 8.81959 14.9979Z"
        fill={color}
      />
      <path
        d="M12.6664 14.9979H11.3551C11.2426 14.9979 11.1802 14.9103 11.1802 14.8228V8.19222C11.1802 8.07962 11.2676 8.01709 11.3551 8.01709H12.6664C12.7788 8.01709 12.8413 8.10464 12.8413 8.19222V14.7977C12.8662 14.9103 12.7788 14.9979 12.6664 14.9979Z"
        fill={color}
      />
      <path
        d="M16.5259 14.9979H15.2144C15.102 14.9979 15.0396 14.9103 15.0396 14.8228V8.19222C15.0396 8.07962 15.127 8.01709 15.2144 8.01709H16.5259C16.6383 8.01709 16.7008 8.10464 16.7008 8.19222V14.7977C16.7133 14.9103 16.6258 14.9979 16.5259 14.9979Z"
        fill={color}
      />
      <path
        d="M17.0754 5.47749V5.32733C17.0754 5.2898 17.0504 5.2648 17.0379 5.2648L10.0436 2.5L2.96198 5.2648C2.937 5.28982 2.92447 5.30231 2.92447 5.32733V5.47749C2.92447 5.51502 2.87442 5.56503 2.83695 5.56503H2.59972C2.56225 5.56503 2.51221 5.60257 2.51221 5.65262V7.21642H17.5V5.65262C17.5 5.61508 17.4501 5.56503 17.4126 5.56503H17.1504C17.1254 5.56503 17.0754 5.51502 17.0754 5.47749Z"
        fill={color}
      />
      <path d="M17.4878 15.8737H2.5V17.5H17.4878V15.8737Z" fill={color} />
    </svg>
  );
}

export default SvgCourtHouseSolid;
