import React, { useMemo, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import ApplicationHelper from 'helpers/application_helper';
import AuthHelper from 'helpers/auth_helper';
import { routes } from 'helpers/router_helper';
import CitiesBlock from '../components/cities_block';
import DownloadAppBlock from '../components/download_app_block';
import { AbContext } from 'contexts/contexts';
import { variations } from 'hocs/with_ab_provider';

const useContentMapping = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const isMobile = ApplicationHelper.isMobile;
  const isLoggedIn = AuthHelper.isLoggedIn();
  const abContext = useContext(AbContext);

  const footerContentMapping = useMemo(
    () => [
      {
        hasMainBlock: true,
        extraBlock: CitiesBlock,
        condition:
          abContext.lpVariation === variations.lp.a && isMobile
            ? false
            : pathname === '/' && !isLoggedIn,
        pageLabel: routes.logged_out.landing.label
      },
      {
        hasMainBlock: !isMobile,
        condition:
          abContext.lpVariation === variations.lp.a && isMobile
            ? false
            : pathname === '/' &&
              isLoggedIn &&
              !AuthHelper.currentUser()?.waitlisted,
        pageLabel: routes.discover.base.label
      },
      {
        hasMainBlock: true,
        extraBlock: DownloadAppBlock,
        condition:
          abContext.lpVariation === variations.lp.a && isMobile
            ? false
            : pathname === routes.logged_out.swipecastPro.path,
        pageLabel: routes.logged_out.swipecastPro.label
      }
    ],
    [pathname, isMobile, isLoggedIn, abContext.lpVariation]
  );

  return footerContentMapping;
};

export default useContentMapping;
