import queryString from 'query-string';

/**
 * @description represents operations on query parameters
 */
const QueryHelpers = {
  getParams(searchParams) {
    const search = searchParams || window.location.search;
    return queryString.parse(search);
  },

  getParam(key, searchParams) {
    const search = searchParams || window.location.search;
    return this.getParams(search)[key];
  },

  appendParams(appendParams = {}, searchParams) {
    const search = searchParams || window.location.search;
    return new URLSearchParams({
      ...this.getParams(search),
      ...appendParams
    }).toString();
  },

  getNavActiveness(nav) {
    return window.location.search.includes(`${QueryHelpers.keys.nav}=${nav}`);
  },

  replaceParams(replaceParams = {}) {
    const newParams = QueryHelpers.appendParams(replaceParams);
    window.history.replaceState(
      null,
      null,
      `${window.location.pathname}?${newParams}`
    );
  },

  keys: {
    nav: 'navigation', // used to highlight active navigation item
    redirectUrl: 'redirect_uri', // undefined considered as '/'
    successScreen: 'success_screen' // undefined considered as true
  }
};

export default QueryHelpers;
