import { useMemo } from 'react';

import DateHelpers from 'helpers/date_helpers';

const useSharedFileGroups = (messages, messageType) => {
  const messageGroups = useMemo(() => {
    const groups = {};
    messages.forEach(message => {
      const dateLabel = DateHelpers.timeStampToLabelFull(new Date(message.created_at));
      if (!groups[dateLabel]) groups[dateLabel] = [];

      if (message.message_type === messageType) groups[dateLabel].push(message);
      else if (messageType === 'link' && message.message_links.length) {
        groups[dateLabel].push({ ...message, message_type: 'link' });
      }
    });

    Object.entries(groups).forEach(([dateLabel, messages]) => {
      if (!messages.length) delete groups[dateLabel];
    });

    return groups;
  }, [messages, messageType]);

  return messageGroups;
};

export default useSharedFileGroups;
