import { useCallback, useEffect, useState } from 'react';
import ApplicationHelper from 'helpers/application_helper';

const useAdaptiveDesign = (DesktopComponent, MobileComponent) => {
  const [screenWidth, setScreenWidth] = useState(getWindowWidth());
  const handleScreenChange = useCallback(() => setScreenWidth(getWindowWidth()), []);

  const isMobile = screenWidth <= ApplicationHelper.MOBILE_WIDTH;

  useEffect(() => {
    window.addEventListener('resize', handleScreenChange);
    return () => window.removeEventListener('resize', handleScreenChange);
  }, []);

  if (!MobileComponent) {
    console.warn('Mobile responsive component is missing');
    return DesktopComponent;
  }
  return isMobile ? MobileComponent : DesktopComponent;
};

function getWindowWidth() {
  return window.visualViewport.width;
}

export default useAdaptiveDesign;
