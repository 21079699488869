import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Text from 'components/reusable/text';
import Indent from 'components/reusable/indent';
import Button from 'components/reusable/button';
import { routes } from 'helpers/router_helper';
import { colors } from 'helpers/colors';
import { Column } from 'components/reusable/legacy/header/hamburger/hamburger_menu';
import ApplicationHelper from 'helpers/application_helper';
import Flex from 'components/reusable/flex';
import AuthHelper from 'helpers/auth_helper';
import LocationHelpers from 'helpers/location_helpers';
import { LocalStorage } from 'classes/sc_localstorage';
import Avatar from 'components/complex/user/avatar';
import Link from 'components/reusable/link';
import UserHelpers from 'helpers/user_helpers';

function HamburgerMenuHeader() {
  const history = useHistory();
  const currentUser = AuthHelper.currentUser();
  const location = LocationHelpers.getLocationLabel(currentUser).toUpperCase();
  const onViewProfile = useCallback(() => history.push(routes.myProfile.path), []);

  const username = [AuthHelper.logged_in_firstName, AuthHelper.logged_in_lastName].join(' ').toUpperCase();

  return (
    <Wrapper>
      <Flex flex={1} jc="space-between" wrap="no-wrap" ai="flex-start">
        <Column isMobile={ApplicationHelper.isMobile}>
          <Text size={16} weight={500} lineHeight="140%">
            {username}
          </Text>
          <Indent bottom={4} />
          <Flex ai="flex-start" dir="column">
            <Text size={10} lineHeight="130%">
              {UserHelpers.getUserTitle(AuthHelper.currentUser()).toUpperCase()}
            </Text>
            {location && (
              <>
                <Indent top={4} />
                <Text size={10} lineHeight="130%">
                  {location}
                </Text>
              </>
            )}
          </Flex>
          <Indent bottom={16} />

          <Button type="semantic-wrapper" onClick={onViewProfile}>
            <Text weight={500} style={{ cursor: 'pointer' }} size={12} lineHeight="130%" color={colors.primary[500]}>
              View & Edit
            </Text>
          </Button>
        </Column>

        <StyledLink to={routes.userProfile.redirectUrl(currentUser.public_alias_id)}>
          <Avatar user={currentUser} width={80} />
        </StyledLink>
      </Flex>
    </Wrapper>
  );
}

const StyledLink = styled(Link)`
  margin: auto 0;
`;

const Wrapper = styled.div`
  display: flex;
  box-shadow: 0px 2px 8px rgba(31, 30, 30, 0.08);
  padding: 26px 16px 16px;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

export default HamburgerMenuHeader;
