import React from 'react';

import { colors } from 'helpers/colors';

function SvgCopyOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg height={size} viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.84001 0.675333C4.25048 0.245009 4.81082 0 5.39875 0H9.14979C9.72897 7.56022e-05 10.2818 0.237959 10.6909 0.65723L13.3449 3.37842C13.5534 3.592 13.7181 3.84614 13.8302 4.12546C13.9424 4.40475 14 4.70413 14 5.00617V12.3575C14 12.9581 13.7726 13.5374 13.3627 13.9672C12.9522 14.3975 12.3919 14.6425 11.8039 14.6425H10.7973V15.715C10.7973 16.3156 10.5699 16.8949 10.16 17.3247C9.74952 17.755 9.18918 18 8.60125 18H2.19606C1.60814 18 1.0478 17.755 0.637331 17.3247C0.227417 16.8949 0 16.3156 0 15.715V6.48187C0 5.88128 0.227417 5.30197 0.637331 4.87222C1.0478 4.4419 1.60814 4.19689 2.19606 4.19689H3.20268V2.28497C3.20268 1.68439 3.4301 1.10508 3.84001 0.675333ZM3.20268 5.40933H2.19606C1.93461 5.40933 1.68036 5.51805 1.49022 5.71739C1.29953 5.9173 1.18954 6.19199 1.18954 6.48187V15.715C1.18954 16.0049 1.29953 16.2796 1.49022 16.4795C1.68036 16.6788 1.93461 16.7876 2.19606 16.7876H8.60125C8.86271 16.7876 9.11696 16.6788 9.3071 16.4795C9.49779 16.2796 9.60778 16.0049 9.60778 15.715V14.6425H5.39875C4.81082 14.6425 4.25048 14.3975 3.84001 13.9672C3.4301 13.5374 3.20268 12.9581 3.20268 12.3575V5.40933ZM11.8039 13.4301C12.0654 13.4301 12.3196 13.3213 12.5098 13.122C12.7005 12.9221 12.8105 12.6474 12.8105 12.3575V5.00631C12.8105 5.00629 12.8105 5.00633 12.8105 5.00631C12.8104 4.86108 12.7827 4.71765 12.7293 4.58471C12.676 4.45179 12.5982 4.33251 12.5016 4.23344L9.84733 1.5121C9.65805 1.31807 9.40736 1.21249 9.14979 1.21244C9.14976 1.21244 9.14981 1.21244 9.14979 1.21244H5.39875C5.13729 1.21244 4.88304 1.32116 4.6929 1.5205C4.50221 1.72041 4.39222 1.9951 4.39222 2.28497V12.3575C4.39222 12.6474 4.50221 12.9221 4.6929 13.122C4.88304 13.3213 5.13729 13.4301 5.39875 13.4301H11.8039Z"
        fill={color}
      />
    </svg>
  );
}

export default SvgCopyOutline;
