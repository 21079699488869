import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileWebpOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 24 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14 1V5C14 5.26522 14.1054 5.51957 14.2929 5.70711C14.4804 5.89464 14.7348 6 15 6H19M14 1H7C6.46957 1 5.96086 1.21071 5.58579 1.58579C5.21071 1.96086 5 2.46957 5 3V10M14 1L19 6M19 6V10M1 13L2.55517 19L4.5 14.2L6.44483 19L8 13M13 13H10V19H13M10 16H12M16.5 16C16.8978 16 17.2794 16.158 17.5607 16.4393C17.842 16.7206 18 17.1022 18 17.5C18 17.8978 17.842 18.2794 17.5607 18.5607C17.2794 18.842 16.8978 19 16.5 19H15V13H16.5C16.8978 13 17.2794 13.158 17.5607 13.4393C17.842 13.7206 18 14.1022 18 14.5C18 14.8978 17.842 15.2794 17.5607 15.5607C17.2794 15.842 16.8978 16 16.5 16ZM16.5 16H15M20 16H21.5C21.8978 16 22.2794 15.842 22.5607 15.5607C22.842 15.2794 23 14.8978 23 14.5C23 14.1022 22.842 13.7206 22.5607 13.4393C22.2794 13.158 21.8978 13 21.5 13H20V19"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileWebpOutline;
