import { useCallback } from 'react';

import LocationHelpers from 'helpers/location_helpers';

const { google } = window;

const usePredictions = ({
  changeSearchInput,
  changePredictionsShown,
  onChange,
  mapRef
}) => {
  const onPredictionClick = useCallback(
    prediction => {
      const service = new google.maps.places.PlacesService(mapRef.current);
      service.getDetails({ placeId: prediction.placeID }, details => {
        changePredictionsShown(false);
        changeSearchInput(details.formatted_address);

        onChange(
          LocationHelpers.combinePlaceServiceDetailsAndPrediction(
            details,
            prediction
          )
        );
      });
    },
    [changeSearchInput, changePredictionsShown, onChange]
  );

  return {
    onPredictionClick
  };
};

export default usePredictions;
