import React from 'react';
import { Helmet } from 'react-helmet';

function MetaTagsTemplate({ title, description, image }) {
  const imageURL = image || `${window.location.origin}/images/landing/top_slide/model3.png`;
  const path = `${window.location.origin}${window.location.pathname}`; // Need search params ?
  return (
    <Helmet>
      <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
      <link rel="canonical" href={path} />
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      <meta property="og:type" content="company" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:locale:alternate" content="en_GB" />
      {title && <meta property="og:title" content={title} />}
      <meta property="og:url" content={path} />
      <meta property="og:site_name" content="swipecast.com" />
      <meta property="og:image" content={imageURL} />
      {description && <meta property="og:description" content={description} />}
      <meta property="twitter:card" content="summary" />
      <meta name="twitter:site" content="@swipecast" />
      {title && <meta property="twitter:title" content={title} />}
      {description && <meta property="twitter:description" content={description} />}
      <meta property="twitter:image" content={imageURL} />
    </Helmet>
  );
}

export default MetaTagsTemplate;
