import React from 'react';
import styled from 'styled-components';

import Error from 'components/reusable/error';
import Input from 'components/reusable/input';

const Wrapper = styled.div`
  position: relative;
  ${({ $width }) => $width && `width: ${$width}`};
`;

const ErrorComponent = ({ children, ...props }) => {
  if (typeof children === 'string')
    return <Error children={children} {...props} />;
  return children;
};

const StyledError = styled(ErrorComponent)`
  position: absolute;
  bottom: -2px;
  left: 16px;
  transform: translateY(100%);
  ${({ width }) => width && `width: ${width}`};
`;

const ReduxField = ({
  error,
  width,
  name,
  input = {},
  type,
  placeholder,
  meta,
  errorWidth,
  ...props
}) => {
  const inputProps = {
    id: name,
    name,
    width,
    type,
    placeholder,
    ...props
  };
  const serverValidationFailed = Array.isArray(error)
    ? !!error.length
    : !!error;
  const msg = meta.error || (Array.isArray(error) ? error[0] : error);
  const invalid =
    meta.touched && (serverValidationFailed || (msg && msg !== ''));
  // const invalid = true
  // const msg = 'Some very lllllloooooooooooonnnnnnggggg error'

  const buildError = () =>
    invalid && (
      <StyledError width={errorWidth} id={`${name}Error`}>
        {msg}
      </StyledError>
    );

  return (
    <Wrapper $width={width}>
      <Input {...inputProps} {...input} />
      {buildError()}
    </Wrapper>
  );
};

export default ReduxField;
