import React from 'react';

import { colors } from 'helpers/colors';

function SvgFileDocxOutline({ size = 30, color = colors.gray[700] }) {
  return (
    <svg width={size} viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13 1V5C13 5.26522 13.1054 5.51957 13.2929 5.70711C13.4804 5.89464 13.7348 6 14 6H18M13 1H6C5.46957 1 4.96086 1.21071 4.58579 1.58579C4.21071 1.96086 4 2.46957 4 3V10M13 1L18 6M18 6V10M16 14.5C16 14.1022 15.842 13.7206 15.5607 13.4393C15.2794 13.158 14.8978 13 14.5 13C14.1022 13 13.7206 13.158 13.4393 13.4393C13.158 13.7206 13 14.1022 13 14.5V17.5C13 17.8978 13.158 18.2794 13.4393 18.5607C13.7206 18.842 14.1022 19 14.5 19C14.8978 19 15.2794 18.842 15.5607 18.5607C15.842 18.2794 16 17.8978 16 17.5M18.5 13L21.5 19M18.5 19L21.5 13M1 13V19H2C2.53043 19 3.03914 18.7893 3.41421 18.4142C3.78929 18.0391 4 17.5304 4 17V15C4 14.4696 3.78929 13.9609 3.41421 13.5858C3.03914 13.2107 2.53043 13 2 13H1ZM8.5 13C8.89782 13 9.27936 13.158 9.56066 13.4393C9.84196 13.7206 10 14.1022 10 14.5V17.5C10 17.8978 9.84196 18.2794 9.56066 18.5607C9.27936 18.842 8.89782 19 8.5 19C8.10218 19 7.72064 18.842 7.43934 18.5607C7.15804 18.2794 7 17.8978 7 17.5V14.5C7 14.1022 7.15804 13.7206 7.43934 13.4393C7.72064 13.158 8.10218 13 8.5 13Z"
        stroke={color}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SvgFileDocxOutline;
