import {
  useSharedFilesSelector,
  useSharedLinksSelector,
  useSharedPhotosSelector,
  useSharedVideosSelector
} from 'store/sagas/selectors';

const useSharedFilesSkeleton = messages => {
  const sharedPhotosSlice = useSharedPhotosSelector();
  const sharedVideosSlice = useSharedVideosSelector();
  const sharedFilesSlice = useSharedFilesSelector();
  const sharedLinksSlice = useSharedLinksSelector();
  const slices = [sharedPhotosSlice, sharedVideosSlice, sharedFilesSlice, sharedLinksSlice];

  const countLoaded = slices.every(item => typeof item.meta.count === 'number');
  const loading = slices.some(s => s.isLoading);
  const initializing = messages.length === 0 && loading;
  const paginating = loading && messages.length === 1; // when we make a count request we cant send per_page at least 1
  const isHeaderSkeleton = !countLoaded;
  const isMainSkeleton = initializing || isHeaderSkeleton || paginating;

  return [isMainSkeleton, isHeaderSkeleton];
};

export default useSharedFilesSkeleton;
