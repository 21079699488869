import { deviceType } from 'react-device-detect';

import Settings from 'helpers/settings';
import ApplicationHelper from 'helpers/application_helper';
import { isObject } from 'helpers/underscore';

const getDeviceType = () => {
  return deviceType;

  // const ua = navigator.userAgent;
  // if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
  //   return 'tablet';
  // }
  // // TODO change this condition. Sometimes it defines mac m1 with Chrome as mobile device
  // if (
  //   /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
  //     ua
  //   )
  // ) {
  //   return 'mobile';
  // }
  // return 'desktop';
};

// https://docs.google.com/spreadsheets/d/1U3Bzlkjmz9pVjH4ezD9E5_bedd5KeDJ4fXwBdAk_SRQ/edit#gid=0
export const actions = {
  share: 'share',
  purchase: 'purchase',
  projectCreateFunnel: {
    init: 'init_project_creation',
    lookingFor: 'submit_project_create_looking_for',
    type: 'submit_project_create_project_type',
    name: 'submit_project_create_name',
    rates: 'submit_project_create_rates',
    dates: 'submit_project_create_dates',
    time: 'submit_project_create_start_time',
    location: 'submit_project_create_location',
    image: 'submit_project_create_image',
    notes: 'submit_project_create_notes',
    summary: 'submit_project_create_summary',
    tos: 'submit_project_create_tos',
    create: 'create_project',
    // TODO remove after redesigning direct booking responsive
    filters: 'submit_project_create_filters'
  },
  // rest actions from projectCreateFunnel
  guestPostingFunnel: {
    init: 'init_project_creation',
    guestName: 'submit_user_create_name',
    guestOauth: 'submit_user_create_with_oauth',
    guestEmail: 'submit_user_create_with_email',
    guestGeneral: 'submit_user_create_general_info',
    guestPhone: 'submit_user_create_phone',
    guestCompany: 'submit_user_create_company_name',
    guestSocial: 'submit_user_create_social',
    shareReferral: 'share_referral',
    finalizeGuestPostingAB: 'finalize_guest_posting_ab'
  },
  userCreateFunnel: {
    init: 'init_user_creation',
    emailContinue: 'submit_user_create_with_email',
    generalInfo: 'submit_user_create_general_info',
    profile: 'submit_user_create_type',
    intention: 'submit_user_create_intent_role',
    location: 'submit_user_create_location',
    phone: 'submit_user_create_phone',
    company: 'submit_user_create_company_name',
    website: 'submit_user_create_website',
    social: 'submit_user_create_social',
    referral: 'submit_user_create_referral',
    additionalInfo: 'submit_user_create_additional_info',
    image: 'submit_user_logo',
    complete: 'complete_sign_up'
  },
  job: {
    acceptInvitation: 'accept_job_invitation',
    chooseApplicant: 'choose_applicant',
    selectPM: 'select_payment_method',
    confirmHire: 'confirm_job_hire'
  },
  clickButton: 'click_button',
  submitCalendlyForm: 'submit_calendly_form',
  search: {
    showFilters: 'show_filters',
    applyFilter: 'apply_filter',
    clickFilter: 'click_filter'
  }
};

const sendMetric = (action, params) => {
  const prestineParams = {
    client_type: getDeviceType(),
    ...params
  };

  if (!action) return console.warn('action missing');
  console.log('sendMetric', action, prestineParams);
  window.gtag('event', action, prestineParams);
  // window.dataLayer = window.dataLayer || [];
  // window.dataLayer.push({ event: action, ...prestineParams });
  // GA4.event({ ...prestineParams, action });
};

const SC_GA4 = {
  sendMetric,

  // type: profile_type | location | social, value: string | { min: integer, max: integer }
  sendSearchApplyFilter(type, value) {
    sendMetric(actions.search.applyFilter, {
      ...(isObject(value) ? { min: value.min, max: value.max } : { value }),
      type,
      version: ApplicationHelper.isMobile
        ? Settings.mobileSearchVersion
        : Settings.desktopSearchVersion
    });
  },

  // type: profile_type | location | social,
  sendSearchClickFilter(type) {
    sendMetric(actions.search.clickFilter, {
      type,
      version: ApplicationHelper.isMobile
        ? Settings.mobileSearchVersion
        : Settings.desktopSearchVersion
    });
  },
  buildSendSearchClickFilter(type) {
    return () => this.sendSearchClickFilter(type);
  }
};

export default SC_GA4;
