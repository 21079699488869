import React from 'react';

import Text from 'components/reusable/text';
import styled from 'styled-components';
import { colors } from 'helpers/colors';
import Link from 'components/reusable/link';

function ShareOfferLinks({ links }) {
  return (
    <LinksWrapper>
      {links.map(({ label, icon, ...link }) => (
        <StyledLink key={label} {...link}>
          <Text size={14}>{label}</Text>
          {icon}
        </StyledLink>
      ))}
    </LinksWrapper>
  );
}

const LinksWrapper = styled.div`
  width: 100%;
  > :not(:last-child) {
    border-bottom: 1px solid ${colors.gray[100]};
    padding-bottom: 16px;
  }
  > :not(:first-child) {
    margin-top: 16px;
  }

  @media (max-width: 768px) {
    > :not(:last-child) {
      padding-bottom: 4px;
    }
  }
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export default ShareOfferLinks;
